<template>
	<div class="contentLayout">
		<div class="contentNavigationBar">
			<h2 class="title">만족도조사 관리</h2>
		</div>
		<div class="contentBody">
			<h3 class="contentTitle">만족도조사 등록</h3>
			<div class="infoWrap">
				<table class="infoTable marginBottom-35 marginTop-15">
					<tbody>
						<tr>
							<th style="width:150px;">만족도조사구분</th>
							<td>
								<select
									style="width: 250px"
									class="selectBox"
									v-model="item.surveyDivCode"
									:onchange="selectProgram()"
								>
									<option value="1">
										모유수유담당자교육
									</option>
									<option value="2">만족도조사지원</option>
								</select>
							</td>
							<th style="width:150px;">
								<span v-if="item.surveyDivCode == 1"
									>교육프로그램</span
								>
							</th>
							<td>
								<select
									v-if="item.surveyDivCode == 1"
									style="width: 250px"
									class="selectBox"
									v-model="item.programNo"
									data-vv-name="programNo"
									data-vv-as="교육프로그램 "
									v-validate="'required'"
								>
									<option value="">-- 선택 --</option>
									<option
										v-for="row in programList"
										:key="row.programNo"
										:value="row.programNo"
									>
										{{ row.programName }}
									</option>
								</select>
								<p
									class="validation-text"
									v-if="errors.has('programNo')"
								>
									* {{ errors.first("programNo") }}
								</p>
							</td>
						</tr>
						<tr>
							<th>만족도조사명</th>
							<td>
								<input
									style="width: 250px"
									type="text"
									class="inputBox"
									v-model="item.surveyName"
									data-vv-name="surveyName"
									data-vv-as="만족도조사명 "
									v-validate="'required'"
								/>
								<p
									class="validation-text"
									v-if="errors.has('surveyName')"
								>
									* {{ errors.first("surveyName") }}
								</p>
							</td>
							<th>만족도조사기간</th>
							<td>
								<flat-pickr
									class="inputBox"
									style="width: 110px"
									v-model="item.surveyStartDt"
									:config="{
										locale: require('flatpickr/dist/l10n/ko.js')
											.default.ko,
										allowInput: true,
									}"
									data-vv-name="surveyStartDt"
									data-vv-as="만족도조사시작일 "
									v-validate="'required'"
								/>
								<span style="width: 50px"
									>&nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;&nbsp;</span
								>
								<flat-pickr
									class="inputBox"
									style="width: 110px"
									v-model="item.surveyEndDt"
									:config="{
										locale: require('flatpickr/dist/l10n/ko.js')
											.default.ko,
										allowInput: true,
									}"
									data-vv-name="surveyEndDt"
									data-vv-as="만족도조사종료일 "
									v-validate="'required'"
								/>
								<p
									class="validation-text"
									v-if="errors.has('surveyStartDt')"
								>
									* {{ errors.first("surveyStartDt") }}
								</p>
								<p
									class="validation-text"
									v-if="errors.has('surveyEndDt')"
								>
									* {{ errors.first("surveyEndDt") }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<h3 class="contentTitle">
				만족도조사 항목
				<div class="buttonWrap floatRight">
					<button
						class="radiusSmallBtn bgTuftsBlue"
						style="border-radius: 5px; width: 100px"
						@click.prevent="addRadio()"
					>
						Add Radio
					</button>
					<button
						class="radiusSmallBtn bgTuftsBlue"
						style="border-radius: 5px; width: 100px"
						@click.prevent="addCheckBox()"
					>
						Add CheckBox
					</button>
					<button
						class="radiusSmallBtn bgTuftsBlue"
						style="border-radius: 5px; width: 100px"
						@click.prevent="addText()"
					>
						Add Text
					</button>
					<button
						class="radiusSmallBtn bgTuftsBlue"
						style="border-radius: 5px; width: 100px"
						@click.prevent="addTextarea()"
					>
						Add Textarea
					</button>
				</div>
			</h3>
			<div class="infoWrap">
				<table class="infoTable marginBottom-35 marginTop-15">
					<colgroup>
						<col style="width: 10%" />
						<col style="width: 10%" />
						<col style="width: 80%" />
					</colgroup>
					<thead>
						<tr>
							<th style="text-align: center">질문 구분</th>
							<th style="text-align: center">1줄 표현 갯수</th>
							<th style="text-align: center">질문/답변</th>
						</tr>
					</thead>
					<tbody
						v-for="(row, index) in questionList"
						:key="row.questionNo"
					>
						<tr v-if="row.questionTypeCode == 'RADIO'">
							<td style="text-align: center">
								{{ row.questionTypeCode }}
								<button
									class="radiusSmallBtn bgTuftsBlue"
									style="
										border-radius: 5px;
										width: 50px;
										margin-top: 10px;
									"
									@click.prevent="
										questionList.splice(index, 1)
									"
								>
									삭제
								</button>
							</td>
							<td>
								<select
									v-model="row.radioCols"
									style="width: 60px"
								>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6</option>
									<option value="7">7</option>
									<option value="8">8</option>
								</select>
							</td>
							<td>
								<input
									type="text"
									class="inputBox"
									v-model="row.questionName"
									placeholder="질문을 입력하세요."
									v-validate="'required'"
									data-vv-name="questionName"
									data-vv-as="질문 "
								/>
								<p
									class="validation-text"
									v-if="errors.has('questionName')"
								>
									* {{ errors.first("questionName") }}
								</p>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption1"
									placeholder="(1)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption2"
									placeholder="(2)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption3"
									placeholder="(3)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption4"
									placeholder="(4)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption5"
									placeholder="(5)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption6"
									placeholder="(6)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption7"
									placeholder="(7)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption8"
									placeholder="(8)"
								/>
							</td>
						</tr>
						<tr v-if="row.questionTypeCode == 'CHECK'">
							<td style="text-align: center">
								{{ row.questionTypeCode }}
								<button
									class="radiusSmallBtn bgTuftsBlue"
									style="
										border-radius: 5px;
										width: 50px;
										margin-top: 10px;
									"
									@click.prevent="
										questionList.splice(index, 1)
									"
								>
									삭제
								</button>
							</td>
							<td>
								<select
									v-model="row.checkboxCols"
									style="width: 60px"
								>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6</option>
									<option value="7">7</option>
									<option value="8">8</option>
								</select>
							</td>
							<td>
								<input
									type="text"
									class="inputBox"
									v-model="row.questionName"
									placeholder="질문을 입력하세요."
									v-validate="'required'"
									data-vv-name="questionName"
									data-vv-as="질문 "
								/>
								<p
									class="validation-text"
									v-if="errors.has('questionName')"
								>
									* {{ errors.first("questionName") }}
								</p>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption1"
									placeholder="(1)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption2"
									placeholder="(2)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption3"
									placeholder="(3)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption4"
									placeholder="(4)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption5"
									placeholder="(5)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption6"
									placeholder="(6)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption7"
									placeholder="(7)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption8"
									placeholder="(8)"
								/>
							</td>
						</tr>
						<tr v-if="row.questionTypeCode == 'TEXT'">
							<td style="text-align: center">
								{{ row.questionTypeCode }}
								<button
									class="radiusSmallBtn bgTuftsBlue"
									style="
										border-radius: 5px;
										width: 50px;
										margin-top: 10px;
									"
									@click.prevent="
										questionList.splice(index, 1)
									"
								>
									삭제
								</button>
							</td>
							<td></td>
							<td>
								<input
									type="text"
									class="inputBox"
									v-model="row.questionName"
									placeholder="질문을 입력하세요."
									v-validate="'required'"
									data-vv-name="questionName"
									data-vv-as="질문 "
								/>
								<p
									class="validation-text"
									v-if="errors.has('questionName')"
								>
									* {{ errors.first("questionName") }}
								</p>
								<input
									type="text"
									class="inputBox floatLeft"
									style="background-color: #ccc; width: 100%"
									placeholder="답변입력란"
									readonly="readonly"
								/>
							</td>
						</tr>
						<tr v-if="row.questionTypeCode == 'TEXTAREA'">
							<td style="text-align: center">
								{{ row.questionTypeCode }}
								<button
									class="radiusSmallBtn bgTuftsBlue"
									style="
										border-radius: 5px;
										width: 50px;
										margin-top: 10px;
									"
									@click.prevent="
										questionList.splice(index, 1)
									"
								>
									삭제
								</button>
							</td>
							<td></td>
							<td>
								<input
									type="text"
									class="inputBox"
									v-model="row.questionName"
									placeholder="질문을 입력하세요."
									v-validate="'required'"
									data-vv-name="questionName"
									data-vv-as="질문 "
								/>
								<p
									class="validation-text"
									v-if="errors.has('questionName')"
								>
									* {{ errors.first("questionName") }}
								</p>
								<textarea
									rows="5"
									class="inputBox floatLeft"
									style="
										background-color: #ccc;
										width: 100%;
										height: 60px;
									"
									placeholder="답변입력란"
									readonly="readonly"
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- 하단 버튼 바 -->
			<div class="contentButtonBar">
				<div class="buttonWrap">
					<button class="radiusSmallBtn bgNavy" @click="save()">
						저장
					</button>
					<button class="radiusSmallBtn bgDark" @click="cancel()">
						취소
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/themes/material_blue.css";

export default {
	name: "SurveyCreate",
	data() {
		return {
			siteNo: "",
			item: {
				surveyDivCode: 1,
				questionList: [],
			},
			programList: [],
			questionList: [],
		};
	},
	created() {
		this.logger.debug(this, "created()");
		this.siteNo = this.$route.params.siteNo;
	},
	components: { flatPickr },
	mounted() {},
	computed: {},
	methods: {
		selectProgram() {
			this.logger.debug(this, "selectProgram()");

			if (this.item.surveyDivCode == 1 && this.programList.length == 0) {
				axios
					.post(
						this.config.contextRoot +
							"/survey/selectProgramList.do",
						this.item
					)
					.then((response) => {
						console.log(response);
						if (response.status == 200) {
							this.programList = response.data.programList;
						}
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			}
		},
		addRadio() {
			this.questionList.push({
				questionTypeCode: "RADIO",
				radioCols: 2,
			});
		},
		addCheckBox() {
			this.questionList.push({
				questionTypeCode: "CHECK",
				checkboxCols: 2,
			});
		},
		addText() {
			this.questionList.push({
				questionTypeCode: "TEXT",
				textSize: "100",
			});
		},
		addTextarea() {
			this.questionList.push({
				questionTypeCode: "TEXTAREA",
				textareaRos: "5",
				textareaCols: "200",
			});
		},
		save() {
			this.logger.debug(this, "save()");

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "valid:" + result);
					if (!result) {
						alert("필수 입력항목을 모두 입력해 주세요.");
						return;
					} else {
						if (!confirm("저장 하시겠습니까?")) {
							return;
						}

						this.questionList.forEach((row) => {
							if (row.questionTypeCode == "RADIO") {
								row.radioOptions =
									row.radioOption1 +
									";" +
									row.radioOption2 +
									";" +
									row.radioOption3 +
									";" +
									row.radioOption4 +
									";" +
									row.radioOption5 +
									";" +
									row.radioOption6 +
									";" +
									row.radioOption7 +
									";" +
									row.radioOption8;
							}
							if (row.questionTypeCode == "CHECK") {
								row.checkboxOptions =
									row.checkboxOption1 +
									";" +
									row.checkboxOption2 +
									";" +
									row.checkboxOption3 +
									";" +
									row.checkboxOption4 +
									";" +
									row.checkboxOption5 +
									";" +
									row.checkboxOption6 +
									";" +
									row.checkboxOption7 +
									";" +
									row.checkboxOption8;
							}
						});

						this.item.questionList = this.questionList;

						axios
							.post(
								this.config.contextRoot + "/survey/insert.do",
								this.item
							)
							.then((response) => {
								if (response.status == 200) {
									alert("저장 되었습니다.");
									this.$router.replace(
										this.config.contextRoot +
											"/site/" +
											this.siteNo +
											"/survey"
									);
								}
							})
							.catch((error) => {
								this.exceptionHandler(this, error);
							});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.error.all());
					this.$alert(this.error.all());
					return false;
				});
		},

		cancel() {
			this.logger.debug(this, "cancel()");
			if (confirm("취소 하시겠습니까?")) {
				this.$router.go(-1);
			}
		},
	},
};
</script>
