import Vue from 'vue';
import VueRouter from 'vue-router';

import moment from 'moment';
/* 로그인 */
import Login from '@/components/login/Login';
/* 비밀번호 찾기 */
import FindPassword from '@/components/find/FindPassword';
/* 사이트정보*/
import SiteInfoLayout from '@/components/site/SiteInfoLayout';
/* 업무관리 */
/* 수유실 관리 */
import NursingroomLayout from '@/components/nursingroom/NursingroomLayout';
import NursingroomList from '@/components/nursingroom/NursingroomList';
import NursingroomCreate from '@/components/nursingroom/NursingroomCreate';
import NursingroomEdit from '@/components/nursingroom/NursingroomEdit';
/* 만족도조사 관리 */
import SurveyLayout from '@/components/survey/SurveyLayout';
import SurveyList from '@/components/survey/SurveyList';
import SurveyCreate from '@/components/survey/SurveyCreate';
import SurveyEdit from '@/components/survey/SurveyEdit';
/* 조사결과 관리 */
import AnswerLayout from '@/components/answer/AnswerLayout';
import AnswerList from '@/components/answer/AnswerList';
import AnswerEdit from '@/components/answer/AnswerEdit';
/* 댓글 관리(수유실)  */
import RoomCommentsLayout from '@/components/roomComments/RoomCommentsLayout';
import RoomCommentsList from '@/components/roomComments/RoomCommentsList';
/* 교육개설관리 */
import ProgramLayout from '@/components/program/ProgramLayout';
import ProgramList from '@/components/program/ProgramList';
import ProgramCreate from '@/components/program/ProgramCreate';
import ProgramEdit from '@/components/program/ProgramEdit';
/* 교육신청관리 */
import ApplicantLayout from '@/components/applicant/ApplicantLayout';
import ApplicantList from '@/components/applicant/ApplicantList';
import ApplicantEdit from '@/components/applicant/ApplicantEdit';
/* 등록요청관리 */
import RegistLayout from '@/components/regist/RegistLayout';
import RegistList from '@/components/regist/RegistList';
import RegistEdit from '@/components/regist/RegistEdit';
/* 설치지원관리 */
import SupportLayout from '@/components/support/SupportLayout';
import SupportList from '@/components/support/SupportList';
import SupportEdit from '@/components/support/SupportEdit';
/* 수정요청관리 */
import RequestLayout from '@/components/request/RequestLayout';
import RequestList from '@/components/request/RequestList';
import RequestEdit from '@/components/request/RequestEdit';
/* API관리 */
import ApiLayout from '@/components/api/ApiLayout';
import ApiList from '@/components/api/ApiList';
import ApiEdit from '@/components/api/ApiEdit';
/* 게시판 */
import ApilogLayout from '@/components/apilog/ApilogLayout';
import ApilogList from '@/components/apilog/ApilogList';
import ApilogEdit from '@/components/apilog/ApilogEdit';
/* 회원관리 */
import MemberLayout from '@/components/member/MemberLayout';
import MemberList from '@/components/member/MemberList';
import MemberCreate from '@/components/member/MemberCreate';
import MemberEdit from '@/components/member/MemberEdit';
/* 메뉴관리 */
import MenuLayout from '@/components/menu/MenuLayout';
import MenuList from '@/components/menu/MenuList';
import MenuCreate from '@/components/menu/MenuCreate';
import MenuEdit from '@/components/menu/MenuEdit';
/* 레이아웃 */
import LayoutLayout from '@/components/layout/LayoutLayout';
import LayoutList from '@/components/layout/LayoutList';
import LayoutCreate from '@/components/layout/LayoutCreate';
import LayoutEdit from '@/components/layout/LayoutEdit';
/* 게시판 */
import BoardLayout from '@/components/board/BoardLayout';
import BoardList from '@/components/board/BoardList';
import BoardCreate from '@/components/board/BoardCreate';
import BoardEdit from '@/components/board/BoardEdit';
/* 스킨관리 */
import SkinLayout from '@/components/skin/SkinLayout';
import SkinList from '@/components/skin/SkinList';
import SkinCreate from '@/components/skin/SkinCreate';
import SkinEdit from '@/components/skin/SkinEdit';
/* 게시글 관리 */
import ArticleLayout from '@/components/article/ArticleLayout';
import ArticleList from '@/components/article/ArticleList';
import ArticleCreate from '@/components/article/ArticleCreate';
import ArticleEdit from '@/components/article/ArticleEdit';
/* 컨텐츠관리 */
import ContentsLayout from '@/components/contents/ContentsLayout';
import ContentsList from '@/components/contents/ContentsList';
import ContentsCreate from '@/components/contents/ContentsCreate';
import ContentsEdit from '@/components/contents/ContentsEdit';
/* 에셋 관리 */
import AssetLayout from '@/components/asset/AssetLayout';
import AssetList from '@/components/asset/AssetList';
/* 배너 관리 */
import BannerLayout from '@/components/banner/BannerLayout';
import BannerList from '@/components/banner/BannerList';
import BannerCreate from '@/components/banner/BannerCreate';
import BannerEdit from '@/components/banner/BannerEdit';
/* 팝업 관리 */
import PopupLayout from '@/components/popup/PopupLayout';
import PopupList from '@/components/popup/PopupList';
import PopupCreate from '@/components/popup/PopupCreate';
import PopupEdit from '@/components/popup/PopupEdit';
/* 방문자 통계 */
import VisitorLayout from '@/components/visitor/VisitorLayout';

Vue.use(VueRouter);

//Config
Vue.prototype.config = {
    contextRoot: '/osolit-cms',
    proxyServerUrl: window.location.protocol +
        '//' +
        window.location.hostname +
        ':' +
        window.location.port,
};

const routes = [{
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/applicant',
        component: ApplicantLayout,
        children: [{
                path: '/',
                component: ApplicantList
            },
            {
                path: ':applicantNo/edit',
                component: ApplicantEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/regist',
        component: RegistLayout,
        children: [{
                path: '/',
                component: RegistList
            },
            {
                path: ':supportNo/edit',
                component: RegistEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/support',
        component: SupportLayout,
        children: [{
                path: '/',
                component: SupportList
            },
            {
                path: ':supportNo/edit',
                component: SupportEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/request',
        component: RequestLayout,
        children: [{
                path: '/',
                component: RequestList
            },
            {
                path: ':requestNo/edit',
                component: RequestEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/api',
        component: ApiLayout,
        children: [{
                path: '/',
                component: ApiList
            },
            {
                path: ':applicantNo/edit',
                component: ApiEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/apilog',
        component: ApilogLayout,
        children: [{
                path: '/',
                component: ApilogList
            },
            {
                path: ':confirmApiKey/:useDt/:useNo/edit',
                component: ApilogEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/program',
        component: ProgramLayout,
        children: [{
                path: '/',
                component: ProgramList
            },
            {
                path: 'create',
                component: ProgramCreate
            },
            {
                path: ':programNo/edit',
                component: ProgramEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo',
        //component: SiteInfoLayout,
        redirect: Vue.prototype.config.contextRoot + '/site/:siteNo/nursingroom',
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/siteInfo',
        component: SiteInfoLayout,
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/business',
        redirect: Vue.prototype.config.contextRoot + '/site/:siteNo/nursingroom',
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/survey',
        component: SurveyLayout,
        children: [{
                path: '/',
                component: SurveyList
            },
            {
                path: 'create',
                component: SurveyCreate
            },
            {
                path: ':surveyNo/edit',
                component: SurveyEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/answer',
        component: AnswerLayout,
        children: [{
                path: '/',
                component: AnswerList
            },
            {
                path: ':surveyNo/:surveyDivCode/:userNo/edit',
                component: AnswerEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/nursingroom',
        component: NursingroomLayout,
        children: [{
                path: '/',
                component: NursingroomList
            },
            {
                path: 'create',
                component: NursingroomCreate
            },
            {
                path: ':topRoomNo/edit',
                component: NursingroomEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/roomComments',
        component: RoomCommentsLayout,
        children: [{
            path: '/',
            component: RoomCommentsList
        }]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/member',
        component: MemberLayout,
        children: [{
                path: '/',
                component: MemberList
            },
            {
                path: 'create',
                component: MemberCreate
            },
            {
                path: ':userNo/edit',
                component: MemberEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/menu',
        component: MenuLayout,
        children: [{
                path: '/',
                component: MenuList
            },
            {
                path: 'create',
                component: MenuCreate
            },
            {
                path: ':menuNo/edit',
                component: MenuEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/layout',
        component: LayoutLayout,
        children: [{
                path: '/',
                component: LayoutList
            },
            {
                path: 'create',
                component: LayoutCreate
            },
            {
                path: ':layoutNo/edit',
                component: LayoutEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/board',
        component: BoardLayout,
        children: [{
                path: '/',
                component: BoardList
            },
            {
                path: 'create',
                component: BoardCreate
            },
            {
                path: ':boardNo/edit',
                component: BoardEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/skin',
        component: SkinLayout,
        children: [{
                path: '/',
                component: SkinList
            },
            {
                path: 'create',
                component: SkinCreate
            },
            {
                path: ':skinNo/edit',
                component: SkinEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/article',
        component: ArticleLayout,
        children: [{
                path: '/',
                component: ArticleList
            },
            {
                path: 'create',
                component: ArticleCreate
            },
            {
                path: ':articleNo/edit',
                component: ArticleEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/contents',
        component: ContentsLayout,
        children: [{
                path: '/',
                component: ContentsList
            },
            {
                path: 'create',
                component: ContentsCreate
            },
            {
                path: ':contentsNo/edit',
                component: ContentsEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/asset',
        component: AssetLayout,
        children: [{
            path: '/',
            component: AssetList
        }, ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/banner',
        component: BannerLayout,
        children: [{
                path: '/',
                component: BannerList
            },
            {
                path: 'create',
                component: BannerCreate
            },
            {
                path: ':bannerNo/edit',
                component: BannerEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/popup',
        component: PopupLayout,
        children: [{
                path: '/',
                component: PopupList
            },
            {
                path: 'create',
                component: PopupCreate
            },
            {
                path: ':popupNo/edit',
                component: PopupEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/visitorStats',
        component: VisitorLayout,
    },
    {
        path: Vue.prototype.config.contextRoot + '/findPassword',
        component: FindPassword
    },
    { path: Vue.prototype.config.contextRoot + '/login', component: Login },
    {
        path: Vue.prototype.config.contextRoot + '*',
        redirect: Vue.prototype.config.contextRoot + '/login',
    },
];

//UserInfo
Vue.prototype.userInfo = {
    loginId: sessionStorage.getItem('loginId'),
    userName: sessionStorage.getItem('userName'),
    roleName: sessionStorage.getItem('roleName'),
    isAdmin: function() {
        return sessionStorage.getItem('roleName') !== undefined &&
            sessionStorage.getItem('roleName').indexOf('ROLE_ADMIN') >= 0 ?
            true :
            false;
    },
};

//LogLevel
Vue.prototype.logLevel = 'debug'; //debug, warn, error, none

//Logger
Vue.prototype.logger = {
    debug: function(obj, args) {
        if (Vue.prototype.logLevel == 'debug') {
            var exportName = '';
            if (obj instanceof String) {
                exportName = obj;
            } else {
                exportName = obj.$options.name;
            }
            console.log('[' + exportName + ']' + args);
        }
    },
    warn: function(obj, args) {
        if (Vue.prototype.logLevel == 'debug' || Vue.prototype.logLevel == 'warn') {
            var exportName = '';
            if (obj instanceof String) {
                exportName = obj;
            } else {
                exportName = obj.$options.name;
            }
            console.log('[' + exportName + ']' + args);
        }
    },
    error: function(obj, args) {
        if (
            Vue.prototype.logLevel == 'debug' ||
            Vue.prototype.logLevel == 'warn' ||
            Vue.prototype.logLevel == 'error'
        ) {
            var exportName = '';
            if (obj instanceof String) {
                exportName = obj;
            } else {
                exportName = obj.$options.name;
            }
            console.log('[' + exportName + ']' + args);
        }
    },
};

//Utils
Vue.prototype.utils = {
    isNull: function(args) {
        if (args === undefined || args == null || args == '') {
            return true;
        } else {
            return false;
        }
    },
    isEmpty: function(args) {
        return this.isNull(args);
    },
    formatDate(value) {
        return moment(value).format('YYYY-MM-DD');
    },
};

// NavigationDuplicated 대응
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
            throw err;
        }
    });
};

//AXIOS ExceptionHandler
Vue.prototype.exceptionHandler = function(obj, errors) {
    Vue.prototype.logger.debug(obj, errors);
    console.log(errors.response);

    if(!Vue.prototype.utils.isEmpty(errors.response)){
        if (!Vue.prototype.utils.isEmpty(errors.response.status)) {
            let statusCode = errors.response.status;
            let errorData = Vue.prototype.utils.isEmpty(errors.response.data.error) ?
                errors.response.status :
                errors.response.data.error;
            let message = Vue.prototype.utils.isEmpty(errors.response.data.message) ?
                errors.response.statusText :
                errors.response.data.message;

            if (statusCode == 403) {
                alert('[' + errorData + ']' + message);

                sessionStorage.setItem('loginId', '');
                sessionStorage.setItem('userName', '');
                sessionStorage.setItem('roleName', '');
                Vue.prototype.userInfo = {};
                this.$router.push(Vue.prototype.config.contextRoot);
            } else {
                alert('[' + errorData + ']' + message);

                //this.$router.push(Vue.prototype.config.contextRoot);
            }
        }
    }
};

console.log(Vue.prototype.config.proxyServerUrl);
console.log(Vue.prototype.config.contextRoot);

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    console.log('[router.js]path:' + from.path + ' --> ' + to.path);
    console.log('this.userInfo.loginId:' + Vue.prototype.userInfo.loginId);

    if (
        to.path.indexOf('/login') == -1 &&
        (Vue.prototype.utils.isNull(sessionStorage.getItem('roleName')) ||
            sessionStorage.getItem('roleName') == 'ROLE_ANONYMOUS')
    ) {
        Vue.prototype.userInfo = {};
        sessionStorage.setItem('roleName', '');
        sessionStorage.setItem('loginId', '');
        sessionStorage.setItem('userName', '');
        next(Vue.prototype.config.contextRoot + '/login');
    } else {
        next();
    }
});

export default router;