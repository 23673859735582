<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">수정요청 관리</h2>
        </div>
        <div class="contentBody">
            <!-- 검색 -->
            <div class="searchBar">
                <div class="columnDivision-3">
                    <div class="labelWrap" style="margin-bottom:10px;">
                        <label>이름</label>
                        <input
                            type="text"
                            class="inputBox"
                            v-model="searchForm.requestUserName"
                        />
                    </div>
                    <div class="labelWrap">
                        <label style="width:200px">광역시/도 & 시/군/구</label>
                        <select
                            class="selectBox"
                            v-model="address"
                            style="width:100px; margin-right:10px;"
                            @change="selectComCodeList()"
                        >
                            <option value="">광역시/도</option>
                            <option :key="idx1" :value="adress1.comCode" v-for="(adress1, idx1) in addressList1">{{ adress1.codeName }}</option>
                        </select>
                        <select
                            class="selectBox"
                            v-model="addressDetail"
                            style="width:100px"
                        >
                            <option value="">시/군/구</option>
                            <option :key="idx2" :value="adress2.comCode" v-for="(adress2, idx2) in addressList2">{{ adress2.codeName }}</option>
                        </select>
                    </div>

                    <br/>

                    <div class="labelWrap" style="margin-bottom:10px;">
                        <label>단체명</label>
                        <input
                            type="text"
                            class="inputBox"
                            v-model="searchForm.requestOrganization"
                        />
                    </div>
                    
                    <br/>
                        
                    <div class="radioBoxWrap" style="margin-bottom:15px; margin-left:25px;">
                        <label>처리현황</label>
                        <label >
                            <input
                                type="radio"
                                class="radioBox"
                                name="testRadio-7"
                                v-model="
                                    searchForm.completeCode
                                "
                                value=""
                            />
                            <span>전체</span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                class="radioBox"
                                name="testRadio-7"
                                v-model="
                                    searchForm.completeCode
                                "
                                value="0"
                            />
                            <span>접수</span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                class="radioBox"
                                name="testRadio-7"
                                v-model="
                                    searchForm.completeCode
                                "
                                value="1"
                            />
                            <span>반려</span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                class="radioBox"
                                name="testRadio-7"
                                v-model="
                                    searchForm.completeCode
                                "
                                value="2"
                            />
                            <span>완료</span>
                        </label>
                    </div>

                    <br/>

                    <div class="labelWrap">
                        <label>등록일</label>
                        <div class="datePicker">
                            <input
                                type="text"
                                class="inputBox"
                                placeholder="연도-월-일"
                                id="startDate"
                                v-model="searchForm.startDate"
                                autocomplete="off"
                            />
                            <div
                                ref="startDatePicker"
                                id="startDatePicker"
                            ></div>
                            <i></i>
                        </div>
                    </div>
                    <div class="labelWrap">
                        <label class="textCenter" style="width: 30px">-</label>
                        <div class="datePicker">
                            <input
                                type="text"
                                class="inputBox"
                                placeholder="연도-월-일"
                                id="endDate"
                                v-model="searchForm.endDate"
                                autocomplete="off"
                            />
                            <i></i>
                            <div ref="endDatePicker" id="endDatePicker"></div>
                        </div>
                    </div>
                    
                </div>
                <button class="searchBtn" @click="doSearchPage(1)">
                    조회하기
                </button>
            </div>
            <div class="contentTitleWrap marginTop-35">
                <h3 class="contentTitle">수정요청 목록</h3>
            </div>
            <div class="listWrap">
                <!-- 목록 상단 -->
                <div class="listTop">
                    <span class="listTotal"
                        >(Total <em>{{ pagination.total }}</em
                        >)</span
                    >
                    <button
                        class="radiusSmallBtn bgBlue"
                        @click="downloadExcel()"
                        style="margin-left:10px;"
                    >
                        Excel (전체)
                    </button>
                    <select
                        class="selectBox"
                        @change.prevent="doSearchPage(1)"
                        v-model="searchForm.countPerPage"
                    >
                        <option>5</option>
                        <option selected>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                </div>

                <!-- 목록테이블 -->
                <table class="listTable tableFixed">
                    <colgroup>
                        <col style="width: 2%" />
                        <col style="width: 5%" />
                        <col style="width: 5%" />
                        <col style="width: 10%" />
                        <col style="width: 10%" />
                        <col style="width: 25%" />
                        <col style="width: 30%" />
                        <col style="width: 8%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <label>
                                    <input
                                        type="checkbox"
                                        @click="checkAll"
                                        v-model="allChecked"
                                    />
                                </label>
                            </th>
                            <th>요청번호</th>
                            <th>이름</th>
                            <th>단체명</th>
                            <th>연락처</th>
                            <th>주소</th>
                            <th>등록일<br/>수정일</th>
                            <th>접수현황</th>
                        </tr>
                    </thead>
                    <tbody
                        v-for="request in requestList"
                        :key="request.requestNo"
                        @click="
                            $router.push({
                                path:
                                    config.contextRoot +
                                    '/site/' +
                                    $route.params.siteNo +
                                    '/request/' +
                                    request.requestNo +
                                    '/edit',
                            })
                        "
                    >
                        <tr
                        >
                            <td>
                                <label
                                    ><input
                                        type="checkbox"
                                        v-model="deleteRequestNoList"
                                        :value="request.requestNo"
                                        @click.stop=""
                                    />
                                </label>
                            </td>
                            <td>{{ request.requestNo }}</td>
                            <td>{{ request.requestUserName }}</td>
                            <td>
                                {{ request.requestOrganization }}
                            </td>
                            <td>
                                {{ request.requestTelNo }}
                            </td>
                            <td>
                                {{ request.address1 }}
                                {{ request.address2 ? ", " + request.address2 : ""}}
                            </td>
                            <td>
                                {{ request.firstRegistDate }}
                                <br/>
                                {{ request.lastModifyDate }}
                            </td>
                            <td v-if="request.completeCode != '0' && request.completeCode != '1' && request.completeCode != '2'">없음</td>
                            <td v-if="request.completeCode == '0'">접수</td>
                            <td v-if="request.completeCode == '1'">반려</td>
                            <td v-if="request.completeCode == '2'">완료</td>
                        </tr>
                    </tbody>
                </table>

                <!-- 페이지네이션 -->
				<pagination
					v-if="pagination.total > pagination.per_page"
					v-model="pagination.current_page"
					:records="pagination.total"
					:per-page="pagination.per_page"
					@paginate="doSearchPage"
					:options="{
						texts: {
							count: '',
							first: '',
							last: '',
						},
					}"
				/>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgTuftsBlue"
                        @click.prevent="deleteRequestList()"
                    >
                        선택 삭제
                    </button>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import axios from "axios";
import "tui-date-picker/dist/tui-date-picker.min.css";
import DatePicker from "tui-date-picker";

import Pagination from "vue-pagination-2";

export default {
    name: "RequestList",
    components: { Pagination, }, 
    data() {
        return {
            siteNo: "",
            requestList: [],
            pagination: {},
            searchForm: {
                requestUserName : "",
                address1 : "",
                requestOrganization : "",
                completeCode : "",
                startDate : "",
                endDate : "",
                countPerPage: 10,
                pageNo: 1,
            },

            address : "",
            addressDetail : "",

            displayPageCount: 10,
            isPrev: "",
            isNext: "",
            startPageNo: "",
            endPageNo: "",
            realEndPageNo: "",
            pageNoList: [],
            deleteRequestNoList: [],
            allChecked: false,
            isShowRecruitmentUseYn: false,

            programList: [],
            addressList1: [],
            addressList2: [],

        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.selectRequestList();
        this.selectComCodeList();
    },
    watch: {
        deleteRequestNoList() {
            if (this.requestList.length != this.deleteRequestNoList.length) {
                this.allChecked = false;
            } else {
                this.allChecked = true;
            }
        },
    },
    mounted() {
        // 데이트피커
        var today = new Date();
        const rangePicker = new DatePicker.createRangePicker({
            startpicker: {
                date: today,
                input: "#startDate",
                container: "#startDatePicker",
            },
            endpicker: {
                date: today,
                input: "#endDate",
                container: "#endDatePicker",
            },
            language: "ko",
        });
        rangePicker.on("change:start", () => {
            this.searchForm.startDate =
                document.getElementById("startDate").value;
        });
        rangePicker.on("change:end", () => {
            this.searchForm.endDate = document.getElementById("endDate").value;
        });
    },
    methods: {
        selectRequestList() {
            this.logger.debug(this, "selectRequestList()");
            this.searchForm.siteNo = this.siteNo;

            this.searchForm.address1 = this.address;
            if(this.searchForm.address1){
                this.searchForm.address1 = this.searchForm.address1 + " " + this.addressDetail;
            }
            axios
                .post(
                    this.config.contextRoot + "/request/selectRequestList.do",
                    this.searchForm
                )
                .then((response) => {
                    this.requestList = response.data.requestList;
                    this.pagination = response.data.pagination;
                    this.calcPagination();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        selectComCodeList() {
            this.logger.debug(this, "selectComCodeList()");
            this.searchForm.siteNo = this.siteNo;
            axios
                .post(
                    this.config.contextRoot + "/common/code/selectComCodeList.do",
                    {classCode : this.address ? this.address : "LOCATION_CODE"}
                )
                .then((response) => {
                    if(!this.address){
                        this.addressList1 = response.data.codeList;
                        this.addressList2 = [];
                        this.addressDetail = "";
                    }else{
                        this.addressList2 = response.data.codeList;
                        this.addressDetail = "";
                    }
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        doSearchPage(pageNo) {
            this.logger.debug(this, "doSearchPage()");
            if (typeof pageNo === "undefined") {
                pageNo = 1;
            }
            this.deleteRequestNoList = [];
            this.searchForm.pageNo = pageNo;
            this.selectRequestList();
        },
        calcPagination() {
            this.logger.debug(this, "calcPagination()");
            this.endPageNo =
                Math.ceil(
                    this.pagination.current_page / this.displayPageCount
                ) * this.displayPageCount;
            this.startPageNo = this.endPageNo - this.displayPageCount + 1;

            this.realEndPageNo = Math.ceil(
                this.pagination.total / this.pagination.per_page
            );

            if (this.startPageNo < 0) {
                this.startPageNo = 1;
            }
            if (this.endPageNo > this.realEndPageNo) {
                this.endPageNo = this.realEndPageNo;
            }

            this.isPrev = this.startPageNo == 1 ? false : true;
            this.isNext =
                this.endPageNo * this.pagination.per_page >=
                this.pagination.total
                    ? false
                    : true;

            this.pageNoList = [];
            for (let i = this.startPageNo; i <= this.endPageNo; i++) {
                this.pageNoList.push(i);
            }
        },
        checkAll() {
            this.deleteRequestNoList = [];

            if (!this.allChecked) {
                for (let request of this.requestList) {
                    this.deleteRequestNoList.push(request.requestNo);
                }
            }

            this.allChecked = !this.allChecked;
        },
        deleteRequestList() {
            if (this.deleteRequestNoList.length == 0) {
                alert("삭제항목이 선택되지 않았습니다.");
                return;
            }

            if (!confirm("선택한 항목을 삭제 하시겠습니까?")) {
                return;
            }
            
            const formData = { deleteRequestNoList: this.deleteRequestNoList};

            axios
                .post(
                    this.config.contextRoot + "/request/deleteRequestList.do",
                    formData
                )
                .then((response) => {
                    if (response.status == 200) {
                        // 마지막 페이지에서 전체 선택 삭제할 경우
                        if (
                            this.startPageNo != this.realEndPageNo &&
                            this.searchForm.pageNo == this.realEndPageNo &&
                            this.deleteRequestNoList.length ==
                                this.requestList.length
                        ) {
                            this.$set(
                                this.searchForm,
                                "pageNo",
                                Number(this.searchForm.pageNo) - 1 + ""
                            );
                        }

                        alert("삭제 되었습니다.");
                        this.deleteRequestNoList = [];
                        this.doSearchPage(this.searchForm.pageNo);
                    }
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        openImageRequest(selectRequest){
            
            let html = "";

            let url = this.config.contextRoot + "/common/file/imgLoad.do?"
                    + "fileGroupNo=" + selectRequest.fileGroupNo
                    + "&serverFileSaveName=" + selectRequest.serverFileSaveName;
            html = "<img src=" + url + ">";

            if(selectRequest.requestContents) html += "<br><div>" + selectRequest.requestContents + "</div>";

            let specs = 'menubar=no';
            if(selectRequest.requestPositionX) specs += ", left=" + selectRequest.requestPositionX;
            if(selectRequest.requestPositionY) specs += ", top=" + selectRequest.requestPositionY;
            if(selectRequest.requestSizeX) specs += ", width=" + selectRequest.requestSizeX;
            if(selectRequest.requestSizeY) specs += ", height=" + selectRequest.requestSizeY;

            var OpenWindow = window.open("", "_blank", specs);
            OpenWindow.document.write(html);
        },
        yyyyMMdd(value){
            // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
            if(value == '') return '';

            // 연도, 월, 일 추출
            var year = value.substring(0, 4);
            var month = value.substring(4, 6);
            var day = value.substring(6, 8);

            // 최종 포맷 (ex - '2021-10-08')
            return year + '-' + month + '-' + day;
        },
        downloadExcel(){
            
            axios
                .post(
                    this.config.contextRoot + "/request/downloadExcel.do",
                    {},
                    { responseType: 'blob'},
                )
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '수유/착유실 수정요청 목록.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        
    },
};
</script>

<style>
</style>