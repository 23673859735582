<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">교육개설 관리</h2>
        </div>
        <div class="contentBody">
            <!-- 검색 -->
            <div class="searchBar">
                <div class="columnDivision-3">
                    <div class="labelWrap">
                        <label>교육명</label>
                        <input
                            type="text"
                            class="inputBox"
                            v-model="searchForm.programName"
                        />
                    </div>
                    <div class="labelWrap">
                        <label>교육장소</label>
                        <input
                            type="text"
                            class="inputBox"
                            v-model="searchForm.programLocation"
                        />
                    </div>
                </div>
                <button class="searchBtn" @click="doSearchPage(1)">
                    조회하기
                </button>
            </div>
            <div class="contentTitleWrap marginTop-35">
                <h3 class="contentTitle">교육개설 목록</h3>
            </div>
            <div class="listWrap">
                <!-- 목록 상단 -->
                <div class="listTop">
                    <span class="listTotal"
                        >(Total <em>{{ pagination.total }}</em
                        >)</span
                    >
                    <button
                        class="radiusSmallBtn bgBlue"
                        @click="downloadExcel()"
                        style="margin-left:10px;"
                    >
                        Excel (전체)
                    </button>
                    <select
                        class="selectBox"
                        @change.prevent="doSearchPage(1)"
                        v-model="searchForm.countPerPage"
                    >
                        <option>5</option>
                        <option selected>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                </div>

                <!-- 목록테이블 -->
                <table class="listTable tableFixed">
                    <colgroup>
                        <col style="width: 5%" />
                        <col style="width: 6%" />
                        <col style="width: 20%" />
                        <col style="width: 20%" />
                        <col style="width: 15%" />
                        <col style="width: 20%" />
                        <col style="width: 6%" />
                        <col style="width: 6%" />
                        <col style="width: 10%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <label>
                                    <input
                                        type="checkbox"
                                        @click="checkAll"
                                        v-model="allChecked"
                                    />
                                </label>
                            </th>
                            <th>교육번호</th>
                            <th>교육명</th>
                            <th>신청기간</th>
                            <th>교육장소</th>
                            <th>교육기간</th>
                            <th>모집인원</th>
                            <th>신청인원</th>
                            <th>등록일</th>
                        </tr>
                    </thead>
                    <tbody
                        v-for="program in programList"
                        :key="program.programNo"
                        @click="
                            $router.push({
                                path:
                                    config.contextRoot +
                                    '/site/' +
                                    $route.params.siteNo +
                                    '/program/' +
                                    program.programNo +
                                    '/edit',
                            })
                        "
                    >
                        <tr
                        >
                            <td>
                                <label
                                    ><input
                                        type="checkbox"
                                        v-model="deleteProgramNoList"
                                        :value="program.programNo"
                                        @click.stop=""
                                    />
                                </label>
                            </td>
                            <td>{{ program.programNo }}</td>
                            <td class="textEllipsis textLeft">
                                {{ program.programName }}
                            </td>
                            <td>
                                {{ 
                                program.applicantStartDt && program.applicantEndDt
                                ? yyyyMMdd(program.applicantStartDt) + " ~ " + yyyyMMdd(program.applicantEndDt)
                                : "기간설정필요"
                                }}
                            </td>
                            <td>
                                {{ program.programLocation }}
                            </td>
                            <td>
                                {{ 
                                program.eduStartDt && program.eduEndDt
                                ? yyyyMMdd(program.eduStartDt) + " ~ " + yyyyMMdd(program.eduEndDt)
                                : "기간설정필요"
                                }}
                            </td>
                            <td>
                                {{ program.eduQuota }}
                            </td>
                            <td>
                                {{ program.applicantCount }}
                            </td>
                            <td>
                                {{ program.firstRegistDate }}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- 페이지네이션-->
                <div
                    class="pagination marginTop-50"
                    v-if="pageNoList && pageNoList.length > 0"
                >
                    <ul>
                        <li>
                            <a
                                href="#"
                                @click.prevent="doSearchPage(1)"
                                title="첫 페이지"
                            >
                                <img
                                    src="@/assets/images/component/pagination-first.png"
                                    alt="첫 페이지"
                                />
                            </a>
                        </li>
                        <li v-show="isPrev">
                            <a
                                href="#"
                                @click.prevent="doSearchPage(startPageNo - 1)"
                                :title="'이전' + displayPageCount + '페이지'"
                            >
                                <img
                                    src="@/assets/images/component/pagination-prev.png"
                                    :alt="'이전' + displayPageCount + '페이지'"
                                />
                            </a>
                        </li>
                        <li v-for="pageNo in pageNoList" :key="pageNo">
                            <a
                                href="#"
                                :class="
                                    pagination.current_page == pageNo
                                        ? 'active'
                                        : ''
                                "
                                @click.prevent="doSearchPage(pageNo)"
                            >
                                {{ pageNo }}
                            </a>
                        </li>
                        <li v-show="isNext">
                            <a
                                href="#"
                                @click.prevent="doSearchPage(endPageNo + 1)"
                                :title="'다음' + displayPageCount + '페이지'"
                            >
                                <img
                                    src="@/assets/images/component/pagination-next.png"
                                    :alt="'다음' + displayPageCount + '페이지'"
                                />
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                @click.prevent="doSearchPage(realEndPageNo)"
                                title="마지막 페이지"
                            >
                                <img
                                    src="@/assets/images/component/pagination-last.png"
                                    alt="마지막 페이지"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot + '/site/1/program/create',
                        }"
                        class="radiusSmallBtn bgBlue"
                    >
                        신규
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgTuftsBlue"
                        @click.prevent="deleteProgramList()"
                    >
                        선택 삭제
                    </button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import axios from "axios";
import "tui-date-picker/dist/tui-date-picker.min.css";

export default {
    name: "ProgramList",
    components: {}, 
    data() {
        return {
            siteNo: "",
            programList: [],
            pagination: {},
            searchForm: {
                programName: "",
                programLocation: "",
                countPerPage: 10,
                pageNo: 1,
            },
            displayPageCount: 10,
            isPrev: "",
            isNext: "",
            startPageNo: "",
            endPageNo: "",
            realEndPageNo: "",
            pageNoList: [],
            deleteProgramNoList: [],
            allChecked: false,
            isShowRecruitmentUseYn: false,

        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.selectProgramList();
    },
    watch: {
        deleteProgramNoList() {
            if (this.programList.length != this.deleteProgramNoList.length) {
                this.allChecked = false;
            } else {
                this.allChecked = true;
            }
        },
    },
    mounted() {
    },
    methods: {
        selectProgramList() {
            this.logger.debug(this, "selectProgramList()");
            this.searchForm.siteNo = this.siteNo;
            axios
                .post(
                    this.config.contextRoot + "/program/selectProgramList.do",
                    this.searchForm
                )
                .then((response) => {
                    this.programList = response.data.programList;
                    this.pagination = response.data.pagination;
                    this.calcPagination();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },

        doSearchPage(pageNo) {
            this.logger.debug(this, "doSearchPage()");
            if (typeof pageNo === "undefined") {
                pageNo = 1;
            }
            this.deleteProgramNoList = [];
            this.searchForm.pageNo = pageNo;
            this.selectProgramList();
        },
        calcPagination() {
            this.logger.debug(this, "calcPagination()");
            this.endPageNo =
                Math.ceil(
                    this.pagination.current_page / this.displayPageCount
                ) * this.displayPageCount;
            this.startPageNo = this.endPageNo - this.displayPageCount + 1;

            this.realEndPageNo = Math.ceil(
                this.pagination.total / this.pagination.per_page
            );

            if (this.startPageNo < 0) {
                this.startPageNo = 1;
            }
            if (this.endPageNo > this.realEndPageNo) {
                this.endPageNo = this.realEndPageNo;
            }

            this.isPrev = this.startPageNo == 1 ? false : true;
            this.isNext =
                this.endPageNo * this.pagination.per_page >=
                this.pagination.total
                    ? false
                    : true;

            this.pageNoList = [];
            for (let i = this.startPageNo; i <= this.endPageNo; i++) {
                this.pageNoList.push(i);
            }
        },
        checkAll() {
            this.deleteProgramNoList = [];

            if (!this.allChecked) {
                for (let program of this.programList) {
                    this.deleteProgramNoList.push(program.programNo);
                }
            }

            this.allChecked = !this.allChecked;
        },
        deleteProgramList() {
            if (this.deleteProgramNoList.length == 0) {
                alert("삭제항목이 선택되지 않았습니다.");
                return;
            }

            if (!confirm("선택한 항목을 삭제 하시겠습니까?")) {
                return;
            }
            
            const formData = { deleteProgramNoList: this.deleteProgramNoList};

            axios
                .post(
                    this.config.contextRoot + "/program/deleteProgramList.do",
                    formData
                )
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.exceptionType) {
                            alert("교육 신청자 명단을 확인해주세요.");
                            return;
                        }

                        // 마지막 페이지에서 전체 선택 삭제할 경우
                        if (
                            this.startPageNo != this.realEndPageNo &&
                            this.searchForm.pageNo == this.realEndPageNo &&
                            this.deleteProgramNoList.length ==
                                this.programList.length
                        ) {
                            this.$set(
                                this.searchForm,
                                "pageNo",
                                Number(this.searchForm.pageNo) - 1 + ""
                            );
                        }

                        alert("삭제 되었습니다.");
                        this.deleteProgramNoList = [];
                        this.doSearchPage(this.searchForm.pageNo);
                    }
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        openImageProgram(selectProgram){
            
            let html = "";

            let url = this.config.contextRoot + "/common/file/imgLoad.do?"
                    + "fileGroupNo=" + selectProgram.fileGroupNo
                    + "&serverFileSaveName=" + selectProgram.serverFileSaveName;
            html = "<img src=" + url + ">";

            if(selectProgram.programContents) html += "<br><div>" + selectProgram.programContents + "</div>";

            let specs = 'menubar=no';
            if(selectProgram.programPositionX) specs += ", left=" + selectProgram.programPositionX;
            if(selectProgram.programPositionY) specs += ", top=" + selectProgram.programPositionY;
            if(selectProgram.programSizeX) specs += ", width=" + selectProgram.programSizeX;
            if(selectProgram.programSizeY) specs += ", height=" + selectProgram.programSizeY;

            var OpenWindow = window.open("", "_blank", specs);
            OpenWindow.document.write(html);
        },
        yyyyMMdd(value){
            // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
            if(value == '') return '';

            // 연도, 월, 일 추출
            var year = value.substring(0, 4);
            var month = value.substring(4, 6);
            var day = value.substring(6, 8);

            // 최종 포맷 (ex - '2021-10-08')
            return year + '-' + month + '-' + day;
        },
        downloadExcel(){
            
            axios
                .post(
                    this.config.contextRoot + "/program/downloadExcel.do",
                    {},
                    { responseType: 'blob'},
                )
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '수유실 교육개설 목록.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        
        
    },
};
</script>

<style>
</style>