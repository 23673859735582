<template>
	<div class="loginWrapper">
		<div class="loginPage">
			<form @submit.prevent="doLogin()">
				<h1 class="logo">
					<img src="@/assets/images/login/login-logo.png" alt="OSOLIT" />
					<span>컨텐츠관리시스템(CMS)</span>
				</h1>
				<label class="blockLabel">
					<span>아이디</span>
					<input
						type="text"
						name="loginId"
						id="loginId"
						v-model="loginId"
						autocomplete="off"
						v-validate="'required'"
						data-vv-as="아이디"
					/>
				</label>
				<p class="validation-text" v-if="errors.has('loginId')">* {{ errors.first("loginId") }}</p>
				<label class="idSaveWrap"> <input type="checkbox" v-model="isSaveId" /><span>아이디 저장</span> </label>
				<label class="blockLabel">
					<span>비밀번호</span>
					<input
						type="password"
						name="loginPassword"
						id="loginPassword"
						v-model="loginPassword"
						autocomplete="off"
						v-validate="'required'"
						data-vv-as="비밀번호"
					/>
				</label>
				<p class="validation-text" v-if="errors.has('loginPassword')">* {{ errors.first("loginPassword") }}</p>
				<button class="loginBtn">로그인</button>
				<!-- <div class="textCenter marginTop-20">
                    <a href="#" class="findPwLink">비밀번호 찾기</a>
                </div> -->
			</form>
		</div>
		<footer class="footerLaout">
			<div class="footerSection-1">
				<strong class="footerSectionTitle">Info</strong>
				<address>
					<p>대전광역시 대덕구 대화로 160, 지원상가 2동 304호(대화동, 산업용재유통상가)</p>
				</address>
			</div>
			<div class="footerSection-2">
				<strong class="footerSectionTitle">Customer Service</strong>
				<p>T. 042-624-4055</p>
				<p>(주)오솔정보기술</p>
			</div>
		</footer>
	</div>
</template>

<script>
export default {
	name: "Login",
	data() {
		return {
			loginId: "",
			loginPassword: "",
			isSaveId: false,
		};
	},
	created() {
		if (sessionStorage.getItem("loginId")) {
			this.$router.replace(this.config.contextRoot + "/site/1/nursingroom");
		}
		this.loginId = localStorage.getItem("loginId");
		this.isSaveId = Boolean(this.loginId);
	},
	mounted() {
	},
	methods: {
		doLogin() {
			this.logger.debug(this, "doLogin");

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "valid:" + result);
					if (!result) {
						return;
					}

					this.$Auth.login(this.loginId, this.loginPassword, (loginResult) => {
						if (loginResult) {
							if (this.isSaveId) {
								localStorage.setItem("loginId", this.loginId);
							} else {
								localStorage.removeItem("loginId");
							}

							this.$router.replace(
								this.$route.query.redirect || this.config.contextRoot + "/site/1/nursingroom"
							);
						} else {
							this.logger.debug(this, "loginResult:" + loginResult);
						}
					});
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.error.all());
					this.$alert(this.errors.all());
					return false;
				});
		},
	},
};
</script>
