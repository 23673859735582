<template>
	<div class="contentLayout">
		<div class="contentNavigationBar">
			<h2 class="title">에셋 관리</h2>
		</div>
		<div class="contentBody">
			<div class="contentTitleWrap marginTop-35">
				<h3 class="contentTitle">에셋 목록</h3>
			</div>
			<div class="listWrap">
				<!-- 파일목록테이블 -->
				<table
					class="listTable tableFixed"
					style="width: 20%; float: left; margin-top: 44px; margin-right: 10px"
				>
					<colgroup>
						<col style="width: 55%" />
						<col style="width: 15%" />
						<col style="width: 30%" />
					</colgroup>
					<thead>
						<tr>
							<th>경로</th>
							<th>count</th>
							<th>관리</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="row in pathList"
							:key="row.assetPath"
							@click="
								searchForm.assetPath = row.assetPath;
								selectAssetList();
							"
						>
							<td style="text-align: left">
								{{ row.assetPath }}
							</td>
							<td>
								{{ row.assetCount }}
							</td>
							<td @click.stop="">
								<button class="squareBtn bgLightGray" @click="deletePath(row)" style="width: 50px">
									삭제
								</button>
							</td>
						</tr>
					</tbody>
				</table>
				<div>
					<!-- 목록 상단 -->
					<div class="listTop">
						<span class="listTotal"
							>(Total <em>{{ pagination.total }}</em
							>)</span
						>
						<select class="selectBox" @change.prevent="doSearchPage(1)" v-model="searchForm.countPerPage">
							<option>5</option>
							<option>10</option>
							<option>20</option>
							<option>30</option>
							<option selected>50</option>
							<option>100</option>
						</select>
					</div>
					<!-- 파일목록테이블 -->
					<table class="listTable tableFixed" style="width: 79%">
						<colgroup>
							<col style="width: 50px" />
							<col style="width: 50px" />
							<col style="width: 100px" />
							<col style="width: 150px" />
							<col style="width: 150px" />
							<col />
							<col style="width: 200px" />
						</colgroup>
						<thead>
							<tr>
								<th>
									<label>
										<input type="checkbox" @click="checkAll" v-model="allChecked" />
									</label>
								</th>
								<th>번호</th>
								<th>파일구분</th>
								<th>경로</th>
								<th>파일명</th>
								<th>URL(클립보드복사)</th>
								<th>관리</th>
							</tr>
						</thead>
						<tbody v-for="row in assetList" :key="row.assetNo">
							<tr>
								<td>
									<label
										><input
											type="checkbox"
											v-model="deleteAssetNoList"
											:value="row.assetNo"
											@click.stop=""
										/>
									</label>
								</td>
								<td>{{ row.rowNumber }}</td>
								<td>
									{{ row.assetTypeName }}
								</td>
								<td class="textEllipsis textLeft">
									{{ row.assetPath }}
								</td>
								<td>
									{{ row.assetName }}
                  <span style="font-color: red" v-if="row.assetTypeName != 'CSS' && row.assetTypeName != 'JS' && row.isFile == false">!</span>
								</td>
								<td>
									<input
										type="text"
										style="min-width: 380px"
										onclick="this.select();document.execCommand('copy');"
										:value="(row.assetTypeName == 'CSS' || row.assetTypeName == 'IMAGES' || row.assetTypeName == 'JS' ? '/' + row.assetTypeName.toLowerCase() : '/asset') + row.assetPath + row.assetName"
									/>
								</td>
								<td @click.stop="">
									<button
										v-if="row.assetTypeName == 'IMAGES'"
										class="squareBtn bgGreen"
										@click="openImagePopup(row)"
										style="width: 80px"
									>
										미리보기
									</button>
									<button
										v-if="row.assetTypeName == 'JS' || row.assetTypeName == 'CSS'"
										class="squareBtn bgNavy"
										@click="openEditPopup(row)"
										style="width: 80px"
									>
										편집
									</button>
									<button
										class="squareBtn bgLightGray"
										@click="deleteAssetList(row)"
										style="width: 80px"
									>
										삭제
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="listWrap">
				<!-- 페이지네이션 -->
				<pagination
					v-if="pagination.total > pagination.per_page"
					v-model="pagination.current_page"
					:records="pagination.total"
					:per-page="pagination.per_page"
					@paginate="doSearchPage"
					:options="{
						texts: {
							count: '',
							first: '',
							last: '',
						},
					}"
				/>
			</div>

			<!-- 하단 버튼 바 -->
			<div class="contentButtonBar">
				<div class="buttonWrap floatLeft">
					<button class="radiusSmallBtn bgBlue" @click="openNewPopup()">신규</button>
				</div>
				<div class="buttonWrap floatRight">
					<button class="radiusSmallBtn bgTuftsBlue" @click.prevent="deleteAssetList()">선택 삭제</button>
				</div>
			</div>
		</div>

		<!-- 이미지 팝업 -->
		<div class="popupWrap" id="imagePopup" v-show="showImage">
			<div class="popupBackground"></div>
			<div class="popup contentPopup">
				<div class="popupHead">
					<h2 class="popupTitle">팝업 미리보기</h2>
					<button class="popupClose">
						<img src="@/assets/images/component/popup-close.png" alt="팝업 닫기" @click="closePopup(2)" />
					</button>
				</div>

				<!-- 팝업 페이지 1 -->
				<div style="overflow: auto; max-height: 750px; width: 100%">
					<div class="popupBody" id="imagePopupBody">
						<img ref="popupImage" src="" alt="이미지없음" />
					</div>
				</div>
			</div>
		</div>

		<!-- 신규 팝업 -->
		<div class="popupWrap" id="newPopup" v-show="showNew">
			<div class="popupBackground"></div>
			<div class="popup contentPopup">
				<div class="popupHead">
					<h2 class="popupTitle">에셋 등록</h2>
					<button class="popupClose">
						<img src="@/assets/images/component/popup-close.png" alt="팝업 닫기" @click="closePopup(3)" />
					</button>
				</div>

				<!-- 팝업 페이지 1 -->
				<div class="contentBody">
					<h3 class="contentTitle">에셋 등록</h3>
					<div class="infoWrap">
						<p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
						<table class="infoTable marginBottom-35">
							<tbody>
								<tr>
									<th class="requiredMark">파일경로</th>
									<td>
										<input
											type="text"
											class="inputBox"
											v-model="assetNew.assetPath"
											v-validate="'required|spaceValidate|pathValidate'"
											data-vv-name="assetPath"
											data-vv-as="파일경로 "
										/>
										<p class="validation-text" v-if="errors.has('assetPath')">
											* {{ errors.first("assetPath") }}
										</p>
									</td>
								</tr>
								<tr>
									<th>파일선택</th>
									<td style="height: 500px; vertical-align: top">
										<common-file
											id="importFile"
											name="importFile"
											v-model="assetNew.fileList"
											v-validate="'required'"
											data-vv-as="파일을 선택하세요."
											fileMaxSize="100"
											:multipleUpload="true"
											style="max-height: 500px"
										/>
										<p class="validation-text" v-if="errors.has('importFile')">
											* {{ errors.first("importFile") }}
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<!-- 하단 버튼 바 -->
					<div class="contentButtonBar">
						<div class="buttonWrap" style="text-align:center;">
							<button class="radiusSmallBtn bgNavy" @click="insertAsset()">저장</button>
							<button class="radiusSmallBtn bgDark" @click="cancelNew()">취소</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 수정 팝업 -->
		<div class="popupWrap" id="editPopup" v-show="showEdit">
			<div class="popupBackground"></div>
			<div class="popup contentPopup">
				<div class="popupHead">
					<h2 class="popupTitle">에셋 수정</h2>
					<button
						style="width: 80px; height: 25px; margin-left: 550px"
						@click="setFullScreen('my-editor', true)"
					>
						전체화면
					</button>
					<button class="popupClose">
						<img src="@/assets/images/component/popup-close.png" alt="팝업 닫기" @click="closePopup(4)" />
					</button>
				</div>

				<!-- 팝업 페이지 1 -->
				<div class="contentBody">
					<h3 class="contentTitle">에셋 수정</h3>
					<div class="infoWrap">
						<div style="overflow: auto; width: 100%; height: 750px">
							<prism-editor
								class="my-editor"
								id="my-editor"
								v-model="assetEdit.assetTextContents"
								data-vv-name="assetTextContents"
								data-vv-as="파일내용"
								:highlight="highlighter"
								line-numbers
							></prism-editor>
						</div>
					</div>
					<!-- 하단 버튼 바 -->
					<div class="contentButtonBar">
						<div class="buttonWrap" style="text-align:center;">
							<button class="radiusSmallBtn bgNavy" @click="updateAsset()">저장</button>
							<button class="radiusSmallBtn bgDark" @click="cancelEdit()">취소</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import "tui-date-picker/dist/tui-date-picker.min.css";
import CommonFile from "@/components/common/CommonFile";
import Pagination from "vue-pagination-2";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles

export default {
	name: "AssetList",
	components: { CommonFile, Pagination, PrismEditor },
	data() {
		return {
			isFullScreen: false,
			siteNo: "",
			pathList: [],
			assetList: [],
			pagination: {},
			assetNew: {
				assetPath: "",
				fileList: [],
			},
			assetEdit: {},
			searchForm: {
				assetName: "",
				assetNo: "",
				assetTypeName: "",
				assetPath: "",
				countPerPage: 50,
				pageNo: 1,
			},
			displayPageCount: 10,
			isPrev: "",
			isNext: "",
			startPageNo: "",
			endPageNo: "",
			realEndPageNo: "",
			pageNoList: [],
			deleteAssetNoList: [],
			allChecked: false,
			showCreatePopup: false,
			showImage: false,
			showNew: false,
			showEdit: false,
		};
	},
	created() {
		this.siteNo = this.$route.params.siteNo;
		this.selectPathList();
	},
	watch: {
		deleteAssetNoList() {
			if (this.assetList.length != this.deleteAssetNoList.length) {
				this.allChecked = false;
			} else {
				this.allChecked = true;
			}
		},
	},
	mounted() {
		this.$validator.extend("spaceValidate", {
			getMessage: function (field) {
				return field + "는 공백포함이 불가합니다.";
			},
			validate: function (value) {
				var regExp = /\s/g;
				if (regExp.test(value)) {
					return false;
				}
				return true;
			},
		});

		this.$validator.extend("pathValidate", {
			getMessage: function (field) {
				return field + "가 형식에 맞지 않습니다 (ex. /, /경로/) (허용경로: 영소문자, 숫자, -, _)";
			},
			validate: function (value) {
				// var regExp1 =  /^\/$/;
				// var regExp2 =  /^\/([a-z0-9_-]|\/)+\/$/; // 수정필요 (사이 경로에 /가 포함안됨)
				// if(regExp1.test(value) | regExp2.test(value)){
				//     return true;
				// }
				// return false;

				if (!(value.length > 0)) return false;
				if (!value.startsWith("/") || !value.endsWith("/")) return false;
				if (value.indexOf(".") > 0 || value.indexOf("//") > 0) return false;

				var regExp = /[A-Z가-힣ㄱ-ㅎㅏ-ㅣ]+/;
				if (regExp.test(value)) return false;

				return true;
			},
		});
	},
	methods: {
		highlighter(code) {
			return highlight(code, languages.js); // languages.<insert language> to return html with markup
		},
		setFullScreen(targetObject, isFull) {
			this.isFullScreen = isFull;

			var elem = document.getElementById(targetObject);

			console.log(elem.style.width);

			if (this.isFullScreen) {
				if (elem.requestFullscreen) {
					elem.requestFullscreen();
				} else if (elem.mozRequestFullScreen) {
					/* Firefox */ elem.mozRequestFullScreen();
				} else if (elem.webkitRequestFullscreen) {
					/* Chrome, Safari & Opera */ elem.webkitRequestFullscreen();
				} else if (elem.msRequestFullscreen) {
					/* IE/Edge */ elem.msRequestFullscreen();
				}
			} else {
				document.exitFullscreen();
			}
		},
		selectPathList() {
			this.logger.debug(this, "selectPathList()");
			this.searchForm.siteNo = this.siteNo;
			axios
				.post(this.config.contextRoot + "/asset/selectPathList.do", this.searchForm)
				.then((response) => {
					this.pathList = response.data.pathList;
					if (response.data.pathList.length > 0) {
						this.searchForm.assetPath = response.data.pathList[0].assetPath;
						this.selectAssetList();
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectAssetList() {
			this.logger.debug(this, "selectAssetList()");
			this.searchForm.siteNo = this.siteNo;

			axios
				.post(this.config.contextRoot + "/asset/selectAssetList.do", this.searchForm)
				.then((response) => {
					this.assetList = response.data.assetList;
					this.pagination = response.data.pagination;
					for (let row of this.pathList) {
						if (row.assetPath == this.searchForm.assetPath) {
							row.assetCount = this.pagination.total;
						}
					}
					//this.calcPagination();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},

		doSearchPage(pageNo) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof pageNo === "undefined") {
				pageNo = 1;
			}
			this.deleteAssetNoList = [];
			this.searchForm.pageNo = pageNo;
			this.selectAssetList();
		},
		checkAll() {
			this.deleteAssetNoList = [];

			if (!this.allChecked) {
				for (let row of this.assetList) {
					this.deleteAssetNoList.push(row.assetNo);
				}
			}

			this.allChecked = !this.allChecked;
		},
		deletePath(row) {
			if (!confirm(row.assetPath + "이하 모든 Asset을 삭제 하시겠습니까?")) {
				return;
			}
			axios
				.post(this.config.contextRoot + "/asset/deletePath.do", row)
				.then((response) => {
					if (response.status == 200) {
						alert("삭제 되었습니다.");
						this.selectPathList();
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		deleteAssetList(selectedAsset) {
			if (!selectedAsset && this.deleteAssetNoList.length == 0) {
				alert("삭제항목이 선택되지 않았습니다.");
				return;
			}

			if (!confirm("선택한 항목을 삭제 하시겠습니까?")) {
				return;
			}

			const formData = { deleteAssetNoList: selectedAsset ? [selectedAsset.assetNo] : this.deleteAssetNoList };

			axios
				.post(this.config.contextRoot + "/asset/deleteAssetList.do", formData)
				.then((response) => {
					if (response.status == 200) {
						// 마지막 페이지에서 전체 선택 삭제할 경우
						if (
							this.startPageNo != this.realEndPageNo &&
							this.searchForm.pageNo == this.realEndPageNo &&
							this.deleteAssetNoList.length == this.assetList.length
						) {
							this.$set(this.searchForm, "pageNo", Number(this.searchForm.pageNo) - 1 + "");
						}

						alert("삭제 되었습니다.");
						this.deleteAssetNoList = [];
						this.doSearchPage(this.searchForm.pageNo);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		insertAsset() {
			this.logger.debug(this, "insertAsset()");

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "valid:" + result);
					if (!result || this.assetNameValidateYn) {
						alert("정보가 올바로 입력되지 않았습니다.\n다시 한번 확인해주세요.");
						return;
					} else {
						if (!confirm("등록 하시겠습니까?")) {
							return;
						}
						axios
							.post(this.config.contextRoot + "/asset/insertAsset.do", this.assetNew)
							.then((response) => {
								if (response.status == 200) {
									alert("등록 되었습니다.");
									this.closePopup(3);
								}
							})
							.catch((error) => {
								this.exceptionHandler(this, error);
							});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.error.all());
					this.$alert(this.error.all());
					return false;
				});
		},

		cancelNew() {
			if (confirm("에셋 등록을 취소합니다?")) {
				this.closePopup(5);
			}
		},

		updateAsset() {
			this.logger.debug(this, "updateAsset()");

			if (!confirm("저장 하시겠습니까?")) {
				return;
			}
			axios
				.post(this.config.contextRoot + "/asset/updateAsset.do", this.assetEdit)
				.then((response) => {
					if (response.status == 200) {
						alert("저장 되었습니다.");
						this.closePopup(6);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		cancelEdit() {
			if (confirm("에셋 편집을 취소합니다?")) {
				this.closePopup(4);
			}
		},

		closePopup(kind) {
			this.logger.debug(this, "closePopup(" + kind + ")");
			// this.showCreatePopup = false;
			if (kind == 1) {
				this.$refs.assetCreatePopup.closePopup();
				this.$refs.assetEditPopup.closePopup();
			} else if (kind == 2) {
				this.showImage = false;
				this.$refs.popupImage.src = "";
			} else if (kind == 3) {
				this.showNew = false;
				this.searchForm.assetPath = this.assetNew.assetPath;
				this.selectAssetList();

				this.assetNew = {
					assetPath: "",
					fileList: [],
				};
			} else if (kind == 4) {
				this.showEdit = false;
			} else if (kind == 5) {
				this.showNew = false;
				this.assetNew = {
					assetPath: "",
					fileList: [],
				};
			} else if (kind == 6) {
				this.showEdit = false;
				this.selectAssetList();
			}
		},

		openImagePopup(row) {
			this.showImage = true;
			console.log(row);

			this.$refs.popupImage.src =
				this.config.contextRoot +
				"/common/file/imgLoad.do?fileGroupNo=" +
				row.assetFileGroupNo +
				"&serverFileSaveName=" +
				row.serverFileSaveName;
		},

		openNewPopup() {
			this.logger.debug(this, "openNewPopup()");
			this.showNew = true;
			this.assetNew = { assetPath: "", fileList: [] };
			this.assetNew.assetPath = this.searchForm.assetPath;
		},

		openEditPopup(row) {
			this.logger.debug(this, "openEditPopup()");
			console.log(row);
			this.assetEdit = row;
			console.log(this.assetEdit);
			this.showEdit = true;
		},
	},
};
</script>

<style>
/* .pagination {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0.25rem;
}

.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #007bff;
	background-color: #fff;
	border: 1px solid #dee2e6;
}

.page-link:hover {
	z-index: 2;
	color: #0056b3;
	text-decoration: none;
	background-color: #e9ecef;
	border-color: #dee2e6;
}

.page-link:focus {
	z-index: 2;
	outline: 0;
	-webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
	margin-left: 0;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
	z-index: 1;
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
} */

/* required class */
.my-editor {
	/* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
	background: #2d2d2d;
	color: #ccc;

	/* you must provide font-family font-size line-height. Example: */
	font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
	font-size: 14px;
	line-height: 1.5;
	padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
	outline: none;
}
</style>
