<template>
	<!-- <LoginLayout v-if="isLoginPath" />
	<MainLayout v-if="isMainPath" />
	<SiteLayout v-if="isSitePath" />
	<MemberLayout v-if="isMemberPath" />
	<router-view v-if="!isLoginPath && !isMainPath && !isSitePath && !isMemberPath"/> -->
	<router-view></router-view>
</template>

<script>
import Vue from 'vue';
/*Validation*/
import VeeValidator from "vee-validate";
import ko from 'vee-validate/dist/locale/ko.js';
/*Cookie*/
import VueCookie from 'vue-cookie';
import CKEditor from '@ckeditor/ckeditor5-vue2';

// 플러그인 사용
Vue.use(VueCookie);
Vue.use( CKEditor );

/* 유효성 검사 */
Vue.config.productionTip = false;

const config = {
  locale: 'ko',
  dictionary: {
    ko
  }
}

VeeValidator.Validator.extend('mobile', {
  validate: value => {
    var regExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;
    return regExp.test(value);
  }
})

VeeValidator.Validator.extend('tel', {
  validate: value => {
    var regExp = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/;
    return regExp.test(value);
  }
})

Vue.use(VeeValidator, config);


// 전역 변수
Vue.prototype.global = {
    siteList: [],
}

// 전역 필터
Vue.filter("makeComma", val =>{
	if(!val){
		return 0;
	}
    return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})


export default {
	name: "App",
	created() {},
	components: {},
	computed: {
		// isLoginPath() {
		// 	return this.$route != undefined && this.$route.path.indexOf(this.config.contextRoot + "/login") >= 0
		// 		? true
		// 		: false;
		// },
		// isMainPath() {
		// 	return this.$route != undefined && this.$route.path.indexOf(this.config.contextRoot + "/main") >= 0
		// 		? true
		// 		: false;
		// },
		// isSitePath() {
		// 	return this.$route != undefined && this.$route.path.indexOf(this.config.contextRoot + "/site") >= 0
		// 		? true
		// 		: false;
		// },
		// isMemberPath() {
		// 	return this.$route != undefined && this.$route.path.indexOf(this.config.contextRoot + "/member") >= 0
		// 		? true
		// 		: false;
		// }

	},
};
</script>

<style scoped>
@import "assets/css/osolit-cms.css"
</style>