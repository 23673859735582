<template>
  <!-- 엑셀업로드 모달창 -->
  <div class="popup-container" :style="[getSize(), drag.position]">
    <div
        class="popup-top-wrap"
        @dragstart="dragstart($event)"
        @drag="drag.isDrag ? dragging($event) : ''"
        @dragend="drag.isDrag ? draggend() : ''"
        draggable>
			<span>
				<span>지원자 제출서류 보완여부 엑셀 업로드</span>
			</span>
      <button type="button" @click="close()"></button>
    </div>
    <div class="popup-center-wrap">
      <p style="color: red; text-align: right; margin-top: 20px;">* 업로드 시 기존 내용이 삭제됩니다.</p>
      <div class="recruitBox" style="margin-top: 0;">
        <div class="searchBox" style="width: 100%;">
          <div class="searchWrap">
            <div class="fileBtnWrap">
              <CommonFile
                  id="importFile"
                  name="importFile"
                  fileMaxSize="100"
                  :multipleUpload="false"
                  style="max-height: 500px; width: 100%; display: flex; align-items: center;"
                  :downloadOnly="false"
                  :buttonOnly="true"
                  :after="true"
                  @inputFiles="readExcel"
                  fileExtension="*.xls, *.xlsx"
                  ref="commonFile"
              />
              <p class="validation-text" v-if="errors.has('importFile')">* {{ errors.first("importFile") }}</p>
<!--          <span>{{ fileNm }}</span>-->
            </div>
          </div>
        </div>
      </div>
      <div class="recruitBox flexColumn" style="height: inherit;">
        <div class="listTitleWrap" style="min-height: 31px;">
          <div class="leftListTitle">
            <h3>엑셀 오류 데이터</h3>
            <span>총<strong>{{ errorList.length }}</strong>건</span>
          </div>
        </div>
        <div class="listTable tableScroll" style="max-height: inherit;" ref="scrollContainer">
          <table style="min-width: 2500px;">
            <thead class="tableHeadFix">
            <tr class="first-tr">
              <th style="width: 50px;">카테고리 코드</th>
              <th>광역시/도</th>
              <th>시/군/구</th>
              <th>기관명</th>
              <th>면적</th>
              <th>주소</th>
              <th>위치</th>
              <th>명패호수</th>
              <th>운영현황</th>
              <th>기관구분</th>
              <th>용도구분</th>
              <th>아빠이용</th>
              <th>이용대상</th>
              <th>이용자수</th>
              <th>관리주기</th>
              <th>담당자</th>
              <th>연락처</th>
              <th>실태연도</th>
              <th>조사일</th>
              <th>조사지회</th>
              <th>조사자</th>
              <th>오류</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="selectFile && errorList.length === 0" style="height: 100px;">
              <td colspan="22" style="text-align: start; padding-left: 15px;">오류 데이터가 없습니다. 업로드를 진행하세요.</td>
            </tr>
            <tr v-else-if="errorList.length > 0" v-for="(item, idx) in errorList" :key="idx">
              <td>{{ item.roomNo }}</td>
              <td>{{ item.zoneName }}</td>
              <td>{{ item.cityName }}</td>
              <td>{{ item.roomName }}</td>
              <td>{{ item.area }}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.location }}</td>
              <td>{{ item.locationName }}</td>
              <td>{{ item.manage }}</td>
              <td>{{ item.org }}</td>
              <td>{{ item.room }}</td>
              <td>{{ item.father }}</td>
              <td>{{ item.use }}</td>
              <td>{{ item.useAvg }}</td>
              <td>{{ item.carePeriod }}</td>
              <td>{{ item.managerName }}</td>
              <td>{{ item.managerTelNo }}</td>
              <td>{{ item.inquirySurveyYear }}</td>
              <td>{{ item.surveyDate }}</td>
              <td>{{ item.surveyOrganizationName }}</td>
              <td>{{ item.surveyName }}</td>
              <td v-html="item.errorMsg" style="color: red; line-height: 1.4"></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="popup-bottom-wrap">
      <div class="buttonWrap" style="text-align:right;">
        <button class="radiusSmallBtn bgNavy" style="width:100px;" @click="save();">저장</button>
        <button class="radiusSmallBtn bgDark" style="width:100px;" @click="$emit('close-modal');">닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
// import EventBus from "@/script/EventBus.js";
import CommonFile from "@/components/common/CommonFile";
import * as XLSX from 'xlsx';
import axios from "axios";

export default {
  name: "PopUp",
  props: {
    popupData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.returnPopupStyle();
    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        this.close();
      }
    });
  },
  components: {
    CommonFile,
  },
  data() {
    return {
      drag: {
        isDrag: false,
        position: {
          top: "",
          left: "",
          cursor: "",
        },
      },

      saveForm: {
        voList: [],
        firstRegistProgramId: this.$options.name + ".vue",
        lastUpdateProgramId: this.$options.name + ".vue",
      },

      selectFile: false,
      fileNm: "",
      excelDataList: [],
      errorList: [],
    };
  },
  computed: {},
  methods: {
    close() {
      this.logger.debug(this, "close()");
      this.$emit('close-modal');
    },

    save() {


      if (this.errorList.length > 0) {
        alert("오류사항 수정 후 다시 업로드하세요.");
        return;
      } else if (this.excelDataList.length === 0) {
        alert("파일을 업로드해주세요.");
        return;
      } else {
        if (!confirm("업로드 하시겠습니까?")) {
          return;
        }
      }

      const dataCnt = this.excelDataList.length;
      this.saveForm.voList = this.excelDataList;

      console.log(this.saveForm.voList)
      console.log(this.saveForm.voList)
      console.log(this.saveForm.voList)
      console.log(this.saveForm.voList)

      axios
          .post(this.config.contextRoot + "/nursingroom/updateNursingroomList.do",this.saveForm)
          .then((response) => {
            if (response.status === 200) {
              alert(`${dataCnt}건이 처리 되었습니다.`);
              //this.$emit('uploadResult', true);
              this.$emit('close-modal');
            }
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });

    },

    readExcel(files) {
      this.resetData()

      if (files.length === 0) {
        return;
      }

      if (!['xls', 'xlsx'].includes(files[0].name.split('.').pop())) {
        alert("xls / xlsx 형식의 파일이 필요합니다.");
        return;
      }

      this.fileNm = files[0].name;
      const file = files[0];

      const keyName = ["roomNo", "zoneName", "cityName", "roomName", "area", "address", "location", "locationName", "manage", "org", "room",
        "father", "use", "useAvg", "carePeriod", "managerName", "managerTelNo", "inquirySurveyYear", "surveyDate", "surveyOrganizationName", "surveyName"]

      let reader = new FileReader();

      reader.onload = () => {
        const data = reader.result;
        const workbook = XLSX.read(data, {type: 'array'});
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const rows = XLSX.utils.sheet_to_json(sheet, {header: 1, range: 1});

        // 헤더가 20개가 아니면 엑셀 읽기 종료
        // if (rows[1].length !== 20) {
        //   alert("잘못된 엑셀 양식입니다. 다운로드 받은 양식을 사용하세요.");
        //   this.$refs.commonFile.$refs.myfile.value = '';
        //   this.$refs.commonFile.arrayValue = [];
        //   this.resetData();
        //   return true;
        // }

        // 엑셀 데이터 유효성 정규식
        const roomNoRegex = /^\d+$/;                 // 카테고리 코드 : 숫자
        const areaRegex = /^\d+$/;                   // 면적 : 숫자

        // const manageList = ["운영 중", "일시 중단", "폐쇄"];
        const orgList = ["청사", "공공기관", "교통시설", "공중(다중)시설", "학교", "기업체(민관기관)"];
        const roomList = ["모유수유/착유실", "가족수유실"];
        const fatherList = ["불가", "가능"];
        const useAvgList = ["5명 이하", "6 ~ 10명", "11 ~ 20명", "21명 이상"];
        // const carePeriodList = ["수시", "2시간마다", "1일 2회 이상", "1일 1회", "격일", "1주 2회", "1주 1회"];

        const yearRegex = /^\d{4}$/;                 // 년도 숫자 4자리


        rows.forEach((row, idx) => {
          if (idx === 0) return; //헤더, 예시 데이터 패스

          let vo = {};
          vo.roomNo = row[0]; // 행번호
          vo.errorMsg = "";  // 오류 메시지

          for (let key = 0; key < row.length; key++) {
            let value = row[key];
            vo[keyName[key]] = value;

            // 데이터 유효성 확인
            switch (key) {
              case 0:
                if (!value) vo.errorMsg += "- 카테고리 값은 필수값 입니다.<br>";
                else if (!roomNoRegex.test(value)) vo.errorMsg += "- 카테고리 코드 입력 값을 확인해주세요.<br>";
                break;

              case 4:
                if (value && !areaRegex.test(value)) vo.errorMsg += "- 면적 입력 값을 확인해주세요.<br>";
                break;

              // case 7:
              //   if (value && !manageList.includes(value)) vo.errorMsg += "- 운영현황 입력 값을 확인해주세요.<br>";
              //   break;

              case 9:
                if (value && !orgList.includes(value)) vo.errorMsg += "- 기관구분 입력 값을 확인해주세요.<br>";
                break;

              case 10:
                if (value && !roomList.includes(value)) vo.errorMsg += "- 용도 구분 입력 값을 확인해주세요.<br>";
                break;

              case 11:
                if (value && !fatherList.includes(value)) vo.errorMsg += "- 아빠 이용 입력 값을 확인해주세요.<br>";
                break;

              case 13:
                if (value && !useAvgList.includes(value)) vo.errorMsg += "- 이용자수 입력 값을 확인해주세요.<br>";
                break;

              // case 13:
              //   if (value && !carePeriodList.includes(value)) vo.errorMsg += "- 관리주기 입력 값을 확인해주세요.<br>";
              //   break;

              case 17:
                if (value && !yearRegex.test(value)) vo.errorMsg += "- 실태연도 입력 값을 확인해주세요.<br>";
                break;
            }
          }

          this.excelDataList.push(vo);

          if (vo.errorMsg !== "") {
            this.errorList.push(vo);
          }
        });
      };

      reader.readAsArrayBuffer(file);

      this.selectFile = true;
    },

    resetData() {
      this.$refs.scrollContainer.scrollLeft = 0; //가로스크롤 처음으로 이동
      this.selectFile = false;
      this.fileNm = "";
      this.excelDataList = [];
      this.errorList = [];
    },

    dragstart(event) {
      this.drag.offsetLeft = event.offsetX;
      this.drag.offsetTop = event.offsetY;
      this.drag.isDrag = true;
    },
    dragging(event) {
      var top = event.clientY - this.drag.offsetTop;
      var left = event.clientX - this.drag.offsetLeft;
      if (top > 0 && left > 0) {
        this.drag.position.top = top + "px";
        this.drag.position.left = left + "px";
        this.drag.position.cursor = "grab";
      }
    },
    draggend() {
      this.drag.isDrag = false;
      this.drag.position.cursor = "unset";
    },

    returnPopupStyle() {
      var widthValue = this.popupData.width == undefined ? "60%" : this.popupData.width + "%";
      var heightValue = this.popupData.height == undefined ? "80%" : this.popupData.height + "%";
      var topValue = this.popupData.top == undefined ? "calc(50% - " + heightValue.replace("%", "") / 2 + "%)" : this.popupData.top + "%";
      var leftValue = this.popupData.left == undefined ? "calc(50% - " + widthValue.replace("%", "") / 2 + "%)" : this.popupData.left + "%";

      this.drag.position.top = topValue;
      this.drag.position.left = leftValue;
    },
    getSize() {
      var returnMap = {};
      var widthValue = this.popupData.width == undefined ? "60%" : this.popupData.width + "%";
      var heightValue = this.popupData.height == undefined ? "80%" : this.popupData.height + "%";
      returnMap.width = widthValue;
      returnMap.height = heightValue;

      return returnMap;

    },
  },
};
</script>

<style scoped>
.popup-container {
  position: fixed;
  /* transform: translate(-490px, -340px); */
  /* width: 980px; */
  /* height: 680px; */
  border: 2px solid #0980e0;
  box-shadow: 2px 2px 3px 0 #999;
  background: #fff;
  z-index: 100;
  overflow: auto;
  resize: both;
}

.popup-top-wrap {
  height: 60px;
  padding: 0 30px;
  background-color: #0980e0;
}

.popup-top-wrap span {
  display: inline-flex;
  align-items: center;
  width: calc(100% - 40px);
  height: 100%;
  font-weight: 600;
  font-size: 15px;
  color: #fff;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup-top-wrap button {
  float: right;
  width: 15px;
  height: 15px;
  margin: 22.5px 0;
  background-image: url(" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAABHNCSVQICAgIfAhkiAAAAKhJREFUOE+d1E0OQDAQBWDjSrYWbiBdSaycztYlnEu9ikq186NsGsm8LzqdIu/9SkRz8+NBtkNsoJDFywGorXGQGVG/IOcupBZKgZB9kK9QDhSIBXEAi0iQBIhIDmmAikQIq4unIJ3eq7F50f0Fm3X8IpJuwZojFuF6oEEFojVRgvJhe0ZZaiIHpWNvAtIViRfwM8BBZA2SdrPj1gLSYw72mt9AWov8dAJFKX/WFiewLQAAAABJRU5ErkJggg==");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
}

.popup-top-wrap button:hover,
.popup-top-wrap button:focus {
  width: 16px;
  height: 16px;
  background-size: 16px;
  margin: 22px 0;
}

.popup-center-wrap {
  position: absolute;
  top: 65px;
  left: 0;
  width: calc(100% - 2px);
  height: calc(100% - 160px);
  padding: 0 15px 0 20px;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}

.popup-bottom-wrap {
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 100%;
  height: 60px;
  /*border-top: 1px solid #d8dadd;*/
}

</style>
