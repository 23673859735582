<template>
	<div class="wrapper">
		<Gnb />
		<Top />
		<router-view></router-view>
	</div>
</template>

<script>
import Gnb from "@/components/gnb/Gnb.vue";
import Top from "@/components/top/Top.vue";
export default {
	name: "SurveyLayout",
	components: {
		Gnb,
		Top,
	},
	created() {},
};
</script>

<style></style>
