<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">API 신청 관리</h2>
        </div>
        <div class="contentBody">
            <!-- 검색 -->
            <div class="searchBar">
                <div class="columnDivision-3">
                    <div class="labelWrap">
                        <label>기관명</label>
                        <input
                            type="text"
                            class="inputBox"
                            v-model="searchForm.orgName"
                        />
                    </div>
                    <div class="labelWrap">
                        <label>신청자</label>
                        <input
                            type="text"
                            class="inputBox"
                            v-model="searchForm.managerName"
                        />
                    </div>
                </div>
                <button class="searchBtn" @click="doSearchPage(1)">
                    조회하기
                </button>
            </div>
            <div class="contentTitleWrap marginTop-35">
                <h3 class="contentTitle">API 신청 목록</h3>
            </div>
            <div class="listWrap">
                <!-- 목록 상단 -->
                <div class="listTop">
                    <span class="listTotal"
                        >(Total <em>{{ pagination.total }}</em
                        >)</span
                    >
                    <select
                        class="selectBox"
                        @change.prevent="doSearchPage(1)"
                        v-model="searchForm.countPerPage"
                    >
                        <option>5</option>
                        <option selected>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                </div>

                <!-- 목록테이블 -->
                <table class="listTable tableFixed">
                    <colgroup>
                        <col style="width: 5%" />
                        <col style="width: 15%" />
                        <col style="width: 10%" />
                        <col style="width: 10%" />
                        <col style="width: 15%" />
                        <col style="width: 15%" />
                        <col style="width: 10%" />
                        <col style="width: 10%" />
                        <col style="width: 10%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>신청번호</th>
                            <th>기관명</th>
                            <th>신청자</th>
                            <th>연락처</th>
                            <th>이메일</th>
                            <th>사이트</th>
                            <th>신청일</th>
                            <th>승인여부</th>
                            <th>승인/반려일</th>
                        </tr>
                    </thead>
                    <tbody
                        v-for="api in apiList"
                        :key="api.applicantNo"
                        @click="
                            $router.push({
                                path:
                                    config.contextRoot +
                                    '/site/' +
                                    $route.params.siteNo +
                                    '/api/' +
                                    api.applicantNo +
                                    '/edit',
                            })
                        "
                    >
                        <tr
                        >
                            <td>{{ api.applicantNo }}</td>
                            <td>
                                {{ api.orgName }}
                            </td>
                            <td>
                                {{ api.managerName }}
                            </td>
                            <td>
                                {{ api.telNo }}
                            </td>
                            <td>
                                {{ api.email }}
                            </td>
                            <td>
                                {{ api.siteAddress }}
                            </td>
                            <td>
                                {{ api.applicantDate }}
                            </td>
                            <td>
                                {{
                                !api.confirmYn ? "대기" : 
                                    api.confirmYn == 'Y' ? "승인" : "반려"
                                }}
                            </td>
                            <td>
                                {{
                                !api.confirmYn ? "" : 
                                    api.confirmYn == 'Y' ? api.confirmDate : api.rejectDate
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- 페이지네이션-->
                <div
                    class="pagination marginTop-50"
                    v-if="pageNoList && pageNoList.length > 0"
                >
                    <ul>
                        <li>
                            <a
                                href="#"
                                @click.prevent="doSearchPage(1)"
                                title="첫 페이지"
                            >
                                <img
                                    src="@/assets/images/component/pagination-first.png"
                                    alt="첫 페이지"
                                />
                            </a>
                        </li>
                        <li v-show="isPrev">
                            <a
                                href="#"
                                @click.prevent="doSearchPage(startPageNo - 1)"
                                :title="'이전' + displayPageCount + '페이지'"
                            >
                                <img
                                    src="@/assets/images/component/pagination-prev.png"
                                    :alt="'이전' + displayPageCount + '페이지'"
                                />
                            </a>
                        </li>
                        <li v-for="pageNo in pageNoList" :key="pageNo">
                            <a
                                href="#"
                                :class="
                                    pagination.current_page == pageNo
                                        ? 'active'
                                        : ''
                                "
                                @click.prevent="doSearchPage(pageNo)"
                            >
                                {{ pageNo }}
                            </a>
                        </li>
                        <li v-show="isNext">
                            <a
                                href="#"
                                @click.prevent="doSearchPage(endPageNo + 1)"
                                :title="'다음' + displayPageCount + '페이지'"
                            >
                                <img
                                    src="@/assets/images/component/pagination-next.png"
                                    :alt="'다음' + displayPageCount + '페이지'"
                                />
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                @click.prevent="doSearchPage(realEndPageNo)"
                                title="마지막 페이지"
                            >
                                <img
                                    src="@/assets/images/component/pagination-last.png"
                                    alt="마지막 페이지"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'ApiList',

    data() {
        return {
            siteNo: "",
            apiList: [],
            pagination: {},
            searchForm: {
                orgName: "",
                mangerName: "",
                countPerPage: 10,
                pageNo: 1,
            },
            displayPageCount: 10,
            isPrev: "",
            isNext: "",
            startPageNo: "",
            endPageNo: "",
            realEndPageNo: "",
            pageNoList: [],
        };
    },

    created() {
        this.siteNo = this.$route.params.siteNo;
        this.selectApiList();
    },

    mounted() {
        
    },

    methods: {
        selectApiList() {
            this.logger.debug(this, "selectApiList()");
            this.searchForm.siteNo = this.siteNo;
            axios
                .post(
                    this.config.contextRoot + "/api/selectApiList.do",
                    this.searchForm
                )
                .then((response) => {
                    this.apiList = response.data.apiList;
                    this.pagination = response.data.pagination;
                    this.calcPagination();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        doSearchPage(pageNo) {
            this.logger.debug(this, "doSearchPage()");
            if (typeof pageNo === "undefined") {
                pageNo = 1;
            }
            this.deleteProgramNoList = [];
            this.searchForm.pageNo = pageNo;
            this.selectApiList();
        },
        calcPagination() {
            this.logger.debug(this, "calcPagination()");
            this.endPageNo =
                Math.ceil(
                    this.pagination.current_page / this.displayPageCount
                ) * this.displayPageCount;
            this.startPageNo = this.endPageNo - this.displayPageCount + 1;

            this.realEndPageNo = Math.ceil(
                this.pagination.total / this.pagination.per_page
            );

            if (this.startPageNo < 0) {
                this.startPageNo = 1;
            }
            if (this.endPageNo > this.realEndPageNo) {
                this.endPageNo = this.realEndPageNo;
            }

            this.isPrev = this.startPageNo == 1 ? false : true;
            this.isNext =
                this.endPageNo * this.pagination.per_page >=
                this.pagination.total
                    ? false
                    : true;

            this.pageNoList = [];
            for (let i = this.startPageNo; i <= this.endPageNo; i++) {
                this.pageNoList.push(i);
            }
        },
    },
};
</script>

<style scoped>

</style>