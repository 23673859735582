var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contentLayout"},[_vm._m(0),_c('div',{staticClass:"contentBody"},[_c('h3',{staticClass:"contentTitle"},[_vm._v("API로그 수정")]),_c('div',{staticClass:"infoWrap"},[_vm._m(1),_c('table',{staticClass:"infoTable"},[_c('tbody',[_c('tr',[_c('th',{staticStyle:{"width":"150px"}},[_vm._v("API KEY")]),_c('td',[_vm._v(_vm._s(_vm.apilogInfo.confirmApiKey))])]),_c('tr',[_c('th',[_vm._v("사용일시")]),_c('td',[_vm._v(_vm._s(_vm.apilogInfo.requestDate))])]),_c('tr',[_c('th',[_vm._v("사용번호")]),_c('td',[_vm._v(_vm._s(_vm.apilogInfo.useNo))])]),_c('tr',[_c('th',[_vm._v("요청IP")]),_c('td',[_vm._v(_vm._s(_vm.apilogInfo.requestIpAddr))])]),_c('tr',[_c('th',[_vm._v("요청파라미터")]),_c('td',{staticStyle:{"line-height":"30px"},domProps:{"innerHTML":_vm._s(_vm.requestParamHtml)}})]),_c('tr',[_c('th',[_vm._v("응답상태")]),_c('td',[_vm._v(_vm._s(_vm.apilogInfo.responseCode == '200' ? '성공' : '실패'))])]),_c('tr',[_c('th',[_vm._v("응답메시지")]),_c('td',[_vm._v(_vm._s(_vm.apilogInfo.responseMessage))])]),_c('tr',[_c('th',[_vm._v("응답 수유실 수")]),_c('td',[_vm._v(_vm._s(_vm.apilogInfo.responseCount))])])])])]),_c('div',{staticClass:"contentButtonBar"},[_c('div',{staticClass:"buttonWrap floatLeft"},[_c('router-link',{staticClass:"radiusSmallBtn bgGray",attrs:{"to":{
						path: _vm.config.contextRoot + '/site/' + _vm.$route.params.siteNo + '/apilog',
						query: {
							confirmApiKey: this.$route.query.confirmApiKey,
						},
					}}},[_vm._v(" 목록 ")])],1),_c('div',{staticClass:"buttonWrap floatRight"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contentNavigationBar"},[_c('h2',{staticClass:"title"},[_vm._v("API로그")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"requireText"},[_vm._v("("),_c('em',[_vm._v("*")]),_vm._v(") 항목은 필수 항목입니다.")])
}]

export { render, staticRenderFns }