<template>
  <div class="contentLayout">
    <div class="contentNavigationBar">
      <h2 class="title">게시글 관리</h2>
    </div>
    <div class="contentBody">
      <h3 class="contentTitle">게시글 수정</h3>

      <!-- 입력 폼 -->
      <div class="infoWrap">
        <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
        <table class="infoTable marginBottom-35">
          <tbody>
          <tr>
            <th class="requiredMark">게시판 선택</th>
            <td>
              <select
                  class="selectBox size-300"
                  v-model="articleInfo.contentsNo"
                  @change.prevent="onContentsNoChange"
              >
                <option
                    v-for="board in boardList"
                    :key="board.contentsNo"
                    :value="board.contentsNo"
                >
                  {{ board.contentsTitle }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <th class="requiredMark">게시글 제목</th>
            <td>
              <input
                  type="text"
                  class="inputBox"
                  v-model="articleInfo.articleTitle"
                  v-validate="'required'"
                  data-vv-name="articleTitle"
                  data-vv-as="게시글 제목"
              />
              <p class="validation-text" v-if="errors.has('articleTitle')">
                * {{ errors.first("articleTitle") }}
              </p>
            </td>
          </tr>
          <tr v-if="isShowNoticeUseYn">
            <th class="requiredMark">공지여부</th>
            <td>
              <div class="radioBoxWrap">
                <label>
                  <input
                      type="radio"
                      class="radioBox"
                      name="testRadio-6"
                      v-model="articleInfo.articleNoticeYn"
                      value="Y"
                  />
                  <span>사용</span>
                </label>
                <label>
                  <input
                      type="radio"
                      class="radioBox"
                      name="testRadio-6"
                      v-model="articleInfo.articleNoticeYn"
                      value="N"
                  />
                  <span>미사용</span>
                </label>
              </div>
            </td>
          </tr>

          <tr v-if="isShowNoticeUseYn">
            <th class="requiredMark">공개여부</th>
            <td>
              <div class="radioBoxWrap">
                <label>
                  <input
                      type="radio"
                      class="radioBox"
                      name="openYn"
                      v-model="articleInfo.openYn"
                      value="Y"
                  />
                  <span>공개</span>
                </label>
                <label>
                  <input
                      type="radio"
                      class="radioBox"
                      name="openYn"
                      v-model="articleInfo.openYn"
                      value="N"
                  />
                  <span>미공개</span>
                </label>
              </div>
            </td>
          </tr>

          <tr v-if="articleInfo.contentsNo == '14'">
            <th>
              교육지역
              <br/>/교육장소
            </th>
            <td>
              <select
                  class="selectBox"
                  v-model="articleInfo.locationText"
                  style="width:100px; margin-right:10px;"
              >
                <option value="">광역시/도</option>
                <option :key="idx1" :value="adress1.comCode" v-for="(adress1, idx1) in addressList1">{{ adress1.codeName }}</option>
              </select>
              <input
                  type="text"
                  class="inputBox"
                  v-model="articleInfo.placeText"
                  placeholder="교육장소"
                  style="width: 400px"
              />
            </td>
          </tr>
          <tr v-show="articleInfo.contentsNo == '14'">
            <th>교육기간</th>
            <td style="display: inline-flex">
              <div class="datePicker" style="width: 30%; z-index: 1">
                <input
                    type="text"
                    class="inputBox"
                    placeholder="연도-월-일"
                    id="startDate"
                    autocomplete="off"
                    v-model="articleInfo.startDate"
                />
                <div ref="startDatePicker" id="startDatePicker"></div>
                <i></i>
              </div>
              <label class="textCenter" style="width: 30px; margin-top: 8px">-</label>
              <div class="datePicker" style="width: 30%; z-index: 1">
                <input
                    type="text"
                    class="inputBox"
                    placeholder="연도-월-일"
                    id="endDate"
                    autocomplete="off"
                    v-model="articleInfo.endDate"
                />
                <div ref="endDatePicker" id="endDatePicker"></div>
                <i></i>
              </div><button class="radiusSmallBtn bgDark" @click="initDate()" style="margin-left: 5px; margin-top: 3px;">초기화</button>
            </td>
          </tr>
          <tr>
            <th>편집방법</th>
            <td>
              <button class="radiusSmallBtn bgBlue" @click="editHtml = true">HTML</button>
              <button class="radiusSmallBtn bgGray" @click="editHtml = false">SOURCE</button>
            </td>
          </tr>
          <tr>
            <th class="requiredMark">내용</th>
            <td>
              <div v-show="editHtml">
                <textarea hidden id="ckeditor" v-model="articleInfo.articleContents"
                          v-validate="'required'"
                          data-vv-name="articleContents"
                          data-vv-as="내용"></textarea>
              </div>
              <div v-show="!editHtml">
                <prism-editor
                    class="my-editor"
                    v-model="articleInfo.articleContents"
                    v-validate="'required'"
                    data-vv-name="articleContents"
                    data-vv-as="내용"
                    :highlight="highlighter"
                    line-numbers
                ></prism-editor>
              </div>
              <p class="validation-text" v-if="errors.has('articleContents')">
                * {{ errors.first("articleContents") }}
              </p>
            </td>
          </tr>
          <tr>
            <th>첨부파일</th>
            <td>
              <common-file
                  ref="FileUploader"
                  v-model="articleInfo.fileList"
                  fileMaxSize="10"
                  :multipleUpload="true"
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <!-- 댓글 관리 -->
      <!--
      <h3 class="contentTitle border-0 marginTop-50 marginBottom-10">댓글 관리</h3>
      <div class="commentListWrap">
        <div class="commentListTop">
          <strong
          >댓글(<em class="commentTotalCount">{{ articleInfo.commentsList.length }}</em
          >)</strong
          >
        </div>
        <ul class="commentList">
          <li class="commentListItem" v-if="(articleInfo.commentCount = 0)">
            <p class="textCenter marginTop-30 marginBottom-30">댓글이 없습니다</p>
          </li>
          <li class="commentListItem" v-for="comment in articleInfo.commentsList" :key="comment.commentNo">
            <div class="commentListItemTop">
              <strong>{{ comment.userName }}</strong>
              <span>{{ comment.commentWriteDate }}</span>
            </div>
            <p>{{ comment.commentContents }}</p>
            <button class="commentDelBtn" @click="deleteComment(comment.commentNo)">삭제</button>
          </li>
        </ul>
      </div>
      -->
      <!-- 하단 버튼 바 -->
      <div class="contentButtonBar">
        <div class="buttonWrap floatLeft">
          <router-link
              :to="{
							path: config.contextRoot + '/site/' + $route.params.siteNo + '/article',
							query: {
								contentsNo: this.$route.query.contentsNo,
							},
						}"
              class="radiusSmallBtn bgGray"
          >
            목록
          </router-link>
        </div>
        <div class="buttonWrap floatRight">
          <button class="radiusSmallBtn bgNavy" @click="updateArticle()">저장</button>
          <button class="radiusSmallBtn bgDark" @click="deleteArticle()">삭제</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "tui-date-picker";
import "tui-date-picker/dist/tui-date-picker.min.css";
import CommonFile from "@/components/common/CommonFile";
import EventBus from "../../script/EventBus";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles

import CKEditorConfig from "@/script/CKEditorConfig";

export default {
  name: "ArticleEdit",
  data() {
    return {
      editHtml: true,
      siteNo: "",
      boardList: [],
      item: {},

      articleInfo: {
        contentsNo: "",
        articleTitle: "",
        articleContents: "",
        articleNoticeYn: "N",
        boardCommentUseYn: "N",
        recruitmentYn: "N",
        fileGroupNo: "",
        fileList: [],
        commentsList: [],

        locationText: "",
        plcaeText: "",
        startDate: "",
        endDate: "",
      },
      isShowNoticeUseYn: false,
      addressList1: [],
    };
  },
  created() {
    this.siteNo = this.$route.params.siteNo;
    this.selectBoardList();
    EventBus.$on("addFile", (fileList) => {
      this.logger.debug(this, "eventBus / addFile()");
      this.addFile(fileList);
    });
    this.selectComCodeList();

  },
  mounted() {
    //에디터
    new CKEditorConfig("#ckeditor").create();
    // 데이트피커
    var today = new Date();
    const rangePicker = new DatePicker.createRangePicker({
      startpicker: {
        date: today,
        input: "#startDate",
        container: "#startDatePicker",
      },
      endpicker: {
        date: today,
        input: "#endDate",
        container: "#endDatePicker",
      },
      language: "ko",
    });
    rangePicker.on("change:start", () => {
      this.articleInfo.startDate = document.getElementById("startDate").value;
    });
    rangePicker.on("change:end", () => {
      this.articleInfo.endDate = document.getElementById("endDate").value;
    });
  },
  watch: {
    editHtml(newVal){
        if(newVal) window.editor.setData(this.articleInfo.articleContents);
        else this.articleInfo.articleContents = window.editor.getData();
    }
  },
  components: {
    CommonFile,
    PrismEditor
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js); // languages.<insert language> to return html with markup
    },
    onContentsNoChange() {
      for (let board of this.boardList) {
        if (board.contentsNo == this.articleInfo.contentsNo) {
          if (board.boardNoticeUseYn == "Y") {
            // 공지여부 보임
            this.isShowNoticeUseYn = true;
          } else {
            // 공지여부 숨김
            this.isShowNoticeUseYn = false;
          }
        }
      }
    },
    selectBoardList() {
      this.logger.debug(this, "selectBoardList()");
      const searchData = {};

      axios
          .post(this.config.contextRoot + "/article/selectBoardList.do", searchData)
          .then((response) => {
            this.boardList = response.data.boardList;
            this.selectArticle();
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },
    selectArticle() {
      this.logger.debug(this, "selectArticleList()");
      const formData = {
        articleNo: this.$route.params.articleNo,
      };
      axios
          .post(this.config.contextRoot + "/article/selectArticle.do", formData)
          .then((response) => {
            this.articleInfo = response.data.articleInfo;
            console.log(this.articleInfo)
            window.editor.setData(this.articleInfo.articleContents);

            this.onContentsNoChange();
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },

    updateArticle() {
      this.logger.debug(this, "updateArticle()");

      if (!confirm("등록 하시겠습니까?")) {
        return;
      }
      if(this.editHtml) this.articleInfo.articleContents = window.editor.getData();

      this.$validator
          .validateAll()
          .then((result) => {
            this.logger.debug(this, "valid:" + result);
            if (!result) {
              alert("정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요.");
              return;
            }

            axios
                .post(this.config.contextRoot + "/article/updateArticle.do", this.articleInfo)
                .then((response) => {
                  if (response.data.articleNo) {
                    alert("수정 되었습니다.");
                    this.$router.replace(this.config.contextRoot + "/site/" + this.siteNo + "/article/");
                  }
                })
                .catch((error) => {
                  this.exceptionHandler(this, error);
                });
          })
          .catch(() => {
            this.logger.debug(this, "-->" + this.error.all());
            this.$alert(this.error.all());
            return false;
          });
    },

    reset() {
      if (confirm("취소 하시겠습니까?")) {
        this.$router.go(this.$router.currentRoute);
      }
    },
    openUploader(refName) {
      this.logger.debug(this, "openUploader()");
      this.$refs[refName].openUploader();
    },
    doDownload(data) {
      this.logger.debug(this, "doDownload");
      const formData = new FormData();
      formData.append("fileNo", data.fileNo);
      formData.append("fileName", data.fileName);
      formData.append("serverFileSaveName", data.serverFileSaveName);
      axios
          .post(this.config.contextRoot + "/common/file/download.do", formData, { responseType: "blob" })
          .then((response) => {
            console.log(response);
            const url = window.URL.createObjectURL(
                new Blob([response.data], {
                  type: response.headers["content-type"],
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", data.fileName);
            document.body.appendChild(link);
            link.click();
            location.reload();
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },
    deleteFile(index) {
      this.articleInfo.fileList.splice(index, 1);
    },
    addFile(fileList) {
      this.articleInfo.fileList = this.articleInfo.fileList.concat(fileList);
    },
    deleteArticle() {
      if (confirm("삭제 하시겠습니까?")) {
        axios
            .post(this.config.contextRoot + "/article/deleteArticle.do", this.articleInfo)
            .then((response) => {
              if (response) {
                alert("삭제 되었습니다.");
                this.$router.replace(this.config.contextRoot + "/site/" + this.siteNo + "/article/");
              }
            })
            .catch((error) => {
              this.exceptionHandler(this, error);
            });
      }
    },
    deleteComment(commentNo) {
      this.logger.debug(this, "deleteComment");
      if (!confirm("댓글을 삭제 하시겠습니까?")) {
        return;
      }

      axios
          .post(this.config.contextRoot + "/comments/delete.do", {
            commentNo,
          })
          .then((response) => {
            if (response) {
              alert("삭제 되었습니다.");
              this.articleInfo.commentsList = this.articleInfo.commentsList.filter((comment) => {
                return comment.commentNo != commentNo;
              });
            }
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },
    selectComCodeList() {
      this.logger.debug(this, "selectComCodeList()");
      axios
          .post(
              this.config.contextRoot + "/common/code/selectComCodeList.do",
              {classCode : this.address ? this.address : "LOCATION_CODE"}
          )
          .then((response) => {
            if(!this.address){
              this.addressList1 = response.data.codeList;
            }else{
              this.addressList2 = response.data.codeList;
            }
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },
    initDate(){
      this.articleInfo.startDate = "";
      this.articleInfo.endDate = "";
    }
  },
};
</script>

<style>
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  max-width: 1440px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
<style lang="css">
</style>
