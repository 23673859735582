<template>
  <div class="contentLayout">
    <div class="contentNavigationBar">
      <h2 class="title">수유실 관리</h2>
    </div>

    <div class="contentBody">
      <ExcelUpload v-if="isPopup" @close-modal="isPopup = false" :popup-data="{}"/>
      <!-- 검색 -->
      <div class="searchBar">
        <div class="columnDivision-3">
          <div class="labelWrap">
            <label>지역</label>
            <label style="width:40%">
              <select
                  id="zone"
                  class="selectBox"
                  v-model="searchForm.zoneName"
                  @change="selectCityList()"
              >
                <option ref="optionNull" value="" selected>광역시/도</option>
                <option
                    v-for="zone in zoneList"
                    :key="zone"
                    :value="zone"
                >
                  {{ zone }}
                </option>
              </select>
            </label>
            <label style="width:35%">
              <select
                  id="city"
                  class="selectBox"
                  v-model="searchForm.cityName"
              >
                <option value="" selected>시/군/구</option>
                <option
                    v-for="city in cityList"
                    :key="city"
                    :value="city"
                >
                  {{ city }}
                </option>
              </select>
            </label>
          </div>

          <div class="labelWrap">
            <label>이용대상</label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.useTypeCode"
                  value=""
              />
              <span>  전체</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.useTypeCode"
                  value="0"
              />
              <span>  직원용</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.useTypeCode"
                  value="1"
              />
              <span>  외부인</span>
            </label>
            <label style="width: 115px">
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.useTypeCode"
                  value="2"
              />
              <span>  외부/직원용</span>
            </label>
          </div>

          <div class="labelWrap">
            <label>아빠이용</label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.fatherUseYn"
                  value=""
              />
              <span>  전체</span>
            </label>
            <label style="width: 95px">
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.fatherUseYn"
                  value="0"
              />
              <span>  이용 안함</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.fatherUseYn"
                  value="1"
              />
              <span>  이용</span>
            </label>
          </div>

        </div>
        <div class="columnDivision-3 marginTop-15">
          <div class="labelWrap">
            <label>기관명</label>
            <input
                type="text"
                class="inputBox"
                v-model="searchForm.roomName"
            />
          </div>
          <div class="labelWrap">
            <label>등록일</label>
            <div class="datePicker">
              <input
                  type="text"
                  class="inputBox"
                  placeholder="연도-월-일"
                  id="startDate"
                  v-model="searchForm.startDate"
                  autocomplete="off"
              />
              <div
                  ref="startDatePicker"
                  id="startDatePicker"
              ></div>
              <i></i>
            </div>
          </div>
          <div class="labelWrap marginTop-15">
            <label class="textCenter" style="width: 30px">-</label>
            <div class="datePicker">
              <input
                  type="text"
                  class="inputBox"
                  placeholder="연도-월-일"
                  id="endDate"
                  v-model="searchForm.endDate"
                  autocomplete="off"
              />
              <i></i>
              <div
                  ref="endDatePicker"
                  id="endDatePicker"
              ></div>
            </div>
          </div>
        </div>
        <button class="searchBtn" @click="doSearchPage(1)">
          조회하기
        </button>
        <button class="detailBtn" @click="doDetailSearch($event)">
          상세 검색
        </button>
      </div>

      <!-- 상세 검색 -->
      <div class="searchBar marginTop-15 detailSearch" v-show="showDetailSearch">
        <!-- 기관 구분 start -->
        <div class="columnDivision-3">
          <div class="labelWrap" style="width: 100%;">
            <label>기관 구분</label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.organizationCode"
                  value=""
              />
              <span>  전체</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.organizationCode"
                  value="1"
              />
              <span>  청사</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.organizationCode"
                  value="2"
              />
              <span>  공공기관</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.organizationCode"
                  value="3"
              />
              <span>  교통시설</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.organizationCode"
                  value="4"
              />
              <span>  공중(다중)시설</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.organizationCode"
                  value="5"
              />
              <span>  학교</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.organizationCode"
                  value="6"
              />
              <span>  기업체(민간기관)</span>
            </label>
          </div>
        </div>
        <!-- 기관 구분 end -->
        <!-- 시설 구분 start -->
        <div class="columnDivision-3 marginTop-15">
          <div class="labelWrap" style="width: 100%;">
            <label>시설 구분</label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.roomTypeCode"
                  value=""
              />
              <span>  전체</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.roomTypeCode"
                  value="1"
              />
              <span>  모유수유/착유실</span>
            </label>
            <!-- <label>
                <input
                    type="radio"
                    class="radioBox"
                    v-model="searchForm.roomTypeCode"
                    value="2"
                />
                <span>  모유수유실</span>
            </label> -->
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.roomTypeCode"
                  value="3"
              />
              <span>  가족수유실</span>
            </label>
            <label>1회 이용 가능 인원</label>
            <label style="width: auto;">
              <input
                  type="number"
                  class="inputBox"
                  style="width: 50px;"
                  v-model="searchForm.peakCountMin"
              />
              <span>  ~ </span>
              <input
                  type="number"
                  class="inputBox"
                  style="width: 50px;"
                  v-model="searchForm.peakCountMax"
              />
              <span>  명</span>
            </label>
          </div>
        </div>
        <!-- 시설 구분 end -->
        <!-- 수유실 면적 start -->
        <div class="columnDivision-3 marginTop-15">
          <div class="labelWrap" style="width: 100%;">
            <label>수유실 면적</label>
            <label style="width: auto;">
              <input
                  type="number"
                  class="inputBox"
                  style="width: 90px;"
                  v-model="searchForm.areaMin"
              />
              <span>  ~ </span>
              <input
                  type="number"
                  class="inputBox"
                  style="width: 90px;"
                  v-model="searchForm.areaMax"
              />
            </label>
            <label>
              <select
                  id="areaUnit"
                  class="selectBox"
                  v-model="searchForm.areaUnit"
              >
                <option value="" selected>단위</option>
                <option value="1" selected>㎡</option>
                <option value="2" selected>평</option>
              </select>
            </label>
          </div>
        </div>
        <!-- 수유실 면적 end -->
        <!-- 조사일 start -->
        <div class="columnDivision-3 marginTop-15">
          <div class="labelWrap">
            <label>조사일</label>
            <div class="datePicker">
              <input
                  type="text"
                  class="inputBox"
                  placeholder="연도-월-일"
                  id="startDate2"
                  v-model="searchForm.startDate2"
                  autocomplete="off"
              />
              <div
                  ref="startDatePicker2"
                  id="startDatePicker2"
              ></div>
              <i></i>
            </div>
          </div>
          <div class="labelWrap marginTop-15">
            <label class="textCenter" style="width: 30px">-</label>
            <div class="datePicker">
              <input
                  type="text"
                  class="inputBox"
                  placeholder="연도-월-일"
                  id="endDate2"
                  v-model="searchForm.endDate2"
                  autocomplete="off"
              />
              <i></i>
              <div
                  ref="endDatePicker2"
                  id="endDatePicker2"
              ></div>
            </div>
          </div>
        </div>
        <!-- 조사일 end -->
        <!-- 조사지회 start -->
        <div class="columnDivision-3 marginTop-15">
          <div class="labelWrap" style="width: 100%;">
            <label>조사지회</label>
            <label style="width: auto;">
              <input
                  type="text"
                  class="inputBox"
                  v-model="searchForm.surveyOrganizationName"
              />
            </label>
          </div>
        </div>
        <!-- 조사지회 end -->
        <!-- 조사자 start -->
        <div class="columnDivision-3 marginTop-15">
          <div class="labelWrap" style="width: 100%;">
            <label>조사자</label>
            <label style="width: auto;">
              <input
                  type="text"
                  class="inputBox"
                  v-model="searchForm.surveyName"
              />
            </label>
          </div>
        </div>
        <!-- 조사자 end -->
        <!-- 일일평균 이용자수 start -->
        <div class="columnDivision-3 marginTop-15">
          <div class="labelWrap" style="width: 100%;">
            <label>일일평균<br>이용자수</label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.useAvgAdayCode"
                  value=""
              />
              <span>  전체</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.useAvgAdayCode"
                  value="1"
              />
              <span>  5명 이하</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.useAvgAdayCode"
                  value="2"
              />
              <span>  6~10명</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.useAvgAdayCode"
                  value="3"
              />
              <span>  11~20명</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.useAvgAdayCode"
                  value="4"
              />
              <span>  21명 이상</span>
            </label>
          </div>
        </div>
        <!-- 일일평균 이용자수end -->
        <!--  관리 주기 start -->
        <div class="columnDivision-3 marginTop-15">
          <div class="labelWrap" style="width: 100%;">
            <label>관리 주기</label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.carePeriodCode"
                  value=""
              />
              <span>  전체</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.carePeriodCode"
                  value="1"
              />
              <span>  수시</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.carePeriodCode"
                  value="2"
              />
              <span>  1일 2회</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.carePeriodCode"
                  value="3"
              />
              <span>  1일 1회</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.carePeriodCode"
                  value="4"
              />
              <span>  주 1회</span>
            </label>
            <label>
              <input
                  type="radio"
                  class="radioBox"
                  v-model="searchForm.carePeriodCode"
                  value="5"
              />
              <span>  기타</span>
            </label>
          </div>
        </div>
        <!-- 관리 주기 end -->
      </div>

      <div class="contentTitleWrap marginTop-35">
        <h3 class="contentTitle">수유실 목록</h3>
      </div>
      <div class="listWrap">
        <!-- 목록 상단 -->
        <div class="listTop">
          <span class="listTotal">(Total 기관 : <em>{{ pagination.total }}</em>, 시설 : <em>{{ nursingroomList.length > 0 ? nursingroomList[0].totalRoomCount : 0}}</em>)</span>
          <button
              class="radiusSmallBtn bgBlue"
              @click="downloadExcel()"
              style="margin-left:10px;">Excel (전체)
          </button>

          <button
              v-if="isShow"
              class="radiusSmallBtn bgBlue"
              @click="downloadExcelForm()"
              style="margin-left:10px; width:150px;">업로드 양식 다운로드
          </button>

          <button
              v-if="isShow"
              class="radiusSmallBtn bgBlue"
              style="margin-left:10px; width:100px;"
              @click="isPopup = true;">엑셀 업로드
          </button>
          <select
              class="selectBox"
              @change.prevent="doSearchPage(1)"
              v-model="searchForm.countPerPage">
            <option>5</option>
            <option selected>10</option>
            <option>20</option>
            <option>30</option>
            <option>50</option>
            <option>100</option>
          </select>
        </div>

        <!-- 목록테이블 -->
        <table class="listTable tableFixed">
          <colgroup>
            <col style="width: 2%" v-if="isShow"/>    <!-- 체크박스 -->
            <col style="width: 4%"/>    <!-- 번호 -->
            <col style="width: 6%"/>    <!-- 시/도 -->
            <col style="width: 6%"/>    <!-- 시/군/구 -->
            <col style="width: 10%"/>    <!-- 기관명 -->
            <col style="width: 7%"/>    <!-- 기관구분 -->
            <col style="width: 7%"/>    <!-- 위치 -->


            <col style="width: 7%"/>    <!-- 대상 -->
            <col style="width: 4%"/>    <!-- 아빠이용 -->
            <col style="width: 4%"/>    <!-- 평점 -->
            <col style="width: 5%"/>    <!-- 조회수 -->
            <col style="width: 7%"/>    <!-- 등록일 -->
            <col style="width: 6%"/>    <!-- 조사지회 -->
            <col style="width: 5%"/>    <!-- 조사자 -->
            <col style="width: 7%"/>    <!-- 조사일 -->

            <col style="width: 5%"/>    <!-- 담당자 -->
            <col style="width: 8%"/>    <!-- 연락처 -->
          </colgroup>
          <thead>
          <tr>
            <th v-if="isShow">
              <label>
                <input
                    type="checkbox"
                    @click="checkAll"
                    v-model="allChecked"
                />
              </label>
            </th>
            <th>번호</th>
            <th>시/도</th>
            <th>시/군/구</th>
            <th>기관명</th>
            <th>기관구분</th>
            <th>위치</th>

            <th>대상</th>
            <th>아빠이용</th>
            <th>평점(0~5)</th>
            <th>조회수</th>
            <th>등록일</th>
            <th>조사지회</th>
            <th>조사자</th>
            <th>조사일</th>
            <th>담당자</th>
            <th>연락처</th>
          </tr>
          </thead>
          <tbody
              v-for="nursingroom in nursingroomList"
              :key="nursingroom.topRoomNo"
              @click="
                            $router.push({
                                path:
                                    config.contextRoot +
                                    '/site/' +
                                    $route.params.siteNo +
                                    '/nursingroom/' +
                                    nursingroom.topRoomNo +
                                    '/edit',
                            })
                        "
          >
          <tr>
            <td v-if="isShow">
              <label
              ><input
                  type="checkbox"
                  v-model="deleteNursingroomNoList"
                  :value="nursingroom.topRoomNo"
                  @click.stop=""
              />
              </label>
            </td>
            <td>{{ nursingroom.rowNumber }}</td>
            <td>{{ nursingroom.zoneName }}</td>
            <td>{{ nursingroom.cityName }}</td>
            <td>{{ nursingroom.roomName }}</td>
            <!-- 기관구분 -->
            <td v-if="nursingroom.organizationCode === null"></td>
            <td v-if="nursingroom.organizationCode === '1'">청사</td>
            <td v-if="nursingroom.organizationCode === '2'">공공기관</td>
            <td v-if="nursingroom.organizationCode === '3'">교통시설</td>
            <td v-if="nursingroom.organizationCode === '4'">공중(다중)시설</td>
            <td v-if="nursingroom.organizationCode === '5'">학교</td>
            <td v-if="nursingroom.organizationCode === '6'">기업체(민간기관)</td>

            <td>{{ nursingroom.location }}</td>


            <!-- 대상 -->
            <td v-if="!nursingroom.useTypeCode"></td>
            <td v-if="nursingroom.useTypeCode === '0'">직원용</td>
            <td v-if="nursingroom.useTypeCode === '1'">외부인</td>
            <td v-if="nursingroom.useTypeCode === '2'">외부/직원용</td>
            <td>
              {{nursingroom.fatherUseYn == "0" ? "X" : nursingroom.fatherUseYn == "1" ? "O" : "" }}
            </td>
            <td>{{ nursingroom.ratePoint }}</td>
            <td>{{ nursingroom.useCount }}</td>
            <td>{{ nursingroom.firstRegistDate }}</td>
            <td>{{ nursingroom.surveyOrganizationName }}</td>
            <td>{{ nursingroom.surveyName }}</td>
            <td>{{ nursingroom.surveyDate }}</td>

            <td>{{ nursingroom.managerName }}</td>
            <td>{{ nursingroom.managerTelNo }}</td>
          </tr>
          </tbody>
        </table>

        <!-- 페이지네이션-->
        <div
            class="pagination marginTop-50"
            v-if="pageNoList && pageNoList.length > 0"
        >
          <ul>
            <li>
              <a
                  href="#"
                  @click.prevent="doSearchPage(1)"
                  title="첫 페이지"
              >
                <img
                    src="@/assets/images/component/pagination-first.png"
                    alt="첫 페이지"
                />
              </a>
            </li>
            <li v-show="isPrev">
              <a
                  href="#"
                  @click.prevent="doSearchPage(startPageNo - 1)"
                  :title="'이전' + displayPageCount + '페이지'"
              >
                <img
                    src="@/assets/images/component/pagination-prev.png"
                    :alt="'이전' + displayPageCount + '페이지'"
                />
              </a>
            </li>
            <li v-for="pageNo in pageNoList" :key="pageNo">
              <a
                  href="#"
                  :class="
                                    pagination.current_page == pageNo
                                        ? 'active'
                                        : ''
                                "
                  @click.prevent="doSearchPage(pageNo)"
              >
                {{ pageNo }}
              </a>
            </li>
            <li v-show="isNext">
              <a
                  href="#"
                  @click.prevent="doSearchPage(endPageNo + 1)"
                  :title="'다음' + displayPageCount + '페이지'"
              >
                <img
                    src="@/assets/images/component/pagination-next.png"
                    :alt="'다음' + displayPageCount + '페이지'"
                />
              </a>
            </li>
            <li>
              <a
                  href="#"
                  @click.prevent="doSearchPage(realEndPageNo)"
                  title="마지막 페이지"
              >
                <img
                    src="@/assets/images/component/pagination-last.png"
                    alt="마지막 페이지"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- 하단 버튼 바 -->
      <div class="contentButtonBar">
        <div class="buttonWrap floatLeft">
          <router-link
              :to="{
                            path:
                                config.contextRoot + '/site/1/nursingroom/create',
                        }"
              class="radiusSmallBtn bgBlue"
          >
            신규
          </router-link>
        </div>
        <div class="buttonWrap floatRight">
          <button
              class="radiusSmallBtn bgTuftsBlue"
              @click.prevent="deleteNursingroomList()"
          >
            선택 삭제
          </button>
        </div>
      </div>
    </div>
    <div id="dimmedPopup" v-show="isShowDimmedPopup">
      <p>회원님의 설정된 조사지역이 없습니다. 관리자에게 문의하십시오.</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "tui-date-picker";
import "tui-date-picker/dist/tui-date-picker.min.css";
import ExcelUpload from "@/components/nursingroom/ExcelUpload.vue";

export default {
  name: "NursingroomList",
  components: {
    ExcelUpload,
  },
  data() {
    return {
      siteNo: "",
      nursingroomList: [],
      pagination: {},
      searchForm: {
        siteNo: "1",
        zoneName: "",
        cityName: "",
        useTypeCode: "",
        fatherUseYn: "",
        roomName: "",
        startDate: "",
        endDate: "",
        countPerPage: 10,
        pageNo: 1,
        organizationCode: "",
        roomTypeCode: "",
        peakCountMin: "",
        peakCountMax: "",
        areaMin: "",
        areaMax: "",
        areaUnit: "",
        startDate2: "",
        endDate2: "",
        surveyOrganizationName: "",
        surveyName: "",
        useAvgAdayCode: "",
        carePeriodCode: "",

        selectAllYn: "Y",
      },
      displayPageCount: 10,
      isPrev: "",
      isNext: "",
      startPageNo: "",
      endPageNo: "",
      realEndPageNo: "",
      pageNoList: [],
      deleteNursingroomNoList: [],
      allChecked: false,
      zoneList: [],
      cityList: [],
      showDetailSearch: false,
      userLocationList: [],
      userRoleList: [],
      isShow: false,
      isShowDimmedPopup: false,

      isPopup: false,
    };
  },
  created() {
    this.siteNo = this.$route.params.siteNo;

    // 관리자, 조사자 화면 나눔
    this.userRoleList = sessionStorage.getItem("roleName").split(";");
    var userNo = sessionStorage.getItem("userNo")

    if (sessionStorage.getItem("search_condition" + this.$router.currentRoute.path) != null) {
      this.searchForm = JSON.parse(sessionStorage.getItem("search_condition" + this.$router.currentRoute.path));
    }


    if (!this.userRoleList.includes("ROLE_ADMIN")) {
      //조사자 화면
      this.selectUserLocationList(userNo);
    } else {
      // 관리자 화면
      this.isShow = true;
      this.selectZoneList();
      this.selectNursingroomList();
    }
  },
  watch: {
    deleteNursingroomNoList() {
      if (this.nursingroomList.length != this.deleteNursingroomNoList.length) {
        this.allChecked = false;
      } else {
        this.allChecked = true;
      }
    },

  },
  mounted() {
    // 데이트피커
    var today = new Date();
    const rangePicker = new DatePicker.createRangePicker({
      startpicker: {
        date: today,
        input: "#startDate",
        container: "#startDatePicker",
      },
      endpicker: {
        date: today,
        input: "#endDate",
        container: "#endDatePicker",
      },
      language: "ko",
    });
    rangePicker.on("change:start", () => {
      this.searchForm.startDate =
          document.getElementById("startDate").value;
    });
    rangePicker.on("change:end", () => {
      this.searchForm.endDate = document.getElementById("endDate").value;
    });

    // 상세 검색 조사일 데이트피커
    const rangePicker2 = new DatePicker.createRangePicker({
      startpicker: {
        date: today,
        input: "#startDate2",
        container: "#startDatePicker2",
      },
      endpicker: {
        date: today,
        input: "#endDate2",
        container: "#endDatePicker2",
      },
      language: "ko",
    });
    rangePicker2.on("change:start", () => {
      this.searchForm.startDate2 =
          document.getElementById("startDate2").value;
    });
    rangePicker2.on("change:end", () => {
      this.searchForm.endDate2 = document.getElementById("endDate2").value;
    });
  },
  methods: {
    selectUserLocationList(userNo) {
      axios
          .post(this.config.contextRoot + "/member/selectMember.do",
              {"userNo": userNo}
          )
          .then((response) => {
            if (!response.data.memberInfo.userLocationText) {
              this.isShowDimmedPopup = true;
              return;
            } else {
              this.$refs.optionNull.remove();
              this.zoneList = response.data.memberInfo.userLocationText.split(",");
              this.searchForm.zoneName = this.zoneList[0];
              this.selectNursingroomList();
            }
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },
    selectZoneList() {
      axios
          .post(
              this.config.contextRoot + "/nursingroom/selectZoneList.do"
          )
          .then((response) => {
            this.zoneList = response.data.zoneList;
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },
    selectCityList() {
      this.searchForm.cityName = "";
      axios
          .post(
              this.config.contextRoot + "/nursingroom/selectCityList.do",
              {"zoneName": this.searchForm.zoneName}
          )
          .then((response) => {
            this.cityList = response.data.cityList;
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },
    selectNursingroomList() {
      if (this.searchForm != null && JSON.stringify(this.searchForm) != null) {
        sessionStorage.setItem("search_condition" + this.$router.currentRoute.path, JSON.stringify(this.searchForm));
      }

      // 조사자 권한 사용자는 현재 실태 연도 조회
      if (this.userRoleList.includes("ROLE_ADMIN")) {
        this.searchForm.selectAllYn = 'Y';
      } else {
        this.searchForm.selectAllYn = 'Y';

        if (this.userRoleList.includes("ROLE_SEARCH")) {
          this.searchForm.selectAllYn = 'N';
        }
      }

      axios
          .post(
              this.config.contextRoot + "/nursingroom/selectNursingroomList.do",
              this.searchForm
          )
          .then((response) => {
            this.nursingroomList = response.data.nursingroomList;
            this.pagination = response.data.pagination;
            this.calcPagination();
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },
    doSearchPage(pageNo) {
      this.logger.debug(this, "doSearchPage()");

      // 수유실 면적을 입력했는데 단위를 선택하지 않으면 알림창 띄움
      if ((this.searchForm.areaMin || this.searchForm.areaMax) && !this.searchForm.areaUnit) {
        alert("수유실 면적 단위를 선택해주세요.");
      } else {
        if (typeof pageNo === "undefined") {
          pageNo = 1;
        }
        this.deleteNursingroomNoList = [];
        this.searchForm.pageNo = pageNo;
        this.selectNursingroomList();
      }
    },
    calcPagination() {
      this.logger.debug(this, "calcPagination()");
      this.endPageNo =
          Math.ceil(
              this.pagination.current_page / this.displayPageCount
          ) * this.displayPageCount;
      this.startPageNo = this.endPageNo - this.displayPageCount + 1;

      this.realEndPageNo = Math.ceil(
          this.pagination.total / this.pagination.per_page
      );

      if (this.startPageNo < 0) {
        this.startPageNo = 1;
      }
      if (this.endPageNo > this.realEndPageNo) {
        this.endPageNo = this.realEndPageNo;
      }

      this.isPrev = this.startPageNo == 1 ? false : true;
      this.isNext =
          this.endPageNo * this.pagination.per_page >=
          this.pagination.total
              ? false
              : true;

      this.pageNoList = [];
      for (let i = this.startPageNo; i <= this.endPageNo; i++) {
        this.pageNoList.push(i);
      }
    },
    checkAll() {
      this.deleteNursingroomNoList = [];

      if (!this.allChecked) {
        for (let nursingroom of this.nursingroomList) {
          this.deleteNursingroomNoList.push(nursingroom.topRoomNo);
        }
      }

      this.allChecked = !this.allChecked;
    },
    deleteNursingroomList() {
      if (this.deleteNursingroomNoList.length == 0) {
        alert("삭제항목이 선택되지 않았습니다.");
        return;
      }

      if (!confirm("선택한 항목을 삭제 하시겠습니까?")) {
        return;
      }

      const roomNames = [];
      for (let nursingroomNo of this.deleteNursingroomNoList) {
        const nursingroom = this.nursingroomList.find(item => item.topRoomNo === nursingroomNo);
        if (nursingroom) {
          roomNames.push(nursingroom.roomName);
        }
      }
      const roomNamesString = roomNames.join(', ');
      //console.log("Room Names:", roomNamesString);

      if (!confirm(roomNamesString + "을(를) 정말 삭제 하시겠습니까?")) {
        return;
      }

      const formData = {deleteNursingroomNoList: this.deleteNursingroomNoList};
      axios
          .post(
              this.config.contextRoot + "/nursingroom/deleteNursingroomList.do",
              formData
          )
          .then((response) => {
            if (response.status == 200) {
              // 마지막 페이지에서 전체 선택 삭제할 경우
              if (
                  this.startPageNo != this.realEndPageNo &&
                  this.searchForm.pageNo == this.realEndPageNo &&
                  this.deleteNursingroomNoList.length ==
                  this.nursingroomList.length
              ) {
                this.$set(
                    this.searchForm,
                    "pageNo",
                    Number(this.searchForm.pageNo) - 1 + ""
                );
              }

              alert("삭제 되었습니다.");
              this.deleteNursingroomNoList = [];
              this.doSearchPage(this.searchForm.pageNo);
            }
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },
    doDetailSearch(event) {
      this.showDetailSearch = !this.showDetailSearch;

      // 상세 검색 버튼 문구 & 배경색 변경
      if (this.showDetailSearch) {
        event.target.innerText = '상세 검색 닫기';
        event.target.style.backgroundColor = 'red';
      } else {
        event.target.innerText = '상세 검색';
        event.target.style.backgroundColor = '';
      }
    },
    downloadExcel() {
      this.searchForm.selectAllYn = 'Y';

      // 조사자 권한 사용자는 현재 실태 연도 조회
      if (this.userRoleList.includes("ROLE_SEARCH")) {
        this.searchForm.selectAllYn = 'N';
      }

      axios
          .post(this.config.contextRoot + "/nursingroom/downloadExcel.do", this.searchForm, {responseType: 'blob'},)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.ms-excel'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '수유실 목록.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },
    downloadExcelForm() {
      axios
          .post(
              this.config.contextRoot + "/nursingroom/downloadExcelForm.do",
              {},
              {responseType: 'blob'},
          )
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.ms-excel'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '수유실 목록.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },

  },
};
</script>

<style scoped>
.tui-datepicker {
  z-index: 9;
}

.detailSearch .labelWrap > label {
  width: 140px;
  text-align: left;
}

.detailSearch .labelWrap > label:first-child {
  width: 90px;
  text-align: right;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#dimmedPopup {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.692);
  top: 74px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

#dimmedPopup p {
  margin: 0 auto;
  top: 48%;
  left: 50%;
  color: #fff;
  position: absolute;
}

</style>