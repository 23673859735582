<template>
	<div class="contentLayout">
		<div class="contentNavigationBar">
			<h2 class="title">API로그</h2>
		</div>
		<div class="contentBody">
			<!-- 검색 -->
			<div class="searchBar">
				<div class="columnDivision-3">
					<div class="labelWrap">
						<label>API KEY</label>
						<input type="text" 
							class="inputBox" 
							v-model="searchForm.confirmApiKey" 
							style="width:300px; margin-left:10px;"
						/>
					</div>
					<div class="labelWrap" style="margin-bottom:10px;">
						<label>사용일자</label>
						<div class="datePicker">
							<input
								type="text"
								class="inputBox"
								placeholder="연도-월-일"
								id="startDate"
								v-model="searchForm.startDt"
								autocomplete="off"
								style="margin-left:10px;"
							/>
							<div ref="startDatePicker" id="startDatePicker"></div>
							<i></i>
						</div>
					</div>
					<div class="labelWrap">
						<label class="textCenter" style="width: 30px">&nbsp;&nbsp;-</label>
						<div class="datePicker">
							<input
								type="text"
								class="inputBox"
								placeholder="연도-월-일"
								id="endDate"
								v-model="searchForm.endDt"
								autocomplete="off"
							/>
							<i></i>
							<div ref="endDatePicker" id="endDatePicker"></div>
							<i></i>
						</div>
					</div>
					<div class="labelWrap">
						<label>사용번호</label>
						<label style="margin-right: 15px;">
							<input
								type="number"
								class="inputBox"
								style="width: 90px;" 
								v-model="searchForm.useNo"
							/>
						</label> 
					</div>
					<div class="labelWrap">
						<label>응답상태</label>
						<label>
							<select
								class="selectBox"
								style="width: auto;"
								v-model="searchForm.responseCode"
							>
								<option value="" selected>전체</option>
								<option value="200" selected>성공</option>
								<option value="500" selected>실패</option>
							</select>
						</label>
					</div>
					<div class="labelWrap">
						<label>요청IP</label>
						<input type="text" 
							class="inputBox" 
							v-model="searchForm.requestIpAddr" 
							style="width:300px; margin-left:2px;"
						/>
					</div>
				</div>

				<button class="searchBtn" @click="doSearchPage(1)">조회하기</button>
			</div>
			<h3 class="contentTitle marginTop-35">API로그 목록</h3>
			<div class="listWrap">
				<!-- 목록 상단 -->
				<div class="listTop">
					<span class="listTotal"
						>(Total <em>{{ pagination.total }}</em
						>)</span
					>
					<select class="selectBox" @change.prevent="doSearchPage(1)" v-model="searchForm.countPerPage">
						<option>5</option>
						<option selected>10</option>
						<option>20</option>
						<option>30</option>
						<option>50</option>
						<option>100</option>
					</select>
				</div>

				<!-- 목록테이블 -->
				<table class="listTable">
					<colgroup>
						<!-- <col style="width: 5%" /> -->
						<col style="width: 5%" />
						<col style="width: 30%" />
						<col style="width: 10%" />
						<col style="width: 10%" />
						<col style="width: 7%" />
						<col style="width: 20%" />
					</colgroup>
					<thead>
						<tr>
							<!-- <th>
								<label><input type="checkbox" @click="checkAll" v-model="allChecked" /></label>
							</th> -->
							<th>번호</th>
							<th>API KEY</th>
							<th>사용일자</th>
							<th>사용번호</th>
							<th>응답상태</th>
							<th>요청IP</th>
						</tr>
					</thead>
					<tbody
						v-for="apilog in apilogList"
						:key="apilog.confirmApiKey + apilog.useDt + apilog.useNo"
						@click="
							$router.push({
								path:
									config.contextRoot +
									'/site/' +
									$route.params.siteNo +
									'/apilog/' +
									apilog.confirmApiKey + '/' +
									apilog.useDt + '/' +
									apilog.useNo + 
									'/edit',
								query: {
									confirmApiKey: $route.query.confirmApiKey,
								},
							})
						"
					>
						<tr>
							<!-- <td>
								<label>
									<input
										type="checkbox"
										v-model="deleteApilogNoList"
										:value="apilog.contentsNo"
										@click.stop=""
								/></label>
							</td> -->
							<td>{{ apilog.rowNumber }}</td>
							<td>{{ apilog.confirmApiKey }}</td>
							<td>{{ apilog.useDt.substr(0, 4) + '-' + apilog.useDt.substr(4, 2) + '-' + apilog.useDt.substr(6)}}</td>
							<td>{{ apilog.useNo }}</td>
							<td>
								{{ apilog.responseCode == "200" ? "성공" : "실패" }}
							</td>
							<td>{{ apilog.requestIpAddr }}</td>
						</tr>
					</tbody>
					<tbody v-if="apilogList.length == 0">
						<tr>
							<td colspan="6">
								검색결과가 없습니다.
							</td>
						</tr>
					</tbody>
				</table>

				<!-- 페이지네이션 -->
				<pagination
					v-if="pagination.total > pagination.per_page"
					v-model="pagination.current_page"
					:records="pagination.total"
					:per-page="pagination.per_page"
					@paginate="doSearchPage"
					:options="{
						texts: {
							count: '',
							first: '',
							last: '',
						},
					}"
				/>
			</div>

			<!-- 하단 버튼 바 -->
			<div class="contentButtonBar">
				<!-- <div class="buttonWrap floatRight">
					<button class="radiusSmallBtn bgTuftsBlue" @click.prevent="deleteApilogList()">선택 삭제</button>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import DatePicker from "tui-date-picker";
import "tui-date-picker/dist/tui-date-picker.min.css"; 
import Pagination from "vue-pagination-2";

export default {
	name: "ApilogList",
	data() {
		return {
			siteNo: "",
			apilogList: [],
			pagination: {},
			searchForm: {
				confirmApiKey: "",
				startDt: "",
				endDt: "",
				useNo: "",
				responseCode: "",
				requestIpAddr: "",
				countPerPage: 10,
				pageNo: 1,
			},
			displayPageCount: 10,
			isPrev: "",
			isNext: "",
			startPageNo: "",
			endPageNo: "",
			realEndPage: "",
			pageNoList: [],
			deleteApilogNoList: [],
			allChecked: false,
		};
	},
	components: { Pagination },
	created() {
		this.siteNo = this.$route.params.siteNo;
		if(this.$route.query.confirmApiKey){
				this.searchForm.confirmApiKey = this.$route.query.confirmApiKey;
		}

		this.selectApilogList();
	},
	watch: {
		deleteApilogNoList() {
			if (this.apilogList.length != this.deleteApilogNoList.length) {
				this.allChecked = false;
			} else {
				this.allChecked = true;
			}
		},
	},
	mounted() {
		// 데이트피커
		var today = new Date();
		const rangePicker = new DatePicker.createRangePicker({
			startpicker: {
				date: today,
				input: "#startDate",
				container: "#startDatePicker",
			},
			endpicker: {
				date: today,
				input: "#endDate",
				container: "#endDatePicker",
			},
			language: "ko",
		});
		rangePicker.on("change:start", () => {
			this.searchForm.startDt = document.getElementById("startDate").value;
		});
		rangePicker.on("change:end", () => {
			this.searchForm.endDt = document.getElementById("endDate").value;
		});
	},
	methods: {
		selectApilogList() {
			this.logger.debug(this, "selectApilogList()");
			this.searchForm.siteNo = this.siteNo;
			
			if(this.$route.query.confirmApiKey != this.searchForm.confirmApiKey){
				this.$router.replace({'query': null});
			}

			axios
				.post(this.config.contextRoot + "/api/selectApilogList.do", this.searchForm)
				.then((response) => {
					this.apilogList = response.data.apilogList;
					this.pagination = response.data.pagination;
					this.calcPagination();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},

		doSearchPage(pageNo) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof pageNo === "undefined") {
				pageNo = 1;
			}
			this.deleteApilogNoList = [];
			this.searchForm.pageNo = pageNo;
			this.selectApilogList();
		},
		calcPagination() {
			this.logger.debug(this, "calcPagination()");
			this.endPageNo = Math.ceil(this.pagination.current_page / this.displayPageCount) * this.displayPageCount;
			this.startPageNo = this.endPageNo - this.displayPageCount + 1;

			this.realEndPageNo = Math.ceil(this.pagination.total / this.pagination.per_page);

			if (this.startPageNo < 0) {
				this.startPageNo = 1;
			}
			if (this.endPageNo > this.realEndPageNo) {
				this.endPageNo = this.realEndPageNo;
			}

			this.isPrev = this.startPageNo == 1 ? false : true;
			this.isNext = this.endPageNo * this.pagination.per_page >= this.pagination.total ? false : true;

			this.pageNoList = [];
			for (let i = this.startPageNo; i <= this.endPageNo; i++) {
				this.pageNoList.push(i);
			}
		},
		checkAll() {
			this.deleteApilogNoList = [];

			if (!this.allChecked) {
				for (let apilog of this.apilogList) {
					this.deleteApilogNoList.push(apilog.contentsNo);
				}
			}

			this.allChecked = !this.allChecked;
		},
		deleteApilogList() {
			if (this.deleteApilogNoList.length == 0) {
				alert("삭제항목이 선택되지 않았습니다.");
				return;
			}

			if (!confirm("선택한 항목을 삭제 하시겠습니까?")) {
				return;
			}

			const formData = {
				deleteApilogNoList: this.deleteApilogNoList,
			};
			axios
				.post(this.config.contextRoot + "/apilog/deleteApilogList.do", formData)
				.then((response) => {
					if (response.status == 200) {
						// 마지막 페이지에서 전체 선택 삭제할 경우
						if (
							this.startPageNo != this.realEndPageNo &&
							this.searchForm.pageNo == this.realEndPageNo &&
							this.deleteApilogNoList.length == this.apilogList.length
						) {
							this.$set(this.searchForm, "pageNo", Number(this.searchForm.pageNo) - 1 + "");
						}

						alert("삭제 되었습니다.");
						this.doSearchPage(this.searchForm.pageNo);
					}
				})
				.catch((error) => {
					if (error.response.data.message == "SQLIntegrityConstraintViolationException") {
						alert("해당 API로그은 메뉴관리에서 사용 중이므로 삭제할 수 없습니다.");
						return;
					}

					this.exceptionHandler(this, error);
				});
		},
	},
};
</script>

<style></style>
