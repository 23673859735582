<template>
	<div class="contentLayout">
		<div class="contentNavigationBar">
			<h2 class="title">조사결과 관리</h2>
		</div>
		<div class="contentBody">
			<!-- 검색 -->
			<div class="searchBar">
				<div class="columnDivision-3">
					<div class="labelWrap">
						<label style="width:120px;">만족도조사구분</label>
						<label>
							<select
								style="width: 180px"
								class="selectBox"
								v-model="searchForm.surveyDivCode"
							>
								<option value="">-- 전체 --</option>
								<option value="1">모유수유담당자교육</option>
								<option value="2">만족도조사지원</option>
							</select>
						</label>
					</div>
					<div class="labelWrap">
						<label style="width:100px;">만족도조사명</label>
						<input
							type="text"
							class="inputBox"
							v-model="searchForm.surveyName"
							style="width:300px;"
						/>
					</div>
				</div>
				<div class="columnDivision-3" style="margin-top: 20px; margin-left: 30px;">
					<div class="labelWrap">
                        <label>교육명</label>
                        <select
                            class="selectBox"
                            v-model="searchForm.programNo"
                            style="width:300px; margin-left: 10px;"
                        >
                            <option value="">-- 전체 --</option>
                            <option :key="idx3" :value="program.programNo" v-for="(program, idx3) in programList">{{ program.programName }}</option>
                        </select>
                    </div>
					<div class="labelWrap" style="margin-top: 0px;">
						<label style="width:100px;">참여자명</label>
						<input
							type="text"
							class="inputBox"
							v-model="searchForm.answerUserName"
							style="width:200px;"
						/>
					</div>
				</div>
				<div class="columnDivision-3" style="margin-top: 20px; margin-left: 30px;">
					<div class="labelWrap">
                        <label>참여일자</label>
                        <div class="datePicker">
                            <input
                                type="text"
                                class="inputBox"
                                placeholder="연도-월-일"
                                id="startDate"
                                v-model="searchForm.answerStartDt"
                                autocomplete="off"
                            />
                            <div
                                ref="startDatePicker"
                                id="startDatePicker"
                            ></div>
                            <i></i>
                        </div>
                    </div>
                    <div class="labelWrap">
                        <label class="textCenter" style="width: 30px">-</label>
                        <div class="datePicker">
                            <input
                                type="text"
                                class="inputBox"
                                placeholder="연도-월-일"
                                id="endDate"
                                v-model="searchForm.answerEndDt"
                                autocomplete="off"
                            />
                            <i></i>
                            <div ref="endDatePicker" id="endDatePicker"></div>
                        </div>
                    </div>
				</div>
				
				<button class="searchBtn" @click="doSearchPage(1)">
					조회하기
				</button>
			</div>

			<div class="contentTitleWrap marginTop-35">
				<h3 class="contentTitle">조사결과 목록</h3>
			</div>
			<div class="listWrap">
				<!-- 목록 상단 -->
				<div class="listTop">
					<span class="listTotal"
						>(Total <em>{{ pagination.total }}</em
						>)</span
					>
					<select
						class="selectBox"
						@change.prevent="doSearchPage(1)"
						v-model="searchForm.countPerPage"
					>
						<option>5</option>
						<option selected>10</option>
						<option>20</option>
						<option>30</option>
						<option>50</option>
						<option>100</option>
					</select>
				</div>

				<!-- 목록테이블 -->
				<table class="listTable tableFixed">
					<colgroup>
						<!-- <col style="width: 5%" /> -->
						<col style="width: 7%" />
						<col style="width: 11%" />
						<col style="width: 15%" />
						<col style="width: 10%" />
						<col style="width: 10%" />
						<col
							style="width: 20%"
							v-if="searchForm.surveyDivCode != '2'"
						/>
					</colgroup>
					<thead>
						<tr>
							<!-- <th>
								<label>
									<input
										type="checkbox"
										@click="doCheckAll"
										v-model="allChecked"
									/>
								</label>
							</th> -->
							<th>번호</th>
							<th>만족도조사구분</th>
							<th>만족도조사명</th>
							<th>참여자명</th>
							<th>만족도조사 참여일자</th>
							<th v-if="searchForm.surveyDivCode != '2'">
								교육프로그램
							</th>
						</tr>
					</thead>
					<tbody
						v-for="row in answerList"
						:key="row.surveyNo"
						@click="
							$router.push({
								path:
									config.contextRoot +
									'/site/' +
									$route.params.siteNo +
									'/answer/' +
									row.surveyNo + '/' +
									row.surveyDivCode + '/' +
									row.answerUserNo + 
									'/edit',
							})
						"
					>
						<tr>
							<!-- <td @click.stop="">
								<label
									><input
										type="checkbox"
										v-model="checkedList"
										:value="row.surveyNo"
									/>
								</label>
							</td> -->
							<td>{{ row.rowNumber }}</td>
							<td>
								{{
									row.surveyDivCode == "1"
										? "모유수유담당자교육"
										: "만족도조사지원"
								}}
							</td>
							<td>{{ row.surveyName }}</td>
							<td>
								{{ row.answerUserName }}
							</td>
							<td>
								{{ row.firstRegistDate }}
							</td>
							<td v-if="searchForm.surveyDivCode != '2'">
								{{ row.programName }}
							</td>
						</tr>
					</tbody>
				</table>

				<!-- 페이지네이션 -->
				<pagination
					v-if="pagination.total > pagination.per_page"
					v-model="pagination.current_page"
					:records="pagination.total"
					:per-page="pagination.per_page"
					@paginate="doSearchPage"
					:options="{
						texts: {
							count: '',
							first: '',
							last: '',
						},
					}"
				/>
			</div>

			<!-- 하단 버튼 바 -->
			<div class="contentButtonBar">
				<div class="buttonWrap floatRight">
					<!-- <button
						class="radiusSmallBtn bgTuftsBlue"
						@click.prevent="deleteCheckedList()"
					>
						선택 삭제
					</button> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import "tui-date-picker/dist/tui-date-picker.min.css";
import DatePicker from "tui-date-picker";
import Pagination from "vue-pagination-2";


export default {
	name: "SurveyList",
	components: { Pagination },
	data() {
		return {
			siteNo: "",
			searchForm: {
				countPerPage: 10,
				pageNo: 1,
				surveyDivCode: "",
				surveyName: "",
				programNo: "",
				answerUserName: "",
				answerStartDt: "",
				answerEndDt: "",
			},
			answerList: [],
			pagination: {},
			allChecked: false,
			checkedList: [],

			programList: [],
		};
	},
	created() {
		this.logger.debug(this, "created()");
		this.siteNo = this.$route.params.siteNo;
		this.selectList();
		this.selectProgramOptionList();
	},
	watch: {
		checkedList() {
			if (this.answerList.length != this.checkedList.length) {
				this.allChecked = false;
			} else {
				this.allChecked = true;
			}
		},
	},
	mounted() {
		var today = new Date();
		const rangePicker = new DatePicker.createRangePicker({
            startpicker: {
                date: today,
                input: "#startDate",
                container: "#startDatePicker",
            },
            endpicker: {
                date: today,
                input: "#endDate",
                container: "#endDatePicker",
            },
            language: "ko",
        });
        rangePicker.on("change:start", () => {
            this.searchForm.answerStartDt =
                document.getElementById("startDate").value;
        });
        rangePicker.on("change:end", () => {
            this.searchForm.answerEndDt = document.getElementById("endDate").value;
        });
	},
	methods: {
		selectList() {
			this.logger.debug(this, "selectList()");
			axios
				.post(
					this.config.contextRoot + "/survey/selectAnswerList.do",
					this.searchForm
				)
				.then((response) => {
					this.answerList = response.data.answerList;
					this.pagination = response.data.pagination;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(pageNo) {
			this.logger.debug(this, "doSearchPage()");

			if (typeof pageNo === undefined) {
				pageNo = 1;
			}
			this.checkedList = [];
			this.searchForm.pageNo = pageNo;
			this.selectList();
		},
		doCheckAll() {
			this.checkedList = [];

			if (!this.allChecked && this.answerList.length > 0) {
				for (let row of this.answerList) {
					this.checkedList.push(row.surveyNo);
				}
			}

			this.allChecked = !this.allChecked;
		},
		deleteCheckedList() {
			if (this.checkedList.length == 0) {
				alert("삭제항목이 선택되지 않았습니다.");
				return;
			}

			if (!confirm("선택한 항목을 삭제 하시겠습니까?")) {
				return;
			}

			const formData = {
				checkedList: this.checkedList,
			};

			axios
				.post(
					this.config.contextRoot + "/survey/deleteList.do",
					formData
				)
				.then((response) => {
					if (response.status == 200) {
						alert("삭제 되었습니다.");
						this.checkedList = [];
						this.doSearchPage(1);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectProgramOptionList() {
            this.logger.debug(this, "selectProgramOptionList()");
            axios
                .post(
                    this.config.contextRoot + "/program/selectProgramOptionList.do"
                )
                .then((response) => {
                    this.programList = response.data.programList;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
	},
};
</script>
