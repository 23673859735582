<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">메뉴관리</h2>
        </div>
        <div class="contentBody">
            <!-- 검색 -->
            <div class="searchBar">
                <div class="columnDivision-3">
                    <div class="labelWrap">
                        <label>메뉴명</label>
                        <input
                            type="text"
                            class="inputBox"
                            v-model="searchForm.menuName"
                        />
                    </div>
                    <div class="labelWrap">
                        <label>메뉴타입</label>
                        <input
                            type="text"
                            class="inputBox"
                            v-model="searchForm.menuTypeCodeName"
                        />
                    </div>
                    <div class="labelWrap">
                        <label>컨텐츠명</label>
                        <input
                            type="text"
                            class="inputBox"
                            v-model="searchForm.contentsTitle"
                        />
                    </div>
                </div>
                <button class="searchBtn" @click="doSearchPage(1)">
                    조회하기
                </button>
            </div>

            <h3 class="contentTitle marginTop-35">메뉴목록</h3>
            <div class="listWrap">
                <!-- 목록 상단 -->
                <div class="listTop">
                    <span class="listTotal"
                        >(Total <em>{{ pagination.total | makeComma }}</em
                        >)</span
                    >
                    <select
                        class="selectBox"
                        @change.prevent="doSearchPage(1)"
                        v-model="searchForm.countPerPage"
                    >
                        <option>5</option>
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                </div>

                <!-- 트리 테이블 -->
                <table class="treeTable">
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>메뉴명</th>
                            <th>수청 추가 삭제</th>
                            <th>메뉴타입</th>
                            <th>메뉴레벨</th>
                            <th>컨텐츠명</th>
                            <th>게시여부</th>
                            <th>생성일자</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="menu in menuList" :key="menu.menuNo">
                            <td>{{ menu.rowNumber }}</td>
                            <td :class="'depth-' + menu.menuLevel">
                                {{ menu.menuName }}
                                <a @click="openShortcutPopup(menu)">[바로가기]</a>
                            </td>
                            <td>
                                <router-link
                                    :to="{
                                        path:
                                            config.contextRoot +
                                            '/site/' +
                                            $route.params.siteNo +
                                            '/menu/' +
                                            menu.menuNo +
                                            '/edit',
                                    }"
                                    class="treeBtn editType"
                                    title="메뉴 수정"
                                >
                                    <span class="blind">수정</span>
                                </router-link>
                                <router-link
                                    :to="{
                                        path:
                                            config.contextRoot +
                                            '/site/' +
                                            $route.params.siteNo +
                                            '/menu/create',
                                        query: { upperMenuNo: menu.menuNo },
                                    }"
                                    class="treeBtn addType"
                                    title="하위 메뉴 추가"
                                >
                                    <span class="blind">하위 메뉴 추가</span>
                                </router-link>
                                <span 
                                    v-if="menu.menuLevel == 1"
                                    class="treeBtn"
                                >
                                </span>
                                <button
                                    v-else
                                    type="button"
                                    class="treeBtn deleteType"
                                    @click.prevent="deleteMenu(menu.menuNo)"
                                    title="메뉴 삭제"
                                >
                                    <span class="blind">삭제</span>
                                </button>
                            </td>
                            <td>{{ menu.menuTypeCodeName }}</td>
                            <td>{{ menu.menuLevel }}</td>
                            <td>{{ menu.contentsTitle }}</td>
                            <td>
                                {{
                                    menu.menuPublishYn == "Y"
                                        ? "사용"
                                        : "미사용"
                                }}
                            </td>
                            <td>{{ menu.menuAddDt }}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- 페이지네이션-->
                <div
                    class="pagination marginTop-50"
                    v-if="pageNoList && pageNoList.length > 0"
                >
                    <ul>
                        <li>
                            <a
                                href="#"
                                @click.prevent="doSearchPage(1)"
                                title="첫 페이지"
                            >
                                <img
                                    src="@/assets/images/component/pagination-first.png"
                                    alt="첫 페이지"
                                />
                            </a>
                        </li>
                        <li v-show="isPrev">
                            <a
                                href="#"
                                @click.prevent="doSearchPage(startPageNo - 1)"
                                :title="'이전' + displayPageCount + '페이지'"
                            >
                                <img
                                    src="@/assets/images/component/pagination-prev.png"
                                    :alt="'이전' + displayPageCount + '페이지'"
                                />
                            </a>
                        </li>
                        <li v-for="pageNo in pageNoList" :key="pageNo">
                            <a
                                href="#"
                                :class="
                                    pagination.current_page == pageNo
                                        ? 'active'
                                        : ''
                                "
                                @click.prevent="doSearchPage(pageNo)"
                            >
                                {{ pageNo }}
                            </a>
                        </li>
                        <li v-show="isNext">
                            <a
                                href="#"
                                @click.prevent="doSearchPage(endPageNo + 1)"
                                :title="'다음' + displayPageCount + '페이지'"
                            >
                                <img
                                    src="@/assets/images/component/pagination-next.png"
                                    :alt="'다음' + displayPageCount + '페이지'"
                                />
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                @click.prevent="doSearchPage(realEndPageNo)"
                                title="마지막 페이지"
                            >
                                <img
                                    src="@/assets/images/component/pagination-last.png"
                                    alt="마지막 페이지"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "MemberList",
    data() {
        return {
            siteNo: "",
            menuList: [],
            pagination: {},
            searchForm: {
                menuName: "",
                menuTypeCodeName: "",
                contentsTitle: "",
                countPerPage: 50,
                pageNo: 1,
            },
            displayPageCount: 10,
            isPrev: "",
            isNext: "",
            startPageNo: "",
            endPageNo: "",
            realEndPage: "",
            pageNoList: [],
        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.searchForm.pageNo = 1;
        this.selectMenuList();
    },
    methods: {
        selectMenuList() {
            this.logger.debug(this, "selectMenuList()");
            this.searchForm.siteNo = this.siteNo;
            axios
                .post(
                    this.config.contextRoot + "/menu/selectMenuList.do",
                    this.searchForm
                )
                .then((response) => {
                    this.menuList = response.data.menuList;
                    this.pagination = response.data.pagination;
                    this.calcPagination();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        doSearchPage(pageNo) {
            this.logger.debug(this, "doSearchPage()");
            if (typeof pageNo === "undefined") {
                pageNo = 1;
            }
            this.searchForm.pageNo = pageNo;
            this.selectMenuList();
        },
        calcPagination() {
            this.logger.debug(this, "calcPagination()");
            this.endPageNo =
                Math.ceil(
                    this.pagination.current_page / this.displayPageCount
                ) * this.displayPageCount;
            this.startPageNo = this.endPageNo - this.displayPageCount + 1;

            this.realEndPageNo = Math.ceil(
                this.pagination.total / this.pagination.per_page
            );

            if (this.startPageNo < 0) {
                this.startPageNo = 1;
            }
            if (this.endPageNo > this.realEndPageNo) {
                this.endPageNo = this.realEndPageNo;
            }

            this.isPrev = this.startPageNo == 1 ? false : true;
            this.isNext =
                this.endPageNo * this.pagination.per_page >=
                this.pagination.total
                    ? false
                    : true;

            this.pageNoList = [];
            for (let i = this.startPageNo; i <= this.endPageNo; i++) {
                this.pageNoList.push(i);
            }
        },
        deleteMenu(menuNo) {
            if (!confirm("삭제 하시겠습니까?")) {
                return;
            }

            const formData = {
                menuNo: menuNo,
            };

            axios
                .post(this.config.contextRoot + "/menu/deleteMenu.do", formData)
                .then((response) => {
                    if (response.status == 200) {
                        alert("삭제 되었습니다.");
                        this.selectMenuList();
                    }
                })
                .catch((error) => {
                    if (
                        error.response.data.message ==
                        "SQLIntegrityConstraintViolationException"
                    ) {
                        alert(
                            "해당 메뉴는 하위메뉴가 연결되어 있으므로 삭제할 수 없습니다."
                        );
                        return;
                    }

                    this.exceptionHandler(this, error);
                });
        },
        openShortcutPopup(menu){
            var path = "http://" + menu.siteAddress + "/home/" + menu.menuNo + ".htm";
            window.open(path, "newPage", 'top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no');
        }
    },
};
</script>

<style>
</style>