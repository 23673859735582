<template>
	<div class="contentLayout">
		<div class="contentNavigationBar">
			<h2 class="title">만족도조사 관리</h2>
		</div>
		<div class="contentBody">
			<h3 class="contentTitle">만족도조사 정보</h3>
			<div class="infoWrap">
				<table class="infoTable marginBottom-35 marginTop-15">
					<tbody>
						<tr>
							<th style="width:150px;">만족도조사구분</th>
							<td>
								{{
									item.surveyDivCode == "1"
										? "모유수유담당자교육"
										: "만족도조사지원"
								}}
							</td>
							<th style="width:150px;">
								설문명
							</th>
							<td>
								{{ item.surveyName }}
							</td>
						</tr>
						<tr v-if="item.surveyDivCode == 1">
							<th><span 
									>교육프로그램</span
								></th>
							<td>{{ item.programName }}</td>
							<th></th>
							<td></td>
						</tr>
						<tr>
							<th>참여자명</th>
							<td>
								{{ item.answerUserName }}
							</td>
							<th>참여일자</th>
							<td>
								{{ item.surveyStartDt }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<h3 class="contentTitle">
				만족도조사 결과
			</h3>
			<div class="infoWrap">
				<table class="listTable tableFixed infoTable marginBottom-35 marginTop-15">
					<colgroup>
						<col style="width: 15%" />
						<col style="width: 85%" />
					</colgroup>
					<thead>
						<tr>
							<th style="text-align: center">질문 번호</th>
							<th style="text-align: center">질문/답변</th>
						</tr>
					</thead>
					<tbody
						v-for="(row, index) in item.questionList"
						:key="index"
					>
						<tr v-if="row.questionTypeCode == 'RADIO'">
							<td style="text-align: center">
								{{ row.questionNo }}
							</td>
							<td>
								<h3>{{ row.questionName }}</h3>
								<div class="radioBoxWrap">
                                    <p v-if="row.radioOption1" style="margin-left: 10px; margin-top: 10px;">
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="row.answer.answerValue[0]"
                                            :value="row.radioOption1"
											disabled
                                        />
                                        <span style="margin-left: 10px;">{{row.radioOption1}}</span>
                                    </p>
                                    <p v-if="row.radioOption2" style="margin-left: 10px; margin-top: 10px;">
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="row.answer.answerValue[0]"
                                            :value="row.radioOption2"
											disabled
                                        />
                                        <span style="margin-left: 10px;">{{row.radioOption2}}</span>
                                    </p>
									<p v-if="row.radioOption3" style="margin-left: 10px; margin-top: 10px;">
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="row.answer.answerValue[0]"
                                            :value="row.radioOption3"
											disabled
                                        />
                                        <span style="margin-left: 10px;">{{row.radioOption3}}</span>
                                    </p>
									<p v-if="row.radioOption4" style="margin-left: 10px; margin-top: 10px;">
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="row.answer.answerValue[0]"
                                            :value="row.radioOption4"
											disabled
                                        />
                                        <span style="margin-left: 10px;">{{row.radioOption4}}</span>
                                    </p>
									<p v-if="row.radioOption5" style="margin-left: 10px; margin-top: 10px;">
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="row.answer.answerValue[0]"
                                            :value="row.radioOption5"
											disabled
                                        />
                                        <span style="margin-left: 10px;">{{row.radioOption5}}</span>
                                    </p>
									<p v-if="row.radioOption6" style="margin-left: 10px; margin-top: 10px;">
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="row.answer.answerValue[0]"
                                            :value="row.radioOption6"
											disabled
                                        />
                                        <span style="margin-left: 10px;">{{row.radioOption6}}</span>
                                    </p>
									<p v-if="row.radioOption7" style="margin-left: 10px; margin-top: 10px;">
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="row.answer.answerValue[0]"
                                            :value="row.radioOption7"
											disabled
                                        />
                                        <span style="margin-left: 10px;">{{row.radioOption7}}</span>
                                    </p>
									<p v-if="row.radioOption8" style="margin-left: 10px; margin-top: 10px;">
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="row.answer.answerValue[0]"
                                            :value="row.radioOption8"
											disabled
                                        />
                                        <span style="margin-left: 10px;">{{row.radioOption8}}</span>
                                    </p>
                                </div>
							</td>
						</tr>
						<tr v-if="row.questionTypeCode == 'CHECK'">
							<td style="text-align: center">
								{{ row.questionNo }}
							</td>
							<td>
								<h3>{{ row.questionName }}</h3>
								<p v-if="row.checkboxOption1" style="margin-left: 10px; margin-top: 10px;">
									<input
										type="checkbox"
										class="checkbox"
										:checked="row.answer.answerValue.indexOf(row.checkboxOption1) != -1"
										disabled
									/> <span style="margin-left: 10px;">{{ row.checkboxOption1 }}</span>
								</p>
								<p v-if="row.checkboxOption2" style="margin-left: 10px; margin-top: 10px;">
									<input
										type="checkbox"
										class="checkbox"
										:checked="row.answer.answerValue.indexOf(row.checkboxOption2) != -1"
										disabled
									/> <span style="margin-left: 10px;">{{ row.checkboxOption2 }}</span>
								</p>
								<p v-if="row.checkboxOption3" style="margin-left: 10px; margin-top: 10px;">
									<input
										type="checkbox"
										class="checkbox"
										:checked="row.answer.answerValue.indexOf(row.checkboxOption3) != -1"
										disabled
									/> <span style="margin-left: 10px;">{{ row.checkboxOption3 }}</span>
								</p>
								<p v-if="row.checkboxOption4" style="margin-left: 10px; margin-top: 10px;">
									<input
										type="checkbox"
										class="checkbox"
										:checked="row.answer.answerValue.indexOf(row.checkboxOption4) != -1"
										disabled
									/> <span style="margin-left: 10px;">{{ row.checkboxOption4 }}</span>
								</p>
								<p v-if="row.checkboxOption5" style="margin-left: 10px; margin-top: 10px;">
									<input
										type="checkbox"
										class="checkbox"
										v-model="row.answer.answerValue"
										:value="row.checkboxOption5"
										:checked="row.answer.answerValue.indexOf(row.checkboxOption5) != -1"
										disabled
									/> <span style="margin-left: 10px;">{{ row.checkboxOption5 }}</span>
								</p>
								<p v-if="row.checkboxOption6" style="margin-left: 10px; margin-top: 10px;">
									<input
										type="checkbox"
										class="checkbox"
										v-model="row.answer.answerValue"
										:value="row.checkboxOption6"
										:checked="row.answer.answerValue.indexOf(row.checkboxOption6) != -1"
										disabled
									/> <span style="margin-left: 10px;">{{ row.checkboxOption6 }}</span>
								</p>
								<p v-if="row.checkboxOption7" style="margin-left: 10px; margin-top: 10px;">
									<input
										type="checkbox"
										class="checkbox"
										v-model="row.answer.answerValue"
										:value="row.checkboxOption7"
										:checked="row.answer.answerValue.indexOf(row.checkboxOption7) != -1"
										disabled
									/> <span style="margin-left: 10px;">{{ row.checkboxOption7 }}</span>
								</p>
								<p v-if="row.checkboxOption8" style="margin-left: 10px; margin-top: 10px;">
									<input
										type="checkbox"
										class="checkbox"
										v-model="row.answer.answerValue"
										:value="row.checkboxOption8"
										:checked="row.answer.answerValue.indexOf(row.checkboxOption8) != -1"
										disabled
									/> <span style="margin-left: 10px;">{{ row.checkboxOption8 }}</span>
								</p>
							</td>
						</tr>
						<tr v-if="row.questionTypeCode == 'TEXT'">
							<td style="text-align: center">
								{{ row.questionNo }}
							</td>
							<td>
								<h3>{{ row.questionName }}</h3>
								<input
									type="text"
									class="inputBox"
									v-model="row.answer.answerValue[0]"
									placeholder="질문을 입력하세요."
									v-validate="'required'"
									data-vv-name="questionName"
									data-vv-as="질문 "
									disabled
									style="margin-top: 10px; margin-left: 30px;"
								/>
							</td>
						</tr>
						<tr v-if="row.questionTypeCode == 'TEXTAREA'">
							<td style="text-align: center">
								{{ row.questionNo }}
							</td>
							<td>
								<h3>{{ row.questionName }}</h3>
								<textarea
									rows="5"
									class="inputBox floatLeft"
									style="
										background-color: #ccc;
										width: 100%;
										height: 60px;
										margin-top: 10px; margin-left: 30px;
									"
									v-model="row.answer.answerValue[0]"
									placeholder="답변입력란"
									readonly="readonly"
									disabled
									
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- 하단 버튼 바 -->
			<div class="contentButtonBar">
				<div class="buttonWrap floatLeft">
					<router-link
						:to="{
							path:
								config.contextRoot +
								'/site/' +
								$route.params.siteNo +
								'/answer',
						}"
						class="radiusSmallBtn bgGray"
					>
						목록
					</router-link>
				</div>
				<div class="buttonWrap floatRight">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "SurveyEdit",
	data() {
		return {
			siteNo: "",
			surveyNo: "",
			surveyDivCode: "",
			userNo: "",
			item: {
				surveyDivCode: 1,
				questionList: [],
			},
			programList: [],
		};
	},
	created() {
		this.logger.debug(this, "created()");
		this.siteNo = this.$route.params.siteNo;
		this.surveyNo = this.$route.params.surveyNo;
		this.surveyDivCode = this.$route.params.surveyDivCode;
		this.userNo = this.$route.params.userNo;

		this.selectAnswer();
	},
	components: {  },
	mounted() {},
	computed: {},
	methods: {
		selectAnswer() {
			this.logger.debug(this, "selectAnswer()");
			var searchData = { surveyNo: this.surveyNo, surveyDivCode: this.surveyDivCode, answerUserNo: this.userNo};
			axios
				.post(
					this.config.contextRoot + "/survey/selectAnswer.do",
					searchData
				)
				.then((response) => {
					console.log(response.data.answer);
					this.item = response.data.answer;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		}
	},
};
</script>
