<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">스킨</h2>
        </div>
        <div class="contentBody">
            <div class="contentTitleWrap">
                <h3 class="contentTitle">스킨 수정</h3>
                <div class="titleBtnWrap">
                    <button
                        class="radiusInputBtn"
                        type="button"
                        @click="openPopup"
                    >
                        스킨 이력
                    </button>
                </div>
            </div>

            <!-- 입력 폼 -->
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
                <table class="infoTable tableFixed marginBottom-35">
                    <tbody>
                        <tr>
                            <th class="requiredMark">스킨 이름</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="skinInfo.skinName"
                                    v-validate="'required'"
                                    data-vv-name="skinName"
                                    data-vv-as="스킨 이름"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('skinName')"
                                >
                                    * {{ errors.first("skinName") }}
                                </p>
                            </td>
                            <th class="requiredMark">사용여부</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-6"
                                            v-model="skinInfo.useYn"
                                            value="Y"
                                        />
                                        <span>사용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-6"
                                            v-model="skinInfo.useYn"
                                            value="N"
                                        />
                                        <span>미사용</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="sectionLabel">
                    <strong class="sectionLabelTitle">조회스킨</strong>
                </div>
                <p
                    class="validation-text"
                    v-if="errors.has('skinContentsView')"
                >
                    * {{ errors.first("skinContentsView") }}
                </p>
                <!-- <textarea
                    class="textArea borderDarkGray marginBottom-15"
                    v-validate="''"
                    data-vv-name="skinContentsView"
                    data-vv-as="조회스킨"
                    v-model="skinInfo.skinContentsView"
                    placeholder="내용을 입력하세요."
                ></textarea> -->
                <prism-editor class="my-editor" 
                    v-validate="''"
                    data-vv-name="skinContentsView"
                    data-vv-as="조회스킨"
                    v-model="skinInfo.skinContentsView"
                    :highlight="highlighter" line-numbers></prism-editor>
                <div class="sectionLabel">
                    <strong class="sectionLabelTitle">목록스킨</strong>
                </div>
                <p
                    class="validation-text"
                    v-if="errors.has('skinContentsList')"
                >
                    * {{ errors.first("skinContentsList") }}
                </p>
                <!-- <textarea
                    class="textArea borderDarkGray marginBottom-15"
                    v-validate="''"
                    data-vv-name="skinContentsList"
                    data-vv-as="목록스킨"
                    v-model="skinInfo.skinContentsList"
                    placeholder="내용을 입력하세요."
                ></textarea> -->
                <prism-editor class="my-editor" 
                    v-validate="''"
                    data-vv-name="skinContentsList"
                    data-vv-as="목록스킨"
                    v-model="skinInfo.skinContentsList"
                    :highlight="highlighter" line-numbers></prism-editor>
                <div class="sectionLabel">
                    <strong class="sectionLabelTitle">등록스킨</strong>
                </div>
                <p
                    class="validation-text"
                    v-if="errors.has('skinContentsCreate')"
                >
                    * {{ errors.first("skinContentsCreate") }}
                </p>
                <!-- <textarea
                    class="textArea borderDarkGray marginBottom-15"
                    v-validate="''"
                    data-vv-name="skinContentsCreate"
                    data-vv-as="등록스킨"
                    v-model="skinInfo.skinContentsCreate"
                    placeholder="내용을 입력하세요."
                ></textarea> -->
                <prism-editor class="my-editor" 
                    v-validate="''"
                    data-vv-name="skinContentsCreate"
                    data-vv-as="등록스킨"
                    v-model="skinInfo.skinContentsCreate"
                    :highlight="highlighter" line-numbers></prism-editor>
                <div class="sectionLabel">
                    <strong class="sectionLabelTitle">수정스킨</strong>
                </div>
                <p
                    class="validation-text"
                    v-if="errors.has('skinContentsUpdate')"
                >
                    * {{ errors.first("skinContentsUpdate") }}
                </p>
                <!-- <textarea
                    class="textArea borderDarkGray"
                    v-validate="''"
                    data-vv-name="skinContentsUpdate"
                    data-vv-as="수정스킨"
                    v-model="skinInfo.skinContentsUpdate"
                    placeholder="내용을 입력하세요."
                ></textarea> -->
                <prism-editor class="my-editor" 
                    v-validate="''"
                    data-vv-name="skinContentsUpdate"
                    data-vv-as="수정스킨"
                    v-model="skinInfo.skinContentsUpdate"
                    :highlight="highlighter" line-numbers></prism-editor>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/skin',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button class="radiusSmallBtn bgNavy" @click="insertSkin()">
                        저장
                    </button>
                    <button class="radiusSmallBtn bgDark" @click="deleteSkin()">
                        삭제
                    </button>
                </div>
            </div>

            <!-- 이력 팝업 -->
            <div class="popupWrap" id="roleListPopup" v-show="isShowPopup">
                <div class="popupBackground"></div>
                <div class="popup contentPopup">
                    <div class="popupHead">
                        <h2 class="popupTitle">스킨 이력</h2>
                        <button class="popupClose">
                            <img
                                src="@/assets/images/component/popup-close.png"
                                alt="팝업 닫기"
                                @click="closePopup()"
                            />
                        </button>
                    </div>

                    <!-- 팝업 페이지 1 -->
                    <div
                        class="popupPage popupPage-1"
                        v-show="popupPage[0] == 1"
                    >
                        <div class="popupBody">
                            <div class="contentTitleWrap">
                                <h3 class="contentTitle">스킨 이력 목록</h3>
                            </div>

                            <table class="listTable">
                                <thead>
                                    <tr>
                                        <th>번호</th>
                                        <th>스킨이름</th>
                                        <th>이력일시</th>
                                    </tr>
                                </thead>
                                <tbody
                                    v-for="history in historyList"
                                    :key="history.historyNo"
                                    @click="
                                        showHistoryDetail(history.historyNo)
                                    "
                                >
                                    <tr>
                                        <td>{{ history.rowNumber }}</td>
                                        <td>
                                            <a href="#" @click.prevent="">{{
                                                history.skinName
                                            }}</a>
                                        </td>
                                        <td>{{ history.historyDate }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div
                                class="pagination marginTop-50"
                                v-if="pageNoList && pageNoList.length > 0"
                            >
                                <ul>
                                    <li>
                                        <a
                                            href="#"
                                            @click.prevent="doSearchPage(1)"
                                            title="첫 페이지"
                                        >
                                            <img
                                                src="@/assets/images/component/pagination-first.png"
                                                alt="첫 페이지"
                                            />
                                        </a>
                                    </li>
                                    <li v-show="isPrev">
                                        <a
                                            href="#"
                                            @click.prevent="
                                                doSearchPage(startPageNo - 1)
                                            "
                                            :title="
                                                '이전' +
                                                displayPageCount +
                                                '페이지'
                                            "
                                        >
                                            <img
                                                src="@/assets/images/component/pagination-prev.png"
                                                :alt="
                                                    '이전' +
                                                    displayPageCount +
                                                    '페이지'
                                                "
                                            />
                                        </a>
                                    </li>
                                    <li
                                        v-for="pageNo in pageNoList"
                                        :key="pageNo"
                                    >
                                        <a
                                            href="#"
                                            :class="
                                                pagination.current_page ==
                                                pageNo
                                                    ? 'active'
                                                    : ''
                                            "
                                            @click.prevent="
                                                doSearchPage(pageNo)
                                            "
                                        >
                                            {{ pageNo }}
                                        </a>
                                    </li>
                                    <li v-show="isNext">
                                        <a
                                            href="#"
                                            @click.prevent="
                                                doSearchPage(endPageNo + 1)
                                            "
                                            :title="
                                                '다음' +
                                                displayPageCount +
                                                '페이지'
                                            "
                                        >
                                            <img
                                                src="@/assets/images/component/pagination-next.png"
                                                :alt="
                                                    '다음' +
                                                    displayPageCount +
                                                    '페이지'
                                                "
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            @click.prevent="
                                                doSearchPage(realEndPageNo)
                                            "
                                            title="마지막 페이지"
                                        >
                                            <img
                                                src="@/assets/images/component/pagination-last.png"
                                                alt="마지막 페이지"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- 팝업 페이지 2 -->
                    <div
                        class="popupPage popupPage-2"
                        v-show="popupPage[1] == 1"
                    >
                        <div class="popupBody">
                            <div class="contentTitleWrap">
                                <h3 class="contentTitle">
                                    <small style="font-weight: 300">
                                        일시:
                                        {{ historyDetail.historyDate }}
                                    </small>
                                </h3>

                                <div class="titleBtnWrap">
                                    <button
                                        type="button"
                                        class="titleRadiusBtn bgType1"
                                        @click="showHistoryList"
                                    >
                                        목록
                                    </button>
                                </div>
                            </div>
                            <div style="height: 500px; overflow: auto">
                                <div class="sectionLabel">
                                    <strong class="sectionLabelTitle">
                                        조회스킨
                                    </strong>
                                </div>
                                <!-- <textarea
                                    readonly
                                    class="
                                        textArea
                                        borderDarkGray
                                        marginBottom-15
                                    "
                                    :value="historyDetail.skinContentsView"
                                >
                                </textarea> -->
                                <prism-editor class="my-editor" 
                                    readonly
                                    :value="historyDetail.skinContentsView"
                                    :highlight="highlighter" line-numbers></prism-editor>
                                <div class="sectionLabel">
                                    <strong class="sectionLabelTitle">
                                        목록 스킨
                                    </strong>
                                </div>
                                <!-- <textarea
                                    readonly
                                    class="
                                        textArea
                                        borderDarkGray
                                        marginBottom-15
                                    "
                                    :value="historyDetail.skinContentsList"
                                >
                                </textarea> -->
                                <prism-editor class="my-editor" 
                                    readonly
                                    :value="historyDetail.skinContentsList"
                                    :highlight="highlighter" line-numbers></prism-editor>
                                <div class="sectionLabel">
                                    <strong class="sectionLabelTitle">
                                        등록 스킨
                                    </strong>
                                </div>
                                <!-- <textarea
                                    readonly
                                    class="
                                        textArea
                                        borderDarkGray
                                        marginBottom-15
                                    "
                                    :value="historyDetail.skinContentsCreate"
                                >
                                </textarea> -->
                                <prism-editor class="my-editor" 
                                    readonly
                                    :value="historyDetail.skinContentsCreate"
                                    :highlight="highlighter" line-numbers></prism-editor>
                                <div class="sectionLabel">
                                    <strong class="sectionLabelTitle">
                                        수정 스킨
                                    </strong>
                                </div>
                                <!-- <textarea
                                    readonly
                                    class="
                                        textArea
                                        borderDarkGray
                                        marginBottom-15
                                    "
                                    :value="historyDetail.skinContentsUpdate"
                                >
                                </textarea> -->
                                <prism-editor class="my-editor" 
                                    readonly
                                    :value="historyDetail.skinContentsUpdate"
                                    :highlight="highlighter" line-numbers></prism-editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles
export default {
    name: "SkinCreate",
    data() {
        return {
            siteNo: "",
            skinInfo: {
                skinName: "",
                useYn: "Y",
                skinContentsView: "",
                skinContentsList: "",
                skinContentsCreate: "",
                skinContentsUpdate: "",
            },
            skinNo: "",
            // 이력 팝업 관련..
            isShowPopup: false,
            popupPage: [1, 0],
            historyDetail: {},
            historyList: [],
            pagination: {},
            displayPageCount: 10,
            pageNoList: [],
        };
    },
    components: {
        PrismEditor
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.skinNo = this.$route.params.skinNo;
        this.selectSkin();
    },
    methods: {
        highlighter(code) {
            return highlight(code, languages.js); // languages.<insert language> to return html with markup
        },
        // 이력 팝업 관련..
        doSearchPage(pageNo) {
            this.logger.debug(this, "doSearchPage()");
            if (typeof pageNo === "undefined") {
                pageNo = 1;
            }
            this.selectHistoryList(pageNo);
        },
        calcPagination() {
            this.logger.debug(this, "calcPagination()");
            this.endPageNo =
                Math.ceil(
                    this.pagination.current_page / this.displayPageCount
                ) * this.displayPageCount;
            this.startPageNo = this.endPageNo - this.displayPageCount + 1;

            this.realEndPageNo = Math.ceil(
                this.pagination.total / this.pagination.per_page
            );

            if (this.startPageNo < 0) {
                this.startPageNo = 1;
            }
            if (this.endPageNo > this.realEndPageNo) {
                this.endPageNo = this.realEndPageNo;
            }

            this.isPrev = this.startPageNo == 1 ? false : true;
            this.isNext =
                this.endPageNo * this.pagination.per_page >=
                this.pagination.total
                    ? false
                    : true;

            this.pageNoList = [];
            for (let i = this.startPageNo; i <= this.endPageNo; i++) {
                this.pageNoList.push(i);
            }
        },
        showHistoryList() {
            this.popupPage = [1, 0];
        },
        showHistoryDetail(historyNo) {
            this.selectHistory(historyNo).then(() => {
                this.popupPage = [0, 1];
            });
        },
        closePopup() {
            this.isShowPopup = false;
            this.popupPage = [1, 0];
        },
        openPopup() {
            this.selectHistoryList().then(() => {
                this.isShowPopup = true;
            });
        },
        selectHistory(historyNo) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        this.config.contextRoot + "/skin/selectHistory.do",
                        {
                            historyNo: historyNo,
                        }
                    )
                    .then((response) => {
                        this.historyDetail = response.data.history;
                        resolve();
                    })
                    .catch((error) => {
                        this.exceptionHandler(this, error);
                        reject();
                    });
            });
        },
        selectHistoryList(pageNo) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        this.config.contextRoot + "/skin/selectHistoryList.do",
                        {
                            skinNo: this.skinNo,
                            pageNo: pageNo ? pageNo : 1
                        }
                    )
                    .then((response) => {
                        this.historyList = response.data.historyList;
                        this.pagination = response.data.pagination;
                        this.calcPagination();
                        resolve();
                    })
                    .catch((error) => {
                        this.exceptionHandler(this, error);
                        reject();
                    });
            });
        },
        // 스킨 수정 관련..
        selectSkin() {
            this.logger.debug(this, "selectSkin()");
            const formData = {
                skinNo: this.skinNo,
            };

            axios
                .post(this.config.contextRoot + "/skin/selectSkin.do", formData)
                .then((response) => {
                    this.skinInfo = response.data.skinInfo;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        insertSkin() {
            this.logger.debug(this, "insertSkin()");

            if (!confirm("등록 하시겠습니까?")) {
                return;
            }

            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }

                    this.skinInfo.siteNo = this.siteNo;

                    axios
                        .post(
                            this.config.contextRoot + "/skin/updateSkin.do",
                            this.skinInfo
                        )
                        .then((response) => {
                            if (response) {
                                alert("수정 되었습니다.");
                            }
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });
                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.errors.all());
                    return false;
                });
        },
        deleteSkin() {
            if (confirm("삭제 하시겠습니까?")) {
                axios
                    .post(
                        this.config.contextRoot + "/skin/deleteSkin.do",
                        this.skinInfo
                    )
                    .then((response) => {
                        if (response) {
                            alert("삭제 되었습니다.");
                            this.$router.replace(
                                this.config.contextRoot +
                                    "/site/" +
                                    this.siteNo +
                                    "/skin/"
                            );
                        }
                    })
                    .catch((error) => {
                        if (
                            error.response.data.message ==
                            "SQLIntegrityConstraintViolationException"
                        ) {
                            alert(
                                "삭제할 수 없습니다. 해당 스킨을 사용하는 게시판이 있습니다."
                            );
                            return;
                        }

                        this.exceptionHandler(this, error);
                    });
            }
        },
    },
};
</script>

<style>
  /* required class */
  .my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }
</style>