<template>
	<div class="gnbLayout">
		<h1 class="logo">
			<img src="@/assets/images/layout/logo.png" alt="OSOLIT" />
			<span>컨텐츠관리시스템(CMS)</span>
		</h1>
		<GnbSite />
		<div class="navigation">
			<ul>
				<!--
				<li v-if="isAdmin">
					<router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/siteInfo' }">
						<i class="icon">
							<img src="@/assets/images/layout/menu_2.png" alt="" />
						</i>
						<span>사이트정보</span>
					</router-link>
				</li>
				-->
				<li v-if="isAdmin">
					<router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/business' }">
						<i class="icon">
							<img src="@/assets/images/layout/menu_2.png" alt="" />
						</i>
						<span>업무관리</span>
					</router-link>
				</li>
				<li>
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/nursingroom' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_10.png" alt="" />
						</i>
						<span>수유실 관리</span>
					</router-link>
				</li>
				<!--
				<li>
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/roomComments' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_10.png" alt="" />
						</i>
						<span>댓글 관리</span>
					</router-link>
				</li>
			-->
				<li v-if="isAdmin">
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/program' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>교육개설관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/applicant' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>교육신청관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/regist' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>등록요청관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/support' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>설치지원관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/request' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>수정요청관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/api' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>API신청관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/apilog' }" class="childMenu">
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>API로그관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/survey' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>만족도조사관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/answer' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>조사결과관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/member' }">
						<i class="icon">
							<img src="@/assets/images/layout/menu_3.png" alt="" />
						</i>
						<span>회원관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/menu' }">
						<i class="icon">
							<img src="@/assets/images/layout/menu_4.png" alt="" />
						</i>
						<span>메뉴관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/layout' }">
						<i class="icon">
							<img src="@/assets/images/layout/menu_5.png" alt="" />
						</i>
						<span>레이아웃</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/board' }">
						<i class="icon">
							<img src="@/assets/images/layout/menu_6.png" alt="" />
						</i>
						<span>게시판</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/skin' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_10.png" alt="" />
						</i>
						<span>스킨관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/article' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>게시글관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/contents' }">
						<i class="icon">
							<img src="@/assets/images/layout/menu_7.png" alt="" />
						</i>
						<span>컨텐츠관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/asset' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>에셋관리</span>
					</router-link>
				</li>
				<!--
				<li>
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/assets' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>에셋관리</span>
					</router-link>
				</li>
				-->
				<li v-if="isAdmin">
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/banner' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>배너관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<router-link
						:to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/popup' }"
						class="childMenu"
					>
						<i class="icon">
							<img src="@/assets/images/layout/menu_11.png" alt="" />
						</i>
						<span>팝업관리</span>
					</router-link>
				</li>
				<li v-if="isAdmin">
					<a href="https://analytics.google.com/analytics/web/" target="_blank">
						<i class="icon">
							<img src="@/assets/images/layout/menu_8.png" alt="" />
						</i>
						<span>방문자통계</span>
					</a>
				</li>
				<!--
            <li>
                <router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/articletats'}">
                    <i class="icon">
                        <img src="@/assets/images/layout/menu_9.png" alt="">
                    </i>
                    <span>게시글통계</span>
                </router-link>
            </li> -->
			</ul>
		</div>
	</div>
</template>

<script>
import GnbSite from "@/components/gnb/GnbSite";
export default {
	name: "Gnb",
	components: { GnbSite },
	computed: {
		isAdmin() {
			return sessionStorage.getItem("roleName") != null &&
				sessionStorage.getItem("roleName").indexOf("ROLE_ADMIN") >= 0
				? true
				: false;
		},
	},
};
</script>

<style></style>
