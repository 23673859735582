<template>
	<div class="contentLayout">
		<div class="contentNavigationBar">
			<h2 class="title">만족도조사 관리</h2>
		</div>
		<div class="contentBody">
			<h3 class="contentTitle">만족도조사 수정</h3>
			<div class="infoWrap">
				<table class="infoTable marginBottom-35 marginTop-15">
					<tbody>
						<tr>
							<th style="width:150px;">만족도조사구분</th>
							<td>
								{{
									item.surveyDivCode == "1"
										? "모유수유담당자교육"
										: "만족도조사지원"
								}}
							</td>
							<th style="width:150px;">
								<span v-if="item.surveyDivCode == 1"
									>교육프로그램</span
								>
							</th>
							<td>
								{{ item.programName }}
							</td>
						</tr>
						<tr>
							<th>만족도조사명</th>
							<td>
								<input
									style="width: 250px"
									type="text"
									class="inputBox"
									v-model="item.surveyName"
									data-vv-name="surveyName"
									data-vv-as="만족도조사명 "
									v-validate="'required'"
								/>
								<p
									class="validation-text"
									v-if="errors.has('surveyName')"
								>
									* {{ errors.first("surveyName") }}
								</p>
							</td>
							<th>만족도조사기간</th>
							<td>
								<flat-pickr
									class="inputBox"
									style="width: 110px"
									v-model="item.surveyStartDt"
									:config="{
										locale: require('flatpickr/dist/l10n/ko.js')
											.default.ko,
										allowInput: true,
									}"
									data-vv-name="surveyStartDt"
									data-vv-as="만족도조사시작일 "
									v-validate="'required'"
								/>
								<span style="width: 50px"
									>&nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;&nbsp;</span
								>
								<flat-pickr
									class="inputBox"
									style="width: 110px"
									v-model="item.surveyEndDt"
									:config="{
										locale: require('flatpickr/dist/l10n/ko.js')
											.default.ko,
										allowInput: true,
									}"
									data-vv-name="surveyEndDt"
									data-vv-as="만족도조사종료일 "
									v-validate="'required'"
								/>
								<p
									class="validation-text"
									v-if="errors.has('surveyStartDt')"
								>
									* {{ errors.first("surveyStartDt") }}
								</p>
								<p
									class="validation-text"
									v-if="errors.has('surveyEndDt')"
								>
									* {{ errors.first("surveyEndDt") }}
								</p>
							</td>
						</tr>
						<tr>
							<th style="width:150px;">만족도조사<br/>완료횟수</th>
							<td colspan="3">
								<span style="margin-bottom: 100px;">{{item.answerCount}} 회</span>
								<sapn v-if="item.answerCount != '0'" style="color: red;"> (※ 조사완료자가 있을시 기존질문 삭제 및 순서변경이 불가합니다.)</sapn>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<h3 class="contentTitle">
				만족도조사 항목 
				<div class="buttonWrap floatRight">
					<button
						class="radiusSmallBtn bgTuftsBlue"
						style="border-radius: 5px; width: 100px"
						@click.prevent="addRadio()"
					>
						Add Radio
					</button>
					<button
						class="radiusSmallBtn bgTuftsBlue"
						style="border-radius: 5px; width: 100px"
						@click.prevent="addCheckBox()"
					>
						Add CheckBox
					</button>
					<button
						class="radiusSmallBtn bgTuftsBlue"
						style="border-radius: 5px; width: 100px"
						@click.prevent="addText()"
					>
						Add Text
					</button>
					<button
						class="radiusSmallBtn bgTuftsBlue"
						style="border-radius: 5px; width: 100px"
						@click.prevent="addTextarea()"
					>
						Add Textarea
					</button>
				</div>
			</h3>
			<div class="infoWrap">
				<table class="infoTable marginBottom-35 marginTop-15">
					<colgroup>
						<col style="width: 8%" />
						<col style="width: 8%" />
						<col style="width: 76%" />
						<col style="width: 8%" />
					</colgroup>
					<thead>
						<tr>
							<th style="text-align: center">질문 구분</th>
							<th style="text-align: center">1줄 표현 갯수</th>
							<th style="text-align: center">질문/답변</th>
							<th style="text-align: center">질문 순서</th>
						</tr>
					</thead>

					<!-- 새로 추가되는 질문 -->
					<tbody
						v-for="(row, index) in questionList"
						:key="row.questionNo"
					>
						<tr v-if="row.questionTypeCode == 'RADIO'">
							<td style="text-align: center">
								{{ row.questionTypeCode }}
								<button
									v-if="item.answerCount == '0' || item.questionList.length < row.questionNo"
									class="radiusSmallBtn bgTuftsBlue"
									style="
										border-radius: 5px;
										width: 50px;
										margin-top: 10px;
									"
									@click.prevent="
										deleteQuestion(index)
									"
								>
									삭제
								</button>
							</td>
							<td>
								<select
									v-model="row.radioCols"
									style="width: 60px"
								>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6</option>
									<option value="7">7</option>
									<option value="8">8</option>
								</select>
							</td>
							<td>
								<input
									type="text"
									class="inputBox"
									v-model="row.questionName"
									placeholder="질문을 입력하세요."
									v-validate="'required'"
									data-vv-name="questionName"
									data-vv-as="질문 "
								/>
								<p
									class="validation-text"
									v-if="errors.has('questionName')"
								>
									* {{ errors.first("questionName") }}
								</p>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption1"
									placeholder="(1)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption2"
									placeholder="(2)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption3"
									placeholder="(3)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption4"
									placeholder="(4)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption5"
									placeholder="(5)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption6"
									placeholder="(6)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption7"
									placeholder="(7)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.radioCols + '%'
									"
									v-model="row.radioOption8"
									placeholder="(8)"
								/>
							</td>
							<td>
								<button v-if="(item.answerCount == '0' || item.questionList.length < row.questionNo) && row.questionNo != questionList.length" 
								class="upload2" @click="positionDown(row.questionNo)" style="width:25px;">▼</button>
								<span v-if="(item.answerCount == '0' || item.questionList.length < row.questionNo) && row.questionNo == questionList.length" style="margin-right: 25px;"/>
								<span style="margin-left: 10px; margin-right: 10px;">{{row.questionNo}}</span>
								<span v-if="(item.answerCount == '0' || item.questionList.length + 1 < row.questionNo) && row.questionNo == 1" style="margin-right: 25px;"/>
								<button v-if="(item.answerCount == '0' || item.questionList.length + 1 < row.questionNo) && row.questionNo != 1" class="upload2" @click="positionUp(row.questionNo)" style="width:25px;">▲</button>
							</td>
						</tr>
						<tr v-if="row.questionTypeCode == 'CHECK'">
							<td style="text-align: center">
								{{ row.questionTypeCode }}
								<button
									v-if="item.answerCount == '0' || item.questionList.length < row.questionNo"
									class="radiusSmallBtn bgTuftsBlue"
									style="
										border-radius: 5px;
										width: 50px;
										margin-top: 10px;
									"
									@click.prevent="
										deleteQuestion(index)
									"
								>
									삭제
								</button>
							</td>
							<td>
								<select
									v-model="row.checkboxCols"
									style="width: 60px"
								>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6</option>
									<option value="7">7</option>
									<option value="8">8</option>
								</select>
							</td>
							<td>
								<input
									type="text"
									class="inputBox"
									v-model="row.questionName"
									placeholder="질문을 입력하세요."
									v-validate="'required'"
									data-vv-name="questionName"
									data-vv-as="질문 "
								/>
								<p
									class="validation-text"
									v-if="errors.has('questionName')"
								>
									* {{ errors.first("questionName") }}
								</p>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption1"
									placeholder="(1)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption2"
									placeholder="(2)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption3"
									placeholder="(3)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption4"
									placeholder="(4)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption5"
									placeholder="(5)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption6"
									placeholder="(6)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption7"
									placeholder="(7)"
								/>
								<input
									type="text"
									class="inputBox floatLeft"
									:style="
										'width:' + 100 / row.checkboxCols + '%'
									"
									v-model="row.checkboxOption8"
									placeholder="(8)"
								/>
							</td>
							<td>
								<button v-if="(item.answerCount == '0' || item.questionList.length < row.questionNo) && row.questionNo != questionList.length" 
								class="upload2" @click="positionDown(row.questionNo)" style="width:25px;">▼</button>
								<span v-if="(item.answerCount == '0' || item.questionList.length < row.questionNo) && row.questionNo == questionList.length" style="margin-right: 25px;"/>
								<span style="margin-left: 10px; margin-right: 10px;">{{row.questionNo}}</span>
								<span v-if="(item.answerCount == '0' || item.questionList.length + 1 < row.questionNo) && row.questionNo == 1" style="margin-right: 25px;"/>
								<button v-if="(item.answerCount == '0' || item.questionList.length + 1 < row.questionNo) && row.questionNo != 1" class="upload2" @click="positionUp(row.questionNo)" style="width:25px;">▲</button>
							</td>
						</tr>
						<tr v-if="row.questionTypeCode == 'TEXT'">
							<td style="text-align: center">
								{{ row.questionTypeCode }}
								<button
								v-if="item.answerCount == '0' || item.questionList.length < row.questionNo"
									class="radiusSmallBtn bgTuftsBlue"
									style="
										border-radius: 5px;
										width: 50px;
										margin-top: 10px;
									"
									@click.prevent="
										deleteQuestion(index)
									"
								>
									삭제
								</button>
							</td>
							<td></td>
							<td>
								<input
									type="text"
									class="inputBox"
									v-model="row.questionName"
									placeholder="질문을 입력하세요."
									v-validate="'required'"
									data-vv-name="questionName"
									data-vv-as="질문 "
								/>
								<p
									class="validation-text"
									v-if="errors.has('questionName')"
								>
									* {{ errors.first("questionName") }}
								</p>
								<input
									type="text"
									class="inputBox floatLeft"
									style="background-color: #ccc; width: 100%"
									placeholder="답변입력란"
									readonly="readonly"
								/>
							</td>
							<td>
								<button v-if="(item.answerCount == '0' || item.questionList.length < row.questionNo) && row.questionNo != questionList.length" 
								class="upload2" @click="positionDown(row.questionNo)" style="width:25px;">▼</button>
								<span v-if="(item.answerCount == '0' || item.questionList.length < row.questionNo) && row.questionNo == questionList.length" style="margin-right: 25px;"/>
								<span style="margin-left: 10px; margin-right: 10px;">{{row.questionNo}}</span>
								<span v-if="(item.answerCount == '0' || item.questionList.length + 1 < row.questionNo) && row.questionNo == 1" style="margin-right: 25px;"/>
								<button v-if="(item.answerCount == '0' || item.questionList.length + 1 < row.questionNo) && row.questionNo != 1" class="upload2" @click="positionUp(row.questionNo)" style="width:25px;">▲</button>
							</td>
						</tr>
						<tr v-if="row.questionTypeCode == 'TEXTAREA'">
							<td style="text-align: center">
								{{ row.questionTypeCode }}
								<button
									v-if="item.answerCount == '0' || item.questionList.length < row.questionNo"
									class="radiusSmallBtn bgTuftsBlue"
									style="
										border-radius: 5px;
										width: 50px;
										margin-top: 10px;
									"
									@click.prevent="
										deleteQuestion(index)
									"
								>
									삭제
								</button>
							</td>
							<td></td>
							<td>
								<input
									type="text"
									class="inputBox"
									v-model="row.questionName"
									placeholder="질문을 입력하세요."
									v-validate="'required'"
									data-vv-name="questionName"
									data-vv-as="질문 "
								/>
								<p
									class="validation-text"
									v-if="errors.has('questionName')"
								>
									* {{ errors.first("questionName") }}
								</p>
								<textarea
									rows="5"
									class="inputBox floatLeft"
									style="
										background-color: #ccc;
										width: 100%;
										height: 60px;
									"
									placeholder="답변입력란"
									readonly="readonly"
								/>
							</td>
							<td>
								<button v-if="(item.answerCount == '0' || item.questionList.length < row.questionNo) && row.questionNo != questionList.length" 
								class="upload2" @click="positionDown(row.questionNo)" style="width:25px;">▼</button>
								<span v-if="(item.answerCount == '0' || item.questionList.length < row.questionNo) && row.questionNo == questionList.length" style="margin-right: 25px;"/>
								<span style="margin-left: 10px; margin-right: 10px;">{{row.questionNo}}</span>
								<span v-if="(item.answerCount == '0' || item.questionList.length + 1 < row.questionNo) && row.questionNo == 1" style="margin-right: 25px;"/>
								<button v-if="(item.answerCount == '0' || item.questionList.length + 1 < row.questionNo) && row.questionNo != 1" class="upload2" @click="positionUp(row.questionNo)" style="width:25px;">▲</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- 하단 버튼 바 -->
			<div class="contentButtonBar">
				<div class="buttonWrap floatLeft">
					<router-link
						:to="{
							path:
								config.contextRoot +
								'/site/' +
								$route.params.siteNo +
								'/survey',
						}"
						class="radiusSmallBtn bgGray"
					>
						목록
					</router-link>
				</div>
				<div class="buttonWrap floatRight">
					<button class="radiusSmallBtn bgNavy" @click="checkDivCode()">
						복사
					</button>
					<button class="radiusSmallBtn bgNavy" @click="save()">
						저장
					</button>
					<button
						v-if="item.answerCount == '0'"
						class="radiusSmallBtn bgDark"
						@click="deleteSurvey()"
					>
						삭제
					</button>
				</div>
			</div>
		</div>

		<!-- 신규 팝업 -->
		<div class="popupWrap" id="newPopup" v-show="showNew">
			<div class="popupBackground"></div>
			<div class="popup contentPopup">
				<div class="popupHead">
					<h2 class="popupTitle">복사</h2>
					<button class="popupClose">
						<img src="@/assets/images/component/popup-close.png" alt="팝업 닫기" @click="showNew = false" />
					</button>
				</div>

				<!-- 팝업 페이지 1 -->
				<div class="contentBody">
					<h3 class="contentTitle">복사할 교육프로그램 선택</h3>
					<div class="infoWrap">
						<p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
						<table class="infoTable marginBottom-35">
							<tbody>
								<tr>
									<th class="requiredMark">교육프로그램 목록</th>
									<td>
										<select
											v-if="item.surveyDivCode == 1"
											style="width: 250px"
											class="selectBox"
											v-model="programNo"
											data-vv-name="programNo"
											data-vv-as="교육프로그램 "
											v-validate="''"
										>
											<option value="">-- 선택 --</option>
											<option
												v-for="row in programList"
												:key="row.programNo"
												:value="row.programNo"
											>
												{{ row.programName }}
											</option>
										</select>
										<p
											class="validation-text"
											v-if="errors.has('programNo')"
										>
											* {{ errors.first("programNo") }}
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<!-- 하단 버튼 바 -->
					<div class="contentButtonBar">
						<div class="buttonWrap" style="text-align:center;">
							<button class="radiusSmallBtn bgNavy" @click="chooseProgram()">선택</button>
							<button class="radiusSmallBtn bgDark" @click="showNew = false">취소</button>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/themes/material_blue.css";

export default {
	name: "SurveyEdit",
	data() {
		return {
			siteNo: "",
			surveyNo: "",
			item: {
				surveyDivCode: 1,
				questionList: [],
			},
			programNo: "",
			programList: [],
			questionList: [],
			showNew: false,
		};
	},
	created() {
		this.logger.debug(this, "created()");
		this.siteNo = this.$route.params.siteNo;
		this.surveyNo = this.$route.params.surveyNo;

		this.selectSurvey();
		this.selectProgram();
	},
	components: { flatPickr },
	mounted() {},
	computed: {},
	methods: {
		selectSurvey() {
			this.logger.debug(this, "selectSurvey()");
			var searchData = { surveyNo: this.surveyNo };
			axios
				.post(
					this.config.contextRoot + "/survey/selectSurvey.do",
					searchData
				)
				.then((response) => {
					this.item = response.data.survey;
					this.questionList = JSON.parse(JSON.stringify(this.item.questionList));
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectProgram() {
			this.logger.debug(this, "selectProgram()");

			axios
				.post(
					this.config.contextRoot +
						"/survey/selectProgramList.do",
					this.item
				)
				.then((response) => {
					console.log(response);
					if (response.status == 200) {
						this.programList = response.data.programList;
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		addRadio() {
			this.questionList.push({
				questionTypeCode: "RADIO",
				radioCols: 2,
				surveyNo: this.surveyNo,
				questionNo: this.questionList.length + 1,
			});
		},
		addCheckBox() {
			this.questionList.push({
				questionTypeCode: "CHECK",
				checkboxCols: 2,
				surveyNo: this.surveyNo,
				questionNo: this.questionList.length + 1,
			});
		},
		addText() {
			this.questionList.push({
				questionTypeCode: "TEXT",
				textSize: "100",
				surveyNo: this.surveyNo,
				questionNo: this.questionList.length + 1,
			});
		},
		addTextarea() {
			this.questionList.push({
				questionTypeCode: "TEXTAREA",
				textareaRos: "5",
				textareaCols: "200",
				surveyNo: this.surveyNo,
				questionNo: this.questionList.length + 1,
			});
		},
		save() {
			this.logger.debug(this, "save()");

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "valid:" + result);
					if (!result) {
						alert("필수 입력항목을 모두 입력해 주세요.");
						return;
					} else {
						if (!confirm("저장 하시겠습니까?")) {
							return;
						}

						this.questionList.forEach((row) => {
							if (row.questionTypeCode == "RADIO") {
								row.radioOptions =
									row.radioOption1 +
									";" +
									row.radioOption2 +
									";" +
									row.radioOption3 +
									";" +
									row.radioOption4 +
									";" +
									row.radioOption5 +
									";" +
									row.radioOption6 +
									";" +
									row.radioOption7 +
									";" +
									row.radioOption8;
							}
							if (row.questionTypeCode == "CHECK") {
								row.checkboxOptions =
									row.checkboxOption1 +
									";" +
									row.checkboxOption2 +
									";" +
									row.checkboxOption3 +
									";" +
									row.checkboxOption4 +
									";" +
									row.checkboxOption5 +
									";" +
									row.checkboxOption6 +
									";" +
									row.checkboxOption7 +
									";" +
									row.checkboxOption8;
							}
						});

						this.item.questionList = this.questionList;

						axios
							.post(
								this.config.contextRoot + "/survey/update.do",
								this.item
							)
							.then((response) => {
								if (response.status == 200) {
									alert("저장 되었습니다.");
									this.$router.replace(
										this.config.contextRoot +
											"/site/" +
											this.siteNo +
											"/survey"
									);
								}
							})
							.catch(() => {
								// this.exceptionHandler(this, error);
								alert("서버오류 다시 확인해주세요.");
								this.selectSurvey();
								this.selectProgram();
							});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.error.all());
					this.$alert(this.error.all());
					return false;
				});
		},
		checkDivCode(){
			if(this.item.surveyDivCode == 1)
			{
				this.showNew = true;
			}
			else if(this.item.surveyDivCode == 2)
			{
				this.copy();
			}
		},
		chooseProgram(){
			if(!this.programNo){
				alert("교육프로그램을 선택해주세요.");
				return;
			}
			this.item.programNo = this.programNo;
			this.copy();
		},
		copy() {
			this.logger.debug(this, "save()");

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "valid:" + result);
					if (!result) {
						alert("필수 입력항목을 모두 입력해 주세요.");
						return;
					} else {
						if (!confirm("복사 하시겠습니까?")) {
							return;
						}

						this.questionList.forEach((row) => {
							if (row.questionTypeCode == "RADIO") {
								row.radioOptions =
									row.radioOption1 +
									";" +
									row.radioOption2 +
									";" +
									row.radioOption3 +
									";" +
									row.radioOption4 +
									";" +
									row.radioOption5 +
									";" +
									row.radioOption6 +
									";" +
									row.radioOption7 +
									";" +
									row.radioOption8;
							}
							if (row.questionTypeCode == "CHECK") {
								row.checkboxOptions =
									row.checkboxOption1 +
									";" +
									row.checkboxOption2 +
									";" +
									row.checkboxOption3 +
									";" +
									row.checkboxOption4 +
									";" +
									row.checkboxOption5 +
									";" +
									row.checkboxOption6 +
									";" +
									row.checkboxOption7 +
									";" +
									row.checkboxOption8;
							}
						});

						this.item.questionList = this.questionList;

						axios
							.post(
								this.config.contextRoot + "/survey/insert.do",
								this.item
							)
							.then((response) => {
								if (response.status == 200) {
									alert("복사 되었습니다.");
									this.showNew = false;
									this.$router.replace(
										this.config.contextRoot +
											"/site/" +
											this.siteNo +
											"/survey/" +
											response.data.surveyNo + 
											"/edit"
									);
									this.surveyNo = this.$route.params.surveyNo;

									this.selectSurvey();
									this.selectProgram();
								}
							})
							.catch((error) => {
								this.exceptionHandler(this, error);
							});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.error.all());
					this.$alert(this.error.all());
					return false;
				});
		},
		deleteSurvey() {
			this.logger.debug(this, "cancel()");

			if (!confirm("삭제 하시겠습니까?")) {
				return;
			}

			axios
				.post(this.config.contextRoot + "/survey/delete.do", this.item)
				.then((response) => {
					if (response.status == 200) {
						alert("삭제 되었습니다.");
						this.$router.replace(
							this.config.contextRoot +
								"/site/" +
								this.siteNo +
								"/survey"
						);
					}
				})
				.catch(() => {
					// this.exceptionHandler(this, error);
					alert("서버오류 다시 확인해주세요.");
					this.selectSurvey();
					this.selectProgram();
				});
		},

		positionUp(qNo){
			console.log(this.questionList);
			let qListIdx = qNo - 1;
			
			let tempQ1 = this.questionList[qListIdx];
			let tempQ2 = this.questionList[qListIdx - 1];
			let tempNo1 = tempQ1.questionNo;
			let tempNo2 = tempQ2.questionNo;
			tempQ1.questionNo = tempNo2;
			tempQ2.questionNo = tempNo1;

			this.questionList[qListIdx] = tempQ2;
			this.questionList[qListIdx - 1] = tempQ1;
			console.log(this.questionList);
		},

		positionDown(qNo){
			let qListIdx = qNo - 1;
			
			let tempQ1 = this.questionList[qListIdx];
			let tempQ2 = this.questionList[qListIdx + 1];
			let tempNo1 = tempQ1.questionNo;
			let tempNo2 = tempQ2.questionNo;
			tempQ1.questionNo = tempNo2
			tempQ2.questionNo = tempNo1

			this.questionList[qListIdx] = tempQ2;
			this.questionList[qListIdx + 1] = tempQ1;
		},

		deleteQuestion(index){
			let init = 1;
			this.questionList.splice(index, 1);
			this.questionList.forEach((row) => {
				row.questionNo = init++;
			})
		},
	},
};
</script>

<style>
	.upload2 {
		top: 0;
		right: 0;
		width: 40px;
		height: 28px;
		background-color: #3b80e0;
		font-size: 14px;
		color: #fff;
	}
	</style>