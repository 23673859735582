<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">API 신청 관리</h2>
        </div>
        <div class="contentBody">
            <h3 class="contentTitle">API 신청 상세</h3>

            <!-- 신청 정보 -->
            <div class="infoWrap">
                <label>
                    <span>신청 정보</span>
                </label>
                <table class="infoTable marginBottom-35 marginTop-15">
                    <tbody>
                        <tr>
                            <th>신청 번호</th>
                            <td>
                                {{ apiInfo.applicantNo }}
                            </td>
                        </tr>
                        <tr>
                            <th>기관명</th>
                            <td>
                                {{ apiInfo.orgName }}
                            </td>
                        </tr>
                        <tr>
                            <th>신청자</th>
                            <td>
                                {{ apiInfo.managerName }}
                            </td>
                        </tr>
                        <tr>
                            <th>전화번호</th>
                            <td>
                                {{ apiInfo.telNo }}
                            </td>
                        </tr>
                         <tr>
                            <th>이메일</th>
                            <td>
                                {{ apiInfo.email }}
                            </td>
                        </tr>
                        <tr>
                            <th>사이트</th>
                            <td>
                                {{ apiInfo.siteAddress }}
                            </td>
                        </tr>
                        <tr>
                            <th>내용</th>
                            <td v-html="applicantTextHtml">
                            </td>
                        </tr>
                        <tr>
                            <th>신청일</th>
                            <td>
                                {{ apiInfo.applicantDate }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 승인/반려 -->
            <div class="infoWrap">
                <label>
                    <span>승인/반려</span>
                </label>
                <table class="infoTable marginBottom-35 marginTop-15">
                    <tbody>
                        <tr>
                            <th>승인 여부</th>
                            <td>
                                {{
                                !apiInfo.confirmYn ? "대기" : 
                                    apiInfo.confirmYn == 'Y' ? "승인" : "반려"
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th>승인<br/>API KEY</th>
                            <td>
                                {{ apiInfo.confirmApiKey }}
                            </td>
                        </tr>
                        <tr>
                            <th>승인일</th>
                            <td>
                                {{ apiInfo.confirmDate }}
                            </td>
                        </tr>
                        <tr>
                            <th>반려 사유</th>
                            <td>
                                <label>
                                    <input
                                        type="text"
                                        class="inputBox"
                                        v-model="apiInfo.rejectReason"
                                        placeholder="반려 사유를 입력하세요."
                                    />
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th>반려일</th>
                            <td>
                                {{ apiInfo.rejectDate }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/api',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                    <router-link
                        v-if="apiInfo.confirmApiKey"
						:to="{
							path: config.contextRoot + '/site/' + $route.params.siteNo + '/apilog',
							query: {
								confirmApiKey: this.apiInfo.confirmApiKey,
							},
						}"
						class="radiusSmallBtn bgTuftsBlue"
                        style="width:200px;"
					>
						이용로그 조회하기
					</router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        v-if="!apiInfo.confirmYn"
                        class="radiusSmallBtn bgNavy"
                        @click="confirmApi()"
                    >
                        승인
                    </button>
                    <button
                        v-if="apiInfo.confirmYn != 'N'"
                        class="radiusSmallBtn bgDark"
                        style="background-color: red;"
                        @click="rejectApi()"
                    >
                        반려
                    </button>
                    <button
                        v-if="apiInfo.confirmYn == 'N'"
                        class="radiusSmallBtn bgNavy"
                        @click="updateRejectReason()"
                    >
                        저장
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'ApiEdit',

    data() {
        return {
            siteNo: "",
            applicantNo: "",
            apiInfo: {},
        };
    },

    created() {
        this.siteNo = this.$route.params.siteNo;
        this.applicantNo = this.$route.params.applicantNo;
        this.selectApiInfo();
    },

    mounted() {
        
    },

    computed : {
        applicantTextHtml(){
            return this.apiInfo.applicantText.replaceAll("\n", "<br/>");
        }
    },

    methods: {
        selectApiInfo() {
            this.logger.debug(this, "selectApiInfo()");

            axios
                .post(
                    this.config.contextRoot + "/api/selectApi.do",
                    {"applicantNo": this.applicantNo}
                )
                .then((response) => {
                    this.apiInfo = response.data.apiInfo;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        confirmApi() {
            this.logger.debug(this, "confirmApi()");

            if (!confirm("승인 하시겠습니까?")) {
                return;
            }

            this.apiInfo.confirmYn = 'Y';
            this.apiInfo.rejectReason = '';

            axios
                .post(
                    this.config.contextRoot + "/api/updateApi.do",
                    this.apiInfo
                )
                .then((response) => {
                    alert("처리 되었습니다.");
                    this.applicantNo = response.data.applicantNo;
                    this.selectApiInfo();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        rejectApi() {
            this.logger.debug(this, "rejectApi()");

            if(!this.apiInfo.rejectReason) {
                alert("반려 사유를 입력해주세요.");
                return;
            }

            if (!confirm("반려 하시겠습니까?")) {
                return;
            }

            this.apiInfo.confirmYn = 'N';

            axios
                .post(
                    this.config.contextRoot + "/api/updateApi.do",
                    this.apiInfo
                )
                .then((response) => {
                    alert("처리 되었습니다.");
                    this.applicantNo = response.data.applicantNo;
                    this.selectApiInfo();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        updateRejectReason() {
            this.logger.debug(this, "updateRejectReason()");

            if (!confirm("수정 하시겠습니까?")) {
                return;
            }

            axios
                .post(
                    this.config.contextRoot + "/api/updateRejectReason.do",
                    this.apiInfo
                )
                .then((response) => {
                    alert("수정 되었습니다.");
                    this.applicantNo = response.data.applicantNo;
                    this.selectApiInfo();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
    },
};
</script>

<style scoped>
.infoWrap>label:first-child>span {
    font-weight: bold;
}
</style>