<template>
	<div class="contentLayout">
		<div class="contentNavigationBar">
			<h2 class="title">컨텐츠관리</h2>
		</div>
		<div class="contentBody">
			<!-- 검색 -->
			<div class="searchBar">
				<div class="columnDivision-3">
					<div class="labelWrap">
						<label>컨텐츠 제목</label>
						<input type="text" class="inputBox" v-model="searchForm.contentsTitle" />
					</div>
					<div class="labelWrap">
						<label>작성일자</label>
						<div class="datePicker">
							<input
								type="text"
								class="inputBox"
								placeholder="연도-월-일"
								id="startDate"
								v-model="searchForm.startDate"
								autocomplete="off"
							/>
							<div ref="startDatePicker" id="startDatePicker"></div>
							<i></i>
						</div>
					</div>
					<div class="labelWrap">
						<label class="textCenter" style="width: 30px">-</label>
						<div class="datePicker">
							<input
								type="text"
								class="inputBox"
								placeholder="연도-월-일"
								id="endDate"
								v-model="searchForm.endDate"
								autocomplete="off"
							/>
							<i></i>
							<div ref="endDatePicker" id="endDatePicker"></div>
						</div>
					</div>
				</div>
				<button class="searchBtn" @click="doSearchPage(1)">조회하기</button>
			</div>
			<h3 class="contentTitle marginTop-35">컨텐츠 목록</h3>
			<div class="listWrap">
				<!-- 목록 상단 -->
				<div class="listTop">
					<span class="listTotal"
						>(Total <em>{{ pagination.total | makeComma }}</em
						>)</span
					>
					<select class="selectBox" @change.prevent="doSearchPage(1)" v-model="searchForm.countPerPage">
						<option>5</option>
						<option>10</option>
						<option>20</option>
						<option>30</option>
						<option>50</option>
						<option>100</option>
					</select>
				</div>

				<!-- 목록테이블 -->
				<table class="listTable">
					<thead>
						<tr>
							<th>
								<label>
									<input type="checkbox" @click="checkAll" v-model="allChecked" />
								</label>
							</th>
							<th>컨텐츠 번호</th>
							<th>컨텐츠 제목</th>
							<th>검색 허용 여부</th>
							<th>사용여부</th>
							<th>작성일자</th>
						</tr>
					</thead>
					<tbody
						v-for="contents in contentsList"
						:key="contents.contentNo"
						@click="
							$router.push({
								path:
									config.contextRoot +
									'/site/' +
									$route.params.siteNo +
									'/contents/' +
									contents.contentsNo +
									'/edit',
							})
						"
					>
						<tr>
							<td>
								<label>
									<input
										v-if="!(1 <= contents.contentsNo && contents.contentsNo <= 9)"
										type="checkbox"
										v-model="deleteContentsNoList"
										:value="contents.contentsNo"
										@click.stop=""
									/>
									<div v-if="1 <= contents.contentsNo && contents.contentsNo <= 9" style="color: red">
										(예약)
									</div>
								</label>
							</td>
							<td>{{ contents.contentsNo }}</td>
							<td>{{ contents.contentsTitle }}</td>
							<td>
								{{ contents.searchAllowYn == "Y" ? "허용" : "불가" }}
							</td>
							<td>
								{{ contents.useYn == "Y" ? "사용" : "미사용" }}
							</td>
							<td>{{ contents.contentsAddDt }}</td>
						</tr>
					</tbody>
				</table>

				<!-- 페이지네이션-->
				<div class="pagination marginTop-50" v-if="pageNoList && pageNoList.length > 0">
					<ul>
						<li>
							<a href="#" @click.prevent="doSearchPage(1)" title="첫 페이지">
								<img src="@/assets/images/component/pagination-first.png" alt="첫 페이지" />
							</a>
						</li>
						<li v-show="isPrev">
							<a
								href="#"
								@click.prevent="doSearchPage(startPageNo - 1)"
								:title="'이전' + displayPageCount + '페이지'"
							>
								<img
									src="@/assets/images/component/pagination-prev.png"
									:alt="'이전' + displayPageCount + '페이지'"
								/>
							</a>
						</li>
						<li v-for="pageNo in pageNoList" :key="pageNo">
							<a
								href="#"
								:class="pagination.current_page == pageNo ? 'active' : ''"
								@click.prevent="doSearchPage(pageNo)"
							>
								{{ pageNo }}
							</a>
						</li>
						<li v-show="isNext">
							<a
								href="#"
								@click.prevent="doSearchPage(endPageNo + 1)"
								:title="'다음' + displayPageCount + '페이지'"
							>
								<img
									src="@/assets/images/component/pagination-next.png"
									:alt="'다음' + displayPageCount + '페이지'"
								/>
							</a>
						</li>
						<li>
							<a href="#" @click.prevent="doSearchPage(realEndPageNo)" title="마지막 페이지">
								<img src="@/assets/images/component/pagination-last.png" alt="마지막 페이지" />
							</a>
						</li>
					</ul>
				</div>
			</div>

			<!-- 하단 버튼 바 -->
			<div class="contentButtonBar">
				<div class="buttonWrap floatLeft">
					<router-link
						:to="{
							path: config.contextRoot + '/site/1/contents/create',
						}"
						class="radiusSmallBtn bgBlue"
					>
						신규
					</router-link>
				</div>
				<div class="buttonWrap floatRight">
					<button type="button" class="radiusSmallBtn bgTuftsBlue" @click.prevent="deleteContentsList()">
						선택 삭제
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import DatePicker from "tui-date-picker";
import "tui-date-picker/dist/tui-date-picker.min.css";

export default {
	name: "ContentsList",
	data() {
		return {
			siteNo: "",
			contentsList: [],
			pagination: {},
			searchForm: {
				loginId: "",
				userName: "",
				aliasName: "",
				startDate: "",
				endDate: "",
				countPerPage: 10,
				pageNo: 1,
			},
			displayPageCount: 10,
			isPrev: "",
			isNext: "",
			startPageNo: "",
			endPageNo: "",
			realEndPageNo: "",
			pageNoList: [],
			deleteContentsNoList: [],
			allChecked: false,
		};
	},
	created() {
		this.siteNo = this.$route.params.siteNo;
		this.searchForm.pageNo = 1;
		this.selectContentsList();
	},
	watch: {
		deleteContentsNoList() {
			if (this.contentsList.length != this.deleteContentsNoList.length) {
				this.allChecked = false;
			} else {
				this.allChecked = true;
			}
		},
	},
	mounted() {
		// 데이트피커
		var today = new Date();
		const rangePicker = new DatePicker.createRangePicker({
			startpicker: {
				date: today,
				input: "#startDate",
				container: "#startDatePicker",
			},
			endpicker: {
				date: today,
				input: "#endDate",
				container: "#endDatePicker",
			},
			language: "ko",
		});
		rangePicker.on("change:start", () => {
			this.searchForm.startDate = document.getElementById("startDate").value;
		});
		rangePicker.on("change:end", () => {
			this.searchForm.endDate = document.getElementById("endDate").value;
		});
	},
	methods: {
		selectContentsList() {
			this.logger.debug(this, "selectContentsList()");
			this.searchForm.siteNo = this.siteNo;
			axios
				.post(this.config.contextRoot + "/contents/selectContentsList.do", this.searchForm)
				.then((response) => {
					this.contentsList = response.data.contentsList;
					this.pagination = response.data.pagination;
					this.calcPagination();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(pageNo) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof pageNo === "undefined") {
				pageNo = 1;
			}
			this.deleteContentsNoList = [];
			this.searchForm.pageNo = pageNo;
			this.selectContentsList();
		},
		calcPagination() {
			this.logger.debug(this, "calcPagination()");
			this.endPageNo = Math.ceil(this.pagination.current_page / this.displayPageCount) * this.displayPageCount;
			this.startPageNo = this.endPageNo - this.displayPageCount + 1;

			this.realEndPageNo = Math.ceil(this.pagination.total / this.pagination.per_page);

			if (this.startPageNo < 0) {
				this.startPageNo = 1;
			}
			if (this.endPageNo > this.realEndPageNo) {
				this.endPageNo = this.realEndPageNo;
			}

			this.isPrev = this.startPageNo == 1 ? false : true;
			this.isNext = this.endPageNo * this.pagination.per_page >= this.pagination.total ? false : true;

			this.pageNoList = [];
			for (let i = this.startPageNo; i <= this.endPageNo; i++) {
				this.pageNoList.push(i);
			}
		},
		checkAll() {
			this.deleteContentsNoList = [];

			if (!this.allChecked) {
				for (let contents of this.contentsList) {
					this.deleteContentsNoList.push(contents.contentsNo);
				}
			}

			this.allChecked = !this.allChecked;
		},
		deleteContentsList() {
			if (this.deleteContentsNoList.length == 0) {
				alert("삭제항목이 선택되지 않았습니다.");
				return;
			}

			if (!confirm("선택한 항목을 삭제 하시겠습니까?")) {
				return;
			}

			const formData = {
				deleteContentsNoList: this.deleteContentsNoList,
			};
			axios
				.post(this.config.contextRoot + "/contents/deleteContentsList.do", formData)
				.then((response) => {
					if (response.status == 200) {
						// 마지막 페이지에서 전체 선택 삭제할 경우
						if (
							this.startPageNo != this.realEndPageNo &&
							this.searchForm.pageNo == this.realEndPageNo &&
							this.deleteContentsNoList.length == this.contentsList.length
						) {
							this.$set(this.searchForm, "pageNo", Number(this.searchForm.pageNo) - 1 + "");
						}

						alert("삭제 되었습니다.");
						this.doSearchPage(this.searchForm.pageNo);
					}
				})
				.catch((error) => {
					if (error.response.data.message == "SQLIntegrityConstraintViolationException") {
						alert("해당 컨텐츠는 메뉴관리에서 사용 중이므로 삭제할 수 없습니다.");
						return;
					}

					this.exceptionHandler(this, error);
				});
		},
	},
};
</script>

<style></style>
