<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">배너 관리</h2>
        </div>
        <div class="contentBody">
            <!-- 검색 -->
            <div class="searchBar">
                <div class="columnDivision-3">
                    <div class="labelWrap">
                        <label>배너제목</label>
                        <input
                            type="text"
                            class="inputBox"
                            v-model="searchForm.bannerName"
                        />
                    </div>
                    <div class="labelWrap">
                        <label>사용여부</label>
                        <label>
                            <input
                                type="radio"
                                class="radioBox"
                                v-model="searchForm.useYn"
                                value=""
                            />
                            <span>전체</span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                class="radioBox"
                                v-model="searchForm.useYn"
                                value="Y"
                            />
                            <span>사용</span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                class="radioBox"
                                v-model="searchForm.useYn"
                                value="N"
                            />
                            <span>미사용</span>
                        </label>
                    </div>
                </div>
                <button class="searchBtn" @click="doSearchPage(1)">
                    조회하기
                </button>
            </div>
            <div class="contentTitleWrap marginTop-35">
                <h3 class="contentTitle">배너 목록</h3>
            </div>
            <div class="listWrap">
                <!-- 목록 상단 -->
                <div class="listTop">
                    <span class="listTotal"
                        >(Total <em>{{ pagination.total }}</em
                        >)</span
                    >
                    <select
                        class="selectBox"
                        @change.prevent="doSearchPage(1)"
                        v-model="searchForm.countPerPage"
                    >
                        <option>5</option>
                        <option selected>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                </div>

                <!-- 목록테이블 -->
                <table class="listTable tableFixed">
                    <colgroup>
                        <col style="width: 5%" />
                        <col style="width: 5%" />
                        <col style="width: 20%" />
                        <col style="width: 30%" />
                        <col style="width: 40%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <label>
                                    <input
                                        type="checkbox"
                                        @click="checkAll"
                                        v-model="allChecked"
                                    />
                                </label>
                            </th>
                            <th>배너번호</th>
                            <th>배너제목</th>
                            <th>배너링크주소</th>
                            <th>사용여부</th>
                        </tr>
                    </thead>
                    <tbody
                        v-for="banner in bannerList"
                        :key="banner.bannerNo"
                        @click="
                            $router.push({
                                path:
                                    config.contextRoot +
                                    '/site/' +
                                    $route.params.siteNo +
                                    '/banner/' +
                                    banner.bannerNo +
                                    '/edit',
                            })
                        "
                    >
                        <tr
                        >
                            <td>
                                <label
                                    ><input
                                        type="checkbox"
                                        v-model="deleteBannerNoList"
                                        :value="banner.bannerNo"
                                        @click.stop=""
                                    />
                                </label>
                            </td>
                            <td>{{ banner.bannerNo }}</td>
                            <td class="textEllipsis textLeft">
                                {{ banner.bannerName }}
                            </td>
                            <td>
                                {{  banner.bannerLinkAddress }}
                            </td>
                            <td>
                                {{
                                    banner.useYn == "Y"
                                        ? "사용"
                                        : "미사용"
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- 페이지네이션-->
                <div
                    class="pagination marginTop-50"
                    v-if="pageNoList && pageNoList.length > 0"
                >
                    <ul>
                        <li>
                            <a
                                href="#"
                                @click.prevent="doSearchPage(1)"
                                title="첫 페이지"
                            >
                                <img
                                    src="@/assets/images/component/pagination-first.png"
                                    alt="첫 페이지"
                                />
                            </a>
                        </li>
                        <li v-show="isPrev">
                            <a
                                href="#"
                                @click.prevent="doSearchPage(startPageNo - 1)"
                                :title="'이전' + displayPageCount + '페이지'"
                            >
                                <img
                                    src="@/assets/images/component/pagination-prev.png"
                                    :alt="'이전' + displayPageCount + '페이지'"
                                />
                            </a>
                        </li>
                        <li v-for="pageNo in pageNoList" :key="pageNo">
                            <a
                                href="#"
                                :class="
                                    pagination.current_page == pageNo
                                        ? 'active'
                                        : ''
                                "
                                @click.prevent="doSearchPage(pageNo)"
                            >
                                {{ pageNo }}
                            </a>
                        </li>
                        <li v-show="isNext">
                            <a
                                href="#"
                                @click.prevent="doSearchPage(endPageNo + 1)"
                                :title="'다음' + displayPageCount + '페이지'"
                            >
                                <img
                                    src="@/assets/images/component/pagination-next.png"
                                    :alt="'다음' + displayPageCount + '페이지'"
                                />
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                @click.prevent="doSearchPage(realEndPageNo)"
                                title="마지막 페이지"
                            >
                                <img
                                    src="@/assets/images/component/pagination-last.png"
                                    alt="마지막 페이지"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot + '/site/1/banner/create',
                        }"
                        class="radiusSmallBtn bgBlue"
                    >
                        신규
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgTuftsBlue"
                        @click.prevent="deleteBannerList()"
                    >
                        선택 삭제
                    </button>
                </div>
            </div>
        </div>



        <!-- 이미지 팝업 -->
        <div class="popupWrap" id="imagePopup" v-show="isShowImage">
            <div class="popupBackground"></div>
            <div class="popup contentPopup">
                <div class="popupHead">
                    <h2 class="popupTitle">팝업 미리보기</h2>
                    <button class="popupClose">
                        <img
                            src="@/assets/images/component/popup-close.png"
                            alt="팝업 닫기"
                            @click="closePopup(2)"
                        />
                    </button>
                </div>

                <!-- 팝업 페이지 1 -->
                <div>
                    <div class="popupBody" id="imagePopupBody">
                        <img ref="popupImage" src="" alt="이미지없음"/>
                    </div>
                </div>
            </div>
        </div>

        
    </div>
</template>

<script>
import axios from "axios";
import "tui-date-picker/dist/tui-date-picker.min.css";

export default {
    name: "BannerList",
    components: {}, 
    data() {
        return {
            siteNo: "",
            bannerList: [],
            pagination: {},
            searchForm: {
                bannerName: "",
                useYn: "",
                countPerPage: 10,
                pageNo: 1,
            },
            displayPageCount: 10,
            isPrev: "",
            isNext: "",
            startPageNo: "",
            endPageNo: "",
            realEndPageNo: "",
            pageNoList: [],
            deleteBannerNoList: [],
            allChecked: false,
            isShowRecruitmentUseYn: false,

            showCreatePopup: false,
            isShowImage: false,
            showEditPopup: false,
        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.selectBannerList();
    },
    watch: {
        deleteBannerNoList() {
            if (this.bannerList.length != this.deleteBannerNoList.length) {
                this.allChecked = false;
            } else {
                this.allChecked = true;
            }
        },
    },
    mounted() {
    },
    methods: {
        selectBannerList() {
            this.logger.debug(this, "selectBannerList()");
            this.searchForm.siteNo = this.siteNo;
            axios
                .post(
                    this.config.contextRoot + "/banner/selectBannerList.do",
                    this.searchForm
                )
                .then((response) => {
                    this.bannerList = response.data.bannerList;
                    this.pagination = response.data.pagination;
                    this.calcPagination();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },

        doSearchPage(pageNo) {
            this.logger.debug(this, "doSearchPage()");
            if (typeof pageNo === "undefined") {
                pageNo = 1;
            }
            this.deleteBannerNoList = [];
            this.searchForm.pageNo = pageNo;
            this.selectBannerList();
        },
        calcPagination() {
            this.logger.debug(this, "calcPagination()");
            this.endPageNo =
                Math.ceil(
                    this.pagination.current_page / this.displayPageCount
                ) * this.displayPageCount;
            this.startPageNo = this.endPageNo - this.displayPageCount + 1;

            this.realEndPageNo = Math.ceil(
                this.pagination.total / this.pagination.per_page
            );

            if (this.startPageNo < 0) {
                this.startPageNo = 1;
            }
            if (this.endPageNo > this.realEndPageNo) {
                this.endPageNo = this.realEndPageNo;
            }

            this.isPrev = this.startPageNo == 1 ? false : true;
            this.isNext =
                this.endPageNo * this.pagination.per_page >=
                this.pagination.total
                    ? false
                    : true;

            this.pageNoList = [];
            for (let i = this.startPageNo; i <= this.endPageNo; i++) {
                this.pageNoList.push(i);
            }
        },
        checkAll() {
            this.deleteBannerNoList = [];

            if (!this.allChecked) {
                for (let banner of this.bannerList) {
                    this.deleteBannerNoList.push(banner.bannerNo);
                }
            }

            this.allChecked = !this.allChecked;
        },
        deleteBannerList() {
            if (this.deleteBannerNoList.length == 0) {
                alert("삭제항목이 선택되지 않았습니다.");
                return;
            }

            if (!confirm("선택한 항목을 삭제 하시겠습니까?")) {
                return;
            }
            
            const formData = { deleteBannerNoList: this.deleteBannerNoList};

            axios
                .post(
                    this.config.contextRoot + "/banner/deleteBannerList.do",
                    formData
                )
                .then((response) => {
                    if (response.status == 200) {
                        // 마지막 페이지에서 전체 선택 삭제할 경우
                        if (
                            this.startPageNo != this.realEndPageNo &&
                            this.searchForm.pageNo == this.realEndPageNo &&
                            this.deleteBannerNoList.length ==
                                this.bannerList.length
                        ) {
                            this.$set(
                                this.searchForm,
                                "pageNo",
                                Number(this.searchForm.pageNo) - 1 + ""
                            );
                        }

                        alert("삭제 되었습니다.");
                        this.deleteBannerNoList = [];
                        this.doSearchPage(this.searchForm.pageNo);
                    }
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        openCreatePopup(){
            this.showCreatePopup = true
        },

        closePopup(kind) {
            this.logger.debug(this, "closePopup()");
            // this.showCreatePopup = false;
            if(kind == 1){
                this.$refs.assetCreatePopup.closePopup();
                this.$refs.assetEditPopup.closePopup();
            }else if(kind == 2){
                this.isShowImage = false;
                this.$refs.popupImage.src = "";
            }
        },

        openImagePopup(selectBanner){
            this.isShowImage = true;

            this.$refs.popupImage.src = this.config.contextRoot + "/common/file/imgLoad.do?"
                    + "fileGroupNo=" + selectBanner.assetFileGroupNo
                    + "&serverFileSaveName=" + selectBanner.serverFileSaveName;
        },

        
    },
};
</script>

<style>
</style>