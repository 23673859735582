<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">교육개설 관리</h2>
        </div>
        <div class="contentBody">
            <h3 class="contentTitle">교육개설 수정</h3>

            <!-- 입력 폼 -->
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
                <table class="infoTable marginBottom-35">
                    <tbody>
                        <tr>
                            <th class="requiredMark">교육명</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="programInfo.programName"
                                    v-validate="'required'"
                                    data-vv-name="programName"
                                    data-vv-as="교육명"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('programName')"
                                >
                                    * {{ errors.first("programName") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>신청기간</th>
                            <td>
                                <div class="labelWrap" style="width:300px; display: inline-block;">
                                    <label>시작일</label>
                                    <div class="datePicker">
                                        <input
                                            type="text"
                                            class="inputBox"
                                            placeholder="연도-월-일"
                                            id="applicantStartDt"
                                            v-model="programInfo.applicantStartDt"
                                            autocomplete="off"
                                        />
                                        <div ref="applicantStartDtPicker" id="applicantStartDtPicker"></div>
                                        <i></i>
                                    </div>
                                </div>

                                <div class="labelWrap" style="width:300px; display: inline-block;">
                                    <label>종료일</label>
                                    <div class="datePicker">
                                        <input
                                            type="text"
                                            class="inputBox"
                                            placeholder="연도-월-일"
                                            id="applicantEndDt"
                                            v-model="programInfo.applicantEndDt"
                                            autocomplete="off"
                                        />
                                        <div ref="applicantEndDtPicker" id="applicantEndDtPicker"></div>
                                        <i></i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>교육장소</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="programInfo.programLocation"
                                    v-validate="''"
                                    data-vv-name="programLocation"
                                    data-vv-as="교육장소"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('programLocation')"
                                >
                                    * {{ errors.first("programLocation") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>교육기간</th>
                            <td>
                                <div class="labelWrap" style="width:300px; display: inline-block;">
                                    <label>시작일</label>
                                    <div class="datePicker">
                                        <input
                                            type="text"
                                            class="inputBox"
                                            placeholder="연도-월-일"
                                            id="eduStartDt"
                                            v-model="programInfo.eduStartDt"
                                            autocomplete="off"
                                        />
                                        <div ref="eduStartDtPicker" id="eduStartDtPicker"></div>
                                        <i></i>
                                    </div>
                                </div>

                                <div class="labelWrap" style="width:300px; display: inline-block;">
                                    <label>종료일</label>
                                    <div class="datePicker">
                                        <input
                                            type="text"
                                            class="inputBox"
                                            placeholder="연도-월-일"
                                            id="eduEndDt"
                                            v-model="programInfo.eduEndDt"
                                            autocomplete="off"
                                        />
                                        <div ref="eduEndDtPicker" id="eduEndDtPicker"></div>
                                        <i></i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>모집인원</th>
                            <td>
                                <input
                                    type="number"
                                    class="inputBox"
                                    v-model="programInfo.eduQuota"
                                    v-validate="''"
                                    data-vv-name="eduQuota"
                                    data-vv-as="모집인원"
                                    style="width:60px"
                                    min="0"
                                />명
                                <p
                                    class="validation-text"
                                    v-if="errors.has('eduQuota')"
                                >
                                    * {{ errors.first("eduQuota") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>교육개설현황</th>
                            <td>
                                <div v-if="programInfo.cancelYn == 'N'">
                                    개설
                                    <button @click="cancelProgram()" class="upload2" style="width: 80px; height: 33px; margin-left: 10px; margin-bottom: 4px;">취소 처리</button>
                                </div>
                                <div v-if="programInfo.cancelYn != 'N'">
                                    취소되었습니다.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>파일선택</th>
                            <td>
								<common-file
									ref="FileUploader"
									v-model="programInfo.fileList"
									fileMaxSize="10"
									:multipleUpload="true"
								/>
							</td>
                        </tr>
                        <tr>
                            <th>내용</th>
                            <td>
                                <textarea hidden id="ckeditor" v-model="programInfo.programContents"
                                v-validate="'required'"
                                data-vv-name="programContents"
                                data-vv-as="내용"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            
            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/program',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/program/create',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        신규
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgNavy"
                        @click="updateProgram()"
                    >
                        저장
                    </button>
                    <button
                        class="radiusSmallBtn bgDark"
                        @click="deleteProgram()"
                    >
                        삭제
                    </button>
                </div>
            </div>

        </div>

        

    </div>
</template>

<script>
import axios from "axios";
import "tui-date-picker/dist/tui-date-picker.min.css";
import CommonFile from "@/components/common/CommonFile";
import EventBus from "../../script/EventBus";

import DatePicker from "tui-date-picker";
import "tui-date-picker/dist/tui-date-picker.min.css";

// import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";

import CKEditorConfig from "@/script/CKEditorConfig";

export default {
    name: "ProgramEdit", 
    data() {
        return {
            siteNo: "",
            programNo: "", 
            programInfo: {
                programName: "",
                applicantStartDt: "",
                applicantEndDt: "",
                programLocation: "",
                eduStartDt: "",
                eduEndDt: "",
                eduQuota: 0,
                programContents: "",
            },
        };
    },
    components: {
        CommonFile,
        // VueEditor,
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.programNo = this.$route.params.programNo;
        EventBus.$on("addFile", (fileList) => {
            this.logger.debug(this, "eventBus / addFile()");
            this.addFile(fileList);
        });

        this.selectProgramInfo();

    },
    mounted() {
        //에디터
        new CKEditorConfig("#ckeditor").create();
		// 데이트피커
		var today = new Date();
		const rangePicker1 = new DatePicker.createRangePicker({
			startpicker: {
				date: today,
				input: "#applicantStartDt",
				container: "#applicantStartDtPicker",
			},
            endpicker: {
				date: today,
				input: "#applicantEndDt",
				container: "#applicantEndDtPicker",
			},
			language: "ko",
		});
		rangePicker1.on("change:start", () => {
			this.programInfo.applicantStartDt = document.getElementById("applicantStartDt").value;
		});
		rangePicker1.on("change:end", () => {
			this.programInfo.applicantEndDt = document.getElementById("applicantEndDt").value;
		});

        const rangePicker2 = new DatePicker.createRangePicker({
			startpicker: {
				date: today,
				input: "#eduStartDt",
				container: "#eduStartDtPicker",
			},
            endpicker: {
				date: today,
				input: "#eduEndDt",
				container: "#eduEndDtPicker",
			},
			language: "ko",
		});
		rangePicker2.on("change:start", () => {
			this.programInfo.eduStartDt = document.getElementById("eduStartDt").value;
		});
		rangePicker2.on("change:end", () => {
			this.programInfo.eduEndDt = document.getElementById("eduEndDt").value;
		});
	},
    watch: {
    },
    methods: {
        selectProgramInfo() {
            this.logger.debug(this, "selectProgram()");
            var searchData = {programNo : this.programNo};
            axios
                .post(
                    this.config.contextRoot + "/program/selectProgram.do",
                    searchData
                )
                .then((response) => {
                    this.programInfo = response.data.programInfo;

                    // 날짜변환
                    if(this.programInfo.applicantStartDt){
                        this.programInfo.applicantStartDt = this.yyyyMMdd(this.programInfo.applicantStartDt);
                    } 
                    if(this.programInfo.applicantEndDt){
                        this.programInfo.applicantEndDt = this.yyyyMMdd(this.programInfo.applicantEndDt);
                    } 
                    if(this.programInfo.eduStartDt){
                        this.programInfo.eduStartDt = this.yyyyMMdd(this.programInfo.eduStartDt);
                    } 
                    if(this.programInfo.eduEndDt){
                        this.programInfo.eduEndDt = this.yyyyMMdd(this.programInfo.eduEndDt);
                    }
                    window.editor.setData(this.programInfo.programContents);
                    console.log(this.programInfo);
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        updateProgram() {
            this.logger.debug(this, "insertProgram()");

            this.programInfo.programContents = window.editor.getData();

            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }else{
                        if (!confirm("수정 하시겠습니까?")) {
                            return;
                        }

                        // 날짜변환
                        if(this.programInfo.applicantStartDt){
                            this.programInfo.applicantStartDt = this.programInfo.applicantStartDt.replaceAll("-", "");
                        } 
                        if(this.programInfo.applicantEndDt){
                            this.programInfo.applicantEndDt = this.programInfo.applicantEndDt.replaceAll("-", "");
                        } 
                        if(this.programInfo.eduStartDt){
                            this.programInfo.eduStartDt = this.programInfo.eduStartDt.replaceAll("-", "");
                        } 
                        if(this.programInfo.eduEndDt){
                            this.programInfo.eduEndDt = this.programInfo.eduEndDt.replaceAll("-", "");
                        }

                        axios
                            .post(
                                this.config.contextRoot +
                                    "/program/updateProgram.do",
                                this.programInfo
                            )
                            .then((response) => {
                                alert("수정 되었습니다.");
                                this.programNo = response.data.programNo;
                                this.selectProgramInfo();
                            })
                            .catch((error) => {
                                this.exceptionHandler(this, error);
                            });
                    }

                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.error.all());
                    return false;
                });
        },
        deleteProgram() {
            if (!confirm("삭제 하시겠습니까?")) {
                return;
            }

            const formData = {
                deleteProgramNoList: [this.programNo],
            };
            axios
                .post(
                    this.config.contextRoot + "/program/deleteProgramList.do",
                    formData
                )
                .then((response) => {
                    if (response.status == 200) {
                        alert("삭제 되었습니다.");
                        this.$router.replace(
                            this.config.contextRoot +
                                "/site/" +
                                this.siteNo +
                                "/program"
                        );
                    }
                })
                .catch((error) => {
                    if (
                        error.response.data.message ==
                        "SQLIntegrityConstraintViolationException"
                    ) {
                        alert(
                            "해당 컨텐츠는 메뉴관리에서 사용 중이므로 삭제할 수 없습니다."
                        );
                        return;
                    }

                    this.exceptionHandler(this, error);
                });
        },

        openUploader(refName) {
            this.logger.debug(this, "openUploader()");
            this.$refs[refName].openUploader();
        },
        doDownload(data) {
            this.logger.debug(this, "doDownload");
            const formData = new FormData();
            formData.append("fileNo", data.fileNo);
            formData.append("fileName", data.fileName);
            formData.append("serverFileSaveName", data.serverFileSaveName);
            axios
                .post(
                    this.config.contextRoot + "/common/file/download.do",
                    formData,
                    { responseType: "blob" }
                )
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: response.headers["content-type"],
                        })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", data.fileName);
                    document.body.appendChild(link);
                    link.click();
                    location.reload();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        deleteFile(index) {
            this.programInfo.fileList.splice(index, 1);
        },
        addFile(fileList) {
            this.programInfo.fileList =
                this.programInfo.fileList.concat(fileList);
        },
        yyyyMMdd(value){
            // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
            if(value == '') return '';

            // 연도, 월, 일 추출
            var year = value.substring(0, 4);
            var month = value.substring(4, 6);
            var day = value.substring(6, 8);

            // 최종 포맷 (ex - '2021-10-08')
            return year + '-' + month + '-' + day;
        },
        cancelProgram(){
            if (!confirm("취소하시겠습니까? 다시 변경할 수 없습니다.")) {
                return;
            }

            axios
                .post(
                    this.config.contextRoot +
                        "/program/cancelProgram.do",
                    {programNo : this.programNo}
                )
                .then((response) => {
                    alert("취소저리 되었습니다.");
                    this.programNo = response.data.programNo;
                    this.selectProgramInfo();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
    },
};
</script>

<style>
    .tui-calendar{ z-index: 999; }
</style>