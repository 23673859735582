<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">회원관리</h2>
        </div>
        <div class="contentBody">
            <!-- 회원등록 -->
            <h3 class="contentTitle">회원 수정</h3>
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
                <table class="infoTable tableFixed">
                    <tbody>
                        <tr>
                            <th class="requiredMark">아이디</th>
                            <td>
                                <div class="inputBtnWrap">
                                    {{ memberInfo.loginId }}
                                </div>
                            </td>
                            <th class="requiredMark">비밀번호</th>
                            <td>
                                <button
                                    type="button"
                                    class="radiusInputBtn"
                                    @click="openPasswordPopup"
                                >
                                    비밀번호 변경
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">이름</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="memberInfo.userName"
                                    v-validate="'required'"
                                    data-vv-name="userName"
                                    data-vv-as="이름"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('userName')"
                                >
                                    * {{ errors.first("userName") }}
                                </p>
                            </td>
                            <th class="requiredMark">별칭</th>
                            <td>
                                <div class="inputBtnWrap">
                                    <input
                                        type="text"
                                        class="inputBox"
                                        v-model="memberInfo.aliasName"
                                        v-validate="'required'"
                                        data-vv-name="aliasName"
                                        data-vv-as="별칭"
                                    />
                                    <button
                                        class="radiusInputBtn"
                                        @click="checkAliasName()"
                                    >
                                        중복확인
                                    </button>
                                </div>
                                <p
                                    class="validation-text"
                                    v-if="!checkedAliasName"
                                >
                                    * 중복 체크를 해주세요.
                                </p>
                                <p
                                    class="validation-text"
                                    v-if="errors.has('aliasName')"
                                >
                                    * {{ errors.first("aliasName") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">핸드폰</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="memberInfo.mobileNo"
                                    v-validate="'required'"
                                    data-vv-name="mobileNo"
                                    data-vv-as="핸드폰"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('mobileNo')"
                                >
                                    * {{ errors.first("mobileNo") }}
                                </p>
                            </td>
                            <th>전화번호</th>
                            <td>
                                <div class="inputBtnWrap">
                                    <input
                                        type="text"
                                        class="inputBox"
                                        v-model="memberInfo.telNo"
                                        v-validate="'required'"
                                        data-vv-name="telNo"
                                        data-vv-as="전화번호"
                                    />
                                </div>
                                <p
                                    class="validation-text"
                                    v-if="errors.has('telNo')"
                                >
                                    * {{ errors.first("telNo") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">이메일</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="memberInfo.emailAddress"
                                    v-validate="'required|email'"
                                    data-vv-name="emailAddress"
                                    data-vv-as="이메일"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('emailAddress')"
                                >
                                    * {{ errors.first("emailAddress") }}
                                </p>
                            </td>
                            <th>가입일시</th>
                            <td>
                                {{ memberInfo.userAddDt }}
                            </td>
                        </tr>
                        <tr>
                            <th>조사지역</th>
                            <td colspan="3">
                                <label v-for="item in locationList" :key="item"
                                    style="margin-left: 10px;"
                                >
                                    <input
                                        type="checkbox"
                                        v-model="checkUserLocationList"
                                        :value="item"
                                    />
                                    {{ item }}
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 회원권한 -->
            <div class="contentTitleWrap marginTop-35">
                <h3 class="contentTitle">회원권한</h3>
                <div class="titleBtnWrap">
                    <button class="squareBtn bgLightGray" @click="openPopup()">
                        권한관리
                    </button>
                </div>
            </div>
            <div class="listWrap">
                <!-- 목록 상단 -->
                <div class="listTop">
                    <!-- <span class="listTotal">(Total <em>1,100</em>)</span> -->
                    <select class="selectBox">
                        <option>5</option>
                        <option selected>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                </div>

                <!-- 목록테이블 -->
                <table class="listTable">
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>권한명</th>
                            <th>권한부여</th>
                            <th>권한부여일자</th>
                            <th>설명</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(role, index) in roleList"
                            :key="role.roleNo"
                        >
                            <td>{{ index + 1 }}</td>
                            <td>{{ role.roleName }}</td>
                            <td>
                                <label>
                                    <input
                                        v-if="role.roleName == 'ROLE_ANONYMOUS'"
                                        type="checkbox"
                                        checked
                                        disabled
                                    />
                                    <input
                                        v-else
                                        type="checkbox"
                                        v-model="checkedRoleNoList"
                                        :value="role.roleNo"
                                    />
                                </label>
                            </td>
                            <td>{{ role.roleAddDt }}</td>
                            <td>{{ role.roleDiscription }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/member',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgNavy"
                        @click="updateMember()"
                    >
                        저장
                    </button>
                    <button
                        class="radiusSmallBtn bgDark"
                        @click="deleteMember()"
                    >
                        삭제
                    </button>
                </div>
            </div>
            <!-- 비밀번호변경 팝업 -->
            <div
                class="popupWrap"
                id="passwordPopup"
                v-if="this.passwordPopupData.isShow"
            >
                <div class="popupBackground"></div>
                <div class="popup contentPopup">
                    <div class="popupHead">
                        <h2 class="popupTitle">비밀번호 변경</h2>
                        <button class="popupClose">
                            <img
                                src="@/assets/images/component/popup-close.png"
                                alt="팝업 닫기"
                                @click="closePasswordPopup"
                            />
                        </button>
                    </div>

                    <!-- 팝업 페이지 1 -->
                    <div class="popupPage popupPage-1">
                        <div class="popupBody">
                            <div class="contentTitleWrap">
                                <h3 class="contentTitle">비밀번호 변경</h3>
                            </div>
                            <table class="infoTable marginBottom-35">
                                <tbody>
                                    <tr>
                                        <th class="size-150">비밀번호</th>
                                        <td>
                                            <input
                                                type="password"
                                                class="inputBox"
                                                v-model="
                                                    passwordPopupData.password
                                                "
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>비밀번호 확인</th>
                                        <td>
                                            <input
                                                type="password"
                                                class="inputBox"
                                                v-model="
                                                    passwordPopupData.rePassword
                                                "
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="textCenter">
                                <button
                                    class="squareBtn bgNavy"
                                    @click="updatePassword()"
                                >
                                    저장
                                </button>
                                <button
                                    class="squareBtn bgGray"
                                    @click="closePasswordPopup"
                                >
                                    닫기
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 회원관리 팝업 -->
            <RolePopup v-if="openPopupStatus" />
        </div>
    </div>
</template>

<script>
import RolePopup from "@/components/role/RolePopup";
import axios from "axios";
import EventBus from "@/script/EventBus.js";
export default {
    name: "MemberEdit",
    components: {
        RolePopup,
    },
    data() {
        return {
            siteNo: "",
            userNo: "",
            roleList: [],
            checkedRoleNoList: [],
            memberInfo: {},
            tempAliasName: "",
            currentAliasName: "",
            aliasNameDuplicate: false,
            openPopupStatus: false,
            passwordPopupData: {
                password: "",
                rePassword: "",
                isShow: false,
            },
            locationList: [],
            checkUserLocationList: [],
        };
    },
    computed: {
        checkedAliasName() {
            return (
                !this.memberInfo.aliasName ||
                this.currentAliasName == this.memberInfo.aliasName ||
                this.memberInfo.aliasName == this.tempAliasName
            );
        },
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.userNo = this.$route.params.userNo;
        this.selectMember();

        EventBus.$on("popupOpenStatus", () => {
            this.openPopupStatus = false;
        });
    },
    methods: {
        openPasswordPopup() {
            this.passwordPopupData.isShow = true;
        },
        closePasswordPopup() {
            this.passwordPopupData.isShow = false;
            this.passwordPopupData.password = "";
            this.passwordPopupData.rePassword = "";
        },
        updatePassword() {
            if (this.passwordPopupData.password != this.passwordPopupData.rePassword) {
                alert("두 비밀번호가 서로 다릅니다.");
                return;
            }

            axios
                .post(
                    this.config.contextRoot + "/member/updatePassword.do",
                    {...this.memberInfo, ...{loginPassword: this.passwordPopupData.password}}
                )
                .then((response) => {
                    if (response.status == 200) {
                        alert("변경 되었습니다.");
                        this.passwordPopupData.isShow = false;
                        this.passwordPopupData.password = "";
                        this.passwordPopupData.rePassword = "";
                    }
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        openPopup() {
            this.openPopupStatus = true;
        },
        selectMember() {
            this.logger.debug(this, "selectMember()");
            axios
                .post(this.config.contextRoot + "/member/selectMember.do", {
                    userNo: this.userNo,
                    siteNo: this.siteNo,
                })
                .then((response) => {
                    this.memberInfo = response.data.memberInfo;

                    this.tempAliasName = this.memberInfo.aliasName; // 중복확인 여부를 체크하기 위한 변수
                    this.currentAliasName = this.memberInfo.aliasName; // 수정전 별칭

                    this.roleList = response.data.roleList; // 전체 권한 목록

                    this.checkedRoleNoList =
                        response.data.memberInfo.roleNoList.slice(0); // 회원에게 부여된 권한 목록
                    
                    this.locationList =
                        response.data.locationList;  // 조사지역 전체 리스트

                    this.checkUserLocationList = 
                        response.data.memberInfo.userLocationText.split(','); // 유저 조사지역
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        updateMember() {
            this.logger.debug(this, "updateMember()");

            if (!confirm("수정 하시겠습니까?")) {
                return;
            }

            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result || !this.checkedAliasName) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }

                    this.memberInfo.roleNoList = this.checkedRoleNoList;
                    this.memberInfo.userLocationList = this.checkUserLocationList;
                    axios
                        .post(
                            this.config.contextRoot + "/member/updateMember.do",
                            this.memberInfo
                        )
                        .then((response) => {
                            if (response.status == 200) {
                                alert("수정 되었습니다.");
                            }
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });
                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.errors.all());
                    return false;
                });
        },
        checkAliasName() {
            // 별칭 중복 체크
            axios
                .post(this.config.contextRoot + "/member/hasAliasName.do", {
                    siteNo: this.siteNo,
                    aliasName: this.memberInfo.aliasName,
                })
                .then((response) => {
                    if (response.data.hasAliasName) {
                        // 별칭 중복이면
                        alert("이미 사용된 별칭입니다.");
                        this.aliasNameDuplicate = true;
                        return;
                    }

                    // 사용가능한 별칭
                    alert("사용가능합니다.");
                    this.tempAliasName = this.memberInfo.aliasName;
                    this.aliasNameDuplicate = false;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        deleteMember() {
            if (!confirm("삭제 하시겠습니까?")) {
                return;
            }

            const formData = {
                userNo: this.memberInfo.userNo,
            };
            axios
                .post(
                    this.config.contextRoot + "/member/deleteMember.do",
                    formData
                )
                .then((response) => {
                    if (response.status == 200) {
                        alert("삭제 되었습니다.");
                        this.$router.replace(
                            this.config.contextRoot +
                                "/site/" +
                                this.siteNo +
                                "/member"
                        );
                    }
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
    },
};
</script>

<style>
</style>