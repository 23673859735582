<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">컨텐츠 관리</h2>
        </div>
        <div class="contentBody">
            <h3 class="contentTitle">컨텐츠 등록</h3>

            <!-- 입력 폼 -->
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
                <table class="infoTable marginBottom-35">
                    <tbody>
                        <tr>
                            <th class="requiredMark" style="width: 140px">
                                컨텐츠 제목
                            </th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="contentsInfo.contentsTitle"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">사용 여부</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="contentsInfo.useYn"
                                            value="Y"
                                        />
                                        <span>사용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="contentsInfo.useYn"
                                            value="N"
                                        />
                                        <span>미사용</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">검색 허용 여부</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-2"
                                            v-model="contentsInfo.searchAllowYn"
                                            value="Y"
                                        />
                                        <span>허용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-2"
                                            v-model="contentsInfo.searchAllowYn"
                                            value="N"
                                        />
                                        <span>불가</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
						<tr>
							<th class="requiredMark">내용</th>
							<td>
								<div>
									<prism-editor
										class="my-editor"
										v-model="contentsInfo.articleContents"
										v-validate="'required'"
										data-vv-name="articleContents"
										data-vv-as="내용"
										:highlight="highlighter"
										line-numbers
									></prism-editor>
								</div>
								<p class="validation-text" v-if="errors.has('articleContents')">
									* {{ errors.first("articleContents") }}
								</p>
							</td>
						</tr>
                    </tbody>
                </table>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/contents',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgNavy"
                        @click="openPreviewPopup()"
                    >
                        미리보기
                    </button>
                    <button
                        class="radiusSmallBtn bgNavy"
                        @click="insertContents()"
                    >
                        저장
                    </button>
                    <button class="radiusSmallBtn bgDark" @click="reset()">
                        취소
                    </button>
                </div>
            </div>

            <!-- 미리보기 팝업 -->
            <div class="popupWrap" v-show="isShowPreviewPopup">
                <div class="popupBackground">
                    <div class="popup contentPopup">
                        <div class="popupHead">
                            <h2 class="popupTitle">내용 미리보기</h2>
                            <button class="popupClose">
                                <img
                                    src="@/assets/images/component/popup-close.png"
                                    alt="팝업 닫기"
                                    @click="closePopup()"
                                />
                            </button>
                        </div>
                        <div class="popupPage" style="height: 800px; overflow: scroll;" v-html="contentsInfo.articleContents">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import EventBus from "../../script/EventBus";
// import highlighting library (you can use any library you want just return html string)
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles


export default {
    name: "ContentsCreate",
    data() {
        return {
            siteNo: "",
            contentsNo: "",
            contentsInfo: {
                useYn: "Y",
                searchAllowYn: "Y",
                fileList: [],
            },
            isShowPreviewPopup: false,
        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.contentsNo = this.$route.params.contentsNo;

        EventBus.$on("addFile", (fileList) => {
            this.logger.debug(this, "eventBus / addFile()");
            this.addFile(fileList);
        });
    },
    components: {
        PrismEditor,
    },
    methods: {
        highlighter(code) {
            return highlight(code, languages.js); // languages.<insert language> to return html with markup
        },
        insertContents() {
            this.logger.debug(this, "insertContents()");

            if (!confirm("등록 하시겠습니까?")) {
                return;
            }

            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }

                    axios
                        .post(
                            this.config.contextRoot +
                                "/contents/insertContents.do",
                            this.contentsInfo
                        )
                        .then((response) => {
                            if (response.status == 200) {
                                alert("등록 되었습니다.");
                                this.$router.replace(
                                    this.config.contextRoot +
                                        "/site/" +
                                        this.siteNo +
                                        "/contents/" +
                                        response.data.contentsNo +
                                        "/edit"
                                );
                            }
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });
                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.errors.all());
                    return false;
                });
        },
        reset() {
            if (confirm("취소 하시겠습니까?")) {
                this.$router.go(this.$router.currentRoute);
            }
        },
        addFile(fileList) {
            this.articleInfo.fileList =
                this.articleInfo.fileList.concat(fileList);
        },
        openPreviewPopup() {
            this.isShowPreviewPopup = true;
        },
        closePopup() {
            this.isShowPreviewPopup = false;
        },
    },
};
</script>

<style>
  /* required class */
  .my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }
</style>