<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">수유실 관리</h2>
        </div>
        <div class="contentBody">
            <h3 class="contentTitle">수유실 등록</h3>

            <!-- 입력 폼 -->
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
            </div>

            <!-- 기관 현황 -->
            <div class="infoWrap">
                <label>
                    <span>기관 현황</span>
                    <!-- <button class="openBtn" id="openBtn1" @click="openDetail($event)">
                        +
                    </button> -->
                </label>
                <!-- <table class="infoTable marginBottom-35 marginTop-15" v-show="openAddRoomInfo"> -->
                <table class="infoTable marginBottom-35 marginTop-15">
                    <tbody>
                    <tr>
                        <th class="requiredMark">기관명</th>
                        <td colspan="2">
                            <input
                                type="text"
                                class="inputBox"
                                style="width: 50%; margin-right: 10px;"
                                v-model="nursingroomInfo.roomName"
                                v-validate="'required'"
                                data-vv-name="roomName"
                                data-vv-as="기관명 "
                                placeholder="기관명을 입력하세요."
                            />
                            <button
                                class="radiusInputBtn"
                                @click="checkRoomName()"
                            >
                                중복확인
                            </button>
                            <p
                                class="validation-text"
                                v-if="errors.has('roomName')"
                            >
                                * {{ errors.first("roomName") }}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th class="requiredMark">주소</th>
                        <td colspan="2">
                            <input
                                type="text"
                                class="inputBox"
                                style="width: 93%; margin-right: 10px;"
                                v-model="nursingroomInfo.address"
                                v-validate="'required'"
                                data-vv-name="address"
                                data-vv-as="주소 "
                                readonly
                            />
                            <button
                                class="radiusInputBtn"
                                @click="openDaumPostcode()"
                            >
                                주소 찾기
                            </button>
                            <p
                                class="validation-text"
                                v-if="errors.has('address')"
                            >
                                * {{ errors.first("address") }}
                            </p>
                            <div v-if="openPostcode" style="width: 500px; border: 1px solid; margin-top: 15px;">
                                <vue-daum-postcode @complete="searchDaumPostcode"/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>위도</th>
                        <td colspan="2">
                            <input
                                type="text"
                                class="inputBox"
                                v-model="nursingroomInfo.gpsLat"
                                data-vv-name="gpsLat"
                                data-vv-as="위도 "
                                readonly
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>경도</th>
                        <td colspan="2">
                            <input
                                type="text"
                                class="inputBox"
                                v-model="nursingroomInfo.gpsLong"
                                data-vv-name="gpsLong"
                                data-vv-as="경도 "
                                readonly
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>기관구분</th>
                        <td colspan="2">
                            <label>
                                <input
                                    type="radio"
                                    class="radioBox"
                                    v-model="nursingroomInfo.organizationCode"
                                    value="1"
                                />
                                <span>  청사</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    class="radioBox"
                                    v-model="nursingroomInfo.organizationCode"
                                    value="2"
                                />
                                <span>  공공기관</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    class="radioBox"
                                    v-model="nursingroomInfo.organizationCode"
                                    value="3"
                                />
                                <span>  교통시설</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    class="radioBox"
                                    v-model="nursingroomInfo.organizationCode"
                                    value="4"
                                />
                                <span>  공중(다중)시설</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    class="radioBox"
                                    v-model="nursingroomInfo.organizationCode"
                                    value="5"
                                />
                                <span>  학교</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    class="radioBox"
                                    v-model="nursingroomInfo.organizationCode"
                                    value="6"
                                />
                                <span>  기업체(민간기관)</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <th class="requiredMark">담당자</th>
                        <td colspan="2">
                            <input
                                type="text"
                                class="inputBox"
                                v-model="nursingroomInfo.managerName"
                                v-validate="'required'"
                                data-vv-name="managerName"
                                data-vv-as="담당자 "
                                placeholder="담당자를 입력하세요."
                            />
                            <p
                                class="validation-text"
                                v-if="errors.has('managerName')"
                            >
                                * {{ errors.first("managerName") }}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th class="requiredMark">연락처</th>
                        <td colspan="2">
                            <input
                                type="text"
                                class="inputBox"
                                v-model="nursingroomInfo.managerTelNo"
                                v-validate="'required'"
                                data-vv-name="managerTelNo"
                                data-vv-as="연락처 "
                                placeholder="연락처를 입력하세요."
                            />
                            <p
                                class="validation-text"
                                v-if="errors.has('managerTelNo')"
                            >
                                * {{ errors.first("managerTelNo") }}
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- 수유시설 상세 -->
            <div v-for="(item, index) in nursingroomInfo.nursingroomList" :key="index" :value="item">
                <div>
                    <div class="infoWrap">
                        <label>
                            <span>수유시설 상세</span><span>{{ index + 1 }}</span>
                        </label>
                        <table class="infoTable marginBottom-35 marginTop-15">
                            <tbody>
                            <tr>
                                <th>수유실 면적</th>
                                <td colspan="2">
                                    <label style="margin-right: 15px;">
                                        <input
                                            type="number"
                                            class="inputBox"
                                            style="width: 90px;"
                                            v-model="item.area"
                                        />
                                    </label>
                                    <label>
                                        <select
                                            id="areaUnit"
                                            class="selectBox"
                                            style="width: auto;"
                                            v-model="item.areaUnit"
                                        >
                                            <option value="" selected>단위</option>
                                            <option value="1" selected>㎡</option>
                                            <option value="2" selected>평</option>
                                        </select>
                                    </label>
                                </td>
                            </tr>
                            <!-- TODO.내용추가-->
                            <tr>
                                <th>수유시설<br/>설치일</th>
                                <td colspan="2">
                                    <div class="datePicker" style="width: 200px">
                                        <input
                                            type="text"
                                            class="inputBox"
                                            placeholder="연도-월-일"
                                            :id="'installDate' + index"
                                            v-model="item.installDate"
                                            autocomplete="off"
                                        />
                                        <div
                                            :ref="'installDatePicker' + index"
                                            :id="'installDatePicker' + index"
                                        ></div>
                                        <i></i>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>시설위치</th>
                                <td colspan="2">
                                    <input
                                        type="text"
                                        class="inputBox"
                                        v-model="item.location"
                                        data-vv-name="location"
                                        data-vv-as="위치 "
                                        placeholder="상세 위치를 입력하세요."
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>시설 사진</th>
                                <td colspan="2">
                                    <common-file
                                        ref="FileUploader"
                                        v-model="item.fileList"
                                        :multipleUpload="true"
                                        initAccept="image/*"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>명패호수</th>
                                <td colspan="2">
                                    제
                                    <input
                                        type="number"
                                        class="inputBox"
                                        style="width: 110px;"
                                        v-model="item.locationName"
                                        data-vv-name="locationName"
                                        data-vv-as="명패호수 "
                                        placeholder="명패호수 입력"
                                    />
                                    호
                                </td>
                            </tr>
                            <!-- TODO.INPUT추가-->
                            <tr>
                                <th>운영현황</th>
                                <td colspan="2">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.manageConditionCode"
                                            value="1"
                                        />
                                        <span>  운영 중</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.manageConditionCode"
                                            value="2"
                                            @click="textInputFocus('manageConditionDetail2' + index);"
                                        />
                                        <span>  일시 중단</span>
                                        <input
                                            type="text"
                                            class="inputBox"
                                            :ref="'manageConditionDetail2' + index"
                                            style="width: 15%; margin-left: 10px;"
                                            v-model="item.manageConditionDetail2"
                                            @click="item.manageConditionCode = '2'"
                                            placeholder="사유를 입력하세요."
                                            :disabled="item.manageConditionCode != '2'"
                                        />
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.manageConditionCode"
                                            value="3"
                                            @click="textInputFocus('manageConditionDetail3' + index);"
                                        />
                                        <span>  폐쇄</span>
                                        <input
                                            type="text"
                                            class="inputBox"
                                            :ref="'manageConditionDetail3' + index"
                                            style="width: 15%; margin-left: 10px;"
                                            v-model="item.manageConditionDetail3"
                                            @click="item.manageConditionCode = '3'"
                                            placeholder="사유를 입력하세요."
                                            :disabled="item.manageConditionCode != '3'"
                                        />
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.manageConditionCode"
                                            value="5"
                                            @click="textInputFocus('manageConditionDetail5' + index);"
                                        />
                                        <span>  이전</span>
                                        <input
                                            type="text"
                                            class="inputBox"
                                            :ref="'manageConditionDetail5' + index"
                                            style="width: 15%; margin-left: 10px;"
                                            v-model="item.manageConditionDetail5"
                                            @click="item.manageConditionCode = '5'"
                                            placeholder="사유를 입력하세요."
                                            :disabled="item.manageConditionCode != '5'"
                                        />
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.manageConditionCode"
                                            value="4"
                                            @click="textInputFocus('manageConditionDetail4' + index);"
                                        />
                                        <span>  기타</span>
                                        <input
                                            type="text"
                                            class="inputBox"
                                            :ref="'manageConditionDetail4' + index"
                                            style="width: 15%; margin-left: 10px;"
                                            v-model="item.manageConditionDetail4"
                                            @click="item.manageConditionCode = '4'"
                                            placeholder="내용을 입력하세요."
                                            :disabled="item.manageConditionCode != '4'"
                                        />
                                    </label>
                                </td>
                            </tr>

                            <tr>
                                <th>용도구분</th>
                                <td colspan="2">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.roomTypeCode"
                                            value="4"
                                        />
                                        <span>  모유수유/착유실</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.roomTypeCode"
                                            value="3"
                                        />
                                        <span>  가족수유실</span>
                                    </label>
                                    <label style="margin-right: 15px;">1회 이용 가능 인원</label>
                                    <label>
                                        <input
                                            type="number"
                                            class="inputBox"
                                            style="width: 50px;"
                                            v-model="item.peakCount"
                                            placeholder="인원수 입력"
                                        />
                                        <span>  명</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th class="requiredMark">아빠 이용</th>
                                <td colspan="2">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.fatherUseYn"
                                            v-validate="'required'"
                                            value="0"
                                            :name="'fatherUseYn' + index"
                                            data-vv-as="아빠 이용 "
                                        />
                                        <span>  불가</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.fatherUseYn"
                                            v-validate="'required'"
                                            value="1"
                                            :name="'fatherUseYn' + index"
                                            data-vv-as="아빠 이용 "
                                        />
                                        <span>  가능</span>
                                    </label>
                                    <p
                                        class="validation-text"
                                        v-if="errors.has('fatherUseYn' + index)"
                                    >
                                        * {{ errors.first("fatherUseYn" + index) }}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <th rowspan="3">이용현황</th>
                                <th class="requiredMark">이용 대상</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.useTypeCode"
                                            v-validate="'required'"
                                            value="0"
                                            :name="'useTypeCode' + index"
                                            data-vv-as="이용대상 "
                                        />
                                        <span>  직원용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.useTypeCode"
                                            v-validate="'required'"
                                            value="1"
                                            :name="'useTypeCode' + index"
                                            data-vv-as="이용대상 "
                                        />
                                        <span>  외부인</span>
                                    </label>
                                    <label style="width: 115px">
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.useTypeCode"
                                            v-validate="'required'"
                                            value="2"
                                            :name="'useTypeCode' + index"
                                            data-vv-as="이용대상 "
                                        />
                                        <span>  외부/직원용</span>
                                    </label>
                                    <p
                                        class="validation-text"
                                        v-if="errors.has('useTypeCode' + index)"
                                    >
                                        * {{ errors.first("useTypeCode" + index) }}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <th>일일평균<br>이용자 수</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.useAvgAdayRange"
                                            v-validate="''"
                                            value="1"
                                            data-vv-name="useTypeCode"
                                            data-vv-as="일일평균 이용자 수 "
                                        />
                                        <span>  5명 이하</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.useAvgAdayRange"
                                            v-validate="''"
                                            value="2"
                                            data-vv-name="useTypeCode"
                                            data-vv-as="일일평균 이용자 수"
                                        />
                                        <span>  6 ~ 10명</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.useAvgAdayRange"
                                            v-validate="''"
                                            value="3"
                                            data-vv-name="useTypeCode"
                                            data-vv-as="일일평균 이용자 수"
                                        />
                                        <span>  11 ~ 20명</span>
                                    </label>
                                    <label style="width: 115px">
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.useAvgAdayRange"
                                            v-validate="''"
                                            value="4"
                                            data-vv-name="useTypeCode"
                                            data-vv-as="일일평균 이용자 수"
                                        />
                                        <span>  21명 이상</span>
                                    </label>
                                    <span>* 아기(젖먹이)와 엄마(보호자)는 1명으로 간주</span>
                                </td>
                            </tr>
                            <tr>
                                <th>관리 주기</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePeriodCode"
                                            value="5"
                                        />
                                        <span>  2시간마다</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePeriodCode"
                                            value="2"
                                        />
                                        <span>  1일 2회 이상</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePeriodCode"
                                            value="3"
                                        />
                                        <span>  1일 1회</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePeriodCode"
                                            value="6"
                                        />
                                        <span>  격일</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePeriodCode"
                                            value="7"
                                        />
                                        <span>  1주 2회</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePeriodCode"
                                            value="4"
                                        />
                                        <span>  1주 1회</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePeriodCode"
                                            value="8"
                                            @click="textInputFocus('carePeriodDetail' + index)"
                                        />
                                        <span>  기타</span>
                                        <input
                                            type="text"
                                            class="inputBox"
                                            :ref="'carePeriodDetail' + index"
                                            style="width: 20%; margin-left: 10px;"
                                            v-model="item.carePeriodDetail"
                                            @click="item.carePeriodCode = '8'"
                                            placeholder="관리주기를 입력하세요."
                                            :disabled="item.carePeriodCode != '8'"
                                        />
                                    </label>
                                </td>
                            </tr>

                            <tr>
                                <th>조사일</th>
                                <td colspan="2">
                                    <div class="datePicker" style="width: 200px">
                                        <input
                                            type="text"
                                            class="inputBox"
                                            placeholder="연도-월-일"
                                            :id="'surveyDate' + index"
                                            v-model="item.surveyDate"
                                            autocomplete="off"
                                        />
                                        <div
                                            :ref="'surveyDatePicker' + index"
                                            :id="'surveyDatePicker' + index"
                                        ></div>
                                        <i></i>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>조사지회</th>
                                <td colspan="2">
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox"
                                            v-model="item.surveyOrganizationName"
                                            placeholder="조사지회를 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>조사자</th>
                                <td colspan="2">
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox"
                                            v-model="item.surveyName"
                                            placeholder="조사자를 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- 수유시설 관리 점검표 -->
                    <div class="infoWrap marginTop-30">
                        <label>
                            <span>수유시설 관리 점검표</span>
                            <!-- <button class="openBtn" id="openBtn2" @click="openDetail($event)">
                                +
                            </button> -->
                        </label>
                        <!-- <table class="infoTable marginBottom-35 marginTop-15" v-show="openCarePoint"> -->
                        <table class="infoTable marginBottom-35 marginTop-15">
                            <tbody>
                            <tr>
                                <th rowspan="5">공간</th>
                                <td rowspan="2">위치/안내</td>
                                <td colspan="2">1. 수유실에서 세면대까지 이동 거리는 10m내에 있는가?</td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint01"
                                            value="1"
                                        />
                                        <span>  그렇다</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint01"
                                            value="0"
                                        />
                                        <span>  아니다</span>
                                    </label>
                                    <label>
                                        <span>실측값:&ensp;</span>
                                        <input
                                            type="number"
                                            class="inputBox"
                                            style="width: 30%;"
                                            v-model="item.carePoint01Value"
                                        />
                                        <span>&ensp;m</span>
                                    </label>
                                </td>
                            </tr>
                            <!-- TODO.내용추가-->
                            <tr>
                                <td colspan="2">
                                    2. 수유시설 출입구에 시설 용도(모유수유/착유실, 가족수유실)에 따른 이용자 범위 안내 표시판이 부착되어 있는가?
                                    <br/>
                                    <div style="color:darkgray">*&nbsp;이용범위: 가족수유실은 남성(아빠 등)도 이용</div>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint10"
                                            value="1"
                                        />
                                        <span>  그렇다</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint10"
                                            value="0"
                                        />
                                        <span>  아니다</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>규모</td>
                                <td colspan="2">
                                    3. 시설 용도에 따른 기본 공간을 제공하고 있는가?
                                    <br/>
                                    <div style="color:darkgray">*&nbsp;(참고) 모유수유/착유실 10m², 가족수유실 15m²</div>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint02"
                                            value="1"
                                        />
                                        <span>  그렇다</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint02"
                                            value="0"
                                        />
                                        <span>  아니다</span>
                                    </label>
                                    <label>
                                        <span>실측값:&ensp;</span>
                                        <input
                                            type="number"
                                            class="inputBox"
                                            style="width: 30%;"
                                            v-model="item.carePoint02Value"
                                        />
                                        <span>&ensp;m²</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="2">공간구성</td>
                                <td colspan="2">4. 이용자 동선 등 편의를 고려하여 물품이 배치 되어 있는가?</td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint03"
                                            value="1"
                                        />
                                        <span>  그렇다</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint03"
                                            value="0"
                                        />
                                        <span>  아니다</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">5. 모유수유/착유 공간이 사생활 보호를 위해 잠금장치 또는 공간을 분리(커튼, 가림막)할 수 있도록 되어 있는가?</td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint04"
                                            value="1"
                                        />
                                        <span>  그렇다</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint04"
                                            value="0"
                                        />
                                        <span>  아니다</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th rowspan="5">환경</th>
                                <td>조명</td>
                                <td colspan="2">1. 수유시설에 적합한 조도인 60~150Lux를 준수하고 있는가?</td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint05"
                                            value="1"
                                        />
                                        <span> 그렇다</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint05"
                                            value="0"
                                        />
                                        <span> 아니다</span>
                                    </label>
                                    <label>
                                        <span>실측값:&ensp;</span>
                                        <input
                                            type="number"
                                            class="inputBox"
                                            style="width: 30%;"
                                            v-model="item.carePoint05Value"
                                        />
                                        <span>&ensp;Lux</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="3">실내온도/공기</td>
                                <td colspan="2">
                                    2. 계절별 실내 적정 온도를 유지하고 있는가?
                                    <br/>
                                    <div style="color:darkgray">*&nbsp;(참고) 봄‧가을 19~23℃, 여름 24~28℃, 겨울 18~20℃</div>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint06"
                                            value="1"
                                        />
                                        <span> 그렇다</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint06"
                                            value="0"
                                        />
                                        <span> 아니다</span>
                                    </label>
                                    <label>
                                        <span>실측값:&ensp;</span>
                                        <input
                                            type="number"
                                            class="inputBox"
                                            style="width: 30%;"
                                            v-model="item.carePoint06Value"
                                        />
                                        <span>&ensp;℃</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    3. 계절별 적정 습도를 유지하고 있는가?
                                    <br/>
                                    <div style="color:darkgray">*&nbsp;(참고) 봄‧가을 50%, 여름 60%, 겨울 40%</div>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint07"
                                            value="1"
                                        />
                                        <span> 그렇다</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint07"
                                            value="0"
                                        />
                                        <span> 아니다</span>
                                    </label>
                                    <label>
                                        <span>실측값:&ensp;</span>
                                        <input
                                            type="number"
                                            class="inputBox"
                                            style="width: 30%;"
                                            v-model="item.carePoint07Value"
                                        />
                                        <span>&ensp;%</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    4. 환기를 위한 창이나 기계식 환기설비를 갖추고 있는가?
                                    <br/>
                                    <div style="color:darkgray">*&nbsp;창문, 공기청정기, 환풍기, 기타</div>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint08"
                                            value="1"
                                        />
                                        <span> 그렇다</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint08"
                                            value="0"
                                        />
                                        <span> 아니다</span>
                                    </label>
                                    <label>
                                        <span>품목명:&ensp;</span>
                                        <input
                                            type="text"
                                            class="inputBox"
                                            style="width: 30%;"
                                            v-model="item.carePoint08Value"
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>소음</td>
                                <td colspan="2">5. 50dB의 실내 소음허용 기준을 준수하고 있는가?</td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint09"
                                            value="1"
                                        />
                                        <span> 그렇다</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.carePoint09"
                                            value="0"
                                        />
                                        <span> 아니다</span>
                                    </label>
                                    <label>
                                        <span>실측값:&ensp;</span>
                                        <input
                                            type="number"
                                            class="inputBox"
                                            style="width: 30%;"
                                            v-model="item.carePoint09Value"
                                        />
                                        <span>&ensp;dB</span>
                                    </label>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- 수유시설 청결 점검표 -->
                    <div class="infoWrap marginTop-30">
                        <label>
                            <span>수유시설 청결 점검표</span>
                            <!-- <button class="openBtn" id="openBtn3" @click="openDetail($event)">
                                +
                            </button> -->
                        </label>
                        <!-- <table class="infoTable marginBottom-35 marginTop-15" v-show="openCleanPoint"> -->
                        <table class="infoTable marginBottom-35 marginTop-15">
                            <tbody>
                            <tr>
                                <th rowspan="12">필수|권장<br/>&nbsp;&nbsp;&nbsp;&nbsp;물품</th>
                                <th>소파(의자)</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint01"
                                            @click="changeGoodsExist('01', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint01"
                                            @click="changeGoodsExist('01', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint01"
                                            @click="changeGoodsExist('01', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint01"
                                            @click="changeGoodsExist('01', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint01"
                                            @click="changeGoodsExist('01', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint01"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint01"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>탁자</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint02"
                                            @click="changeGoodsExist('02', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint02"
                                            @click="changeGoodsExist('02', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint02"
                                            @click="changeGoodsExist('02', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint02"
                                            @click="changeGoodsExist('02', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint02"
                                            @click="changeGoodsExist('02', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint02"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint02"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>기저귀<br/>교환대</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint08"
                                            @click="changeGoodsExist('08', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint08"
                                            @click="changeGoodsExist('08', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint08"
                                            @click="changeGoodsExist('08', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint08"
                                            @click="changeGoodsExist('08', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint08"
                                            @click="changeGoodsExist('08', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint08"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint08"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>가림막<br/>(파티션 등)</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint16"
                                            @click="changeGoodsExist('16', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint16"
                                            @click="changeGoodsExist('16', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint16"
                                            @click="changeGoodsExist('16', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint16"
                                            @click="changeGoodsExist('16', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint16"
                                            @click="changeGoodsExist('16', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint16"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint16"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>기저귀<br/>폐기물통</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint29"
                                            @click="changeGoodsExist('29', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint29"
                                            @click="changeGoodsExist('29', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint29"
                                            @click="changeGoodsExist('29', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint29"
                                            @click="changeGoodsExist('29', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint29"
                                            @click="changeGoodsExist('29', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint29"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint29"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>전자레인지</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint15"
                                            @click="changeGoodsExist('15', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint15"
                                            @click="changeGoodsExist('15', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint15"
                                            @click="changeGoodsExist('15', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint15"
                                            @click="changeGoodsExist('15', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint15"
                                            @click="changeGoodsExist('15', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint15"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint15"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>세면대</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint04"
                                            @click="changeGoodsExist('04', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint04"
                                            @click="changeGoodsExist('04', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint04"
                                            @click="changeGoodsExist('04', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint04"
                                            @click="changeGoodsExist('04', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint04"
                                            @click="changeGoodsExist('04', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint04"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint04"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>유축기</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint10"
                                            @click="changeGoodsExist('10', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint10"
                                            @click="changeGoodsExist('10', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint10"
                                            @click="changeGoodsExist('10', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint10"
                                            @click="changeGoodsExist('10', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint10"
                                            @click="changeGoodsExist('10', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint10"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint10"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>소독기</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint14"
                                            @click="changeGoodsExist('14', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint14"
                                            @click="changeGoodsExist('14', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint14"
                                            @click="changeGoodsExist('14', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint14"
                                            @click="changeGoodsExist('14', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint14"
                                            @click="changeGoodsExist('14', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint14"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint14"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>냉장고</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint09"
                                            @click="changeGoodsExist('09', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint09"
                                            @click="changeGoodsExist('09', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint09"
                                            @click="changeGoodsExist('09', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint09"
                                            @click="changeGoodsExist('09', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint09"
                                            @click="changeGoodsExist('09', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint09"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint09"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>아기의자</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint30"
                                            @click="changeGoodsExist('30', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint30"
                                            @click="changeGoodsExist('30', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint30"
                                            @click="changeGoodsExist('30', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint30"
                                            @click="changeGoodsExist('30', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint30"
                                            @click="changeGoodsExist('30', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint30"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint30"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>환기설비<br/>(공기청정기 등)</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint19"
                                            @click="changeGoodsExist('19', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint19"
                                            @click="changeGoodsExist('19', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint19"
                                            @click="changeGoodsExist('19', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint19"
                                            @click="changeGoodsExist('19', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint19"
                                            @click="changeGoodsExist('19', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint19"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint19"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th rowspan="2">청결상태</th>
                                <th>수유실 내<br/>바닥 오염</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint05"
                                            @click="changeGoodsExist('05', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint05"
                                            @click="changeGoodsExist('05', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint05"
                                            @click="changeGoodsExist('05', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint05"
                                            @click="changeGoodsExist('05', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint05"
                                            @click="changeGoodsExist('05', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint05"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint05"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>수유실 내<br/>불쾌한 냄새</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint06"
                                            @click="changeGoodsExist('06', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint06"
                                            @click="changeGoodsExist('06', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint06"
                                            @click="changeGoodsExist('06', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint06"
                                            @click="changeGoodsExist('06', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint06"
                                            @click="changeGoodsExist('06', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint06"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint06"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th rowspan="10">기타<br/>물품</th>
                                <th>정수기</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint23"
                                            @click="changeGoodsExist('23', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint23"
                                            @click="changeGoodsExist('23', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint23"
                                            @click="changeGoodsExist('23', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint23"
                                            @click="changeGoodsExist('23', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint23"
                                            @click="changeGoodsExist('23', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint23"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint23"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>가습기</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint24"
                                            @click="changeGoodsExist('24', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint24"
                                            @click="changeGoodsExist('24', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint24"
                                            @click="changeGoodsExist('24', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint24"
                                            @click="changeGoodsExist('24', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint24"
                                            @click="changeGoodsExist('24', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint24"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint24"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>제습기</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint25"
                                            @click="changeGoodsExist('25', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint25"
                                            @click="changeGoodsExist('25', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint25"
                                            @click="changeGoodsExist('25', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint25"
                                            @click="changeGoodsExist('25', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint25"
                                            @click="changeGoodsExist('25', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint25"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint25"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>아기침대</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint13"
                                            @click="changeGoodsExist('13', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint13"
                                            @click="changeGoodsExist('13', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint13"
                                            @click="changeGoodsExist('13', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint13"
                                            @click="changeGoodsExist('13', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint13"
                                            @click="changeGoodsExist('13', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint13"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint13"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>풋스툴</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint11"
                                            @click="changeGoodsExist('11', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint11"
                                            @click="changeGoodsExist('11', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint11"
                                            @click="changeGoodsExist('11', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint11"
                                            @click="changeGoodsExist('11', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint11"
                                            @click="changeGoodsExist('11', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint11"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint11"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>수유쿠션</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint07"
                                            @click="changeGoodsExist('07', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint07"
                                            @click="changeGoodsExist('07', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint07"
                                            @click="changeGoodsExist('07', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint07"
                                            @click="changeGoodsExist('07', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint07"
                                            @click="changeGoodsExist('07', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint07"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint07"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>보관함</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint12"
                                            @click="changeGoodsExist('12', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint12"
                                            @click="changeGoodsExist('12', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint12"
                                            @click="changeGoodsExist('12', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint12"
                                            @click="changeGoodsExist('12', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint12"
                                            @click="changeGoodsExist('12', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint12"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint12"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>휴지통</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint26"
                                            @click="changeGoodsExist('26', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint26"
                                            @click="changeGoodsExist('26', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint26"
                                            @click="changeGoodsExist('26', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint26"
                                            @click="changeGoodsExist('26', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint26"
                                            @click="changeGoodsExist('26', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint26"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint26"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>냉/난방기</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint27"
                                            @click="changeGoodsExist('27', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint27"
                                            @click="changeGoodsExist('27', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint27"
                                            @click="changeGoodsExist('27', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint27"
                                            @click="changeGoodsExist('27', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint27"
                                            @click="changeGoodsExist('27', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint27"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint27"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>거울</th>
                                <td colspan="3">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint03"
                                            @click="changeGoodsExist('03', index);"
                                            value="5"
                                        />
                                        <span> 5점(매우 양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint03"
                                            @click="changeGoodsExist('03', index);"
                                            value="4"
                                        />
                                        <span> 4점(양호)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint03"
                                            @click="changeGoodsExist('03', index);"
                                            value="3"
                                        />
                                        <span> 3점(보통)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint03"
                                            @click="changeGoodsExist('03', index);"
                                            value="2"
                                        />
                                        <span> 2점(미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint03"
                                            @click="changeGoodsExist('03', index);"
                                            value="1"
                                        />
                                        <span> 1점(매우 미흡)</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.cleanPoint03"
                                            @click="changeGoodsExist('03', index);"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="text"
                                            class="inputBox width-30"
                                            v-model="item.opnionPoint03"
                                            placeholder="주요 의견을 입력하세요."
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">시설 담당자 인터뷰 등 전달내용</th>
                                <td>
                                        <textarea
                                            class="inputBox width-30"
                                            style="width: 100%; height: 100px;"
                                            v-model="item.managerReviewContents"
                                            placeholder="내용을 입력하세요."
                                        >
                                        </textarea>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- 물품비치(보유) 현황 -->
                    <div class="infoWrap marginTop-30">
                        <label>
                            <span>물품비치(보유) 현황</span>
                            <!-- <button class="openBtn" id="openBtn4" @click="openDetail($event)">
                                +
                            </button> -->
                        </label>
                        <!-- <table class="infoTable marginBottom-35 marginTop-15" v-show="openGoodsPoint"> -->
                        <table class="infoTable marginBottom-35 marginTop-15">
                            <tbody>
                            <tr>
                                <th colspan="3" style="text-align: center;">필수 및 권장 물품</th>
                                <th colspan="3" style="text-align: center;">기타 물품</th>
                            </tr>
                            <!-- <tr>
                                    <th colspan="2" style="text-align: center;">비치 물품</th>
                                    <th style="text-align: center;">확인 내용</th>
                                    <th colspan="2" style="text-align: center;">비치 물품</th>
                                    <th style="text-align: center;">확인 내용</th>
                                </tr> -->
                            <tr>
                                <th colspan="2">쇼파(의자)</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint03"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint03"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">물티슈</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint23"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint23"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">탁자</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint05"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint05"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">정수기</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint12"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint12"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">손 소독제</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint18"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint18"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">가습기</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint24"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint24"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">기저귀 교환대</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint07"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint07"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">제습기</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint25"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint25"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">가림막(파티션 등)</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint01"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint01"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">아기침대</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint09"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint09"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">기저귀 폐기물통</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint28"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint28"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">풋스툴</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint14"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint14"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">전자레인지</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint10"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint10"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">수유쿠션</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint16"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint16"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">세면대</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint11"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint11"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">보관함</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint15"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint15"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">온도계</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint17"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint17"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">휴지통</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint26"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint26"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">습도계</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint19"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint19"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">냉/난방기</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint04"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint04"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">유축기</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint21"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint21"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">거울</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint13"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint13"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">소독기</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint08"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint08"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">사용중/비어있음 표식</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint30"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint30"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">냉장고</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint06"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint06"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">움직임감지기</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint31"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint31"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">아기의자</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint29"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint29"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">관리점검표</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint22"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint22"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">환기설비<br/>(공기청정기 등)</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint02"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint02"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                                <th colspan="2">의견수렴카드</th>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint27"
                                            value="1"
                                        />
                                        <span> 있음</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="item.goodsPoint27"
                                            value="0"
                                        />
                                        <span> 없음</span>
                                    </label>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="contentButtonBar">
                <div class="buttonWrap">
                    <button class="radiusSmallBtn bgNavy" @click="addNursingRoom"
                            v-if="nursingroomInfo.nursingroomList.length < 3">+ 추가
                    </button>
                    <button class="radiusSmallBtn bgDark" @click="deleteNursingroom"
                            v-if="nursingroomInfo.nursingroomList.length > 1">- 제거
                    </button>
                </div>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap">
                    <button
                        class="radiusSmallBtn bgNavy"
                        @click="insertNursingroom()"
                    >
                        저장
                    </button>
                    <button class="radiusSmallBtn bgDark" @click="cancel()">
                        취소
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import DatePicker from "tui-date-picker";
import "tui-date-picker/dist/tui-date-picker.min.css";
import CommonFile from "@/components/common/CommonFile";
import EventBus from "../../script/EventBus";
import {VueDaumPostcode} from "vue-daum-postcode"

export default {
    name: "NursingroomCreate",
    data() {
        return {
            siteNo: "",
            nursingroomInfo: {
                roomName: "",
                managerName: "",
                managerTelNo: "",
                address: "",
                installDate: "",
                zoneName: "",
                cityName: "",
                townName: "",
                location: "",
                gpsLat: "",
                gpsLong: "",
                useTypeCode: "0",
                fatherUseYn: "0",
                fileList: [],
                locationName: "",
                organizationCode: "",
                roomTypeCode: "",
                peakCount: "",
                area: "",
                areaUnit: "",
                surveyOrganizationName: "",
                surveyName: "",
                surveyDate: "",
                useAvgAday: "",
                useAvgAdayRange: "",
                carePeriodCode: "",
                manageConditionCode: "",
                carePoint01: "",
                carePoint02: "",
                carePoint03: "",
                carePoint04: "",
                carePoint05: "",
                carePoint06: "",
                carePoint07: "",
                carePoint08: "",
                carePoint09: "",
                carePoint10: "",
                carePoint01Value: "",
                carePoint02Value: "",
                carePoint05Value: "",
                carePoint06Value: "",
                carePoint07Value: "",
                carePoint08Value: "",
                carePoint09Value: "",
                cleanPoint01: "",
                cleanPoint02: "",
                cleanPoint03: "",
                cleanPoint04: "",
                cleanPoint05: "",
                cleanPoint06: "",
                cleanPoint07: "",
                cleanPoint08: "",
                cleanPoint09: "",
                cleanPoint10: "",
                cleanPoint11: "",
                cleanPoint12: "",
                cleanPoint13: "",
                cleanPoint14: "",
                cleanPoint15: "",
                cleanPoint16: "",
                cleanPoint17: "",
                cleanPoint18: "",
                cleanPoint19: "",
                cleanPoint20: "",
                cleanPoint21: "",
                cleanPoint22: "",
                cleanPoint23: "",
                cleanPoint24: "",
                cleanPoint25: "",
                cleanPoint26: "",
                cleanPoint27: "",
                cleanPoint28: "",
                cleanPoint29: "",
                cleanPoint30: "",
                opnionPoint01: "",
                opnionPoint02: "",
                opnionPoint03: "",
                opnionPoint04: "",
                opnionPoint05: "",
                opnionPoint06: "",
                opnionPoint07: "",
                opnionPoint08: "",
                opnionPoint09: "",
                opnionPoint10: "",
                opnionPoint11: "",
                opnionPoint12: "",
                opnionPoint13: "",
                opnionPoint14: "",
                opnionPoint15: "",
                opnionPoint16: "",
                opnionPoint17: "",
                opnionPoint18: "",
                opnionPoint19: "",
                opnionPoint20: "",
                opnionPoint21: "",
                opnionPoint22: "",
                opnionPoint23: "",
                opnionPoint24: "",
                opnionPoint25: "",
                opnionPoint26: "",
                opnionPoint27: "",
                opnionPoint28: "",
                opnionPoint29: "",
                opnionPoint30: "",
                managerReviewContents: "",
                goodsPoint01: "",
                goodsPoint02: "",
                goodsPoint03: "",
                goodsPoint04: "",
                goodsPoint05: "",
                goodsPoint06: "",
                goodsPoint07: "",
                goodsPoint08: "",
                goodsPoint09: "",
                goodsPoint10: "",
                goodsPoint11: "",
                goodsPoint12: "",
                goodsPoint13: "",
                goodsPoint14: "",
                goodsPoint15: "",
                goodsPoint16: "",
                goodsPoint17: "",
                goodsPoint18: "",
                goodsPoint19: "",
                goodsPoint21: "",
                goodsPoint22: "",
                goodsPoint23: "",
                goodsPoint24: "",
                goodsPoint25: "",
                goodsPoint26: "",
                goodsPoint27: "",
                goodsPoint28: "",
                goodsPoint29: "",
                goodsPoint30: "",
                goodsPoint31: "",
                manageConditionDetail: "",
                nursingroomList: [
                    {
                        roomName: "",
                        managerName: "",
                        managerTelNo: "",
                        address: "",
                        installDate: "",
                        zoneName: "",
                        cityName: "",
                        townName: "",
                        location: "",
                        gpsLat: "",
                        gpsLong: "",
                        useTypeCode: "0",
                        fatherUseYn: "0",
                        fileList: [],
                        locationName: "",
                        organizationCode: "",
                        roomTypeCode: "",
                        peakCount: "",
                        area: "",
                        areaUnit: "",
                        surveyOrganizationName: "",
                        surveyName: "",
                        surveyDate: "",
                        useAvgAday: "",
                        useAvgAdayRange: "",
                        carePeriodCode: "",
                        carePeriodDetail: "",
                        manageConditionCode: "",
                        carePoint01: "",
                        carePoint02: "",
                        carePoint03: "",
                        carePoint04: "",
                        carePoint05: "",
                        carePoint06: "",
                        carePoint07: "",
                        carePoint08: "",
                        carePoint09: "",
                        carePoint10: "",
                        carePoint01Value: "",
                        carePoint02Value: "",
                        carePoint05Value: "",
                        carePoint06Value: "",
                        carePoint07Value: "",
                        carePoint08Value: "",
                        carePoint09Value: "",
                        cleanPoint01: "",
                        cleanPoint02: "",
                        cleanPoint03: "",
                        cleanPoint04: "",
                        cleanPoint05: "",
                        cleanPoint06: "",
                        cleanPoint07: "",
                        cleanPoint08: "",
                        cleanPoint09: "",
                        cleanPoint10: "",
                        cleanPoint11: "",
                        cleanPoint12: "",
                        cleanPoint13: "",
                        cleanPoint14: "",
                        cleanPoint15: "",
                        cleanPoint16: "",
                        cleanPoint17: "",
                        cleanPoint18: "",
                        cleanPoint19: "",
                        cleanPoint20: "",
                        cleanPoint21: "",
                        cleanPoint22: "",
                        cleanPoint23: "",
                        cleanPoint24: "",
                        cleanPoint25: "",
                        cleanPoint26: "",
                        cleanPoint27: "",
                        cleanPoint28: "",
                        cleanPoint29: "",
                        cleanPoint30: "",
                        opnionPoint01: "",
                        opnionPoint02: "",
                        opnionPoint03: "",
                        opnionPoint04: "",
                        opnionPoint05: "",
                        opnionPoint06: "",
                        opnionPoint07: "",
                        opnionPoint08: "",
                        opnionPoint09: "",
                        opnionPoint10: "",
                        opnionPoint11: "",
                        opnionPoint12: "",
                        opnionPoint13: "",
                        opnionPoint14: "",
                        opnionPoint15: "",
                        opnionPoint16: "",
                        opnionPoint17: "",
                        opnionPoint18: "",
                        opnionPoint19: "",
                        opnionPoint20: "",
                        opnionPoint21: "",
                        opnionPoint22: "",
                        opnionPoint23: "",
                        opnionPoint24: "",
                        opnionPoint25: "",
                        opnionPoint26: "",
                        opnionPoint27: "",
                        opnionPoint28: "",
                        opnionPoint29: "",
                        opnionPoint30: "",
                        managerReviewContents: "",
                        goodsPoint01: "",
                        goodsPoint02: "",
                        goodsPoint03: "",
                        goodsPoint04: "",
                        goodsPoint05: "",
                        goodsPoint06: "",
                        goodsPoint07: "",
                        goodsPoint08: "",
                        goodsPoint09: "",
                        goodsPoint10: "",
                        goodsPoint11: "",
                        goodsPoint12: "",
                        goodsPoint13: "",
                        goodsPoint14: "",
                        goodsPoint15: "",
                        goodsPoint16: "",
                        goodsPoint17: "",
                        goodsPoint18: "",
                        goodsPoint19: "",
                        goodsPoint21: "",
                        goodsPoint22: "",
                        goodsPoint23: "",
                        goodsPoint24: "",
                        goodsPoint25: "",
                        goodsPoint26: "",
                        goodsPoint27: "",
                        goodsPoint28: "",
                        goodsPoint29: "",
                        goodsPoint30: "",
                        goodsPoint31: "",
                        manageConditionDetail: ""
                    }
                ],
            },
            roomNameDuplicate: true,
            openPostcode: false,
            // openAddRoomInfo: false,
            // openCarePoint: false,
            // openCleanPoint: false,
            // openGoodsPoint: false,
            carePeriodDetail: "",
            manageConditionDetail2: "",
            manageConditionDetail3: "",
            manageConditionDetail4: "",
            manageConditionDetail5: "",
        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        EventBus.$on("addFile", (fileList) => {
            this.logger.debug(this, "eventBus / addFile()");
            this.addFile(fileList);
        });
    },
    components: {
        CommonFile,
        VueDaumPostcode,
    },
    mounted() {

        // DOM 생성완료 후, DatePicker 호출
        this.$nextTick(() => {
            this.makeDatePicker(0);
        });

    },
    methods: {
        deleteNursingroom() {
            if (confirm("최근에 작성하신 수유시설 정보를 제거 하시겠습니까?")) {
                // 마지막 항목 제거
                if (this.nursingroomInfo.nursingroomList.length > 1) {
                    this.nursingroomInfo.nursingroomList.pop();
                }
            }
        },
        addNursingRoom() {
            if (this.nursingroomInfo.nursingroomList.length >= 3) {
                alert("최대 3개까지의 수유시설만 등록 가능합니다.")
                return;
            }

            this.nursingroomInfo.nursingroomList.push({
                roomName: "",
                managerName: "",
                managerTelNo: "",
                address: "",
                installDate: "",
                zoneName: "",
                cityName: "",
                townName: "",
                location: "",
                gpsLat: "",
                gpsLong: "",
                useTypeCode: "0",
                fatherUseYn: "0",
                fileList: [],
                locationName: "",
                organizationCode: "",
                roomTypeCode: "",
                peakCount: "",
                area: "",
                areaUnit: "",
                surveyOrganizationName: "",
                surveyName: "",
                surveyDate: "",
                useAvgAday: "",
                useAvgAdayRange: "",
                carePeriodCode: "",
                manageConditionCode: "",
                carePoint01: "",
                carePoint02: "",
                carePoint03: "",
                carePoint04: "",
                carePoint05: "",
                carePoint06: "",
                carePoint07: "",
                carePoint08: "",
                carePoint09: "",
                carePoint10: "",
                carePoint01Value: "",
                carePoint02Value: "",
                carePoint05Value: "",
                carePoint06Value: "",
                carePoint07Value: "",
                carePoint08Value: "",
                carePoint09Value: "",
                cleanPoint01: "",
                cleanPoint02: "",
                cleanPoint03: "",
                cleanPoint04: "",
                cleanPoint05: "",
                cleanPoint06: "",
                cleanPoint07: "",
                cleanPoint08: "",
                cleanPoint09: "",
                cleanPoint10: "",
                cleanPoint11: "",
                cleanPoint12: "",
                cleanPoint13: "",
                cleanPoint14: "",
                cleanPoint15: "",
                cleanPoint16: "",
                cleanPoint17: "",
                cleanPoint18: "",
                cleanPoint19: "",
                cleanPoint20: "",
                cleanPoint21: "",
                cleanPoint22: "",
                cleanPoint23: "",
                cleanPoint24: "",
                cleanPoint25: "",
                cleanPoint26: "",
                cleanPoint27: "",
                cleanPoint28: "",
                cleanPoint29: "",
                cleanPoint30: "",
                opnionPoint01: "",
                opnionPoint02: "",
                opnionPoint03: "",
                opnionPoint04: "",
                opnionPoint05: "",
                opnionPoint06: "",
                opnionPoint07: "",
                opnionPoint08: "",
                opnionPoint09: "",
                opnionPoint10: "",
                opnionPoint11: "",
                opnionPoint12: "",
                opnionPoint13: "",
                opnionPoint14: "",
                opnionPoint15: "",
                opnionPoint16: "",
                opnionPoint17: "",
                opnionPoint18: "",
                opnionPoint19: "",
                opnionPoint20: "",
                opnionPoint21: "",
                opnionPoint22: "",
                opnionPoint23: "",
                opnionPoint24: "",
                opnionPoint25: "",
                opnionPoint26: "",
                opnionPoint27: "",
                opnionPoint28: "",
                opnionPoint29: "",
                opnionPoint30: "",
                managerReviewContents: "",
                goodsPoint01: "",
                goodsPoint02: "",
                goodsPoint03: "",
                goodsPoint04: "",
                goodsPoint05: "",
                goodsPoint06: "",
                goodsPoint07: "",
                goodsPoint08: "",
                goodsPoint09: "",
                goodsPoint10: "",
                goodsPoint11: "",
                goodsPoint12: "",
                goodsPoint13: "",
                goodsPoint14: "",
                goodsPoint15: "",
                goodsPoint16: "",
                goodsPoint17: "",
                goodsPoint18: "",
                goodsPoint19: "",
                goodsPoint21: "",
                goodsPoint22: "",
                goodsPoint23: "",
                goodsPoint24: "",
                goodsPoint25: "",
                goodsPoint26: "",
                goodsPoint27: "",
                goodsPoint28: "",
                goodsPoint29: "",
                goodsPoint30: "",
                goodsPoint31: "",
                manageConditionDetail: ""
            });

            // DOM 업데이트 후 DatePicker 초기화
            this.$nextTick(() => {
                this.makeDatePicker(this.nursingroomInfo.nursingroomList.length - 1);
            });
        },

        makeDatePicker(index) {
            var today = new Date();
            const datePicker = new DatePicker("#surveyDatePicker" + index, {
                date: today,
                type: "date",
                input: {
                    element: "#surveyDate" + index
                },
                language: "ko",
            });
            datePicker.on("change", () => {
                this.nursingroomInfo.nursingroomList[index].surveyDate =
                    document.getElementById("surveyDate" + index).value;
            });

            const datePicker2 = new DatePicker("#installDatePicker" + index, {
                date: today,
                type: "date",
                input: {
                    element: "#installDate" + index
                },
                language: "ko",
            });
            datePicker2.on("change", () => {
                this.nursingroomInfo.nursingroomList[index].installDate =
                    document.getElementById("installDate" + index).value;
            });
        },

        checkRoomName() {
            const formData = {
                roomName: this.nursingroomInfo.roomName,
            };
            axios
                .post(
                    this.config.contextRoot + "/nursingroom/hasRoomName.do",
                    formData
                )
                .then((response) => {
                    if (response.data.hasRoomName) {
                        // 중복이면
                        alert("이미 사용된 기관명입니다.");
                        this.roomNameDuplicate = true;
                        return;
                    }

                    // 사용가능
                    alert("사용가능합니다.");
                    this.roomNameDuplicate = false;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        insertNursingroom() {
            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    } else if (this.roomNameDuplicate) {
                        alert(
                            "기관명 중복 확인해주세요."
                        );
                        return;
                    }

                    for (const nursingroom of this.nursingroomInfo.nursingroomList) {

                        if (nursingroom.area && !nursingroom.areaUnit) {
                            alert("수유실 면적 단위를 선택해주세요.");
                            return;
                        } else if (!nursingroom.area && nursingroom.areaUnit) {
                            alert("수유실 면적을 입력해주세요.");
                            return;
                        }
                    }

                    if (!confirm("등록 하시겠습니까?")) {
                        return;
                    }

                    for (const nursingroom of this.nursingroomInfo.nursingroomList) {
                        // 관리 주기 기타 선택 시 입력값을 담음
                        if (nursingroom.carePeriodCode === "8") {
                            nursingroom.carePeriodCode = nursingroom.carePeriodDetail
                        }

                        // 2024-06-03.운영현황 내의 일시중단, 폐쇄, 이전, 기타의 입력값 처리
                        if (nursingroom.manageConditionCode === "2") {
                            nursingroom.manageConditionDetail = nursingroom.manageConditionDetail2;
                        } else if (nursingroom.manageConditionCode === "3") {
                            nursingroom.manageConditionDetail = nursingroom.manageConditionDetail3;
                        } else if (nursingroom.manageConditionCode === "4") {
                            nursingroom.manageConditionDetail = nursingroom.manageConditionDetail4;
                        } else if (nursingroom.manageConditionCode === "5") {
                            nursingroom.manageConditionDetail = nursingroom.manageConditionDetail5;
                        }
                    }

                    axios
                        .post(
                            this.config.contextRoot +
                            "/nursingroom/insertNursingroom.do",
                            this.nursingroomInfo
                        )
                        .then((response) => {
                            if (response.status == 200) {
                                alert("등록 되었습니다.");
                                this.$router.replace(
                                    this.config.contextRoot +
                                    "/site/" +
                                    this.siteNo +
                                    "/nursingroom"
                                );
                            }
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });


                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.error.all());
                    return false;
                });

        },

        openUploader(refName) {
            this.logger.debug(this, "openUploader()");
            this.$refs[refName].openUploader();
        },
        doDownload(data) {
            this.logger.debug(this, "doDownload");
            const formData = new FormData();
            formData.append("fileNo", data.fileNo);
            formData.append("fileName", data.fileName);
            formData.append("serverFileSaveName", data.serverFileSaveName);
            axios
                .post(
                    this.config.contextRoot + "/common/file/download.do",
                    formData,
                    {responseType: "blob"}
                )
                .then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: response.headers["content-type"],
                        })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", data.fileName);
                    document.body.appendChild(link);
                    link.click();
                    location.reload();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        deleteFile(index) {
            this.nursingroomInfo.fileList.splice(index, 1);
        },
        addFile(fileList) {
            this.nursingroomInfo.fileList =
                this.nursingroomInfo.fileList.concat(fileList);
        },
        cancel() {
            if (confirm("취소 하시겠습니까?")) {
                this.$router.go(-1);
            }
        },
        openDaumPostcode() {
            this.openPostcode = true;
        },
        searchDaumPostcode(data) {
            this.logger.debug(this, "searchDaumPostcode()");
            this.openPostcode = false;
            this.nursingroomInfo.zoneName = data.sido;
            this.nursingroomInfo.cityName = data.sigungu;
            if (data.userSelectedType === "R") {
                this.nursingroomInfo.address = data.buildingName ? `${data.roadAddress} (${data.buildingName})` : data.roadAddress;
                this.nursingroomInfo.townName = data.roadname;
            } else {
                this.nursingroomInfo.address = data.jibunAddress;
                this.nursingroomInfo.townName = data.bname;
            }

            // 위도/경도 추출용 코드
            var address = data.roadAddress;
            var further = ''; // 조합형 주소 변수

            // 기본 주소가 도로명 타입일때 조합한다.
            if (data.bname !== '') { //법정동명이 있을 경우 추가한다.
                further += data.bname;
            }
            if (data.buildingName !== '') { // 건물명이 있을 경우 추가한다.
                further += (further !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 조합형주소의 유무에 따라 양쪽에 괄호를 추가하여 최종 주소를 만든다.
            address += (further !== '' ? ' (' + further + ')' : '');

            // 위도/경도 및 데이터 API 호출하는 함수
            this.getCoord(address);
        },
        getCoord(address) {
            this.logger.debug(this, "getCoord()");
            axios
                .post(
                    "https://dapi.kakao.com/v2/local/search/address.json",
                    "query=" + encodeURI(address),
                    {headers: {Authorization: "KakaoAK 9e06f4e66ea1a3526490e633feb34deb"}}
                )
                .then((response) => {
                    this.nursingroomInfo.gpsLat = response.data.documents[0].address.y;
                    this.nursingroomInfo.gpsLong = response.data.documents[0].address.x;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        // openDetail(event) {
        //     let id = event.target.id;
        //     switch(id) {
        //         case 'openBtn1':
        //             if(this.openAddRoomInfo) {
        //                 this.openAddRoomInfo = false;
        //                 event.target.innerText = "+";
        //             } else {
        //                 this.openAddRoomInfo = true;
        //                 event.target.innerText = "-";
        //             }
        //             break;
        //         case 'openBtn2':
        //             if(this.openCarePoint) {
        //                 this.openCarePoint = false;
        //                 event.target.innerText = "+";
        //             } else {
        //                 this.openCarePoint = true;
        //                 event.target.innerText = "-";
        //             }
        //             break;
        //         case 'openBtn3':
        //             if(this.openCleanPoint) {
        //                 this.openCleanPoint = false;
        //                 event.target.innerText = "+";
        //             } else {
        //                 this.openCleanPoint = true;
        //                 event.target.innerText = "-";
        //             }
        //             break;
        //         case 'openBtn4':
        //             if(this.openGoodsPoint) {
        //                 this.openGoodsPoint = false;
        //                 event.target.innerText = "+";
        //             } else {
        //                 this.openGoodsPoint = true;
        //                 event.target.innerText = "-";
        //             }
        //             break;
        //     }
        // },
        textInputFocus(refName) {
            this.$nextTick(() => {
                const refElement = this.$refs[refName];
                if (Array.isArray(refElement) && refElement[0]) {
                    refElement[0].focus();
                } else if (refElement) {
                    refElement.focus();
                } else {
                    console.error(`No ref found with name: ${refName}`);
                }
            });
        },
        // 청결 점수를 주면 해당 물품(비치)을 "있음" 처리하는 함수
        changeGoodsExist(cleanNo, index) {
            // 청결(기준) vs 비치물품 매핑
            // (청결의 5, 6번은 바닥/냄새로 물품이 아니므로 없음)
            let cleanGoodsMapping =
                {
                    // "청결 번호" : "비치물품 번호"
                    "01": "03",  // 소파(의자)
                    "02": "05",  // 탁자
                    "03": "13", // 거울
                    "04": "11", // 세면대
                    "07": "16", // 수유쿠션
                    "08": "07",  // 기저귀 교환대
                    "09": "06",  // 냉장고
                    "10": "21",// 유축기
                    "11": "14",// 풋스툴
                    "12": "15",// 보관함
                    "13": "09", // 아기침대
                    "14": "08", // 소독기
                    "15": "10",// 전자레인지
                    "16": "01", // 가림막(파티션 등)
                    "17": "17",// 온도계
                    "18": "19",// 습도계
                    "19": "02", // 공기청정기
                    "20": "18",// 손 소독제
                    "21": "23",// 물티슈
                    "22": "22",// 관리점검표
                    "23": "12",// 정수기
                    "24": "24",// 가습기
                    "25": "25",// 제습기
                    "26": "26",// 휴지통
                    "27": "04", // 냉/난방기
                    "28": "27", // 의견수렴카드
                    "29": "28", // 기저귀 폐기물통
                    "30": "29"  // 아기의자
                }
            this.nursingroomInfo.nursingroomList[index]["goodsPoint" + cleanGoodsMapping[cleanNo]] = "1";
        }
    },
};
</script>

<style scoped>
.tui-datepicker {
    z-index: 9;
}

.tui-calendar td {
    border-bottom: none;
}

label {
    margin-right: 50px;
}

*:read-only {
    outline: none;
}

.openBtn {
    width: 20px;
    margin-left: 10px;
    font-weight: bold;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.width-30 {
    width: 30%;
}

.infoWrap > label:first-child > span {
    font-weight: bold;
}
</style>