<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">방문자 통계</h2>
        </div>
        <div class="contentBody">
            <!-- 검색 -->
            <div class="searchBar" style="z-index: 9;">
                <div class="columnDivision-3">
                    <div class="labelWrap">
                        <label>조회일자</label>
                        <div class="datePicker">
                            <input
                                type="text"
                                class="inputBox"
                                placeholder="연도-월-일"
                                id="startDate"
                                v-model="searchForm.startDate"
                                autocomplete="off"
                            />
                            <div
                                ref="startDatePicker"
                                id="startDatePicker"
                            ></div>
                            <i></i>
                        </div>
                    </div>
                    <div class="labelWrap">
                        <label class="textCenter" style="width: 30px">-</label>
                        <div class="datePicker">
                            <input
                                type="text"
                                class="inputBox"
                                placeholder="연도-월-일"
                                id="endDate"
                                v-model="searchForm.endDate"
                                autocomplete="off"
                            />
                            <i></i>
                            <div ref="endDatePicker" id="endDatePicker"></div>
                        </div>
                    </div>
                </div>
                <button class="searchBtn" @click="doSearch()">
                    조회하기
                </button>
            </div>
            <h3 class="contentTitle marginTop-35">방문현황</h3>
            <table class="infoTable tableFixed">
                <thead>
                    <tr>
                        <th class="textCenter">방문자수</th>
                        <th class="textCenter">방문횟수</th>
                        <th class="textCenter">신규방문자수</th>
                        <th class="textCenter">재방문자수</th>
                        <th class="textCenter">방문당PV</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="textCenter">
                            {{ visitorStatsInfo.visitorCount | makeComma }}
                        </td>
                        <td class="textCenter">
                            {{ visitorStatsInfo.visitCount | makeComma }}
                        </td>
                        <td class="textCenter">
                            {{ visitorStatsInfo.newVisitorCount | makeComma }}
                        </td>
                        <td class="textCenter">
                            {{ visitorStatsInfo.reVisitorCount | makeComma }}
                        </td>
                        <td class="textCenter">
                            {{ visitorStatsInfo.visitorPageView | makeComma }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="contentTitleWrap marginTop-50">
                <h3 class="contentTitle">방문현황 그래프</h3>
                <div class="titleBtnWrap">
                    <button
                        :class="[
                            'titleRadiusBtn',
                            chartTab[0] == 1 ? 'bgType2' : 'bgType1',
                        ]"
                        @click="setDayChart()"
                    >
                        일간
                    </button>
                    <button
                        :class="[
                            'titleRadiusBtn',
                            chartTab[1] == 1 ? 'bgType2' : 'bgType1',
                        ]"
                        @click="setYearmonthChart()"
                    >
                        월간
                    </button>
                    <button
                        :class="[
                            'titleRadiusBtn',
                            chartTab[2] == 1 ? 'bgType2' : 'bgType1',
                        ]"
                        @click="setYearChart()"
                    >
                        년간
                    </button>
                </div>
                <div style="height: 500px">
                    <VueApexCharts
                        v-if="isShowChart"
                        width="100%"
                        height="500"
                        type="bar"
                        :options="options"
                        :series="series"
                    ></VueApexCharts>
                </div>
            </div>
            <table class="infoTable tableFixed">
                <tbody></tbody>
            </table>
            <!-- <h3 class="contentTitle marginTop-50">인기 페이지</h3>
            <table class="infoTable tableFixed">
                <tbody></tbody>
            </table>
            <h3 class="contentTitle marginTop-50">방문자 정보</h3>
            <table class="infoTable tableFixed">
                <tbody></tbody>
            </table> -->
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import DatePicker from "tui-date-picker";
import "tui-date-picker/dist/tui-date-picker.min.css";

/*Chart*/
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

export default {
    name: "VisitorStats",
    components: {
        VueApexCharts,
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.setDefaultDate();
        this.selectVisitorStatsInfo();
    },
    data() {
        return {
            siteNo: "",
            visitorStatsInfo: {
                visitorCount: "",
                visitCount: "",
                newVisitorCount: "",
                reVisitorCount: "",
                visitorPageView: "",
                visitDayChart: [],
                visitYearmonthChart: [],
                visitYearChart: [],
            },
            searchForm: {
                startDate: "",
                endDate: "",
            },
            options: {
                chart: {
                    id: "vuechart-example",
                    animations: {
                        enabled: false,
                    },
                },
            },
            series: [
                {
                    name: "",
                    data: [],
                },
            ],
            isShowChart: false,
            chartTab: [1, 0, 0],
        };
    },
     mounted() {
        // 데이트피커
        var today = new Date();
        const rangePicker = new DatePicker.createRangePicker({
            startpicker: {
                date: today,
                input: "#startDate",
                container: "#startDatePicker",
            },
            endpicker: {
                date: today,
                input: "#endDate",
                container: "#endDatePicker",
            },
            language: "ko",
        });
        rangePicker.on("change:start", () => {
            this.searchForm.startDate =
                document.getElementById("startDate").value;
        });
        rangePicker.on("change:end", () => {
            this.searchForm.endDate = document.getElementById("endDate").value;
        });
    },
    methods: {
        selectVisitorStatsInfo() {
            
            axios
                .post(
                    this.config.contextRoot +
                        "/visitor/selectVisitorStatsInfo.do",
                    { siteNo: this.siteNo, startDate: this.searchForm.startDate, endDate: this.searchForm.endDate }
                )
                .then((response) => {
                    this.visitorStatsInfo = response.data.visitorStatsInfo;

                    //기본 세팅 일간차트
                    this.setDayChart();
                });
        },
        setDayChart() {
            new Promise((resolve) => {
                this.chartTab = [1, 0, 0];
                this.isShowChart = false;
                this.$set(
                    this.series[0],
                    "data",
                    this.visitorStatsInfo.visitDayChart
                );
                resolve();
            }).then(() => {
                this.isShowChart = true;

                
            });
        },
        setYearmonthChart() {
            new Promise((resolve) => {
                this.chartTab = [0, 1, 0];
                this.isShowChart = false;
                this.$set(
                    this.series[0],
                    "data",
                    this.visitorStatsInfo.visitYearmonthChart
                );
                resolve();
            }).then(() => {
                this.isShowChart = true;
            });
        },
        setYearChart() {
            new Promise((resolve) => {
                this.chartTab = [0, 0, 1];
                this.isShowChart = false;
                this.$set(
                    this.series[0],
                    "data",
                    this.visitorStatsInfo.visitYearChart
                );
                resolve();
            }).then(() => {
                this.isShowChart = true;
            });
        },
        doSearch() {
            this.selectVisitorStatsInfo();
        },
        setDefaultDate(){
            let today = new Date();
            let year = today.getFullYear(); // 년도
            let month = today.getMonth() + 1;  // 이번달
            let lastMonth = month - 1 >= 10 ? month - 1 : "0" + (month - 1); //저번달
            month = month >= 10 ? month : "0" + month;
            let date = today.getDate() >= 10 ? today.getDate() : "0" + today.getDate();  // 날짜

            this.searchForm.startDate = year + "-" + lastMonth + "-01";
            this.searchForm.endDate = year + "-" + month + "-" + date;
        }
    },
};
</script>

<style>
</style>