<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">회원관리</h2>
        </div>
        <div class="contentBody">
            <!-- 회원등록 -->
            <h3 class="contentTitle">회원 등록</h3>
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
                <table class="infoTable tableFixed">
                    <tbody>
                        <tr>
                            <th class="requiredMark">아이디</th>
                            <td>
                                <div class="inputBtnWrap">
                                    <input
                                        type="text"
                                        class="inputBox"
                                        v-model="memberInfo.loginId"
                                        v-validate="'required'"
                                        data-vv-name="loginId"
                                        data-vv-as="아이디"
                                        minlength="6"
                                    />
                                    <button
                                        class="radiusInputBtn"
                                        @click="checkLoginId()"
                                    >
                                        중복확인
                                    </button>
                                </div>
                                <p
                                    class="validation-text"
                                    v-if="!checkedLoginId"
                                >
                                    * 중복 체크를 해주세요.
                                </p>
                                <p
                                    class="validation-text"
                                    v-if="errors.has('loginId')"
                                >
                                    * {{ errors.first("loginId") }}
                                </p>
                            </td>
                            <th class="requiredMark">최초비밀번호</th>
                            <td>
                                <div class="inputBtnWrap">
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="memberInfo.loginPassword"
                                    v-validate="'required'"
                                    data-vv-name="loginPassword"
                                    data-vv-as="최초비밀번호"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('loginPassword')"
                                >
                                    * {{ errors.first("loginPassword") }}
                                </p></div>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">이름</th>
                            <td>
                                <div class="inputBtnWrap">
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="memberInfo.userName"
                                    v-validate="'required'"
                                    data-vv-name="userName"
                                    data-vv-as="이름"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('userName')"
                                >
                                    * {{ errors.first("userName") }}
                                </p></div>
                            </td>
                            <th class="requiredMark">별칭</th>
                            <td>
                                <div class="inputBtnWrap">
                                    <input
                                        type="text"
                                        class="inputBox"
                                        v-model="memberInfo.aliasName"
                                        v-validate="'required'"
                                        data-vv-name="aliasName"
                                        data-vv-as="별칭"
                                    />
                                    <button
                                        class="radiusInputBtn"
                                        @click="checkAliasName()"
                                    >
                                        중복확인
                                    </button>
                                </div>
                                <p
                                    class="validation-text"
                                    v-if="!checkedAliasName"
                                >
                                    * 중복 체크를 해주세요.
                                </p>
                                <p
                                    class="validation-text"
                                    v-if="errors.has('aliasName')"
                                >
                                    * {{ errors.first("aliasName") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">이메일</th>
                            <td>
                                <div class="inputBtnWrap">
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="memberInfo.emailAddress"
                                    v-validate="'required|email'"
                                    data-vv-name="emailAddress"
                                    data-vv-as="이메일"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('emailAddress')"
                                >
                                    * {{ errors.first("emailAddress") }}
                                </p></div>
                            </td>
                            <th class="requiredMark">핸드폰</th>
                            <td>
                                <div class="inputBtnWrap">
                                    <input
                                        type="text"
                                        class="inputBox"
                                        v-model="memberInfo.mobileNo"
                                        v-validate="'required'"
                                        data-vv-name="mobileNo"
                                        data-vv-as="핸드폰"
                                    />
                                    <p
                                        class="validation-text"
                                        v-if="errors.has('mobileNo')"
                                    >
                                        * {{ errors.first("mobileNo") }}
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>전화번호</th>
                            <td>
                                <div class="inputBtnWrap">
                                    <input
                                        type="text"
                                        class="inputBox"
                                        v-model="memberInfo.telNo"
                                    />
                                </div>
                            </td>
                            <th></th>
                            <td>
                                <div class="inputBtnWrap"></div>
                            </td>
                        </tr>
                        <tr>
                            <th>조사지역</th>
                            <td colspan="3">
                                <label v-for="item in locationList" :key="item"
                                    style="margin-left: 10px;"
                                >
                                    <input
                                        type="checkbox"
                                        v-model="checkUserLocationList"
                                        :value="item"
                                    />
                                    {{ item }}
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 회원권한 -->
            <div class="contentTitleWrap marginTop-35">
                <h3 class="contentTitle">회원권한</h3>
                <div class="titleBtnWrap">
                    <button
                        class="squareBtn bgLightGray"
                        @click="openPopup();"
                    >
                        권한관리
                    </button>
                </div>
            </div>
            <div class="listWrap">
                <!-- 목록 상단 -->
                <div class="listTop">
                    <!-- <span class="listTotal">(Total <em>1,100</em>)</span> -->
                    <select class="selectBox">
                        <option>5</option>
                        <option selected>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                </div>

                <!-- 목록테이블 -->
                <table class="listTable">
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>권한명</th>
                            <th>권한부여</th>
                            <th>설명</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="role in roleList" :key="role.roleNo">
                            <td>{{ role.rowNumber }}</td>
                            <td>{{ role.roleName }}</td>
                            <td>
                                <label>
                                    <input
                                        v-if="role.roleName == 'ROLE_ANONYMOUS'"
                                        type="checkbox"
                                        checked
                                        disabled
                                    />
                                    <input
                                        v-else
                                        type="checkbox"
                                        v-model="checkedRoleNoList"
                                        :value="role.roleNo"
                                    />
                                </label>
                            </td>
                            <td>{{ role.roleDiscription }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/member',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgNavy"
                        @click="insertMember()"
                    >
                        저장
                    </button>
                    <button class="radiusSmallBtn bgDark" @click="reset()">
                        취소
                    </button>
                </div>
            </div>

            <!-- 권한관리 팝업 -->
            <RolePopup v-if="openPopupStatus" />
        </div>
    </div>
</template>

<script>
import RolePopup from "@/components/role/RolePopup";
import axios from "axios";
import EventBus from "@/script/EventBus.js";
export default {
    name: "MemberCreate",
    components: {
        RolePopup,
    },
    data() {
        return {
            siteNo: "",
            userNo: "",
            roleList: [],
            checkedRoleNoList: [],
            memberInfo: {},
            tempLoginId: "",
            currentLoginId: "",
            tempAliasName: "",
            currentAliasName: "",
            loginIdDuplicate: false,
            aliasNameDuplicate: false,
            openPopupStatus: false,
            locationList: [],
            checkUserLocationList: [],
        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.selectLocationList();
        this.selectRoleList();

        EventBus.$on('popupOpenStatus', ()=>{
            this.openPopupStatus = false;
        });
    },
    computed: {
        checkedLoginId() {
            return (
                !this.memberInfo.loginId ||
                this.currentLoginId == this.memberInfo.loginId ||
                this.memberInfo.loginId == this.tempLoginId
            );
        },
        checkedAliasName() {
            return (
                !this.memberInfo.aliasName ||
                this.currentAliasName == this.memberInfo.aliasName ||
                this.memberInfo.aliasName == this.tempAliasName
            );
        },
    },
    methods: {        
        openPopup() {
            this.openPopupStatus = true;
        },
        selectRoleList() {
            axios
                .post(this.config.contextRoot + "/role/selectRoleList.do", {
                    siteNo: this.siteNo,
                })
                .then((response) => {
                    this.roleList = response.data.roleList;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        selectLocationList() {
            axios
                .post(this.config.contextRoot + "/member/selectLocationList.do", {
                    siteNo: this.siteNo,
                })
                .then((response) => {
                    this.locationList = response.data.locationList;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        insertMember() {
            this.logger.debug(this, "updateMember()");

            if (!confirm("등록 하시겠습니까?")) {
                return;
            }

            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (
                        !result ||
                        !this.checkedAliasName ||
                        !this.checkedLoginId
                    ) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }

                    this.memberInfo.roleNoList = this.checkedRoleNoList;
                    this.memberInfo.siteNo = this.siteNo;
                    this.memberInfo.userLocationList = this.checkUserLocationList;

                    axios
                        .post(
                            this.config.contextRoot + "/member/insertMember.do",
                            this.memberInfo
                        )
                        .then((response) => {
                            if (response.data.userNo) {
                                alert("등록 되었습니다.");
                                this.$router.replace(
                                    this.config.contextRoot +
                                        "/site/" +
                                        this.siteNo +
                                        "/member/" +
                                        response.data.userNo +
                                        "/edit"
                                );
                            }
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });
                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.errors.all());
                    return false;
                });
        },
        checkLoginId() {
            if(this.memberInfo.loginId.length < 6) {
                alert("아이디항목의 값은 최소 6글자이어야 합니다");
                return;
            }

            const formData = {
                siteNo: this.siteNo,
                loginId: this.memberInfo.loginId,
            };
            axios
                .post(
                    this.config.contextRoot + "/member/hasLoginId.do",
                    formData
                )
                .then((response) => {
                    if (response.data.hasLoginId) {
                        // 아이디 중복이면
                        alert("이미 사용된 아이디입니다.");
                        this.loginIdDuplicate = true;
                        return;
                    }

                    // 사용가능한 아이디
                    alert("사용가능합니다.");
                    this.tempLoginId = this.memberInfo.loginId;
                    this.loginIdDuplicate = false;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        checkAliasName() {
            const formData = {
                siteNo: this.siteNo,
                aliasName: this.memberInfo.aliasName,
            };
            axios
                .post(
                    this.config.contextRoot + "/member/hasAliasName.do",
                    formData
                )
                .then((response) => {
                    if (response.data.hasAliasName) {
                        // 별칭 중복이면
                        alert("이미 사용된 별칭입니다.");
                        this.aliasNameDuplicate = true;
                        return;
                    }

                    // 사용가능한 별칭
                    alert("사용가능합니다.");
                    this.tempAliasName = this.memberInfo.aliasName;
                    this.aliasNameDuplicate = false;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        reset() {
            if (confirm("취소 하시겠습니까?")) {
                this.$router.go(this.$router.currentRoute);
            }
        },
    },
};
</script>

<style>
</style>