<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">팝업 관리</h2>
        </div>
        <div class="contentBody">
            <h3 class="contentTitle">팝업 등록</h3>

            <!-- 입력 폼 -->
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
                <table class="infoTable marginBottom-35">
                    <tbody>
                        <tr>
                            <th class="requiredMark">팝업제목</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="popupInfo.popupName"
                                    v-validate="'required'"
                                    data-vv-name="popupName"
                                    data-vv-as="팝업제목 "
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('popupName')"
                                >
                                    * {{ errors.first("popupName") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>팝업위치</th>
                            <td>
                                <label>
                                X:
                                <input
                                    type="number"
                                    class="inputBox"
                                    v-model="popupInfo.popupPositionX"
                                    data-vv-name="popupPositionX"
                                    data-vv-as="팝업위치X"
                                    style="width:100px;"
                                />
                                </label>
                                <label>
                                Y:
                                <input
                                    type="number"
                                    class="inputBox"
                                    v-model="popupInfo.popupPositionY"
                                    data-vv-name="popupPositionY"
                                    data-vv-as="팝업위치Y"
                                    style="width:100px;"
                                />
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th>팝업크기</th>
                            <td>
                                <label>
                                X:
                                <input
                                    type="number"
                                    class="inputBox"
                                    v-model="popupInfo.popupSizeX"
                                    data-vv-name="popupSizeX"
                                    data-vv-as="팝업크기X"
                                    style="width:100px;"
                                />
                                </label>
                                <label>
                                Y:
                                <input
                                    type="number"
                                    class="inputBox"
                                    v-model="popupInfo.popupSizeY"
                                    data-vv-name="popupSizeY"
                                    data-vv-as="팝업크기Y"
                                    style="width:100px;"
                                />
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th>팝업기간</th>
                            <td>
                                <div class="labelWrap" style="width:600px; display: inline-block;">
                                    <label>시작일</label>
                                    <div class="datePicker" style="width:200px;">
                                        <input
                                            type="text"
                                            class="inputBox"
                                            placeholder="연도-월-일"
                                            id="popupStartDay"
                                            v-model="popupStartDay"
                                            autocomplete="off"
                                        />
                                        <div ref="popupStartDayPicker" id="popupStartDayPicker"></div>
                                        <i></i>
                                    </div>
                                    <input
                                        type="number"
                                        class="inputBox"
                                        v-model="popupStartHour"
                                        v-validate="''"
                                        data-vv-name="popupStartHour"
                                        data-vv-as="팝업시작(시)"
                                        min="0" max="23"
                                        style="width:60px;"
                                    />
                                    <label style="width:30px;">시</label>
                                    <input
                                        type="number"
                                        class="inputBox"
                                        v-model="popupStartMin"
                                        v-validate="''"
                                        data-vv-name="popupStartMin"
                                        data-vv-as="팝업시작(분)"
                                        min="0" max="59"
                                        style="width:60px;"
                                    />
                                    <label style="width:30px;">분</label>
                                    
                                </div>

                                <div class="labelWrap" style="width:600px; display: inline-block;">
                                    <label>종료일</label>
                                    <div class="datePicker" style="width:200px;">
                                        <input
                                            type="text"
                                            class="inputBox"
                                            placeholder="연도-월-일"
                                            id="popupEndDay"
                                            v-model="popupEndDay"
                                            autocomplete="off"
                                        />
                                        <div ref="popupEndDayPicker" id="popupEndDayPicker"></div>
                                        <i></i>
                                    </div>
                                    <input
                                        type="number"
                                        class="inputBox"
                                        v-model="popupEndHour"
                                        v-validate="''"
                                        data-vv-name="popupEndHour"
                                        data-vv-as="팝업종료(시)"
                                        min="0" max="23"
                                        style="width:60px;"
                                    />
                                    <label style="width:30px;">시</label>
                                    <input
                                        type="number"
                                        class="inputBox"
                                        v-model="popupEndMin"
                                        v-validate="''"
                                        data-vv-name="popupEndMin"
                                        data-vv-as="팝업종료(분)"
                                        min="0" max="59"
                                        style="width:60px;"
                                    />
                                    <label style="width:30px;">분</label>
                                </div>
                                <p
                                    class="validation-text"
                                    v-if="errors.has('popupStartHour')"
                                >
                                    * {{ errors.first("popupStartHour") }}
                                </p>
                                <p
                                    class="validation-text"
                                    v-if="errors.has('popupStartMin')"
                                >
                                    * {{ errors.first("popupStartMin") }}
                                </p>
                                <p
                                    class="validation-text"
                                    v-if="errors.has('popupEndHour')"
                                >
                                    * {{ errors.first("popupEndHour") }}
                                </p>
                                <p
                                    class="validation-text"
                                    v-if="errors.has('popupEndMin')"
                                >
                                    * {{ errors.first("popupEndMin") }}
                                </p>
                                
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">사용 여부</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="popupInfo.useYn"
                                            value="Y"
                                        />
                                        <span>사용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="popupInfo.useYn"
                                            value="N"
                                        />
                                        <span>미사용</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>파일선택</th>
                            <td>
								<common-file
									ref="FileUploader"
									v-model="popupInfo.fileList"
									fileMaxSize="10"
									:multipleUpload="false"
								/>
							</td>
                        </tr>
                        <!-- <tr>
							<th>편집방법</th>
							<td>
								<button class="radiusSmallBtn bgBlue" @click="editHtml = true">HTML</button>
								<button class="radiusSmallBtn bgGray" @click="editHtml = false">SOURCE</button>
							</td>
						</tr> -->
                        <tr>
                            <th>편집방법</th>
                            <td>
                            <button class="radiusSmallBtn bgBlue" @click="editHtml = true">HTML</button>
                            <button class="radiusSmallBtn bgGray" @click="editHtml = false">SOURCE</button>
                            </td>
                        </tr>
						<tr>
							<th>내용</th>
							<td>
								<div v-show="editHtml">
									<textarea hidden id="ckeditor" v-model="popupInfo.popupContents"
                                        v-validate="'required'"
                                        data-vv-name="popupContents"
                                        data-vv-as="내용"></textarea>
								</div>
								<div v-show="!editHtml">
									<prism-editor
										class="my-editor"
										v-model="popupInfo.popupContents"
										v-validate="''"
										data-vv-name="popupContents"
										data-vv-as="내용"
										:highlight="highlighter"
										line-numbers
									></prism-editor>
								</div>
								<p class="validation-text" v-if="errors.has('popupContents')">
									* {{ errors.first("popupContents") }}
								</p>
							</td>
						</tr>
                    </tbody>
                </table>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap">
                    <button
                        class="radiusSmallBtn bgNavy"
                        @click="insertPopup()"
                    >
                        저장
                    </button>
                    <button class="radiusSmallBtn bgDark" @click="cancel()">
                        취소
                    </button>
                </div>
            </div>
        </div>

        

    </div>
</template>

<script>
import axios from "axios";
import "tui-date-picker/dist/tui-date-picker.min.css";
import CommonFile from "@/components/common/CommonFile";
import EventBus from "../../script/EventBus";

import DatePicker from "tui-date-picker";
import "tui-date-picker/dist/tui-date-picker.min.css";

import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

// import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";

import CKEditorConfig from "@/script/CKEditorConfig";
export default {
    name: "PopupCreate",
    data() {
        return {
            editHtml: true,
            siteNo: "",
            popupInfo: {
                popupName: "",

                popupPositionX: 0,
                popupPositionY: 0,
                popupSizeX: 400,
                popupSizeY: 800,

                popupStartDate: "",
                popupEndDate: "",
    
                useYn: "Y",
                fileGroupNo: "1",
                fileList: [],

                popupContents: "",
            },

            popupStartDay: "",
            popupStartHour: 0,
            popupStartMin: 0,

            popupEndDay: "",
            popupEndHour: 0,
            popupEndMin: 0,
        };
    },
    created(){
        this.siteNo = this.$route.params.siteNo;
        EventBus.$on("addFile", (fileList) => {
            this.logger.debug(this, "eventBus / addFile()");
            this.addFile(fileList);
        });

    },
    mounted(){
      var today = new Date();
		const rangePicker = new DatePicker.createRangePicker({
			startpicker: {
				date: today,
				input: "#popupStartDay",
				container: "#popupStartDayPicker",
			},
            endpicker: {
				date: today,
				input: "#popupEndDay",
				container: "#popupEndDayPicker",
			},
			language: "ko",
		});
		rangePicker.on("change:start", () => {
			this.popupStartDay = document.getElementById("popupStartDay").value;
		});
		rangePicker.on("change:end", () => {
			this.popupEndDay = document.getElementById("popupEndDay").value;
		});  
        //에디터
        new CKEditorConfig("#ckeditor").create();
    },
    watch: {
        editHtml(newVal){
            if(newVal) window.editor.setData(this.popupInfo.popupContents);
            else this.popupInfo.popupContents = window.editor.getData();
        }
    },
    components: {
        CommonFile,
        PrismEditor,
        // VueEditor,
        
    },
    methods: {
        highlighter(code) {
            return highlight(code, languages.js); // languages.<insert language> to return html with markup
        },
        insertPopup() {
            this.logger.debug(this, "insertPopup()");
            
            if(this.editHtml) this.popupInfo.popupContents = window.editor.getData();

            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }else{
                        if (!confirm("등록 하시겠습니까?")) {
                            return;
                        }
                        
                        console.log(this.popupInfo);

                        // (날짜, 시간) 병합
                        this.concatDate();

                        axios
                            .post(
                                this.config.contextRoot +
                                    "/popup/insertPopup.do",
                                this.popupInfo
                            )
                            .then((response) => {
                                if (response.status == 200) {
                                    alert("등록 되었습니다.");
                                    console.log(this.config.contextRoot +
                                            "/site/" +
                                            this.siteNo +
                                            "/popup/" +
                                            response.data.popupNo +
                                            "/edit");
                                    this.$router.replace(
                                        this.config.contextRoot +
                                            "/site/" +
                                            this.siteNo +
                                            "/popup/" +
                                            response.data.popupNo +
                                            "/edit"
                                    );
                                }
                            })
                            .catch((error) => {
                                this.exceptionHandler(this, error);
                            });
                    }

                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.error.all());
                    return false;
                });
        },

        openUploader(refName) {
            this.logger.debug(this, "openUploader()");
            this.$refs[refName].openUploader();
        },
        doDownload(data) {
            this.logger.debug(this, "doDownload");
            const formData = new FormData();
            formData.append("fileNo", data.fileNo);
            formData.append("fileName", data.fileName);
            formData.append("serverFileSaveName", data.serverFileSaveName);
            axios
                .post(
                    this.config.contextRoot + "/common/file/download.do",
                    formData,
                    { responseType: "blob" }
                )
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: response.headers["content-type"],
                        })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", data.fileName);
                    document.body.appendChild(link);
                    link.click();
                    location.reload();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        deleteFile(index) {
            this.popupInfo.fileList.splice(index, 1);
        },
        addFile(fileList) {
            this.popupInfo.fileList =
                this.popupInfo.fileList.concat(fileList);
        },
        cancel() {
            if (confirm("취소 하시겠습니까?")) {
                this.$router.go(-1);
            }
        },
        concatDate(){
            this.logger.debug(this, "concatDate()");
            // 날짜 생성(년월일, 시간 병합)
            if(this.popupStartDay){
                if(!this.popupStartHour || this.popupStartHour < 0) this.popupStartHour = 0;
                if(this.popupStartHour > 23) this.popupStartHour = 23; 
                if(!this.popupStartMin || this.popupStartMin < 0) this.popupStartMin = 0;
                if(this.popupStartMin > 59) this.popupStartMin = 59;
                
                this.popupInfo.popupStartDate = this.popupStartDay 
                                                + ' ' + ('0' + this.popupStartHour).slice(-2)
                                                + ':' + ('0' + this.popupStartMin).slice(-2);
            } 
            if(this.popupEndDay){
                if(!this.popupEndHour || this.popupEndHour < 0) this.popupEndHour = 0;
                if(this.popupEndHour > 23) this.popupEndHour = 23; 
                if(!this.popupEndMin || this.popupEndMin < 0) this.popupEndMin = 0;
                if(this.popupEndMin > 59) this.popupEndMin = 59;
                
                this.popupInfo.popupEndDate = this.popupEndDay 
                                                + ' ' + ('0' + this.popupEndHour).slice(-2)
                                                + ':' + ('0' + this.popupEndMin).slice(-2);
            } 
        },
    },
};
</script>

<style>
</style>