<template>
	<div class="popup-container" :style="[getSize(), drag.position]">
		<div
			class="popup-top-wrap"
			@dragstart="dragstart($event)"
			@drag="drag.isDrag ? dragging($event) : ''"
			@dragend="drag.isDrag ? draggend() : ''"
			draggable
		>
			<span>
				{{ popupData.title }}
			</span>
			<button type="button" @click="close()"></button>
		</div>
		<div
			class="popup-center-wrap thin-grey-scroll"
			:class="popupData.contentsType == 'previewpdf' ? 'preview-container-background' : ''"
		>
			<img :src="imageBinary" />
		</div>
		<div class="popup-bottom-wrap">
			<button
				type="button"
				v-for="(item, index) in popupData.button"
				:key="index"
				:style="{ width: 100 / popupData.button.length + '%' }"
				@click.prevent="btnClick(popupData.buttonAction[index])"
			>
				{{ item }}
			</button>
		</div>
	</div>
</template>
<script>
import EventBus from "@/script/EventBus.js";
import axios from "axios";

export default {
	name: "PopUp",
	props: {
		popupData: {
			type: Object,
			required: true,
		},
	},
	created() {
		this.logger.debug(this, "created()");
		this.returnPopupStyle();
		const item = this.popupData.parameter.dataInputItem;
		const fileGroupNo = item.fileGroupNo;
		const fileNo = item.fileNo;
		const serverFileSaveName = item.serverFileSaveName;

		axios
			.get(this.config.contextRoot + "/common/file/imgLoad.do", {
				responseType: "arraybuffer",
				params: {
					fileGroupNo,
					fileNo,
					serverFileSaveName,
				},
			})
			.then((response) => {
				console.log(response);
				let binary = "";
				const bytes = new Uint8Array(response.data);
				const len = bytes.byteLength;
				for (let i = 0; i < len; i++) {
					binary += String.fromCharCode(bytes[i]);
				}
				const filedata = window.btoa(binary);

				var prefix = "data:" + response.headers["content-type"] + ";base64,";
				this.imageBinary = prefix + filedata;
			})
			.catch((error) => {
				this.exceptionHandler(this, error);
			});
	},
	components: {},
	data() {
		return {
			drag: {
				isDrag: false,
				position: {
					top: "",
					left: "",
					cursor: "",
				},
			},
			imageBinary: "",
		};
	},
	computed: {},
	methods: {
		close() {
			this.logger.debug(this, "close()");
			EventBus.$emit("closeImagePopUp");
		},
		dragstart(event) {
			this.drag.offsetLeft = event.offsetX;
			this.drag.offsetTop = event.offsetY;
			this.drag.isDrag = true;
		},
		dragging(event) {
			var top = event.clientY - this.drag.offsetTop;
			var left = event.clientX - this.drag.offsetLeft;
			if (top > 0 && left > 0) {
				this.drag.position.top = top + "px";
				this.drag.position.left = left + "px";
				this.drag.position.cursor = "grab";
			}
		},
		draggend() {
			this.drag.isDrag = false;
			this.drag.position.cursor = "unset";
		},
		returnPopupStyle() {
			var widthValue = this.popupData.width == undefined ? "980px" : this.popupData.width + "px";
			var heightValue = this.popupData.height == undefined ? "680px" : this.popupData.height + "px";
			var topValue =
				this.popupData.top == undefined
					? "calc(50% - " + heightValue.replace("px", "") / 2 + "px)"
					: this.popupData.top + "%";
			var leftValue =
				this.popupData.left == undefined
					? "calc(50% - " + widthValue.replace("px", "") / 2 + "px)"
					: this.popupData.left + "%";

			this.drag.position.top = topValue;
			this.drag.position.left = leftValue;
		},
		getSize() {
			var returnMap = {};

			var widthValue = this.popupData.width == undefined ? "980px" : this.popupData.width + "px";
			var heightValue = this.popupData.height == undefined ? "680px" : this.popupData.height + "px";
			returnMap.width = widthValue;
			returnMap.height = heightValue;

			return returnMap;
		},
	},
};
</script>

<style scoped>
.popup-container {
	position: fixed;
	/* transform: translate(-490px, -340px); */
	/* width: 980px; */
	/* height: 680px; */
	border: 2px solid #0980e0;
	box-shadow: 2px 2px 3px 0 #999;
	background: #fff;
	z-index: 100;
	overflow: auto;
	resize: both;
}

.popup-top-wrap {
	height: 60px;
	padding: 0 30px;
	background-color: #0980e0;
}

.popup-top-wrap span {
	display: inline-flex;
	align-items: center;
	width: calc(100% - 40px);
	height: 100%;
	font-weight: 600;
	font-size: 15px;
	color: #fff;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.popup-top-wrap button {
	float: right;
	width: 15px;
	height: 15px;
	margin: 22.5px 0;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAABHNCSVQICAgIfAhkiAAAAKhJREFUOE+d1E0OQDAQBWDjSrYWbiBdSaycztYlnEu9ikq186NsGsm8LzqdIu/9SkRz8+NBtkNsoJDFywGorXGQGVG/IOcupBZKgZB9kK9QDhSIBXEAi0iQBIhIDmmAikQIq4unIJ3eq7F50f0Fm3X8IpJuwZojFuF6oEEFojVRgvJhe0ZZaiIHpWNvAtIViRfwM8BBZA2SdrPj1gLSYw72mt9AWov8dAJFKX/WFiewLQAAAABJRU5ErkJggg==");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 15px;
}

.popup-top-wrap button:hover,
.popup-top-wrap button:focus {
	width: 16px;
	height: 16px;
	background-size: 16px;
	margin: 22px 0;
}

.popup-center-wrap {
	position: absolute;
	top: 65px;
	left: 0;
	width: calc(100% - 2px);
	height: calc(100% - 120px);
	padding: 0 15px 0 20px;
	background-color: #fff;
	overflow-x: hidden;
	overflow-y: auto;
}

.popup-bottom-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50px;
	border-top: 1px solid #d8dadd;
}

.popup-bottom-wrap button {
	height: 100%;
	border-right: 1px solid #d8dadd;
	font-weight: 700;
	font-size: 14px;
	color: #aaa;
}

.popup-bottom-wrap button:hover {
	background-color: #f0f0f0;
}

.popup-bottom-wrap button:last-child {
	border-right: none;
	color: #0980e0;
}

.popup-inspection-center-wrap {
	position: absolute;
	left: 0;
	width: calc(100% - 2px);
	height: calc(100% - 120px);
	padding: 0 10px;
	background-color: #fff;
	overflow-x: hidden;
	overflow-y: auto;
}

.popup-inspection-bottom-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50px;
	border-top: 1px solid #d8dadd;
	margin: 0;
}

.popup-inspection-bottom-wrap button {
	height: 100%;
	border-right: 1px solid #d8dadd;
	font-weight: 700;
	font-size: 14px;
	width: 100%;
	color: #aaa;
}

.popup-inspection-bottom-wrap button:hover {
	background-color: #f0f0f0;
}

.popup-inspection-bottom-wrap button:last-child {
	border-right: none;
	color: #0980e0;
}
</style>
