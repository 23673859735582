<template>
	<div class="contentLayout">
		<div class="contentNavigationBar">
			<h2 class="title">API로그</h2>
		</div>
		<div class="contentBody">
			<h3 class="contentTitle">API로그 수정</h3>

			<!-- 입력 폼 -->
			<div class="infoWrap">
				<p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
				<table class="infoTable">
					<tbody>
						<tr>
							<th style="width: 150px;">API KEY</th>
							<td>{{ apilogInfo.confirmApiKey }}</td>
						</tr>
						<tr>
							<th>사용일시</th>
							<td>{{ apilogInfo.requestDate }}</td>
						</tr>
						<tr>
							<th>사용번호</th>
							<td>{{ apilogInfo.useNo }}</td>
						</tr>
						<tr>
							<th>요청IP</th>
							<td>{{ apilogInfo.requestIpAddr }}</td>
						</tr>
						<tr>
							<th>요청파라미터</th>
							<td 
								v-html="requestParamHtml"
								style="line-height: 30px;"
							>
							</td>
						</tr>
						<tr>
							<th>응답상태</th>
							<td>{{ apilogInfo.responseCode == '200' ? '성공' : '실패' }}</td>
						</tr>
						<tr>
							<th>응답메시지</th>
							<td>{{ apilogInfo.responseMessage }}</td>
						</tr>
						<tr>
							<th>응답 수유실 수</th>
							<td>{{ apilogInfo.responseCount }}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- 하단 버튼 바 -->
			<div class="contentButtonBar">
				<div class="buttonWrap floatLeft">
					<router-link
					:to="{
							path: config.contextRoot + '/site/' + $route.params.siteNo + '/apilog',
							query: {
								confirmApiKey: this.$route.query.confirmApiKey,
							},
						}"
						class="radiusSmallBtn bgGray"
					>
						목록
					</router-link>
				</div>
				<div class="buttonWrap floatRight">
					<!-- <button class="radiusSmallBtn bgDark" @click="deleteApilog()">삭제</button> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
	name: "ApilogCreate",
	data() {
		return {
			siteNo: "",
			apilogInfo: {
			},
		};
	},
	created() {
		this.siteNo = this.$route.params.siteNo;
		
		this.confirmApiKey = this.$route.params.confirmApiKey;
		this.useDt = this.$route.params.useDt;
		this.useNo = this.$route.params.useNo;

		this.selectApilog();
	},
	computed:{
		requestParamHtml(){
			return this.apilogInfo.requestParam.replaceAll("\n", "<br />");
		}
	},
	methods: {
		selectApilog() {
			this.logger.debug(this, "selectApilog()");
			this.apilogNo = this.$route.params.apilogNo;
			const formData = {
				confirmApiKey: this.confirmApiKey,
				useDt: this.useDt,
				useNo: this.useNo,
			};
			axios
				.post(this.config.contextRoot + "/api/selectApilog.do", formData)
				.then((response) => {
					this.apilogInfo = response.data.apilogInfo;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		deleteApilog() {
			if (confirm("삭제 하시겠습니까?")) {
				axios
					.post(this.config.contextRoot + "/apilog/deleteApilog.do", this.apilogInfo)
					.then((response) => {
						if (response) {
							alert("삭제 되었습니다.");
							this.$router.replace(this.config.contextRoot + "/site/" + this.siteNo + "/apilog/");
						}
					})
					.catch((error) => {
						if (error.response.data.message == "SQLIntegrityConstraintViolationException") {
							alert("해당 API로그은 메뉴관리에서 사용 중이므로 삭제할 수 없습니다.");
							return;
						}

						this.exceptionHandler(this, error);
					});
			}
		},
	},
};
</script>

<style></style>
