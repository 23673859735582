<template>
	<div class="contentLayout">
		<div class="contentNavigationBar">
			<h2 class="title">등록요청 관리</h2>
		</div>
		<div class="contentBody">
			<!-- 검색 -->
			<div class="searchBar">
				<div class="columnDivision-3">
					<div class="labelWrap" style="margin-bottom: 10px">
						<label>기관</label>
						<input
							type="text"
							class="inputBox"
							v-model="searchForm.applicantOrganization"
						/>
					</div>
					<div class="labelWrap">
						<label style="width: 200px">광역시/도 & 시/군/구</label>
						<select
							class="selectBox"
							v-model="address"
							style="width: 100px; margin-right: 10px"
							@change="selectComCodeList()"
						>
							<option value="">광역시/도</option>
							<option
								:key="idx1"
								:value="adress1.comCode"
								v-for="(adress1, idx1) in addressList1"
							>
								{{ adress1.codeName }}
							</option>
						</select>
						<select
							class="selectBox"
							v-model="addressDetail"
							style="width: 100px"
						>
							<option value="">시/군/구</option>
							<option
								:key="idx2"
								:value="adress2.comCode"
								v-for="(adress2, idx2) in addressList2"
							>
								{{ adress2.codeName }}
							</option>
						</select>
					</div>

					<br />

					<div class="labelWrap" style="margin-bottom: 10px">
						<label>담당자명</label>
						<input
							type="text"
							class="inputBox"
							v-model="searchForm.applicantUserName"
						/>
					</div>

					<br />

					<div
						class="radioBoxWrap"
						style="
							width: 1000px;
							margin-bottom: 15px;
							margin-left: 10px;
						"
					>
						<label style="width: 300px"
							>설치용도 및 <br />활용 대상자</label
						>
						<label>
							<input
								type="radio"
								class="radioBox"
								name="testRadio-6"
								v-model="searchForm.supportCode"
								value=""
							/>
							<span>전체</span>
						</label>
						<label>
							<input
								type="radio"
								class="radioBox"
								name="testRadio-6"
								v-model="searchForm.supportCode"
								value="3"
							/>
							<span>가족수유실</span>
						</label>
						<label>
							<input
								type="radio"
								class="radioBox"
								name="testRadio-6"
								v-model="searchForm.supportCode"
								value="4"
							/>
							<span>모유수유/착유실</span>
						</label>
					</div>

					<br />

					<div
						class="radioBoxWrap"
						style="margin-bottom: 15px; margin-left: 25px"
					>
						<label>처리현황</label>
						<label>
							<input
								type="radio"
								class="radioBox"
								name="testRadio-7"
								v-model="searchForm.applicantStateCode"
								value=""
							/>
							<span>전체</span>
						</label>
						<label>
							<input
								type="radio"
								class="radioBox"
								name="testRadio-7"
								v-model="searchForm.applicantStateCode"
								value="0"
							/>
							<span>접수</span>
						</label>
						<label>
							<input
								type="radio"
								class="radioBox"
								name="testRadio-7"
								v-model="searchForm.applicantStateCode"
								value="1"
							/>
							<span>반려</span>
						</label>
						<label>
							<input
								type="radio"
								class="radioBox"
								name="testRadio-7"
								v-model="searchForm.applicantStateCode"
								value="2"
							/>
							<span>완료</span>
						</label>
					</div>

					<br />

					<div class="labelWrap">
						<label>등록일</label>
						<div class="datePicker">
							<input
								type="text"
								class="inputBox"
								placeholder="연도-월-일"
								id="startDate"
								v-model="searchForm.startDate"
								autocomplete="off"
							/>
							<div
								ref="startDatePicker"
								id="startDatePicker"
							></div>
							<i></i>
						</div>
					</div>
					<div class="labelWrap">
						<label class="textCenter" style="width: 30px">-</label>
						<div class="datePicker">
							<input
								type="text"
								class="inputBox"
								placeholder="연도-월-일"
								id="endDate"
								v-model="searchForm.endDate"
								autocomplete="off"
							/>
							<i></i>
							<div ref="endDatePicker" id="endDatePicker"></div>
						</div>
					</div>
				</div>
				<button class="searchBtn" @click="doSearchPage(1)">
					조회하기
				</button>
			</div>
			<div class="contentTitleWrap marginTop-35">
				<h3 class="contentTitle">등록요청 목록</h3>
			</div>
			<div class="listWrap">
				<!-- 목록 상단 -->
				<div class="listTop">
					<span class="listTotal"
						>(Total <em>{{ pagination.total }}</em
						>)</span
					>
					<button
						class="radiusSmallBtn bgBlue"
						@click="downloadExcel()"
						style="margin-left: 10px"
					>
						Excel (전체)
					</button>
					<select
						class="selectBox"
						@change.prevent="doSearchPage(1)"
						v-model="searchForm.countPerPage"
					>
						<option>5</option>
						<option selected>10</option>
						<option>20</option>
						<option>30</option>
						<option>50</option>
						<option>100</option>
					</select>
				</div>

				<!-- 목록테이블 -->
				<table class="listTable tableFixed">
					<colgroup>
						<col style="width: 2%" />
						<col style="width: 6%" />
						<col style="width: 15%" />
						<col style="width: 20%" />
						<col style="width: 10%" />
						<col style="width: 7%" />
						<col style="width: 10%" />
						<col style="width: 10%" />
						<col style="width: 8%" />
						<col style="width: 6%" />
					</colgroup>
					<thead>
						<tr>
							<th>
								<label>
									<input
										type="checkbox"
										@click="checkAll"
										v-model="allChecked"
									/>
								</label>
							</th>
							<th>지원번호</th>
							<th>기관명</th>
							<th>주소/위치</th>
							<th>담당자명/<br />직위</th>
							<th>연락처/<br />팩스</th>
							<th>휴대폰<br />이메일</th>
							<th>설치용도<br />활용대상자</th>
							<th>등록일<br />수정일</th>
							<th>접수현황</th>
						</tr>
					</thead>
					<tbody
						v-for="support in registList"
						:key="support.supportNo"
						@click="
							$router.push({
								path:
									config.contextRoot +
									'/site/' +
									$route.params.siteNo +
									'/regist/' +
									support.supportNo +
									'/edit',
							})
						"
					>
						<tr>
							<td>
								<label
									><input
										type="checkbox"
										v-model="deleteRegistNoList"
										:value="support.supportNo"
										@click.stop=""
									/>
								</label>
							</td>
							<td>{{ support.supportNo }}</td>
							<td>{{ support.applicantOrganization }}</td>
							<td>
								{{ support.address1 }}
								{{
									support.address2
										? ", " + support.address2
										: ""
								}}
								{{
									support.address3
										? ", " + support.address3
										: ""
								}}
							</td>
							<td>
								{{ support.applicantUserName }}
								<br />
								{{ support.applicantPosition }}
							</td>
							<td>
								{{ support.applicantTelNo }}
								<br />
								{{ support.applicantFaxNo }}
							</td>
							<td>
								{{ support.applicantHpNo }}
								<br />
								{{ support.applicantEmail }}
							</td>
							<td
								v-if="
									support.supportCode != '3' &&
									support.supportCode != '4'
								"
							>
								없음
							</td>
							<td v-if="support.supportCode == '3'">
								가족수유실<br />(직원용)
							</td>
							<td v-if="support.supportCode == '4'">
								모유수유/착유실<br />(직원 및 고객용)
							</td>
							<td>
								{{ support.firstRegistDate }}
								<br />
								{{ support.lastModifyDate }}
							</td>
							<td
								v-if="
									support.applicantStateCode != '0' &&
									support.applicantStateCode != '1' &&
									support.applicantStateCode != '2'
								"
							>
								없음
							</td>
							<td v-if="support.applicantStateCode == '0'">
								접수
							</td>
							<td v-if="support.applicantStateCode == '1'">
								반려
							</td>
							<td v-if="support.applicantStateCode == '2'">
								완료
							</td>
						</tr>
					</tbody>
				</table>

				<!-- 페이지네이션 -->
				<pagination
					v-if="pagination.total > pagination.per_page"
					v-model="pagination.current_page"
					:records="pagination.total"
					:per-page="pagination.per_page"
					@paginate="doSearchPage"
					:options="{
						texts: {
							count: '',
							first: '',
							last: '',
						},
					}"
				/>
			</div>

			<!-- 하단 버튼 바 -->
			<div class="contentButtonBar">
				<div class="buttonWrap floatRight">
					<button
						class="radiusSmallBtn bgTuftsBlue"
						@click.prevent="deleteRegistList()"
					>
						선택 삭제
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import "tui-date-picker/dist/tui-date-picker.min.css";
import DatePicker from "tui-date-picker";

import Pagination from "vue-pagination-2";

export default {
	name: "SupportList",
	components: { Pagination },
	data() {
		return {
			siteNo: "",
			registList: [],
			pagination: {},
			searchForm: {
				applicantOrganization: "",
				address1: "",
				applicantUserName: "",
				supportCode: "",
				applicantStateCode: "",
				startDate: "",
				endDate: "",
				countPerPage: 10,
				pageNo: 1,
			},

			address: "",
			addressDetail: "",

			displayPageCount: 10,
			isPrev: "",
			isNext: "",
			startPageNo: "",
			endPageNo: "",
			realEndPageNo: "",
			pageNoList: [],
			deleteRegistNoList: [],
			allChecked: false,
			isShowRecruitmentUseYn: false,

			programList: [],
			addressList1: [],
			addressList2: [],
		};
	},
	created() {
		this.siteNo = this.$route.params.siteNo;
		this.selectRegistList();
		this.selectComCodeList();
	},
	watch: {
		deleteRegistNoList() {
			if (this.registList.length != this.deleteRegistNoList.length) {
				this.allChecked = false;
			} else {
				this.allChecked = true;
			}
		},
	},
	mounted() {
		// 데이트피커
		var today = new Date();
		const rangePicker = new DatePicker.createRangePicker({
			startpicker: {
				date: today,
				input: "#startDate",
				container: "#startDatePicker",
			},
			endpicker: {
				date: today,
				input: "#endDate",
				container: "#endDatePicker",
			},
			language: "ko",
		});
		rangePicker.on("change:start", () => {
			this.searchForm.startDate =
				document.getElementById("startDate").value;
		});
		rangePicker.on("change:end", () => {
			this.searchForm.endDate = document.getElementById("endDate").value;
		});
	},
	methods: {
		selectRegistList() {
			this.logger.debug(this, "selectRegistList()");
			this.searchForm.siteNo = this.siteNo;

			this.searchForm.address1 = this.address;
			if (this.searchForm.address1) {
				this.searchForm.address1 =
					this.searchForm.address1 + " " + this.addressDetail;
			}
			axios
				.post(
					this.config.contextRoot + "/regist/selectRegistList.do",
					this.searchForm
				)
				.then((response) => {
					this.registList = response.data.registList;
					this.pagination = response.data.pagination;
					this.calcPagination();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectComCodeList() {
			this.logger.debug(this, "selectComCodeList()");
			this.searchForm.siteNo = this.siteNo;
			axios
				.post(
					this.config.contextRoot +
						"/common/code/selectComCodeList.do",
					{ classCode: this.address ? this.address : "LOCATION_CODE" }
				)
				.then((response) => {
					if (!this.address) {
						this.addressList1 = response.data.codeList;
						this.addressList2 = [];
						this.addressDetail = "";
					} else {
						this.addressList2 = response.data.codeList;
						this.addressDetail = "";
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(pageNo) {
			this.logger.debug(this, "doSearchPage()");
			if (typeof pageNo === "undefined") {
				pageNo = 1;
			}
			this.deleteRegistNoList = [];
			this.searchForm.pageNo = pageNo;
			this.selectRegistList();
		},
		calcPagination() {
			this.logger.debug(this, "calcPagination()");
			this.endPageNo =
				Math.ceil(
					this.pagination.current_page / this.displayPageCount
				) * this.displayPageCount;
			this.startPageNo = this.endPageNo - this.displayPageCount + 1;

			this.realEndPageNo = Math.ceil(
				this.pagination.total / this.pagination.per_page
			);

			if (this.startPageNo < 0) {
				this.startPageNo = 1;
			}
			if (this.endPageNo > this.realEndPageNo) {
				this.endPageNo = this.realEndPageNo;
			}

			this.isPrev = this.startPageNo == 1 ? false : true;
			this.isNext =
				this.endPageNo * this.pagination.per_page >=
				this.pagination.total
					? false
					: true;

			this.pageNoList = [];
			for (let i = this.startPageNo; i <= this.endPageNo; i++) {
				this.pageNoList.push(i);
			}
		},
		checkAll() {
			this.deleteRegistNoList = [];

			if (!this.allChecked) {
				for (let support of this.registList) {
					this.deleteRegistNoList.push(support.supportNo);
				}
			}

			this.allChecked = !this.allChecked;
		},
		deleteRegistList() {
			if (this.deleteRegistNoList.length == 0) {
				alert("삭제항목이 선택되지 않았습니다.");
				return;
			}

			if (!confirm("선택한 항목을 삭제 하시겠습니까?")) {
				return;
			}

			const formData = { deleteRegistNoList: this.deleteRegistNoList };

			axios
				.post(
					this.config.contextRoot + "/support/deleteRegistList.do",
					formData
				)
				.then((response) => {
					if (response.status == 200) {
						// 마지막 페이지에서 전체 선택 삭제할 경우
						if (
							this.startPageNo != this.realEndPageNo &&
							this.searchForm.pageNo == this.realEndPageNo &&
							this.deleteRegistNoList.length ==
								this.registList.length
						) {
							this.$set(
								this.searchForm,
								"pageNo",
								Number(this.searchForm.pageNo) - 1 + ""
							);
						}

						alert("삭제 되었습니다.");
						this.deleteRegistNoList = [];
						this.doSearchPage(this.searchForm.pageNo);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		openImageSupport(selectSupport) {
			let html = "";

			let url =
				this.config.contextRoot +
				"/common/file/imgLoad.do?" +
				"fileGroupNo=" +
				selectSupport.fileGroupNo +
				"&serverFileSaveName=" +
				selectSupport.serverFileSaveName;
			html = "<img src=" + url + ">";

			if (selectSupport.supportContents)
				html += "<br><div>" + selectSupport.supportContents + "</div>";

			let specs = "menubar=no";
			if (selectSupport.supportPositionX)
				specs += ", left=" + selectSupport.supportPositionX;
			if (selectSupport.supportPositionY)
				specs += ", top=" + selectSupport.supportPositionY;
			if (selectSupport.supportSizeX)
				specs += ", width=" + selectSupport.supportSizeX;
			if (selectSupport.supportSizeY)
				specs += ", height=" + selectSupport.supportSizeY;

			var OpenWindow = window.open("", "_blank", specs);
			OpenWindow.document.write(html);
		},
		yyyyMMdd(value) {
			// 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
			if (value == "") return "";

			// 연도, 월, 일 추출
			var year = value.substring(0, 4);
			var month = value.substring(4, 6);
			var day = value.substring(6, 8);

			// 최종 포맷 (ex - '2021-10-08')
			return year + "-" + month + "-" + day;
		},
		downloadExcel() {
			axios
				.post(
					this.config.contextRoot + "/regist/downloadExcel.do",
					{},
					{ responseType: "blob" }
				)
				.then((response) => {
					const url = window.URL.createObjectURL(
						new Blob([response.data], {
							type: "application/vnd.ms-excel",
						})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute(
						"download",
						"수유/착유실 등록요청 목록.xlsx"
					);
					document.body.appendChild(link);
					link.click();
					link.remove();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
	},
};
</script>

<style>
</style>