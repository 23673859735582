<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">교육신청 관리</h2>
        </div>
        <div class="contentBody">
            <h3 class="contentTitle">교육신청 수정</h3>

            <!-- 입력 폼 -->
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
                <table class="infoTable marginBottom-35">
                    <tbody>
                        <tr>
                            <th>소속기관</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="applicantInfo.applicantOrganization"
                                    v-validate="''"
                                    data-vv-name="applicantOrganization"
                                    data-vv-as="소속기관"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('applicantOrganization')"
                                >
                                    * {{ errors.first("applicantOrganization") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>기관주소</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="applicantInfo.address1"
                                    v-validate="''"
                                    data-vv-name="address1"
                                    data-vv-as="주소"
                                    style="width:600px;"
                                    readonly
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('address1')"
                                >
                                    * {{ errors.first("address1") }}
                                </p>
                                <button class="squareBtn bgLightGray"
                                    @click="selectAddress()"
                                    >주소찾기</button>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="applicantInfo.address2"
                                    v-validate="''"
                                    data-vv-name="address2"
                                    data-vv-as="상세주소"
                                    style="width:300px;"
                                    placeholder="상세주소를 입력하여 주세요."
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('address2')"
                                >
                                    * {{ errors.first("address2") }}
                                </p>  
                            </td>
                        </tr>
                        <tr>
                            <th>이름</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="applicantInfo.applicantUserName"
                                    v-validate="''"
                                    data-vv-name="applicantUserName"
                                    data-vv-as="이름"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('applicantUserName')"
                                >
                                    * {{ errors.first("applicantUserName") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>생년월일</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="applicantInfo.applicantBirthDt"
                                    v-validate="''"
                                    data-vv-name="applicantBirthDt"
                                    data-vv-as="생년월일"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('applicantBirthDt')"
                                >
                                    * {{ errors.first("applicantBirthDt") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>연락처</th>
                            <td>
                                사무실
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="applicantInfo.applicantTelNo"
                                    v-validate="''"
                                    data-vv-name="applicantTelNo"
                                    data-vv-as="사무실"
                                    style="width:300px;"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('applicantTelNo')"
                                >
                                    * {{ errors.first("applicantTelNo") }}
                                </p>
                                핸드폰
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="applicantInfo.applicantHpNo"
                                    v-validate="''"
                                    data-vv-name="applicantHpNo"
                                    data-vv-as="핸드폰"
                                    style="width:300px;"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('applicantHpNo')"
                                >
                                    * {{ errors.first("applicantHpNo") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>이메일</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="applicantInfo.applicantEmail"
                                    v-validate="''"
                                    data-vv-name="applicantEmail"
                                    data-vv-as="이메일"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('applicantEmail')"
                                >
                                    * {{ errors.first("applicantEmail") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>비밀번호</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="applicantInfo.applicantPassword"
                                    v-validate="''"
                                    data-vv-name="applicantPassword"
                                    data-vv-as="비밀번호"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('applicantPassword')"
                                >
                                    * {{ errors.first("applicantPassword") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>비밀번호확인</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="applicantPasswordChk"
                                    v-validate="''"
                                    data-vv-name="applicantPasswordChk"
                                    data-vv-as="비밀번호확인"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('applicantPasswordChk')"
                                >
                                    * {{ errors.first("applicantPasswordChk") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>개인정보 수집 이용 동의</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-6"
                                            v-model="applicantInfo.privateAgreeYn"
                                            value="1"
                                        />
                                        <span>동의</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-6"
                                            v-model="applicantInfo.privateAgreeYn"
                                            value="0"
                                        />
                                        <span>거부</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>개인정보 제3자 이용 동의</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-7"
                                            v-model="applicantInfo.privateAgreeYn2"
                                            value="1"
                                        />
                                        <span>동의</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-7"
                                            v-model="applicantInfo.privateAgreeYn2"
                                            value="0"
                                        />
                                        <span>거부</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>접수현황</th>
                            <td>
                                <div v-if="applicantInfo.applicantStateCode == 0">
                                    접수
                                    <button @click="cancelApplicant()" class="upload2" style="width: 80px; height: 33px; margin-left: 10px; margin-bottom: 4px;">취소 처리</button>
                                </div>
                                <div v-if="applicantInfo.applicantStateCode == 1">
                                    취소되었습니다.
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            
            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/applicant',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgNavy"
                        @click="updateApplicant()"
                    >
                        저장
                    </button>
                    <button
                        class="radiusSmallBtn bgDark"
                        @click="deleteApplicant()"
                    >
                        삭제
                    </button>
                </div>
            </div>

        </div>

        

    </div>
</template>

<script>
import axios from "axios";
import "tui-date-picker/dist/tui-date-picker.min.css";
import EventBus from "../../script/EventBus";

export default {
    name: "ApplicantEdit", 
    data() {
        return {
            siteNo: "",
            applicantNo: "", 
            applicantInfo: {
                applicantOrganization : "",
                address1 : "",
                address2 : "",
                applicantUserName : "",
                applicantBirthDt : "",
                applicantTelNo : "",
                applicantHpNo : "",
                applicantPassword : "",
                privateAgreeYn : "",
                privateAgreeYn2 : "",
                applicantStateCode : "",
                applicantEmail: "",
            },

            applicantPasswordChk : "",
        };
    },
    components: {
      
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.applicantNo = this.$route.params.applicantNo;
        EventBus.$on("addFile", (fileList) => {
            this.logger.debug(this, "eventBus / addFile()");
            this.addFile(fileList);
        });

        this.selectApplicantInfo();

    },
    mounted() {
      let scriptTag = document.createElement('script');
      scriptTag.setAttribute('src', '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js');
      document.head.appendChild(scriptTag);
    },
    methods: {
        selectApplicantInfo() {
            this.logger.debug(this, "selectApplicant()");
            var searchData = {applicantNo : this.applicantNo};
            axios
                .post(
                    this.config.contextRoot + "/applicant/selectApplicant.do",
                    searchData
                )
                .then((response) => {
                    this.applicantInfo = response.data.applicantInfo;
                    this.applicantInfo.applicantPassword = "";
                    this.applicantPasswordChk = "";
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        updateApplicant() {
            this.logger.debug(this, "insertApplicant()");

            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }else if(this.applicantInfo.applicantPassword != this.applicantPasswordChk){
                        console.log(this.applicantInfo.applicantPassword);
                        console.log(this.applicantPasswordChk);
                         alert(
                            "비밀번호/비밀번호확인이 일치하는지 확인해주세요."
                        );       
                    }else{
                        if (!confirm("수정 하시겠습니까?")) {
                            return;
                        }

                        axios
                            .post(
                                this.config.contextRoot +
                                    "/applicant/updateApplicant.do",
                                this.applicantInfo
                            )
                            .then((response) => {
                                alert("수정 되었습니다.");
                                this.applicantNo = response.data.applicantNo;
                                this.selectApplicantInfo();
                            })
                            .catch((error) => {
                                this.exceptionHandler(this, error);
                            });
                    }

                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.error.all());
                    return false;
                });
        },
        deleteApplicant() {
            if (!confirm("삭제 하시겠습니까?")) {
                return;
            }

            const formData = {
                deleteApplicantNoList: [this.applicantNo],
            };
            axios
                .post(
                    this.config.contextRoot + "/applicant/deleteApplicantList.do",
                    formData
                )
                .then((response) => {
                    if (response.status == 200) {
                        alert("삭제 되었습니다.");
                        this.$router.replace(
                            this.config.contextRoot +
                                "/site/" +
                                this.siteNo +
                                "/applicant"
                        );
                    }
                })
                .catch((error) => {
                    if (
                        error.response.data.message ==
                        "SQLIntegrityConstraintViolationException"
                    ) {
                        alert(
                            "해당 컨텐츠는 메뉴관리에서 사용 중이므로 삭제할 수 없습니다."
                        );
                        return;
                    }

                    this.exceptionHandler(this, error);
                });
        },

        openUploader(refName) {
            this.logger.debug(this, "openUploader()");
            this.$refs[refName].openUploader();
        },
        doDownload(data) {
            this.logger.debug(this, "doDownload");
            const formData = new FormData();
            formData.append("fileNo", data.fileNo);
            formData.append("fileName", data.fileName);
            formData.append("serverFileSaveName", data.serverFileSaveName);
            axios
                .post(
                    this.config.contextRoot + "/common/file/download.do",
                    formData,
                    { responseType: "blob" }
                )
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: response.headers["content-type"],
                        })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", data.fileName);
                    document.body.appendChild(link);
                    link.click();
                    location.reload();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        deleteFile(index) {
            this.applicantInfo.fileList.splice(index, 1);
        },
        addFile(fileList) {
            this.applicantInfo.fileList =
                this.applicantInfo.fileList.concat(fileList);
        },
        yyyyMMdd(value){
            // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
            if(value == '') return '';

            // 연도, 월, 일 추출
            var year = value.substring(0, 4);
            var month= value.substring(4, 6);
            var day = value.substring(6, 8);

            // 최종 포맷 (ex - '2021-10-08')
            return year + '-' + month+ '-' + day;
        },
        selectAddress(){
            new window.daum.Postcode({
                oncomplete: (data) => {
                    let selectAdress = data.address;
                    let additionalAdress = "";
                    if(data.bname){
                        additionalAdress = data.bname;
                    }
                    if(data.buildingName){
                        if(additionalAdress){
                            additionalAdress = additionalAdress + ", " + data.buildingName;
                        }else{
                            additionalAdress = data.buildingName;
                        }
                    }
                    if(additionalAdress){
                        selectAdress = selectAdress + " (" + additionalAdress + ")";
                    }

                    this.applicantInfo.address1 = selectAdress;
                }
            }).open();
        },
        cancelApplicant(){
            if (!confirm("취소하시겠습니까? 다시 변경할 수 없습니다.\n다시 신청해야할 경우 재신청하는 것으로 유도하십시오.")) {
                return;
            }

            axios
                .post(
                    this.config.contextRoot +
                        "/applicant/cancelApplicant.do",
                    {applicantNo : this.applicantNo}
                )
                .then((response) => {
                    alert("취소저리 되었습니다.");
                    this.applicantNo = response.data.applicantNo;
                    this.selectApplicantInfo();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
    },
};
</script>

<style>
.upload2 {
    top: 0;
    right: 0;
    width: 40px;
    height: 28px;
    background-color: #3b80e0;
    font-size: 14px;
    color: #fff;
}
</style>