<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">메뉴관리</h2>
        </div>
        <div class="contentBody">
            <h3 class="contentTitle">메뉴 등록</h3>
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
                <table class="infoTable tableFixed">
                    <tbody>
                        <tr>
                            <th class="requiredMark">메뉴명</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="menuInfo.menuName"
                                    v-validate="'required|max:30'"
                                    data-vv-name="menuName"
                                    data-vv-as="메뉴명"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('menuName')"
                                >
                                    * {{ errors.first("menuName") }}
                                </p>
                            </td>
                            <th>컨텐츠</th>
                            <td>
                                <select
                                    class="selectBox"
                                    v-model="menuInfo.contentsNo"
                                    v-validate="''"
                                    data-vv-name="contentsNo"
                                    data-vv-as="컨텐츠"
                                >   
                                    <option
                                        :key="null"
                                        :value="null">
                                        컨텐츠 없음
                                    </option>
                                    <option
                                        v-for="contents in contentsList"
                                        :key="contents.contentsNo"
                                        :value="contents.contentsNo"
                                    >
                                        {{ contents.contentsTitle }}
                                    </option>
                                </select>
                                <p
                                    class="validation-text"
                                    v-if="errors.has('contentsNo')"
                                >
                                    * {{ errors.first("contentsNo") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">메뉴타입</th>
                            <td>
                                <select
                                    class="selectBox"
                                    v-model="menuInfo.menuTypeCode"
                                    v-validate="'required'"
                                    data-vv-name="menuTypeCode"
                                    data-vv-as="메뉴타입"
                                >
                                    <option
                                        v-for="menuType in menuTypeList"
                                        :key="menuType.comCode"
                                        :value="menuType.comCode"
                                    >
                                        {{ menuType.codeName }}
                                    </option>
                                </select>
                                <p
                                    class="validation-text"
                                    v-if="errors.has('codeName')"
                                >
                                    * {{ errors.first("codeName") }}
                                </p>
                            </td>
                            <th class="requiredMark">상위메뉴</th>
                            <td>
                                <select
                                    class="selectBox"
                                    v-model="menuInfo.upperMenuNo"
                                    v-validate="'required'"
                                    data-vv-name="upperMenuNo"
                                    data-vv-as="상위메뉴"
                                >
                                    <option
                                        v-show="menuNo != menu.menuNo"
                                        v-for="menu in menuList"
                                        :key="menu.menuNo"
                                        :value="menu.menuNo"
                                    >
                                        {{ menu.menuName }}
                                    </option>
                                </select>
                                <p
                                    class="validation-text"
                                    v-if="errors.has('upperMenuNo')"
                                >
                                    * {{ errors.first("upperMenuNo") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>정렬순서</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox size-100"
                                    v-model="menuInfo.menuSortOrder"
                                    v-validate="'required|numeric'"
                                    data-vv-name="menuSortOrder"
                                    data-vv-as="정렬순서"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('menuSortOrder')"
                                >
                                    * {{ errors.first("menuSortOrder") }}
                                </p>
                            </td>
                            <th>게시일시</th>
                            <td>{{ menuInfo.menuPublishDate }}</td>
                        </tr>
                        <tr>
                            <th>레이아웃</th>
                            <td>
                                <select
                                    class="selectBox"
                                    v-model="menuInfo.layoutNo"
                                    v-validate="'required'"
                                    data-vv-name="layoutNo"
                                    data-vv-as="레이아웃"
                                >
                                    <option
                                        v-for="layout in layoutList"
                                        :key="layout.layoutNo"
                                        :value="layout.layoutNo"
                                    >
                                        {{ layout.layoutName }}
                                    </option>
                                </select>
                                <p
                                    class="validation-text"
                                    v-if="errors.has('layoutNo')"
                                >
                                    * {{ errors.first("layoutNo") }}
                                </p>
                            </td>
                            <th>생성일자</th>
                            <td>{{ menuInfo.menuAddDt }}</td>
                        </tr>
                        <tr>
                            <th class="inputBtnWrap">링크주소</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="menuInfo.menuLinkAddress"
                                    v-validate="'max:500'"
                                    data-vv-name="menuLinkAddress"
                                    data-vv-as="링크주소"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('menuLinkAddress')"
                                >
                                    * {{ errors.first("menuLinkAddress") }}
                                </p>
                            </td>
                            <th class="requiredMark">게시여부</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-3"
                                            v-model="menuInfo.menuPublishYn"
                                            value="Y"
                                            v-validate="'required'"
                                            data-vv-name="menuPublishYn"
                                            data-vv-as="게시여부"
                                        />
                                        <span>사용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-3"
                                            v-model="menuInfo.menuPublishYn"
                                            value="N"
                                        />
                                        <p
                                            class="validation-text"
                                            v-if="errors.has('menuPublishYn')"
                                        >
                                            *
                                            {{ errors.first("menuPublishYn") }}
                                        </p>
                                        <span>미사용</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="inputBtnWrap"></th>
                            <td>

                            </td>
                            <th class="requiredMark">탑메뉴여부</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-4"
                                            v-model="menuInfo.navigateYn"
                                            value="Y"
                                            v-validate="'required'"
                                            data-vv-name="navigateYn"
                                            data-vv-as="탑메뉴여부"
                                        />
                                        <span>사용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-4"
                                            v-model="menuInfo.navigateYn"
                                            value="N"
                                        />
                                        <p
                                            class="validation-text"
                                            v-if="errors.has('navigateYn')"
                                        >
                                            *
                                            {{ errors.first("navigateYn") }}
                                        </p>
                                        <span>미사용</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="contentTitleWrap marginTop-35">
                <h3 class="contentTitle">권한목록</h3>
                <div class="titleBtnWrap">
                    <label>
                        <input
                            type="checkbox"
                            name="hierarchyYN"
                            v-model="hierarchyYN"
                            true-value="Y"
                            false-value="N"
                        /> 계층별권한부여
                    </label>
                    <button class="squareBtn bgLightGray" @click="openPopup()">
                        권한관리
                    </button>
                </div>
            </div>
            <div class="listWrap">
                <!-- 목록테이블 -->
                <table class="listTable">
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>권한이름</th>
                            <th>접근 권한부여</th>
                            <th>게시글쓰기 권한부여</th>
                            <th>댓글쓰기 권한부여</th>
                            <th>공지사항쓰기 권한부여</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(role, roleIdx) in roleList" :key="role.roleNo">
                            <td>{{ role.rowNumber }}</td>
                            <td>{{ role.roleName }}</td>
                            <td>
                                <label
                                    ><input
                                        type="checkbox"
                                        name="pageReadRole"
                                        v-model="role.pageReadRoleYn"
                                        true-value="Y"
                                        false-value="N"
                                        @click="checkRolesByHierarchy(roleIdx, 'pageReadRoleYn')"
                                /></label>
                            </td>
                            <td>
                                <label
                                    ><input
                                        type="checkbox"
                                        name="articleWriteRole"
                                        v-model="role.articleWriteRoleYn"
                                        :disabled="!role.pageReadRoleYn || role.pageReadRoleYn == 'N'"
                                        true-value="Y"
                                        false-value="N"
                                        @click="checkRolesByHierarchy(roleIdx, 'articleWriteRoleYn')"
                                /></label>
                            </td>
                            <td>
                                <label
                                    ><input
                                        type="checkbox"
                                        name="commentWriteRole"
                                        v-model="role.commentWriteRoleYn"
                                        :disabled="!role.pageReadRoleYn || role.pageReadRoleYn == 'N'"
                                        true-value="Y"
                                        false-value="N"
                                        @click="checkRolesByHierarchy(roleIdx, 'commentWriteRoleYn')"
                                /></label>
                            </td>
                            <td>
                                <label
                                    ><input
                                        type="checkbox"
                                        name="noticeWriteRole"
                                        v-model="role.noticeWriteRoleYn"
                                        :disabled="!role.pageReadRoleYn || role.pageReadRoleYn == 'N'"
                                        true-value="Y"
                                        false-value="N"
                                        @click="checkRolesByHierarchy(roleIdx, 'noticeWriteRoleYn')"
                                /></label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/menu',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button class="radiusSmallBtn bgNavy" @click="insertMenu()">
                        저장
                    </button>
                    <button class="radiusSmallBtn bgDark" @click="reset()">
                        취소
                    </button>
                </div>
            </div>

            <!-- 권한관리 팝업 -->
            <RolePopup v-if="openPopupStatus" />
        </div>
    </div>
</template>

<script>
import RolePopup from "@/components/role/RolePopup";
import axios from "axios";
import EventBus from "@/script/EventBus.js";
export default {
    name: "MenuCreate",
    components: {
        RolePopup,
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.menuNo = this.$route.params.menuNo;
        this.selectData();

        EventBus.$on("popupOpenStatus", () => {
            this.openPopupStatus = false;
        });
    },
    data() {
        return {
            siteNo: "",
            contentsList: [],
            menuList: [],
            layoutList: [],
            menuTypeList: [],
            roleList: [],
            menuInfo: {},
            openPopupStatus: false,

            hierarchyYN: "Y",
        };
    },
    methods: {
        openPopup() {
            this.openPopupStatus = true;
        },
        initData() {
            this.menuInfo.upperMenuNo = this.$route.query.upperMenuNo;
            this.menuInfo.menuSortOrder = "0";
            this.menuInfo.layoutNo =
                this.layoutList.length > 0 ? this.layoutList[0].layoutNo : null;
            this.menuInfo.menuPublishYn = "Y";
            this.menuInfo.menuTypeCode =
                this.menuTypeList.length > 0
                    ? this.menuTypeList[0].comCode
                    : null;
        },
        selectData() {
            axios
                .post(this.config.contextRoot + "/menu/selectData.do", {
                    siteNo: this.siteNo,
                })
                .then((response) => {
                    this.roleList = response.data.roleList;
                    this.layoutList = response.data.layoutList;
                    this.menuTypeList = response.data.menuTypeList;
                    this.menuList = response.data.menuList;
                    this.contentsList = response.data.contentsList;
                    this.initData();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        insertMenu() {
            if (!confirm("등록 하시겠습니까?")) {
                return;
            }

            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }
                    
                    this.menuInfo.roleList = [];
                    for (let role of this.roleList) {
                        if (role.pageReadRoleYn == 'Y') {
                            this.menuInfo.roleList.push({
                                articleWriteRoleYn: role.articleWriteRoleYn,
                                commentWriteRoleYn: role.commentWriteRoleYn,
                                noticeWriteRoleYn: role.noticeWriteRoleYn,
                                roleNo: role.roleNo,
                            });
                        }
                    }

                    this.menuInfo.siteNo = this.siteNo;

                    axios
                        .post(
                            this.config.contextRoot + "/menu/insertMenu.do",
                            this.menuInfo
                        )
                        .then((response) => {
                            console.log("response====>", response);
                            if (response.status == 200) {
                                alert("등록되었습니다.");
                                this.$router.replace(
                                    this.config.contextRoot +
                                        "/site/" +
                                        this.siteNo +
                                        "/menu/" +
                                        response.data.menuNo +
                                        "/edit"
                                );
                            }
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });
                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.errors.all());
                    return false;
                });
        },
        reset() {
            if (confirm("취소 하시겠습니까?")) {
                this.$router.go(this.$router.currentRoute);
            }
        },
        checkRolesByHierarchy(roleIdx, roleTypeName){
            if(this.hierarchyYN == 'N') return;

            // click시 oldValue를 가져오는듯 함 -> (현재 값은 Y/N이 반대이므로 바꿔서 사용)
            let checkYN = this.roleList[roleIdx][roleTypeName] == 'N' ||  this.roleList[roleIdx][roleTypeName] == undefined ? 'Y' : 'N';

            if(checkYN == 'Y'){
                for(let role in this.roleList){
                    if(role >= roleIdx){
                        this.$set(
                            this.roleList[role],
                            roleTypeName,
                            'Y'
                        );
                    } 
                }
            }
            if(checkYN == 'N'){
                for(let role in this.roleList){
                    if(role <= roleIdx){
                        this.$set(
                            this.roleList[role],
                            roleTypeName,
                            'N'
                        );
                    } 
                }
            }
            
        }
    },
};
</script>

<style>
</style>