<template>
	<div class="contentLayout">
		<div class="contentNavigationBar">
			<h2 class="title">게시판</h2>
		</div>
		<div class="contentBody">
			<h3 class="contentTitle">게시판 수정</h3>

			<!-- 입력 폼 -->
			<div class="infoWrap">
				<p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
				<table class="infoTable">
					<tbody>
						<tr>
							<th class="requiredMark">게시판 명</th>
							<td>
								<input
									type="text"
									class="inputBox"
									v-model="boardInfo.contentsTitle"
									v-validate="'required'"
									data-vv-name="contentsTitle"
									data-vv-as="게시판명"
								/>
								<p class="validation-text" v-if="errors.has('contentsTitle')">
									* {{ errors.first("contentsTitle") }}
								</p>
							</td>
						</tr>
						<tr>
							<th class="requiredMark">스킨선택</th>
							<td>
								<div class="inputBtnWrap">
									<select class="selectBox" v-model="boardInfo.skinNo">
										<option v-for="skin in skinList" :key="skin.skinNo" :value="skin.skinNo">
											{{ skin.skinName }}
										</option>
									</select>
									<router-link
										:to="{
											path: config.contextRoot + '/site/' + $route.params.siteNo + '/skin',
										}"
										class="squareBtn bgLightGray"
									>
										스킨 관리
									</router-link>
								</div>
							</td>
						</tr>
						<tr>
							<th>생성일자</th>
							<td>{{ boardInfo.contentsAddDt }}</td>
						</tr>
						<tr>
							<th class="requiredMark">공지기능</th>
							<td>
								<div class="radioBoxWrap">
									<label>
										<input
											type="radio"
											class="radioBox"
											name="testRadio-6"
											v-model="boardInfo.boardNoticeUseYn"
											value="Y"
										/>
										<span>사용</span>
									</label>
									<label>
										<input
											type="radio"
											class="radioBox"
											name="testRadio-6"
											v-model="boardInfo.boardNoticeUseYn"
											value="N"
										/>
										<span>미사용</span>
									</label>
								</div>
							</td>
						</tr>
						<tr>
							<th class="requiredMark">댓글기능</th>
							<td>
								<div class="radioBoxWrap">
									<label>
										<input
											type="radio"
											class="radioBox"
											name="testRadio-8"
											v-model="boardInfo.boardCommentUseYn"
											value="Y"
										/>
										<span>사용</span>
									</label>
									<label>
										<input
											type="radio"
											class="radioBox"
											name="testRadio-8"
											v-model="boardInfo.boardCommentUseYn"
											value="N"
										/>
										<span>미사용</span>
									</label>
								</div>
							</td>
						</tr>
						<tr>
							<th class="requiredMark">사용여부</th>
							<td>
								<div class="radioBoxWrap">
									<label>
										<input
											type="radio"
											class="radioBox"
											name="testRadio-9"
											v-model="boardInfo.useYn"
											value="Y"
										/>
										<span>사용</span>
									</label>
									<label>
										<input
											type="radio"
											class="radioBox"
											name="testRadio-9"
											v-model="boardInfo.useYn"
											value="N"
										/>
										<span>미사용</span>
									</label>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- 하단 버튼 바 -->
			<div class="contentButtonBar">
				<div class="buttonWrap floatLeft">
					<router-link
						:to="{
							path: config.contextRoot + '/site/' + $route.params.siteNo + '/board',
						}"
						class="radiusSmallBtn bgGray"
					>
						목록
					</router-link>
				</div>
				<div class="buttonWrap floatRight">
					<button class="radiusSmallBtn bgNavy" @click="insertBoard()">저장</button>
					<button class="radiusSmallBtn bgDark" @click="deleteBoard()">삭제</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
	name: "BoardCreate",
	data() {
		return {
			siteNo: "",
			skinList: [],
			boardInfo: {
				contentsNo: "",
				contentsTitle: "",
				skinNo: "",
				useYn: "Y",
				boardCommentUseYn: "N",
				boardNoticeUseYn: "N",
				boardRecruitmentUseYn: "N",
			},
		};
	},
	created() {
		this.siteNo = this.$route.params.siteNo;
		this.selectSkinList();
		this.selectBoard();
	},
	methods: {
		selectBoard() {
			this.logger.debug(this, "selectBoard()");
			this.boardNo = this.$route.params.boardNo;
			const formData = {
				contentsNo: this.boardNo,
			};
			axios
				.post(this.config.contextRoot + "/board/selectBoard.do", formData)
				.then((response) => {
					this.boardInfo = response.data.boardInfo;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectSkinList() {
			this.logger.debug(this, "selectSkinList()");

			axios
				.post(this.config.contextRoot + "/board/selectSkinList.do")
				.then((response) => {
					this.skinList = response.data.skinList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		insertBoard() {
			this.logger.debug(this, "insertBoard()");

			console.log(this.boardInfo.boardNoticeUseYn);
			if (this.boardInfo.boardNoticeUseYn == "N") {
				this.boardInfo.boardRecruitmentUseYn = "N";
			}

			if (!confirm("등록 하시겠습니까?")) {
				return;
			}

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "valid:" + result);
					if (!result) {
						alert("정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요.");
						return;
					}

					this.boardInfo.siteNo = this.siteNo;

					axios
						.post(this.config.contextRoot + "/board/updateBoard.do", this.boardInfo)
						.then((response) => {
							if (response) {
								alert("수정 되었습니다.");
								this.$router.replace(this.config.contextRoot + "/site/" + this.siteNo + "/board/");
							}
						})
						.catch((error) => {
							this.exceptionHandler(this, error);
						});
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.error.all());
					this.$alert(this.error.all());
					return false;
				});
		},

		deleteBoard() {
			if (confirm("삭제 하시겠습니까?")) {
				axios
					.post(this.config.contextRoot + "/board/deleteBoard.do", this.boardInfo)
					.then((response) => {
						if (response) {
							alert("삭제 되었습니다.");
							this.$router.replace(this.config.contextRoot + "/site/" + this.siteNo + "/board/");
						}
					})
					.catch((error) => {
						if (error.response.data.message == "SQLIntegrityConstraintViolationException") {
							alert("해당 게시판은 메뉴관리에서 사용 중이므로 삭제할 수 없습니다.");
							return;
						}

						this.exceptionHandler(this, error);
					});
			}
		},
	},
};
</script>

<style></style>
