import CKEditorUploadAdapter from "@/script/CKEditorUploadAdapter";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function SimpleUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new CKEditorUploadAdapter(loader);
    };
}

class CKEditorConfig {
    constructor(target = "#ckeditor", onCreate = () => {
    }) {
        this.target = target;

        this.onCreate = onCreate;
    }

    create() {
        return ClassicEditor.create(document.querySelector(this.target), {
            licenseKey: '',
            extraPlugins: [SimpleUploadAdapterPlugin],
        }).then(editor => {
            window.editor = editor;

            this.onCreate();

        }).catch(error => {
            console.error(error);
        });
    }
}

export default CKEditorConfig