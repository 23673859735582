<template>
	<div class="contentLayout">
		<div class="contentNavigationBar">
			<h2 class="title">만족도조사 관리</h2>
		</div>
		<div class="contentBody">
			<!-- 검색 -->
			<div class="searchBar">
				<div class="columnDivision-3">
					<div class="labelWrap">
						<label style="width:120px;">만족도조사구분</label>
						<label>
							<select
								style="width: 180px"
								class="selectBox"
								v-model="searchForm.surveyDivCode"
							>
								<option value="">-- 전체 --</option>
								<option value="1">모유수유담당자교육</option>
								<option value="2">만족도조사지원</option>
							</select>
						</label>
					</div>
					<div class="labelWrap">
						<label style="width:100px;">만족도조사명</label>
						<input
							type="text"
							class="inputBox"
							v-model="searchForm.surveyName"
							style="width:300px;"
						/>
					</div>
				</div>
				<button class="searchBtn" @click="doSearchPage(1)">
					조회하기
				</button>
			</div>

			<div class="contentTitleWrap marginTop-35">
				<h3 class="contentTitle">만족도조사 목록</h3>
			</div>
			<div class="listWrap">
				<!-- 목록 상단 -->
				<div class="listTop">
					<span class="listTotal"
						>(Total <em>{{ pagination.total }}</em
						>)</span
					>
					<select
						class="selectBox"
						@change.prevent="doSearchPage(1)"
						v-model="searchForm.countPerPage"
					>
						<option>5</option>
						<option selected>10</option>
						<option>20</option>
						<option>30</option>
						<option>50</option>
						<option>100</option>
					</select>
				</div>

				<!-- 목록테이블 -->
				<table class="listTable tableFixed">
					<colgroup>
						<col style="width: 5%" />
						<col style="width: 7%" />
						<col style="width: 11%" />
						<col style="width: 15%" />
						<col style="width: 10%" />
						<col style="width: 10%" />
						<col style="width: 10%" />
						<col
							style="width: 20%"
							v-if="searchForm.surveyDivCode != '2'"
						/>
					</colgroup>
					<thead>
						<tr>
							<th>
								<label>
									<input
										type="checkbox"
										@click="doCheckAll"
										v-model="allChecked"
									/>
								</label>
							</th>
							<th>만족도조사번호</th>
							<th>만족도조사구분</th>
							<th>만족도조사 명</th>
							<th>만족도조사시작일</th>
							<th>만족도조사종료일</th>
							<th>만족도조사완료횟수<br/>(조사결과 Excel)</th>
							<th v-if="searchForm.surveyDivCode != '2'">
								교육프로그램
							</th>
						</tr>
					</thead>
					<tbody
						v-for="row in surveyList"
						:key="row.surveyNo"
						@click="
							$router.push({
								path:
									config.contextRoot +
									'/site/' +
									$route.params.siteNo +
									'/survey/' +
									row.surveyNo +
									'/edit',
							})
						"
					>
						<tr>
							<td @click.stop="">
								<label
									><input
										type="checkbox"
										v-model="checkedList"
										:value="row.surveyNo"
									/>
								</label>
							</td>
							<td>{{ row.surveyNo }}</td>
							<td>
								{{
									row.surveyDivCode == "1"
										? "모유수유담당자교육"
										: "만족도조사지원"
								}}
							</td>
							<td>{{ row.surveyName }}</td>
							<td>
								{{
									row.surveyStartDt.substr(0, 4) +
									"-" +
									row.surveyStartDt.substr(4, 2) +
									"-" +
									row.surveyStartDt.substr(6, 2)
								}}
							</td>
							<td>
								{{
									row.surveyEndDt.substr(0, 4) +
									"-" +
									row.surveyEndDt.substr(4, 2) +
									"-" +
									row.surveyEndDt.substr(6, 2)
								}}
							</td>
							<td>
								<button
									class="radiusSmallBtn bgBlue"
									@click="downloadExcel(row)"
									style="margin-left:10px;"
									@click.stop=""
								>
								{{ row.answerCount }}
								</button>
							</td>
							<td v-if="searchForm.surveyDivCode != '2'">
								{{ row.programName }}
							</td>
						</tr>
					</tbody>
				</table>

				<!-- 페이지네이션 -->
				<pagination
					v-if="pagination.total > pagination.per_page"
					v-model="pagination.current_page"
					:records="pagination.total"
					:per-page="pagination.per_page"
					@paginate="doSearchPage"
					:options="{
						texts: {
							count: '',
							first: '',
							last: '',
						},
					}"
				/>
			</div>

			<!-- 하단 버튼 바 -->
			<div class="contentButtonBar">
				<div class="buttonWrap floatLeft">
					<router-link
						:to="{
							path: config.contextRoot + '/site/1/survey/create',
						}"
						class="radiusSmallBtn bgBlue"
					>
						신규
					</router-link>
				</div>
				<div class="buttonWrap floatRight">
					<button
						class="radiusSmallBtn bgTuftsBlue"
						@click.prevent="deleteCheckedList()"
					>
						선택 삭제
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import Pagination from "vue-pagination-2";

export default {
	name: "SurveyList",
	components: { Pagination },
	data() {
		return {
			siteNo: "",
			searchForm: {
				countPerPage: 10,
				pageNo: 1,
				surveyDivCode: "",
				surveyName: "",
			},
			surveyList: [],
			pagination: {},
			allChecked: false,
			checkedList: [],
		};
	},
	created() {
		this.logger.debug(this, "created()");
		this.siteNo = this.$route.params.siteNo;
		this.selectList();
	},
	watch: {
		checkedList() {
			if (this.surveyList.length != this.checkedList.length) {
				this.allChecked = false;
			} else {
				this.allChecked = true;
			}
		},
	},
	mounted() {},
	methods: {
		selectList() {
			this.logger.debug(this, "selectList()");
			axios
				.post(
					this.config.contextRoot + "/survey/selectList.do",
					this.searchForm
				)
				.then((response) => {
					this.surveyList = response.data.surveyList;
					this.pagination = response.data.pagination;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSearchPage(pageNo) {
			this.logger.debug(this, "doSearchPage()");

			if (typeof pageNo === undefined) {
				pageNo = 1;
			}
			this.checkedList = [];
			this.searchForm.pageNo = pageNo;
			this.selectList();
		},
		doCheckAll() {
			this.checkedList = [];

			if (!this.allChecked && this.surveyList.length > 0) {
				for (let row of this.surveyList) {
					this.checkedList.push(row.surveyNo);
				}
			}

			this.allChecked = !this.allChecked;
		},
		deleteCheckedList() {
			if (this.checkedList.length == 0) {
				alert("삭제항목이 선택되지 않았습니다.");
				return;
			}
			
			// 만족도조사 답변있는지 체크
			let delDenyList = [];
			let checkedList = this.checkedList;
			this.surveyList.forEach(function(row){
				if(checkedList.indexOf(row.surveyNo) != -1 && row.answerCount > 0){
					delDenyList.push(row);
					console.log(delDenyList);
				}
			})

			if(delDenyList.length > 0){
				let temp = "";
				delDenyList.forEach(function(row){
					if(temp) temp += "\n";
					temp += "  " + row.surveyNo + " - " + row.surveyName;
				})
				alert(" 다음 만족도조사는 답변이 존재해 삭제할 수 없습니다. \n" 
					+ "  (ex. 만족도조사번호 - 만족도조사명)\n\n" + temp);
				return;
			}

			if (!confirm("선택한 항목을 삭제 하시겠습니까?")) {
				return;
			}

			const formData = {
				checkedList: this.checkedList,
			};

			axios
				.post(
					this.config.contextRoot + "/survey/deleteList.do",
					formData
				)
				.then((response) => {
					if (response.status == 200) {
						alert("삭제 되었습니다.");
						this.checkedList = [];
						this.doSearchPage(1);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		downloadExcel(row){
            axios
                .post(
                    this.config.contextRoot + "/survey/downloadExcel.do",
                    {surveyNo: row.surveyNo},
                    { responseType: 'blob'},
                )
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', row.surveyName.replaceAll(' ', '_') + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
	},
};
</script>
