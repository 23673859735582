<template>
  <!-- 엑셀업로드 모달창 -->
  <div class="popup-container" :style="[getSize(), drag.position]">
    <div
        class="popup-top-wrap"
        @dragstart="dragstart($event)"
        @drag="drag.isDrag ? dragging($event) : ''"
        @dragend="drag.isDrag ? draggend() : ''"
        draggable>
			<span>
				<span>수유실 수정 내역</span>
			</span>
      <button type="button" @click="close()"></button>
    </div>
    <div class="popup-center-wrap">
      <p style="color: red; text-align: right; margin-top: 20px;">* 일시를 선택해주세요.</p>
      <div class="recruitBox" style="margin-top: 0;">
        <div class="searchBox" style="width: 100%;">
          <div class="searchWrap">
            <div class="columnDivision-2">
              <div class="labelWrap">
                <select class="selectBox" v-model="searchForm.registYear" @change="changeLogNo();">
                  <option ref="optionNull" value="" selected>-- 전체 --</option>
                  <option v-for="(item, index) in nursingroomLogYearList" :key="index" :value="item">
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="labelWrap" style="margin-top: 0px;">
                <select class="selectBox" v-model="selectedIndex" @change="changeLogNo();">
                  <option ref="optionNull" value="" selected>-- 선택 --</option>
                  <option v-for="(item, index) in $options.filters.yearFilter(nursingroomLogList, searchForm.registYear)" :key="index" :value="index">
                    {{ item.registDate }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 목록 상단 -->
      <div class="">
        <div class="buttonWrap" style="text-align:right; margin-top: 15px;">
          <button class="radiusSmallBtn bgBlue" style="width:100px;" @click="downloadLog();">엑셀 다운로드</button>
        </div>
      </div>

      <div class="recruitBox flexColumn" v-show="typeof selectedIndex === 'number'">
        <!-- 기관 현황 -->
        <div class="infoWrap">
          <label>
            <span>기관 현황</span>
          </label>
          <table class="infoTable marginBottom-35 marginTop-15">
            <tbody>
            <tr>
              <th class="">기관명</th>
              <td colspan="2">
                <input
                    type="text"
                    class="inputBox"
                    style="margin-right: 10px;"
                    v-model="nursingroomInfo.roomName"
                />
              </td>
            </tr>
            <tr>
              <th class="">주소</th>
              <td colspan="2">
                <input
                    type="text"
                    class="inputBox"
                    style="margin-right: 10px;"
                    v-model="nursingroomInfo.address"
                />
              </td>
            </tr>
            <tr>
              <th>위도</th>
              <td colspan="2">
                <input
                    type="text"
                    class="inputBox"
                    v-model="nursingroomInfo.gpsLat"
                />
              </td>
            </tr>
            <tr>
              <th>경도</th>
              <td colspan="2">
                <input
                    type="text"
                    class="inputBox"
                    v-model="nursingroomInfo.gpsLong"
                />
              </td>
            </tr>
            <tr>
              <th>기관구분</th>
              <td colspan="2">
                <label>
                  <input
                      type="radio"
                      class="radioBox"
                      v-model="nursingroomInfo.organizationCode"
                      value="1"
                  />
                  <span>  청사</span>
                </label>
                <label>
                  <input
                      type="radio"
                      class="radioBox"
                      v-model="nursingroomInfo.organizationCode"
                      value="2"
                  />
                  <span>  공공기관</span>
                </label>
                <label>
                  <input
                      type="radio"
                      class="radioBox"
                      v-model="nursingroomInfo.organizationCode"
                      value="3"
                  />
                  <span>  교통시설</span>
                </label>
                <label>
                  <input
                      type="radio"
                      class="radioBox"
                      v-model="nursingroomInfo.organizationCode"
                      value="4"
                  />
                  <span>  공중(다중)시설</span>
                </label>
                <label>
                  <input
                      type="radio"
                      class="radioBox"
                      v-model="nursingroomInfo.organizationCode"
                      value="5"
                  />
                  <span>  학교</span>
                </label>
                <label>
                  <input
                      type="radio"
                      class="radioBox"
                      v-model="nursingroomInfo.organizationCode"
                      value="6"
                  />
                  <span>  기업체(민간기관)</span>
                </label>
              </td>
            </tr>
            <tr>
              <th class="">담당자</th>
              <td colspan="2">
                <input
                    type="text"
                    class="inputBox"
                    v-model="nursingroomInfo.managerName"
                />
              </td>
            </tr>
            <tr>
              <th class="">연락처</th>
              <td colspan="2">
                <input
                    type="text"
                    class="inputBox"
                    v-model="nursingroomInfo.managerTelNo"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- 수유시설 상세 -->
        <div>
          <div>
            <div class="infoWrap">
              <label style="display: flex; justify-content: space-between; margin-right: 0px;">
                <span>수유시설 상세</span>
              </label>
              <table class="infoTable marginBottom-35 marginTop-15">
                <tbody>
                <tr>
                  <th>수유실 면적</th>
                  <td colspan="2">
                    <label style="margin-right: 15px;">
                      <input
                          type="number"
                          class="inputBox"
                          style="width: 90px;"
                          v-model="nursingroomInfo.area"
                      />
                    </label>
                    <label>
                      <select
                          id="areaUnit"
                          class="selectBox"
                          style="width: auto;"
                          v-model="nursingroomInfo.areaUnit"
                      >
                        <option value="" selected>단위</option>
                        <option value="1" selected>㎡</option>
                        <option value="2" selected>평</option>
                      </select>
                    </label>
                  </td>
                </tr>
                <!-- TODO.내용추가-->
                <tr>
                  <th>수유시설<br/>설치일</th>
                  <td colspan="2">
                    <div class="datePicker" style="width: 200px">
                      <input
                          type="text"
                          class="inputBox"
                          :id="'installDate'"
                          v-model="nursingroomInfo.installDate"
                          autocomplete="off"
                      />
                      <div></div>
                      <i></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>시설위치</th>
                  <td colspan="2">
                    <input
                        type="text"
                        class="inputBox"
                        v-model="nursingroomInfo.location"/>
                  </td>
                </tr>
                <tr>
                  <th>시설 사진</th>
                  <td colspan="2">
                    <common-file
                        ref="FileUploader"
                        v-model="nursingroomInfo.fileList"
                        :multipleUpload="true"
                        initAccept="image/*"
                    />
                  </td>
                </tr>
                <tr>
                  <th>명패호수</th>
                  <td colspan="2">
                    제
                    <input
                        type="number"
                        class="inputBox"
                        style="width: 110px;"
                        v-model="nursingroomInfo.locationName"
                    />
                    호
                  </td>
                </tr>
                <tr>
                  <th>운영현황</th>
                  <td colspan="2">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.manageConditionCode"
                          value="1"
                      />
                      <span>  운영 중</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.manageConditionCode"
                          value="2"
                      />
                      <span>  일시 중단</span>
                      <input
                          type="text"
                          class="inputBox"
                          style="width: 15%; margin-left: 10px;"
                          v-model="nursingroomInfo.manageConditionDetail2"
                      />
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.manageConditionCode"
                          value="3"
                      />
                      <span>  폐쇄</span>
                      <input
                          type="text"
                          class="inputBox"
                          style="width: 15%; margin-left: 10px;"
                          v-model="nursingroomInfo.manageConditionDetail3"
                      />
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.manageConditionCode"
                          value="5"
                      />
                      <span>  이전</span>
                      <input
                          type="text"
                          class="inputBox"
                          style="width: 15%; margin-left: 10px;"
                          v-model="nursingroomInfo.manageConditionDetail5"
                      />
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.manageConditionCode"
                          value="4"
                      />
                      <span>  기타</span>
                      <input
                          type="text"
                          class="inputBox"
                          style="width: 15%; margin-left: 10px;"
                          v-model="nursingroomInfo.manageConditionDetail4"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <th>용도구분</th>
                  <td colspan="2">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.roomTypeCode"
                          value="4"
                      />
                      <span>  모유수유/착유실</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.roomTypeCode"
                          value="3"
                      />
                      <span>  가족수유실</span>
                    </label>
                    <label style="margin-right: 15px;">1회 이용 가능 인원</label>
                    <label>
                      <input
                          type="number"
                          class="inputBox"
                          style="width: 50px;"
                          v-model="nursingroomInfo.peakCount"
                      />
                      <span>  명</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th class="requiredMark">아빠 이용</th>
                  <td colspan="2">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.fatherUseYn"
                          value="0"
                          data-vv-as="아빠 이용 "
                      />
                      <span>  불가</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.fatherUseYn"
                          value="1"
                          data-vv-as="아빠 이용 "
                      />
                      <span>  가능</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th rowspan="3">이용현황</th>
                  <th class="requiredMark">이용 대상</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.useTypeCode"
                          value="0"
                          data-vv-as="이용대상 "
                      />
                      <span>  직원용</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.useTypeCode"
                          value="1"
                      />
                      <span>  외부인</span>
                    </label>
                    <label style="width: 115px">
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.useTypeCode"
                          value="2"
                      />
                      <span>  외부/직원용</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>일일평균<br>이용자 수</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.useAvgAdayRange"
                          v-validate="''"
                          value="1"
                      />
                      <span>  5명 이하</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.useAvgAdayRange"
                          v-validate="''"
                          value="2"
                      />
                      <span>  6 ~ 10명</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.useAvgAdayRange"
                          v-validate="''"
                          value="3"
                      />
                      <span>  11 ~ 20명</span>
                    </label>
                    <label style="width: 115px">
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.useAvgAdayRange"
                          v-validate="''"
                          value="4"
                      />
                      <span>  21명 이상</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>관리 주기</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePeriodCode"
                          value="5"
                      />
                      <span>  2시간마다</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePeriodCode"
                          value="2"
                      />
                      <span>  1일 2회 이상</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePeriodCode"
                          value="3"
                      />
                      <span>  1일 1회</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePeriodCode"
                          value="6"
                      />
                      <span>  격일</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePeriodCode"
                          value="7"
                      />
                      <span>  1주 2회</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePeriodCode"
                          value="4"
                      />
                      <span>  1주 1회</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePeriodCode"
                          value="8"
                      />
                      <span>  기타</span>
                      <input
                          type="text"
                          class="inputBox"
                          style="width: 20%; margin-left: 10px;"
                          v-model="nursingroomInfo.carePeriodDetail"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <th>조사일</th>
                  <td colspan="2">
                    <div class="datePicker" style="width: 200px">
                      <input
                          type="text"
                          class="inputBox"
                          v-model="nursingroomInfo.surveyDate"
                          autocomplete="off"
                      />
                      <div></div>
                      <i></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>조사지회</th>
                  <td colspan="2">
                    <label>
                      <input
                          type="text"
                          class="inputBox"
                          v-model="nursingroomInfo.surveyOrganizationName"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>조사자</th>
                  <td colspan="2">
                    <label>
                      <input
                          type="text"
                          class="inputBox"
                          v-model="nursingroomInfo.surveyName"
                      />
                    </label>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <!-- 수유시설 관리 점검표 -->
            <div class="infoWrap marginTop-30">
              <label>
                <span>수유시설 관리 점검표</span>
              </label>
              <table class="infoTable marginBottom-35 marginTop-15">
                <tbody>
                <tr>
                  <th rowspan="5">공간</th>
                  <td rowspan="2">위치/안내</td>
                  <td colspan="2">1. 수유실에서 세면대까지 이동 거리는 10m내에 있는가?</td>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint01"
                          value="1"
                      />
                      <span>  그렇다</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint01"
                          value="0"
                      />
                      <span>  아니다</span>
                    </label>
                    <br>
                    <label>
                      <span>실측값:&ensp;</span>
                      <input
                          type="number"
                          class="inputBox"
                          style="width: 30%;"
                          v-model="nursingroomInfo.carePoint01Value"
                      />
                      <span>&ensp;m</span>
                    </label>
                  </td>
                </tr>
                <!-- TODO.내용추가-->
                <tr>
                  <td colspan="2">
                    2. 수유시설 출입구에 시설 용도(모유수유/착유실, 가족수유실)에 따른 이용자 범위 안내 표시판이 부착되어 있는가?
                    <br/>
                    <div style="color:darkgray">*&nbsp;이용범위: 가족수유실은 남성(아빠 등)도 이용</div>
                  </td>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint10"
                          value="1"
                      />
                      <span>  그렇다</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint10"
                          value="0"
                      />
                      <span>  아니다</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>규모</td>
                  <td colspan="2">
                    3. 시설 용도에 따른 기본 공간을 제공하고 있는가?
                    <br/>
                    <div style="color:darkgray">*&nbsp;(참고) 모유수유/착유실 10m², 가족수유실 15m²</div>
                  </td>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint02"
                          value="1"
                      />
                      <span>  그렇다</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint02"
                          value="0"
                      />
                      <span>  아니다</span>
                    </label>
                    <br>
                    <label>
                      <span>실측값:&ensp;</span>
                      <input
                          type="number"
                          class="inputBox"
                          style="width: 30%;"
                          v-model="nursingroomInfo.carePoint02Value"
                      />
                      <span>&ensp;m²</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td rowspan="2">공간구성</td>
                  <td colspan="2">4. 이용자 동선 등 편의를 고려하여 물품이 배치 되어 있는가?</td>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint03"
                          value="1"
                      />
                      <span>  그렇다</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint03"
                          value="0"
                      />
                      <span>  아니다</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">5. 모유수유/착유 공간이 사생활 보호를 위해 잠금장치 또는 공간을 분리(커튼, 가림막)할 수 있도록 되어 있는가?</td>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint04"
                          value="1"
                      />
                      <span>  그렇다</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint04"
                          value="0"
                      />
                      <span>  아니다</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th rowspan="5">환경</th>
                  <td>조명</td>
                  <td colspan="2">1. 수유시설에 적합한 조도인 60~150Lux를 준수하고 있는가?</td>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint05"
                          value="1"
                      />
                      <span> 그렇다</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint05"
                          value="0"
                      />
                      <span> 아니다</span>
                    </label>
                    <br>
                    <label>
                      <span>실측값:&ensp;</span>
                      <input
                          type="number"
                          class="inputBox"
                          style="width: 30%;"
                          v-model="nursingroomInfo.carePoint05Value"
                      />
                      <span>&ensp;Lux</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td rowspan="3">실내온도/공기</td>
                  <td colspan="2">
                    2. 계절별 실내 적정 온도를 유지하고 있는가?
                    <br/>
                    <div style="color:darkgray">*&nbsp;(참고) 봄‧가을 19~23℃, 여름 24~28℃, 겨울 18~20℃</div>
                  </td>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint06"
                          value="1"
                      />
                      <span> 그렇다</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint06"
                          value="0"
                      />
                      <span> 아니다</span>
                    </label>
                    <br>
                    <label>
                      <span>실측값:&ensp;</span>
                      <input
                          type="number"
                          class="inputBox"
                          style="width: 30%;"
                          v-model="nursingroomInfo.carePoint06Value"
                      />
                      <span>&ensp;℃</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    3. 계절별 적정 습도를 유지하고 있는가?
                    <br/>
                    <div style="color:darkgray">*&nbsp;(참고) 봄‧가을 50%, 여름 60%, 겨울 40%</div>
                  </td>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint07"
                          value="1"
                      />
                      <span> 그렇다</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint07"
                          value="0"
                      />
                      <span> 아니다</span>
                    </label>
                    <br>
                    <label>
                      <span>실측값:&ensp;</span>
                      <input
                          type="number"
                          class="inputBox"
                          style="width: 30%;"
                          v-model="nursingroomInfo.carePoint07Value"
                      />
                      <span>&ensp;%</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    4. 환기를 위한 창이나 기계식 환기설비를 갖추고 있는가?
                    <br/>
                    <div style="color:darkgray">*&nbsp;창문, 공기청정기, 환풍기, 기타</div>
                  </td>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint08"
                          value="1"
                      />
                      <span> 그렇다</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint08"
                          value="0"
                      />
                      <span> 아니다</span>
                    </label>
                    <br>
                    <label>
                      <span>품목명:&ensp;</span>
                      <input
                          type="text"
                          class="inputBox"
                          style="width: 30%;"
                          v-model="nursingroomInfo.carePoint08Value"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>소음</td>
                  <td colspan="2">5. 50dB의 실내 소음허용 기준을 준수하고 있는가?</td>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint09"
                          value="1"
                      />
                      <span> 그렇다</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.carePoint09"
                          value="0"
                      />
                      <span> 아니다</span>
                    </label>
                    <br>
                    <label>
                      <span>실측값:&ensp;</span>
                      <input
                          type="number"
                          class="inputBox"
                          style="width: 30%;"
                          v-model="nursingroomInfo.carePoint09Value"
                      />
                      <span>&ensp;dB</span>
                    </label>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <!-- 수유시설 청결 점검표 -->
            <div class="infoWrap marginTop-30">
              <label>
                <span>수유시설 청결 점검표</span>
              </label>
              <table class="infoTable marginBottom-35 marginTop-15">
                <tbody>
                <tr>
                  <th rowspan="12">필수|권장<br/>&nbsp;&nbsp;&nbsp;&nbsp;물품</th>
                  <th>소파(의자)</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint01"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint01"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint01"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint01"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint01"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint01"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint01"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>탁자</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint02"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint02"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint02"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint02"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint02"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint02"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint02"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>기저귀<br/>교환대</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint08"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint08"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint08"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint08"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint08"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint08"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint08"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>가림막<br/>(파티션 등)</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint16"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint16"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint16"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint16"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint16"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint16"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint16"
                      />
                    </label>
                  </td>
                </tr>
                <!-- TODO.내용추가-->
                <tr>
                  <th>기저귀<br/>폐기물통</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint29"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint29"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint29"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint29"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint29"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint29"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint29"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>전자레인지</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint15"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint15"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint15"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint15"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint15"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint15"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint15"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>세면대</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint04"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint04"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint04"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint04"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint04"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint04"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint04"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>유축기</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint10"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint10"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint10"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint10"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint10"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint10"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint10"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>소독기</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint14"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint14"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint14"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint14"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint14"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint14"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint14"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>냉장고</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint09"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint09"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint09"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint09"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint09"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint09"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint09"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>아기의자</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint30"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint30"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint30"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint30"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint30"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint30"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint30"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>환기설비<br/>(공기청정기 등)</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint19"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint19"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint19"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint19"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint19"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint19"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint19"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th rowspan="2">청결상태</th>
                  <th>수유실 내<br/>바닥 오염</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint05"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint05"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint05"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint05"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint05"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint05"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint05"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>수유실 내<br/>불쾌한 냄새</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint06"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint06"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint06"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint06"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint06"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint06"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint06"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th rowspan="10">기타<br/>물품</th>
                  <th>정수기</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint23"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint23"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint23"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint23"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint23"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint23"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint23"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>가습기</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint24"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint24"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint24"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint24"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint24"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint24"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint24"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>제습기</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint25"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint25"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint25"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint25"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint25"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint25"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint25"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>아기침대</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint13"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint13"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint13"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint13"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint13"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint13"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint13"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>풋스툴</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint11"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint11"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint11"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint11"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint11"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint11"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint11"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>수유쿠션</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint07"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint07"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint07"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint07"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint07"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint07"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint07"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>보관함</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint12"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint12"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint12"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint12"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint12"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint12"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint12"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>휴지통</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint26"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint26"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint26"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint26"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint26"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint26"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint26"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>냉/난방기</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint27"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint27"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint27"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint27"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint27"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint27"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint27"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>거울</th>
                  <td colspan="3">
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint03"
                          value="5"
                      />
                      <span> 5점(매우 양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint03"
                          value="4"
                      />
                      <span> 4점(양호)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint03"
                          value="3"
                      />
                      <span> 3점(보통)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint03"
                          value="2"
                      />
                      <span> 2점(미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint03"
                          value="1"
                      />
                      <span> 1점(매우 미흡)</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.cleanPoint03"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                    <label>
                      <input
                          type="text"
                          class="inputBox width-30"
                          v-model="nursingroomInfo.opnionPoint03"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">시설 담당자 인터뷰 등 전달내용</th>
                  <td>
                    <textarea
                        class="inputBox width-30"
                        style="width: 100%; height: 100px;"
                        v-model="nursingroomInfo.managerReviewContents"
                        disabled
                    >
                    </textarea>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <!-- 물품비치(보유) 현황 -->
            <div class="infoWrap marginTop-30">
              <label>
                <span>물품비치(보유) 현황</span>
              </label>
              <table class="infoTable marginBottom-35 marginTop-15">
                <tbody>
                <tr>
                  <th colspan="3" style="text-align: center;">필수 및 권장 물품</th>
                  <th colspan="3" style="text-align: center;">기타 물품</th>
                </tr>
                <tr>
                  <th colspan="2">쇼파(의자)</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint03"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint03"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">물티슈</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint23"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint23"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">탁자</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint05"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint05"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">정수기</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint12"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint12"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">손 소독제</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint18"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint18"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">가습기</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint24"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint24"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">기저귀 교환대</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint07"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint07"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">제습기</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint25"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint25"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">가림막(파티션 등)</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint01"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint01"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">아기침대</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint09"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint09"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">기저귀 폐기물통</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint28"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint28"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">풋스툴</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint14"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint14"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">전자레인지</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint10"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint10"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">수유쿠션</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint16"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint16"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">세면대</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint11"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint11"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">보관함</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint15"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint15"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">온도계</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint17"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint17"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">휴지통</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint26"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint26"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">습도계</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint19"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint19"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">냉/난방기</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint04"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint04"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">유축기</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint21"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint21"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">거울</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint13"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint13"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">소독기</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint08"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint08"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">사용중/비어있음 표식</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint30"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint30"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">냉장고</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint06"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint06"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">움직임감지기</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint31"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint31"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">아기의자</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint29"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint29"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">관리점검표</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint22"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint22"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">환기설비<br/>(공기청정기 등)</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint02"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint02"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                  <th colspan="2">의견수렴카드</th>
                  <td>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint27"
                          value="1"
                      />
                      <span> 있음</span>
                    </label>
                    <label>
                      <input
                          type="radio"
                          class="radioBox"
                          v-model="nursingroomInfo.goodsPoint27"
                          value="0"
                      />
                      <span> 없음</span>
                    </label>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-bottom-wrap">
      <div class="buttonWrap" style="text-align:right;">
        <button class="radiusSmallBtn bgDark" style="width:100px;" @click="$emit('close-modal');">닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CommonFile from "@/components/common/CommonFile";

export default {
  name: "NursingroomLogPopup",
  props: {
    popupData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.returnPopupStyle();

    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        this.close();
      }
    });

    this.searchForm = this.popupData;
    this.searchForm.registYear = "";
  },
  mounted() {
    this.selectLog();
  },
  components: {
    CommonFile,
  },
  data() {
    return {
      drag: {
        isDrag: false,
        position: {
          top: "",
          left: "",
          cursor: "",
        },
      },

      selectedIndex: "",

      searchForm: {
        registYear: "",
      },

      nursingroomLogList: [],
      nursingroomLogYearList: [],
      nursingroomInfo: {},
    };
  },
  computed: {},
  methods: {
    close() {
      this.$emit('close-modal');
    },

    selectLog() {
      axios
          .post(this.config.contextRoot + "/nursingroom/selectNursingRoomLog.do", this.searchForm)
          .then((response) => {
            this.nursingroomLogList = response.data.nursingRoomLog;
            this.nursingroomLogYearList = [...new Set(this.nursingroomLogList.map(item => item.registYear))];
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },

    downloadLog() {
      axios
          .post(this.config.contextRoot + "/nursingroom/downloadLogExcel.do", this.searchForm, {responseType: 'blob'},)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.ms-excel'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '수유실 수정 내역.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch((error) => {
            this.exceptionHandler(this, error);
          });
    },

    changeLogNo() {
      if (typeof this.selectedIndex === "number") {
        this.nursingroomInfo = this.nursingroomLogList[this.selectedIndex];
      }

      this.$el.querySelectorAll(".infoWrap input").forEach(o => o.disabled = true);
      this.$el.querySelectorAll(".infoWrap select").forEach(o => o.disabled = true);
    },

    dragstart(event) {
      this.drag.offsetLeft = event.offsetX;
      this.drag.offsetTop = event.offsetY;
      this.drag.isDrag = true;
    },
    dragging(event) {
      var top = event.clientY - this.drag.offsetTop;
      var left = event.clientX - this.drag.offsetLeft;
      if (top > 0 && left > 0) {
        this.drag.position.top = top + "px";
        this.drag.position.left = left + "px";
        this.drag.position.cursor = "grab";
      }
    },
    draggend() {
      this.drag.isDrag = false;
      this.drag.position.cursor = "unset";
    },

    returnPopupStyle() {
      var widthValue = this.popupData.width == undefined ? "60%" : this.popupData.width + "%";
      var heightValue = this.popupData.height == undefined ? "80%" : this.popupData.height + "%";
      var topValue = this.popupData.top == undefined ? "calc(50% - " + heightValue.replace("%", "") / 2 + "%)" : this.popupData.top + "%";
      var leftValue = this.popupData.left == undefined ? "calc(50% - " + widthValue.replace("%", "") / 2 + "%)" : this.popupData.left + "%";

      this.drag.position.top = topValue;
      this.drag.position.left = leftValue;
    },
    getSize() {
      var returnMap = {};
      var widthValue = this.popupData.width == undefined ? "60%" : this.popupData.width + "%";
      var heightValue = this.popupData.height == undefined ? "80%" : this.popupData.height + "%";
      returnMap.width = widthValue;
      returnMap.height = heightValue;

      return returnMap;

    },
  },
  filters: {
    /* 년도 필터링 */
    yearFilter: function (list, selectedYear) {
      if (selectedYear === "") {
        return list;
      }

      let filterList = list.filter((item) => item.registYear === selectedYear);

      return filterList;
    },
  }
};
</script>

<style scoped>
.popup-container {
  position: fixed;
  /* transform: translate(-490px, -340px); */
  /* width: 980px; */
  /* height: 680px; */
  border: 2px solid #0980e0;
  box-shadow: 2px 2px 3px 0 #999;
  background: #fff;
  z-index: 100;
  overflow: auto;
  resize: both;
}

.popup-top-wrap {
  height: 60px;
  padding: 0 30px;
  background-color: #0980e0;
}

.popup-top-wrap span {
  display: inline-flex;
  align-items: center;
  width: calc(100% - 40px);
  height: 100%;
  font-weight: 600;
  font-size: 15px;
  color: #fff;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup-top-wrap button {
  float: right;
  width: 15px;
  height: 15px;
  margin: 22.5px 0;
  background-image: url(" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAABHNCSVQICAgIfAhkiAAAAKhJREFUOE+d1E0OQDAQBWDjSrYWbiBdSaycztYlnEu9ikq186NsGsm8LzqdIu/9SkRz8+NBtkNsoJDFywGorXGQGVG/IOcupBZKgZB9kK9QDhSIBXEAi0iQBIhIDmmAikQIq4unIJ3eq7F50f0Fm3X8IpJuwZojFuF6oEEFojVRgvJhe0ZZaiIHpWNvAtIViRfwM8BBZA2SdrPj1gLSYw72mt9AWov8dAJFKX/WFiewLQAAAABJRU5ErkJggg==");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
}

.popup-top-wrap button:hover,
.popup-top-wrap button:focus {
  width: 16px;
  height: 16px;
  background-size: 16px;
  margin: 22px 0;
}

.popup-center-wrap {
  position: absolute;
  top: 65px;
  left: 0;
  width: calc(100% - 2px);
  height: calc(100% - 160px);
  padding: 0 15px 0 20px;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}

.popup-bottom-wrap {
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 100%;
  height: 60px;
  /*border-top: 1px solid #d8dadd;*/
}

</style>
