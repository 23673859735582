<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">교육신청 관리</h2>
        </div>
        <div class="contentBody">
            <!-- 검색 -->
            <div class="searchBar">
                <div class="columnDivision-3">
                    <div class="labelWrap" style="margin-bottom:10px;">
                        <label>소속</label>
                        <input
                            type="text"
                            class="inputBox"
                            v-model="searchForm.applicantOrganization"
                        />
                    </div>
                    <div class="labelWrap">
                        <label style="width:200px">광역시/도 & 시/군/구</label>
                        <select
                            class="selectBox"
                            v-model="address"
                            style="width:100px; margin-right:10px;"
                            @change="selectComCodeList()"
                        >
                            <option value="">광역시/도</option>
                            <option :key="idx1" :value="adress1.comCode" v-for="(adress1, idx1) in addressList1">{{ adress1.codeName }}</option>
                        </select>
                        <select
                            class="selectBox"
                            v-model="addressDetail"
                            style="width:100px"
                        >
                            <option value="">시/군/구</option>
                            <option :key="idx2" :value="adress2.comCode" v-for="(adress2, idx2) in addressList2">{{ adress2.codeName }}</option>
                        </select>
                    </div>

                    <br/>

                    <div class="labelWrap" style="margin-bottom:10px;">
                        <label>이름</label>
                        <input
                            type="text"
                            class="inputBox"
                            v-model="searchForm.applicantUserName"
                        />
                    </div>
                    <div class="labelWrap" style="margin-left:25px;">
                        <label>교육명</label>
                        <select
                            class="selectBox"
                            v-model="searchForm.programNo"
                            style="width:300px"
                        >
                            <option value="">-- 전체 --</option>
                            <option :key="idx3" :value="program.programNo" v-for="(program, idx3) in programList">{{ program.programName }}</option>
                        </select>
                    </div>
                    

                    <div class="radioBoxWrap" style="margin-bottom:15px;  margin-left:54px;">
                        <label>상태</label>
                        <label>
                            <input
                                type="radio"
                                class="radioBox"
                                name="testRadio-8"
                                v-model="
                                    searchForm.applicantStateCode
                                "
                                value=""
                            />
                            <span>전체</span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                class="radioBox"
                                name="testRadio-8"
                                v-model="
                                    searchForm.applicantStateCode
                                "
                                value="0"
                            />
                            <span>접수</span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                class="radioBox"
                                name="testRadio-8"
                                v-model="
                                    searchForm.applicantStateCode
                                "
                                value="1"
                            />
                            <span>취소</span>
                        </label>
                    </div>

                    <br/>

                    <div class="labelWrap">
                        <label>등록일</label>
                        <div class="datePicker">
                            <input
                                type="text"
                                class="inputBox"
                                placeholder="연도-월-일"
                                id="startDate"
                                v-model="searchForm.startDate"
                                autocomplete="off"
                            />
                            <div
                                ref="startDatePicker"
                                id="startDatePicker"
                            ></div>
                            <i></i>
                        </div>
                    </div>
                    <div class="labelWrap">
                        <label class="textCenter" style="width: 30px">-</label>
                        <div class="datePicker">
                            <input
                                type="text"
                                class="inputBox"
                                placeholder="연도-월-일"
                                id="endDate"
                                v-model="searchForm.endDate"
                                autocomplete="off"
                            />
                            <i></i>
                            <div ref="endDatePicker" id="endDatePicker"></div>
                        </div>
                    </div>
                    
                </div>
                <button class="searchBtn" @click="doSearchPage(1)">
                    조회하기
                </button>
            </div>
            <div class="contentTitleWrap marginTop-35">
                <h3 class="contentTitle">교육신청 목록</h3>
            </div>
            <div class="listWrap">
                <!-- 목록 상단 -->
                <div class="listTop">
                    <span class="listTotal"
                        >(Total <em>{{ pagination.total }}</em
                        >)</span
                    >
                    <button
                        class="radiusSmallBtn bgBlue"
                        @click="downloadExcel()"
                        style="margin-left:10px; width:110px;"
                    >
                        Excel (목록전체)
                    </button>
                    <select
                        class="selectBox"
                        @change.prevent="doSearchPage(1)"
                        v-model="searchForm.countPerPage"
                    >
                        <option>5</option>
                        <option selected>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                </div>

                <!-- 목록테이블 -->
                <table class="listTable tableFixed">
                    <colgroup>
                        <col style="width: 2%" />
                        <col style="width: 7%" />
                        <col style="width: 25%" />
                        <col style="width: 15%" />
                        <col style="width: 20%" />
                        <col style="width: 5%" />
                        <col style="width: 7%" />
                        <col style="width: 10%" />
                        <col style="width: 6%" />
                        <col style="width: 8%" />
                        <col style="width: 6%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <label>
                                    <input
                                        type="checkbox"
                                        @click="checkAll"
                                        v-model="allChecked"
                                    />
                                </label>
                            </th>
                            <th>신청번호</th>
                            <th>교육명</th>
                            <th>소속</th>
                            <th>주소</th>
                            <th>이름</th>
                            <th>생년월일</th>
                            <th>사무실<br/>핸드폰</th>
                            <th>수집동의<br/>3자동의</th>
                            <th>등록일<br/>수정일</th>
                            <th>접수현황</th>
                        </tr>
                    </thead>
                    <tbody
                        v-for="applicant in applicantList"
                        :key="applicant.applicantNo"
                        @click="
                            $router.push({
                                path:
                                    config.contextRoot +
                                    '/site/' +
                                    $route.params.siteNo +
                                    '/applicant/' +
                                    applicant.applicantNo +
                                    '/edit',
                            })
                        "
                    >
                        <tr
                        >
                            <td>
                                <label
                                    ><input
                                        type="checkbox"
                                        v-model="deleteApplicantNoList"
                                        :value="applicant.applicantNo"
                                        @click.stop=""
                                    />
                                </label>
                            </td>
                            <td>{{ applicant.applicantNo }}</td>
                            <td class="textEllipsis">
                                {{ applicant.programName }}
                            </td>
                            <td>{{ applicant.applicantOrganization }}</td>
                            <td>{{ applicant.address1 }}{{ applicant.address2 ? ", " + applicant.address2 : ""}}</td>
                            <td>{{ applicant.applicantUserName }}</td>
                            <td>{{ applicant.applicantBirthDt }}</td>
                            <td>
                                {{ applicant.applicantTelNo }}
                                <br/>
                                {{ applicant.applicantHpNo }}
                            </td>
                            <td>
                                {{ 
                                applicant.privateAgreeYn == "1"
                                ? "O"
                                : "X"
                                }}
                                <br/>
                                {{ 
                                applicant.privateAgreeYn2 == "1"
                                ? "O"
                                : "X"
                                }}
                            </td>
                            <td>
                                {{ applicant.firstRegistDate }}
                                <br/>
                                {{ applicant.lastModifyDate }}
                            </td>
                            <td>
                                {{ 
                                applicant.applicantStateCode == "0"
                                ? "접수"
                                : "취소"
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- 페이지네이션-->
                <div
                    class="pagination marginTop-50"
                    v-if="pageNoList && pageNoList.length > 0"
                >
                    <ul>
                        <li>
                            <a
                                href="#"
                                @click.prevent="doSearchPage(1)"
                                title="첫 페이지"
                            >
                                <img
                                    src="@/assets/images/component/pagination-first.png"
                                    alt="첫 페이지"
                                />
                            </a>
                        </li>
                        <li v-show="isPrev">
                            <a
                                href="#"
                                @click.prevent="doSearchPage(startPageNo - 1)"
                                :title="'이전' + displayPageCount + '페이지'"
                            >
                                <img
                                    src="@/assets/images/component/pagination-prev.png"
                                    :alt="'이전' + displayPageCount + '페이지'"
                                />
                            </a>
                        </li>
                        <li v-for="pageNo in pageNoList" :key="pageNo">
                            <a
                                href="#"
                                :class="
                                    pagination.current_page == pageNo
                                        ? 'active'
                                        : ''
                                "
                                @click.prevent="doSearchPage(pageNo)"
                            >
                                {{ pageNo }}
                            </a>
                        </li>
                        <li v-show="isNext">
                            <a
                                href="#"
                                @click.prevent="doSearchPage(endPageNo + 1)"
                                :title="'다음' + displayPageCount + '페이지'"
                            >
                                <img
                                    src="@/assets/images/component/pagination-next.png"
                                    :alt="'다음' + displayPageCount + '페이지'"
                                />
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                @click.prevent="doSearchPage(realEndPageNo)"
                                title="마지막 페이지"
                            >
                                <img
                                    src="@/assets/images/component/pagination-last.png"
                                    alt="마지막 페이지"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgTuftsBlue"
                        @click.prevent="deleteApplicantList()"
                    >
                        선택 삭제
                    </button>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import axios from "axios";
import "tui-date-picker/dist/tui-date-picker.min.css";
import DatePicker from "tui-date-picker";

export default {
    name: "ApplicantList",
    components: {}, 
    data() {
        return {
            siteNo: "",
            applicantList: [],
            pagination: {},
            searchForm: {
                applicantOrganization : "",
                address1 : "",
                applicantUserName : "",
                privateAgreeYn : "",
                privateAgreeYn2 : "",
                applicantStateCode : "",
                programNo : "",
                startDate : "",
                endDate : "",
                countPerPage: 10,
                pageNo: 1,
            },

            address : "",
            addressDetail : "",

            displayPageCount: 10,
            isPrev: "",
            isNext: "",
            startPageNo: "",
            endPageNo: "",
            realEndPageNo: "",
            pageNoList: [],
            deleteApplicantNoList: [],
            allChecked: false,
            isShowRecruitmentUseYn: false,

            programList: [],
            addressList1: [],
            addressList2: [],

        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.selectApplicantList();
        this.selectProgramOptionList();
        this.selectComCodeList();
    },
    watch: {
        deleteApplicantNoList() {
            if (this.applicantList.length != this.deleteApplicantNoList.length) {
                this.allChecked = false;
            } else {
                this.allChecked = true;
            }
        },
    },
    mounted() {
        // 데이트피커
        var today = new Date();
        const rangePicker = new DatePicker.createRangePicker({
            startpicker: {
                date: today,
                input: "#startDate",
                container: "#startDatePicker",
            },
            endpicker: {
                date: today,
                input: "#endDate",
                container: "#endDatePicker",
            },
            language: "ko",
        });
        rangePicker.on("change:start", () => {
            this.searchForm.startDate =
                document.getElementById("startDate").value;
        });
        rangePicker.on("change:end", () => {
            this.searchForm.endDate = document.getElementById("endDate").value;
        });
    },
    methods: {
        selectApplicantList() {
            this.logger.debug(this, "selectApplicantList()");
            this.searchForm.siteNo = this.siteNo;

            this.searchForm.address1 = this.address;
            if(this.searchForm.address1){
                this.searchForm.address1 = this.searchForm.address1 + " " + this.addressDetail;
            }
            axios
                .post(
                    this.config.contextRoot + "/applicant/selectApplicantList.do",
                    this.searchForm
                )
                .then((response) => {
                    this.applicantList = response.data.applicantList;
                    this.pagination = response.data.pagination;
                    this.calcPagination();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        selectProgramOptionList() {
            this.logger.debug(this, "selectProgramOptionList()");
            axios
                .post(
                    this.config.contextRoot + "/program/selectProgramOptionList.do"
                )
                .then((response) => {
                    this.programList = response.data.programList;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        selectComCodeList() {
            this.logger.debug(this, "selectComCodeList()");
            this.searchForm.siteNo = this.siteNo;
            axios
                .post(
                    this.config.contextRoot + "/common/code/selectComCodeList.do",
                    {classCode : this.address ? this.address : "LOCATION_CODE"}
                )
                .then((response) => {
                    if(!this.address){
                        this.addressList1 = response.data.codeList;
                        this.addressList2 = [];
                        this.addressDetail = "";
                    }else{
                        this.addressList2 = response.data.codeList;
                        this.addressDetail = "";
                    }
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        doSearchPage(pageNo) {
            this.logger.debug(this, "doSearchPage()");
            if (typeof pageNo === "undefined") {
                pageNo = 1;
            }
            this.deleteApplicantNoList = [];
            this.searchForm.pageNo = pageNo;
            this.selectApplicantList();
        },
        calcPagination() {
            this.logger.debug(this, "calcPagination()");
            this.endPageNo =
                Math.ceil(
                    this.pagination.current_page / this.displayPageCount
                ) * this.displayPageCount;
            this.startPageNo = this.endPageNo - this.displayPageCount + 1;

            this.realEndPageNo = Math.ceil(
                this.pagination.total / this.pagination.per_page
            );

            if (this.startPageNo < 0) {
                this.startPageNo = 1;
            }
            if (this.endPageNo > this.realEndPageNo) {
                this.endPageNo = this.realEndPageNo;
            }

            this.isPrev = this.startPageNo == 1 ? false : true;
            this.isNext =
                this.endPageNo * this.pagination.per_page >=
                this.pagination.total
                    ? false
                    : true;

            this.pageNoList = [];
            for (let i = this.startPageNo; i <= this.endPageNo; i++) {
                this.pageNoList.push(i);
            }
        },
        checkAll() {
            this.deleteApplicantNoList = [];

            if (!this.allChecked) {
                for (let applicant of this.applicantList) {
                    this.deleteApplicantNoList.push(applicant.applicantNo);
                }
            }

            this.allChecked = !this.allChecked;
        },
        deleteApplicantList() {
            if (this.deleteApplicantNoList.length == 0) {
                alert("삭제항목이 선택되지 않았습니다.");
                return;
            }

            if (!confirm("선택한 항목을 삭제 하시겠습니까?")) {
                return;
            }
            
            const formData = { deleteApplicantNoList: this.deleteApplicantNoList};

            axios
                .post(
                    this.config.contextRoot + "/applicant/deleteApplicantList.do",
                    formData
                )
                .then((response) => {
                    if (response.status == 200) {
                        // 마지막 페이지에서 전체 선택 삭제할 경우
                        if (
                            this.startPageNo != this.realEndPageNo &&
                            this.searchForm.pageNo == this.realEndPageNo &&
                            this.deleteApplicantNoList.length ==
                                this.applicantList.length
                        ) {
                            this.$set(
                                this.searchForm,
                                "pageNo",
                                Number(this.searchForm.pageNo) - 1 + ""
                            );
                        }

                        alert("삭제 되었습니다.");
                        this.deleteApplicantNoList = [];
                        this.doSearchPage(this.searchForm.pageNo);
                    }
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        openImageApplicant(selectApplicant){
            
            let html = "";

            let url = this.config.contextRoot + "/common/file/imgLoad.do?"
                    + "fileGroupNo=" + selectApplicant.fileGroupNo
                    + "&serverFileSaveName=" + selectApplicant.serverFileSaveName;
            html = "<img src=" + url + ">";

            if(selectApplicant.applicantContents) html += "<br><div>" + selectApplicant.applicantContents + "</div>";

            let specs = 'menubar=no';
            if(selectApplicant.applicantPositionX) specs += ", left=" + selectApplicant.applicantPositionX;
            if(selectApplicant.applicantPositionY) specs += ", top=" + selectApplicant.applicantPositionY;
            if(selectApplicant.applicantSizeX) specs += ", width=" + selectApplicant.applicantSizeX;
            if(selectApplicant.applicantSizeY) specs += ", height=" + selectApplicant.applicantSizeY;

            var OpenWindow = window.open("", "_blank", specs);
            OpenWindow.document.write(html);
        },
        yyyyMMdd(value){
            // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
            if(value == '') return '';

            // 연도, 월, 일 추출
            var year = value.substring(0, 4);
            var month = value.substring(4, 6);
            var day = value.substring(6, 8);

            // 최종 포맷 (ex - '2021-10-08')
            return year + '-' + month + '-' + day;
        },
        downloadExcel(){
            axios
                .post(
                    this.config.contextRoot + "/applicant/downloadExcel.do",
                    this.searchForm,
                    { responseType: 'blob'},
                )
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '수유실 교육신청 목록.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        
    },
};
</script>

<style>
</style>