<template>
	<div class="contentLayout">
		<div class="contentNavigationBar">
			<h2 class="title">배너 관리</h2>
		</div>
		<div class="contentBody">
			<h3 class="contentTitle">배너 등록</h3>

			<!-- 입력 폼 -->
			<div class="infoWrap">
				<p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
				<table class="infoTable marginBottom-35">
					<tbody>
						<tr>
							<th class="requiredMark">배너제목</th>
							<td>
								<input
									type="text"
									class="inputBox"
									v-model="bannerInfo.bannerName"
									v-validate="'required'"
									data-vv-name="bannerName"
									data-vv-as="배너제목 "
								/>
								<p class="validation-text" v-if="errors.has('bannerName')">
									* {{ errors.first("bannerName") }}
								</p>
							</td>
						</tr>
						<tr>
							<th>배너링크주소</th>
							<td>
								<input
									type="text"
									class="inputBox"
									v-model="bannerInfo.bannerLinkAddress"
									data-vv-name="bannerLinkAddress"
									data-vv-as="배너링크주소 "
									placeholder="http://www.domain.com"
								/>
								<p class="validation-text" v-if="errors.has('bannerLinkAddress')">
									* {{ errors.first("bannerLinkAddress") }}
								</p>
							</td>
						</tr>
						<tr>
							<th class="requiredMark">사용 여부</th>
							<td>
								<div class="radioBoxWrap">
									<label>
										<input type="radio" class="radioBox" v-model="bannerInfo.useYn" value="Y" />
										<span>사용</span>
									</label>
									<label>
										<input type="radio" class="radioBox" v-model="bannerInfo.useYn" value="N" />
										<span>미사용</span>
									</label>
								</div>
							</td>
						</tr>
						<tr>
							<th>파일선택</th>
							<td>
								<common-file
									ref="FileUploader"
									v-model="bannerInfo.fileList"
									fileMaxSize="10"
									:multipleUpload="false"
									style="max-height: 50px"
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- 하단 버튼 바 -->
			<div class="contentButtonBar">
				<div class="buttonWrap">
					<button class="radiusSmallBtn bgNavy" @click="insertBanner()">저장</button>
					<button class="radiusSmallBtn bgDark" @click="cancel()">취소</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import DatePicker from "tui-date-picker";
import "tui-date-picker/dist/tui-date-picker.min.css";
import CommonFile from "@/components/common/CommonFile";
import EventBus from "../../script/EventBus";

export default {
	name: "BannerCreate",
	data() {
		return {
			siteNo: "",
			bannerInfo: {
				bannerName: "",
				bannerLinkAddress: "",
				useYn: "Y",
				fileGroupNo: "1",
				fileList: [],
			},
		};
	},
	created() {
		this.siteNo = this.$route.params.siteNo;
		EventBus.$on("addFile", (fileList) => {
			this.logger.debug(this, "eventBus / addFile()");
			this.addFile(fileList);
		});
	},
	components: {
		CommonFile,
	},
	methods: {
		picker() {
			var today = new Date();
			const rangePicker = new DatePicker.createRangePicker({
				startpicker: {
					date: today,
					input: "#startDate",
					container: "#startDatePicker",
				},
				endpicker: {
					date: today,
					input: "#endDate",
					container: "#endDatePicker",
				},
				language: "ko",
			});
			rangePicker.on("change:start", () => {
				this.bannerInfo.recruitmentFirstRegistDate = document.getElementById("startDate").value;
			});
			rangePicker.on("change:end", () => {
				this.bannerInfo.recruitmentLastRegistDate = document.getElementById("endDate").value;
			});
		},
		insertBanner() {
			this.logger.debug(this, "insertBanner()");

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "valid:" + result);
					if (!result) {
						alert("정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요.");
						return;
					} else {
						if (!confirm("등록 하시겠습니까?")) {
							return;
						}
						axios
							.post(this.config.contextRoot + "/banner/insertBanner.do", this.bannerInfo)
							.then((response) => {
								if (response.status == 200) {
									alert("등록 되었습니다.");
									console.log(
										this.config.contextRoot +
											"/site/" +
											this.siteNo +
											"/banner/" +
											response.data.bannerNo +
											"/edit"
									);
									this.$router.replace(
										this.config.contextRoot +
											"/site/" +
											this.siteNo +
											"/banner/" +
											response.data.bannerNo +
											"/edit"
									);
								}
							})
							.catch((error) => {
								this.exceptionHandler(this, error);
							});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.error.all());
					this.$alert(this.error.all());
					return false;
				});
		},

		openUploader(refName) {
			this.logger.debug(this, "openUploader()");
			this.$refs[refName].openUploader();
		},
		doDownload(data) {
			this.logger.debug(this, "doDownload");
			const formData = new FormData();
			formData.append("fileNo", data.fileNo);
			formData.append("fileName", data.fileName);
			formData.append("serverFileSaveName", data.serverFileSaveName);
			axios
				.post(this.config.contextRoot + "/common/file/download.do", formData, { responseType: "blob" })
				.then((response) => {
					console.log(response);
					const url = window.URL.createObjectURL(
						new Blob([response.data], {
							type: response.headers["content-type"],
						})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", data.fileName);
					document.body.appendChild(link);
					link.click();
					location.reload();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		deleteFile(index) {
			this.bannerInfo.fileList.splice(index, 1);
		},
		addFile(fileList) {
			this.bannerInfo.fileList = this.bannerInfo.fileList.concat(fileList);
		},
		cancel() {
			if (confirm("취소 하시겠습니까?")) {
				this.$router.go(-1);
			}
		},
	},
};
</script>

<style></style>
