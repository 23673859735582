<template>
	<div class="contentLayout">
		<div class="contentNavigationBar">
			<h2 class="title">배너 관리</h2>
		</div>
		<div class="contentBody">
			<h3 class="contentTitle">배너 수정</h3>

			<!-- 입력 폼 -->
			<div class="infoWrap">
				<p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
				<table class="infoTable marginBottom-35">
					<tbody>
						<tr>
							<th class="requiredMark">배너제목</th>
							<td>
								<input
									type="text"
									class="inputBox"
									v-model="bannerInfo.bannerName"
									v-validate="'required'"
									data-vv-name="bannerName"
									data-vv-as="배너제목 "
								/>
								<p class="validation-text" v-if="errors.has('bannerName')">
									* {{ errors.first("bannerName") }}
								</p>
							</td>
						</tr>
						<tr>
							<th>배너링크주소</th>
							<td>
								<input
									type="text"
									class="inputBox"
									v-model="bannerInfo.bannerLinkAddress"
									data-vv-name="bannerLinkAddress"
									data-vv-as="배너링크주소 "
								/>
								<p class="validation-text" v-if="errors.has('bannerLinkAddress')">
									* {{ errors.first("bannerLinkAddress") }}
								</p>
							</td>
						</tr>
						<tr>
							<th class="requiredMark">사용 여부</th>
							<td>
								<div class="radioBoxWrap">
									<label>
										<input type="radio" class="radioBox" v-model="bannerInfo.useYn" value="Y" />
										<span>사용</span>
									</label>
									<label>
										<input type="radio" class="radioBox" v-model="bannerInfo.useYn" value="N" />
										<span>미사용</span>
									</label>
								</div>
							</td>
						</tr>
						<tr>
							<th>파일선택</th>
							<td>
								<common-file
									ref="FileUploader"
									v-model="bannerInfo.fileList"
									fileMaxSize="10"
									:multipleUpload="false"
									style="max-height: 50px"
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- 하단 버튼 바 -->
			<div class="contentButtonBar">
				<div class="buttonWrap floatLeft">
					<router-link
						:to="{
							path: config.contextRoot + '/site/' + $route.params.siteNo + '/banner',
						}"
						class="radiusSmallBtn bgGray"
					>
						목록
					</router-link>
					<router-link
						:to="{
							path: config.contextRoot + '/site/' + $route.params.siteNo + '/banner/create',
						}"
						class="radiusSmallBtn bgGray"
					>
						신규
					</router-link>
				</div>
				<div class="buttonWrap floatRight">
					<button class="radiusSmallBtn bgNavy" @click="updateBanner()">저장</button>
					<button class="radiusSmallBtn bgDark" @click="deleteBanner()">삭제</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import DatePicker from "tui-date-picker";
import "tui-date-picker/dist/tui-date-picker.min.css";
import CommonFile from "@/components/common/CommonFile";
import EventBus from "../../script/EventBus";

export default {
	name: "BannerEdit",
	data() {
		return {
			siteNo: "",
			bannerNo: "",
			bannerInfo: {
				bannerName: "",
				bannerLinkAddress: "",
				useYn: "",
			},
		};
	},
	components: {
		CommonFile,
	},
	created() {
		this.siteNo = this.$route.params.siteNo;
		this.bannerNo = this.$route.params.bannerNo;
		EventBus.$on("addFile", (fileList) => {
			this.logger.debug(this, "eventBus / addFile()");
			this.addFile(fileList);
		});

		this.selectBannerInfo();
	},
	watch: {},
	methods: {
		picker() {
			var today = new Date();
			const rangePicker = new DatePicker.createRangePicker({
				startpicker: {
					date: today,
					input: "#startDate",
					container: "#startDatePicker",
				},
				endpicker: {
					date: today,
					input: "#endDate",
					container: "#endDatePicker",
				},
				language: "ko",
			});
			rangePicker.on("change:start", () => {
				this.bannerInfo.recruitmentFirstRegistDate = document.getElementById("startDate").value;
			});
			rangePicker.on("change:end", () => {
				this.bannerInfo.recruitmentLastRegistDate = document.getElementById("endDate").value;
			});
		},
		selectBannerInfo() {
			this.logger.debug(this, "selectBanner()");
			var searchData = { bannerNo: this.bannerNo };
			axios
				.post(this.config.contextRoot + "/banner/selectBanner.do", searchData)
				.then((response) => {
					this.bannerInfo = response.data.bannerInfo;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		updateBanner() {
			this.logger.debug(this, "insertBanner()");

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "valid:" + result);
					if (!result) {
						alert("정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요.");
						return;
					} else {
						if (this.bannerInfo.fileList.length > 1) {
							alert("파일은 1개만 선택할 수 있습니다");
							return;
						}

						if (!confirm("수정 하시겠습니까?")) {
							return;
						}
						axios
							.post(this.config.contextRoot + "/banner/updateBanner.do", this.bannerInfo)
							.then((response) => {
								alert("수정 되었습니다.");
								this.bannerNo = response.data.bannerNo;
								this.selectBannerInfo();
							})
							.catch((error) => {
								this.exceptionHandler(this, error);
							});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.error.all());
					this.$alert(this.error.all());
					return false;
				});
		},
		deleteBanner() {
			if (!confirm("삭제 하시겠습니까?")) {
				return;
			}

			const formData = {
				deleteBannerNoList: [this.bannerNo],
			};
			axios
				.post(this.config.contextRoot + "/banner/deleteBannerList.do", formData)
				.then((response) => {
					if (response.status == 200) {
						alert("삭제 되었습니다.");
						this.$router.replace(this.config.contextRoot + "/site/" + this.siteNo + "/banner");
					}
				})
				.catch((error) => {
					if (error.response.data.message == "SQLIntegrityConstraintViolationException") {
						alert("해당 컨텐츠는 메뉴관리에서 사용 중이므로 삭제할 수 없습니다.");
						return;
					}

					this.exceptionHandler(this, error);
				});
		},

		openUploader(refName) {
			this.logger.debug(this, "openUploader()");
			this.$refs[refName].openUploader();
		},
		doDownload(data) {
			this.logger.debug(this, "doDownload");
			const formData = new FormData();
			formData.append("fileNo", data.fileNo);
			formData.append("fileName", data.fileName);
			formData.append("serverFileSaveName", data.serverFileSaveName);
			axios
				.post(this.config.contextRoot + "/common/file/download.do", formData, { responseType: "blob" })
				.then((response) => {
					console.log(response);
					const url = window.URL.createObjectURL(
						new Blob([response.data], {
							type: response.headers["content-type"],
						})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", data.fileName);
					document.body.appendChild(link);
					link.click();
					location.reload();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		deleteFile(index) {
			this.bannerInfo.fileList.splice(index, 1);
		},
		addFile(fileList) {
			this.bannerInfo.fileList = this.bannerInfo.fileList.concat(fileList);
		},
	},
};
</script>

<style></style>
